import React, { useContext, useEffect, useState } from "react";
import { config } from "~/config";
import { useApi } from "~/hooks/useApi";
import { useAppMetadataForCsm } from "~/hooks/useAppMetadataForCsm";
import { useImpersonationStatus } from "~/hooks/useImpersonationStatus";
import { useIntercomForFigures } from "~/hooks/useIntercomForFigures";
import { setSegmentUser } from "~/lib/external/segment/web/client";
import { UNAVAILABLE_REQUIRED_USER_ERROR_MESSAGE } from "~/lib/requiredUserMessage";
import { type UpdateUserFlagInput, type UpdateUserFlagResponse } from "~/pages/api/update-user-flag";
import { type UserResponse } from "~/pages/api/user";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { type ImpersonationStatus } from "~/services/impersonationStatus";

type SessionProps = {
  isLoadingUser: boolean;
  user: NullableAuthenticatedUser;
  impersonatingCompany: boolean;
  impersonatingUser: boolean;
  isUpdatingFlag: boolean;

  signIn: (provider: "google" | "microsoft", options?: SignInOptions) => Promise<void>;
  signOut: () => Promise<void>;
  updateFlag: (input: Partial<UpdateUserFlagInput>, successMessage?: string) => Promise<void>;
  getRequiredUser: () => RequiredAuthenticatedUser;
};

const noop = async () => {
  //
};

const SessionContext = React.createContext<SessionProps>({
  isLoadingUser: false,
  user: null,
  impersonatingCompany: false,
  impersonatingUser: false,
  isUpdatingFlag: false,

  signOut: noop,
  signIn: noop,
  updateFlag: noop,
  getRequiredUser: () => undefined as unknown as RequiredAuthenticatedUser,
});

export const useSession = (): SessionProps => {
  return useContext(SessionContext);
};

type Props = React.PropsWithChildren<{
  user: NullableAuthenticatedUser;
  userHash: string | null;
  hasRenderedUser: boolean;
  impersonationStatus: ImpersonationStatus | undefined;
}>;

type SignInOptions = {
  redirect?: string;
};

export const SessionProvider: React.FC<Props> = ({
  user: initialUser,
  userHash: initialUserHash,
  children,
  hasRenderedUser,
  impersonationStatus: initialImpersonatingStatus,
}) => {
  const [user, setUser] = useState<NullableAuthenticatedUser>(initialUser);
  const [userHash, setUserHash] = useState<string | null>(initialUserHash);
  const [isLoadingUser, setIsLoadingUser] = useState(!hasRenderedUser);
  const [isUpdatingFlag, setIsUpdatingFlag] = useState(false);
  const { apiFetch } = useApi();
  const intercom = useIntercomForFigures();
  const { impersonatingUser, impersonatingCompany } = useImpersonationStatus({
    user,
    initialImpersonatingStatus,
  });

  useEffect(() => {
    if (hasRenderedUser || !!user) {
      return;
    }

    void authenticate();
  }, [hasRenderedUser]);

  useEffect(() => {
    if (initialUser !== user) {
      setUser(initialUser);
    }

    if (initialUserHash !== userHash) {
      setUserHash(initialUserHash);
    }
  }, [initialUser, initialUserHash]);

  const authenticate = async () => {
    const json = await apiFetch<UserResponse>("/api/user", { method: "GET", setIsLoading: setIsLoadingUser });

    setUser(json.user);
    setUserHash(json.userHash);
  };

  const signIn = async (provider: "google" | "microsoft", options?: SignInOptions) => {
    const redirect = options?.redirect;

    location.href = `${config.app.url}/api/auth/${provider}/redirect${redirect ? `?redirect=${redirect}` : ""}`;
  };

  const signOut = async () => {
    await apiFetch("/api/auth/sign-out");
  };

  const getRequiredUser = () => {
    if (user) {
      return user;
    }

    throw new Error(UNAVAILABLE_REQUIRED_USER_ERROR_MESSAGE);
  };

  const updateFlag = async (input: Partial<UpdateUserFlagInput>, successMessage?: string) => {
    if (!user) {
      return;
    }

    const res = await apiFetch<UpdateUserFlagResponse>("/api/update-user-flag", {
      body: input,
      setIsLoading: setIsUpdatingFlag,
      ...(successMessage && { successMessage }),
    });

    setUser({
      ...user,
      flags: res.flags,
    });
  };

  const metadata = useAppMetadataForCsm({ user });

  useEffect(() => {
    void setSegmentUser(user, { impersonatingCompany, metadata });

    if (user) {
      intercom.update({
        userId: `${user.id}`,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        avatar: { type: "avatar", imageUrl: user.profilePictureUrl },
        company: {
          companyId: `${user.companyId}`,
          name: user.company.name,
          website: user.company.url,
        },
      });
    }
  }, [user, metadata]);

  useEffect(() => {
    if (userHash) {
      intercom.update({ userHash });
    }
  }, [userHash]);

  return (
    <SessionContext.Provider
      value={{
        isLoadingUser,
        user,
        impersonatingCompany,
        impersonatingUser,
        signIn,
        signOut,
        updateFlag,
        isUpdatingFlag,
        getRequiredUser,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

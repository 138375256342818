import { HrisSyncStatus, QueueJobName, QueueJobStatus } from "@prisma/client";
import { map } from "bluebird";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { notifyHrisSyncFailedDueToError } from "~/lib/external/slack/notifications";
import { logError, logInfo } from "~/lib/logger";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { fillJobCache } from "~/lib/queue/queueJobCache";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { fetchExternalEmployeesFromHRIS } from "~/services/synchronization/fetchExternalEmployeesFromHris";
import { updateIntegrationCounters } from "~/services/synchronization/postSyncExternalEmployees";
import {
  EmployeeDataSchema,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/syncExternalEmployees";
import { PostSyncExternalEmployeesDeletesReportDataSchema } from "~/workers/postSyncExternalEmployees";
import { sendSyncExternalEmployeesJob } from "~/workers/syncExternalEmployees";

const PreSyncExternalEmployeesJobDataSchemaWithCampaign = BaseJobDataSchema.concat(
  object({ hrisSyncCampaignId: number().nullable() })
);
const PreSyncExternalEmployeesJobDataSchemaWithSync = BaseJobDataSchema.concat(
  object({ hrisSyncId: number().required() })
);

export type PreSyncExternalEmployeesJobDataWithCampaign = YupOutputType<
  typeof PreSyncExternalEmployeesJobDataSchemaWithCampaign
>;
export type PreSyncExternalEmployeesJobDataWithSync = YupOutputType<
  typeof PreSyncExternalEmployeesJobDataSchemaWithSync
>;

export const preSyncExternalEmployeesWorkerService = async (
  ctx: AppContext,
  data: PreSyncExternalEmployeesJobDataWithSync
) => {
  const { companyId, hrisSyncId } = PreSyncExternalEmployeesJobDataSchemaWithSync.validateSync(data, {
    abortEarly: false,
  });

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: {
      integrationSettings: {
        where: { enabled: true },
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  const hrisSync = await ctx.prisma.hrisSync.findUniqueOrThrow({
    where: { id: hrisSyncId },
    include: { campaign: true },
  });

  logInfo(ctx, "[pre-sync-external-employees] Starting HRIS Sync", {
    companyId: company.id,
    hrisSyncId: hrisSync.id,
    activeIntegrations: company.integrationSettings.map((integration) => integration.source),
    ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
  });

  try {
    let totalEmployeeCount = 0;

    await map(
      company.integrationSettings,
      async (integrationSettings) => {
        await cancelPreviousJobCacheData(ctx, { companyId: company.id });

        const { deleted, mappedEmployees } = await fetchExternalEmployeesFromHRIS(ctx, company, integrationSettings);

        logInfo(ctx, "[pre-sync-external-employees] Fetched external employees", {
          deleted,
          companyId: company.id,
          hrisSyncId: hrisSync.id,
          source: integrationSettings.source,
          employees: mappedEmployees.length,
          ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
        });

        if (mappedEmployees.length === 0) {
          logInfo(ctx, "[pre-sync-external-employees] No external employees to fetch", {
            companyId: company.id,
            hrisSyncId: hrisSync.id,
            source: integrationSettings.source,
            ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
          });

          await updateIntegrationCounters(ctx, {
            company,
            deletes: [{ source: integrationSettings.source, deleted }],
            updates: [],
          });

          return;
        }

        totalEmployeeCount += mappedEmployees.length;

        await fillJobCache(ctx, {
          companyId: company.id,
          queueJobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
          data: mappedEmployees as YupOutputType<typeof EmployeeDataSchema>[],
          validationSchema: EmployeeDataSchema,
        });

        await fillJobCache(ctx, {
          companyId: company.id,
          queueJobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
          data: { deleted, source: integrationSettings.source },
          validationSchema: PostSyncExternalEmployeesDeletesReportDataSchema,
        });
      },
      { concurrency: 5 }
    );

    logInfo(ctx, "[pre-sync-external-employees] Done fetching external employees", {
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    if (totalEmployeeCount > 0) {
      await ctx.prisma.hrisSync.update({
        where: { id: hrisSync.id },
        data: {
          status: HrisSyncStatus.IN_PROGRESS,
          totalEmployeeCount,
        },
      });

      await sendSyncExternalEmployeesJob(ctx, {
        companyId: company.id,
        hrisSyncId: hrisSync.id,
      });
      return;
    }

    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: { status: HrisSyncStatus.SUCCESS },
    });
  } catch (error) {
    logError(ctx, "[pre-sync-external-employees] Failed to fetch employees", {
      error,
      companyId: company.id,
      hrisSyncId: hrisSync.id,
      ...(hrisSync.campaignId && { hrisSyncCampaignId: hrisSync.campaignId }),
    });

    await notifyHrisSyncFailedDueToError(ctx, {
      error,
      company,
      hrisSyncId: hrisSync.id,
      hrisSyncCampaignId: hrisSync.campaignId,
      campaignMessageTimestamp: hrisSync.campaign?.messageTimestamp,
    });

    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: { status: HrisSyncStatus.FAILURE },
    });

    throw error;
  }
};

export const sendPreSyncExternalEmployeesJob = async (
  ctx: AppContext,
  data: PreSyncExternalEmployeesJobDataWithCampaign
) => {
  const hrisSync = await ctx.prisma.hrisSync.create({
    data: {
      status: HrisSyncStatus.CREATED,
      totalEmployeeCount: 0,
      processedEmployeeCount: 0,
      company: { connect: { id: data.companyId } },
      ...(data.hrisSyncCampaignId && { campaign: { connect: { id: data.hrisSyncCampaignId } } }),
    },
  });

  const job = await sendJob(ctx, {
    jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    data: { ...data, hrisSyncId: hrisSync.id },
    options: { singletonKey: makeSingletonKey({ companyId: data.companyId }, { unique: true }) },
  });

  if (job) {
    await ctx.prisma.hrisSync.update({
      where: { id: hrisSync.id },
      data: {
        queueJobIds: { push: job.id },
      },
    });
  }
};

const cancelPreviousJobCacheData = async (ctx: AppContext, params: { companyId: number }) => {
  return Promise.all([
    ctx.prisma.queueJobCache.deleteMany({
      where: {
        metadata: { path: ["companyId"], equals: params.companyId },
        jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
        isBeingProcessed: true,
      },
    }),

    ctx.prisma.queueJob.updateMany({
      where: {
        name: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
        singletonKey: { startsWith: `${params.companyId}-`, mode: "insensitive" },
        status: { in: [QueueJobStatus.SCHEDULED, QueueJobStatus.IN_PROGRESS] },
      },
      data: {
        status: QueueJobStatus.ABORTED,
      },
    }),
  ]);
};

import { type SpreadsheetImportRowStatus } from "@prisma/client";
import type { AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";

export const getImportRowsCount = async (
  ctx: AppContext,
  params: { importId: number; statuses?: SpreadsheetImportRowStatus[] }
) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployeeSpreadsheetImportRow.count({
    where: {
      companyId: user.companyId,
      importId: params.importId,
      ...(params.statuses && { status: { in: params.statuses } }),
    },
  });
};

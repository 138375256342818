import { UserRole } from "@prisma/client";
import { ApiImpersonationForbiddenError } from "~/lib/errors/apiImpersonationForbiddenError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { SessionKey, stopCompanyImpersonation } from "~/lib/session";
import { getId, isIn } from "~/lib/utils";
import { AuthenticatedUserIncludes } from "~/services/auth/authenticatedUserIncludes";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const resolveSubsidiaryImpersonation = async (
  ctx: MiddlewareContext<{
    user: RequiredAuthenticatedUser;
    impersonatingUser: NullableAuthenticatedUser;
  }>
) => {
  const subsidiaryId = ctx.req.session.get<string>(SessionKey.IMPERSONATED_SUBSIDIARY_ID);

  if (!subsidiaryId) {
    return next(ctx);
  }

  const subsidiaryIdAsInt = parseInt(subsidiaryId);

  const subsidiary = await ctx.req.prisma.company.findUniqueOrThrow({
    where: { id: subsidiaryIdAsInt },
    select: { masterUsers: { select: { id: true } } },
  });

  const hasSubsidiaryAccess = isIn(ctx.impersonatingUser?.id ?? ctx.user.id, subsidiary.masterUsers.map(getId));

  if (hasSubsidiaryAccess) {
    return next({
      ...ctx,
      user: {
        ...ctx.user,
        permissions: { ...ctx.user.permissions, role: UserRole.ADMIN },
        companyId: subsidiaryIdAsInt,
        company: await ctx.req.prisma.company.findUniqueOrThrow({
          where: { id: subsidiaryIdAsInt },
          include: AuthenticatedUserIncludes["company"]["include"],
        }),
      },
    });
  }

  await stopCompanyImpersonation(ctx.req);

  throw new ApiImpersonationForbiddenError("Subsidiary impersonation not allowed", subsidiaryId);
};

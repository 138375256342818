import { SalaryGridMeasure, type SalaryRangeEmployee } from "@prisma/client";
import { match } from "ts-pattern";
import { percentageDifferenceToTarget, roundTo } from "~/lib/math";

export const computeCompaRatio = (params: { amount: number; midpoint: number }) => {
  return roundTo(1 + percentageDifferenceToTarget(params.amount, params.midpoint), 0.01);
};

export const getCompaRatioForMeasure = (
  salaryRangeEmployee: Pick<SalaryRangeEmployee, "baseSalaryCompaRatio" | "onTargetEarningsCompaRatio">,
  measure: SalaryGridMeasure
) =>
  match(measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => salaryRangeEmployee.baseSalaryCompaRatio)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => salaryRangeEmployee.onTargetEarningsCompaRatio)
    .exhaustive();

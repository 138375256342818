import { type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { getId } from "~/lib/utils";
import { buildSalaryRangeWhereClause } from "~/services/salary-bands/helpers/salaryGridFilters";
import { updateSalaryRangesMarketData } from "~/services/salary-bands/update/updateSalaryRangesMarketData";
import { type BulkUpdateSalaryBandMeasuresInput } from "~/workers/updateSalaryRangeSettings";
export const updateSalaryBandMeasures = async (ctx: AppContext, input: BulkUpdateSalaryBandMeasuresInput) => {
  const user = getRequiredUser(ctx);
  const filtersWhereClause = buildSalaryRangeWhereClause(ctx, { salaryGridId: input.salaryGridId }, input.filters);
  const bands = await ctx.prisma.salaryBand.findMany({
    where: {
      ranges: { some: { ...filtersWhereClause, ...(!input.allBands && { id: { in: input.salaryRangeIds } }) } },
    },
    select: bandSelect,
  });

  await mapSeries(bands, async (band) => {
    if (band.measure === input.measure) return;

    await ctx.prisma.salaryBand.update({ where: { id: band.id }, data: { measure: input.measure } });

    await updateSalaryRangesMarketData(ctx, {
      companyId: user.company.id,
      salaryBandId: band.id,
      salaryGridId: band.gridId,
      salaryRangeIds: band.ranges.map(getId),
    });
  });
};

const bandSelect = {
  gridId: true,
  id: true,
  measure: true,
  ranges: { select: { id: true } },
} satisfies Prisma.SalaryBandSelect;

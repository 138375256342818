import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { chain } from "~/lib/lodash";
import { compileMatchedJobs } from "~/services/company-dashboard/compileMatchedJobs";
import { computeBucketsCompensationStats } from "~/services/company-dashboard/computeBucketsCompensationStats";
import { type EmployeesBucket } from "~/services/company-dashboard/generateEmployeesBuckets";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";

export const computeCompensationStatsByJobFamilyCategory = async (
  ctx: AppContext,
  params: {
    company: CompanyWithCompensationSettings;
    buckets: EmployeesBucket[];
  }
) => {
  const { company, buckets } = params;

  const groupedBuckets = chain(buckets)
    .groupBy(({ job }) => job.family.category)
    .values()

    .value();

  return mapSeries(groupedBuckets, async (buckets) => {
    const { job } = buckets[0] as EmployeesBucket;

    return {
      jobFamilyCategory: job.family.category,
      stats: await computeBucketsCompensationStats(ctx, { company, buckets }),
      matches: compileMatchedJobs({ buckets }),
    };
  });
};

import type { NextApiResponse } from "next";

export class AuthenticationRequiresProviderConfirmationError extends Error {
  constructor(
    public email: string,
    public provider: string
  ) {
    super("Authentication requires provider confirmation");
    Object.setPrototypeOf(this, AuthenticationRequiresProviderConfirmationError.prototype);
  }
}

export const handleAuthenticationRequiresProviderConfirmationError = (
  res: NextApiResponse,
  error: AuthenticationRequiresProviderConfirmationError
) => {
  return res.redirect(
    `/sign-in?authentication-requires-provider-confirmation=true&email=${error.email}&provider=${error.provider}`
  );
};

import { type Prisma } from "@prisma/client";

export const AuthenticatedUserIncludes = {
  company: {
    include: {
      portfolioCompanies: {
        orderBy: { name: "asc" },
      },
      logo: true,
      companyTargetPercentiles: true,
      userPermissionsPolicy: true,
      companyPermissions: true,
      companyBenchmarkRestriction: {
        include: {
          allowedJobFamilies: true,
          allowedCompanyTags: true,
          allowedLocations: true,
          allowedJobs: true,
        },
      },
    },
  },
  permissions: {
    include: {
      allowedJobFamilies: true,
      allowedCountries: true,
      allowedBenchmarkLevels: true,
      allowedAdditionalFields: true,
      salaryBandShares: {
        include: {
          allowedLevels: true,
        },
      },
    },
  },
  flags: true,
  subsidiaries: {
    select: {
      id: true,
      name: true,
      logo: true,
    },
  },
} satisfies Prisma.UserInclude;

import { type Currency } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { compact } from "~/lib/lodash";
import { type SetNonNullable } from "~/lib/utils";
import { hasTags, whereCompanyIsPartOfDataset } from "~/services/company";
import { hasFundingRound, isWithinSize } from "~/services/company-survey/helpers";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { getHeadcountRange } from "~/services/headcount";

export const getDashboardComparableCompanies = async (
  ctx: AppContext,
  params: { currency: Currency; company: CompanyWithCompensationSettings }
) => {
  const { company } = params;

  const companies = await value(async () => {
    const companies = await ctx.prisma.company.findMany({
      where: {
        ...whereCompanyIsPartOfDataset(ctx.user),
        disabledAt: null,
        liveSurveyId: { not: null },
        employees: { some: { status: "LIVE" } },
      },
      select: {
        id: true,
        tags: true,
        stats: true,
        liveSurvey: true,
      },
    });

    return companies as SetNonNullable<(typeof companies)[number], "liveSurvey">[];
  });

  const companySize = getCompanySize(company.marketPositioning, {
    canAccessFiguresAiV2: ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2,
  });
  const filters = {
    size: isWithinSize(companySize),
    fundingRounds: hasFundingRound(company.marketPositioning?.fundingRounds ?? []),
    industries: hasTags(compact([company.marketPositioning?.industryId])),
  };

  const comparableCompanies = companies.filter((company) => {
    if (company.id === params.company.id) {
      return true;
    }

    return filters.size(company.liveSurvey) && filters.fundingRounds(company.liveSurvey) && filters.industries(company);
  });

  return { companies, comparableCompanies };
};

const getCompanySize = (
  marketPositioning: CompanyWithCompensationSettings["marketPositioning"],
  options: { canAccessFiguresAiV2: boolean }
) => {
  const defaultSize = { min: null, max: null };

  if (!marketPositioning) return defaultSize;

  if (options.canAccessFiguresAiV2) return getHeadcountRange(marketPositioning.headcount);

  return { min: marketPositioning.minHeadcount, max: marketPositioning.maxHeadcount };
};

export type DashboardComparableCompany = AsyncReturnType<
  typeof getDashboardComparableCompanies
>["comparableCompanies"][number];

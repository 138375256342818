import { type AppContext } from "~/lib/context";
import { ForbiddenError } from "~/lib/errors/forbiddenError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { isNil } from "~/lib/lodash";

export const validateRequestAuthorised = (ctx: AppContext, params: { companyId: number | null | undefined }) => {
  const { companyId } = params;

  if (isNil(companyId) || isNaN(companyId)) {
    return;
  }

  const user = getRequiredUser(ctx);
  const isRequestAuthorised = user.isSuperAdmin || companyId === user.companyId;

  if (!isRequestAuthorised) {
    throw new ForbiddenError(`Unauthorised action for user ${user.id} on company ${companyId}`);
  }
};

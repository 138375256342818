import { type ExternalRemunerationType } from "@prisma/client";
import { chain } from "~/lib/lodash";
import { type RemunerationItemWithNature } from "~/services/employee/employeeMapping";

export const sumRemunerationItems = (
  remunerationItems: RemunerationItemWithNature[],
  externalRemunerationType: ExternalRemunerationType
): number => {
  return Math.round(
    chain(remunerationItems)
      .filter(({ nature }) => nature.mappedType === externalRemunerationType)
      .sumBy(({ amount }) => amount)
      .value()
  );
};

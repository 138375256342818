import { type EmployeeLevel, EmployeeStatus, type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { compact, keyBy } from "~/lib/lodash";
import { arrayHasValues, getId } from "~/lib/utils";
import { whereCompanyIsPartOfDataset } from "~/services/company";
import { generateEmployeeBucketHash } from "~/services/company-dashboard/generateEmployeeBucketHash";
import { getAllowedLevels } from "~/services/employee/employeeLevel";
import { getAllowedCountryIds, getCompanyAllowedLocationIds } from "~/services/employee/employeeLocation";
import { getAllowedJobFamilyIds } from "~/services/job";
import { getCapitalLocationsByCountryId } from "~/services/locations/location";

export type EmployeeRow = AsyncReturnType<typeof getLiveEmployees>[number];

export type GetLiveEmployeesOptions = {
  buckets?: {
    countryId: number;
    jobId: number;
    level: EmployeeLevel;
  }[];
  companyIds?: number[];
  countryIds?: number[];
  jobIds?: number[];
  levels?: EmployeeLevel[];
  benchmarkLevelIds?: number[];
  benchmarkLevelsRange?: {
    min: number;
    max: number;
  } | null;
  locationIds?: number[];
};

/**
 * Notes:
 * Fetching all employees is expensive, even more when requesting their currency, job, location & company as includes
 * in the main query.
 * To solve this, we don't request those relationships with includes, but rather fetch all of them and patch the
 * employees at runtime, avoiding a lot of duplicated objects.
 */
export const getLiveEmployees = async (ctx: AppContext, options: GetLiveEmployeesOptions = {}) => {
  const baseEmployees = await ctx.prisma.employee.findMany({
    where: buildLiveEmployeesWhere(ctx, options),
    include: {
      benchmarkLevel: true,
      picture: true,
      survey: true,
      user: true,
      externalEmployee: {
        select: {
          picture: true,
          job: {
            select: {
              name: true,
            },
          },
        },
      },
    },
  });

  const { capitalLocationsByCountryId, companies, currencies, jobs, locations } = await getStaticModels(ctx);

  return baseEmployees.map((employee) => {
    const company = companies[employee.companyId] as (typeof companies)[number];
    const location = locations[employee.locationId] as (typeof locations)[number];
    const capitalLocationId = capitalLocationsByCountryId[location.countryId];
    const currency = currencies[employee.currencyId] as (typeof currencies)[number];
    const job = jobs[employee.jobId] as (typeof jobs)[number];

    return {
      ...employee,
      capitalLocationId,
      company,
      currency,
      job,
      location,
      survey: employee.survey,
    };
  });
};

const buildLiveEmployeesWhere = (ctx: AppContext, options: GetLiveEmployeesOptions) => {
  const allowedJobFamilyIds = getAllowedJobFamilyIds(ctx.user);
  const allowedCountryIds = getAllowedCountryIds(ctx.user);
  const allowedLocationIds = getCompanyAllowedLocationIds(ctx.user);
  const allowedLevels = getAllowedLevels(ctx.user, !ctx.user?.company.useAdvancedLevels).filter(
    (level) => level !== "MA0X"
  ) as EmployeeLevel[];

  return {
    company: whereCompanyIsPartOfDataset(ctx.user),
    status: EmployeeStatus.LIVE,

    AND: compact([
      options.companyIds?.length && { companyId: { in: options.companyIds } },

      options.levels?.length && { level: { in: options.levels } },
      allowedLevels.length && { level: { in: allowedLevels } },
      arrayHasValues(options.jobIds) && { job: { id: { in: options.jobIds } } },
      arrayHasValues(allowedJobFamilyIds) && { job: { familyId: { in: allowedJobFamilyIds } } },
      arrayHasValues(options.countryIds) && { location: { countryId: { in: options.countryIds } } },
      arrayHasValues(options.locationIds) && { location: { id: { in: options.locationIds } } },
      arrayHasValues(allowedCountryIds) && { location: { countryId: { in: allowedCountryIds } } },
      arrayHasValues(allowedLocationIds) && { locationId: { in: allowedLocationIds } },
      arrayHasValues(options.buckets) && { bucketHash: { in: options.buckets.map(generateEmployeeBucketHash) } },
      ctx.featureFlags.CAN_ACCESS_LEVEL_FRAMEWORKS &&
        options.benchmarkLevelsRange &&
        options.benchmarkLevelsRange?.max > 0 && {
          benchmarkLevel: {
            min: { gte: options.benchmarkLevelsRange.min },
            max: { lte: options.benchmarkLevelsRange.max },
          },
        },
    ]),
  } satisfies Prisma.EmployeeWhereInput;
};

const getStaticModels = async (ctx: AppContext) => {
  const capitalLocationsByCountryId = await getCapitalLocationsByCountryId(ctx);
  const companies = keyBy(await ctx.prisma.company.findMany({ include: { tags: true } }), getId);
  const currencies = keyBy(await ctx.prisma.currency.findMany(), getId);
  const jobs = keyBy(await ctx.prisma.job.findMany({ include: { family: true } }), getId);
  const locations = keyBy(await ctx.prisma.employeeLocation.findMany({ include: { country: true } }), getId);

  return { capitalLocationsByCountryId, companies, currencies, jobs, locations };
};

import { UserLocale } from "@prisma/client";
import { type GetServerSideProps } from "nextjs-routes";
import { type JsonObject } from "type-fest";
import { ValidationError } from "yup";
import { type OptionalObjectSchema } from "yup/lib/object";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { CacheKeys } from "~/lib/external/redis/cacheKeys";
import { trackEmailSent } from "~/lib/external/segment/server/events";
import { fetch } from "~/lib/fetch";
import { translateYupError } from "~/lib/i18n/yupErrors";
import { logError, logInfo } from "~/lib/logger";
import { ssr } from "~/lib/ssr";
import { safeEmailProps } from "~/lib/utils";
import { type AccessRevokedEmailProps } from "~/pages/emails/access-revoked";
import { type LoginLinkProps } from "~/pages/emails/auth-login-link";
import { type CompensationReviewCampaignReviewerReminderEmailProps } from "~/pages/emails/compensation-review-campaign-reviewer-reminder";
import { type CompensationReviewCampaignStartEmailProps } from "~/pages/emails/compensation-review-campaign-start";
import { type InvitationDeniedEmailProps } from "~/pages/emails/invitation-denied";
import { type MarketDataAccessValidationProps } from "~/pages/emails/market-data-access-validation";
import { type MarketDataSearchProps } from "~/pages/emails/market-data-search";
import { type MarketPositioningEmailProps } from "~/pages/emails/market-positioning";
import { type OffCycleReviewApprovedEmailProps } from "~/pages/emails/off-cycle-review-approved";
import { type OffCycleReviewCompletedEmailProps } from "~/pages/emails/off-cycle-review-completed";
import { type OffCycleReviewDeclinedEmailProps } from "~/pages/emails/off-cycle-review-declined";
import { type OffCycleReviewPendingEmailProps } from "~/pages/emails/off-cycle-review-pending";
import { type OffCycleReviewRequestedEmailProps } from "~/pages/emails/off-cycle-review-requested";
import { type UserProviderConfirmationProps } from "~/pages/emails/user-provider-confirmation";
import { type WelcomeEmailProps } from "~/pages/emails/welcome";

type Templates = {
  // http://localhost:3000/api/dev/emails/welcome?props={"name": "Bastien Formery", "company": {"name": "Figures"}}
  "welcome": WelcomeEmailProps;
  // http://localhost:3000/api/dev/emails/welcome?props={"email": "john@doe.com", "author": {"firstName": "Raphaël", email: "raphael@figures.hr"}}
  "invitation-denied": InvitationDeniedEmailProps;
  // http://localhost:3000/api/dev/emails/access-revoked?props={"firstName": "Bastien"}
  "access-revoked": AccessRevokedEmailProps;
  // http://localhost:3000/api/dev/emails/market-data-access-validation?props={"company": {"name": "Figures"}}
  "market-data-access-validation": MarketDataAccessValidationProps;
  // http://localhost:3000/api/dev/emails/market-data-search?props={"name": "Bastien Formery", "company": "Figures", "body": "aGV5IGR1ZGUh", "hash": "abcdef"}
  "market-data-search": MarketDataSearchProps;
  // http://localhost:3000/api/dev/emails/auth-login-link?props={"token": "shorty-get-down-good-lord"}
  "auth-login-link": LoginLinkProps;
  // http://localhost:3000/api/dev/emails/user-provider-confirmation?props={"token": "shorty-get-down-good-lord"}
  "user-provider-confirmation": UserProviderConfirmationProps;
  // http://localhost:3000/api/dev/emails/market-positioning?props={"wayBelowTargetEmployees":[{"firstName": "Bastien", "lastName": "Formery", "id": 2345}],"belowTargetEmployees":[]}
  "market-positioning": MarketPositioningEmailProps;
  // http://localhost:3000/api/dev/emails/compensation-review-campaign-start?props={"campaignId": 1, "campaignName": "Test Campaign"}
  "compensation-review-campaign-start": CompensationReviewCampaignStartEmailProps;
  // http://localhost:3000/api/dev/emails/compensation-review-campaign-reviewer-reminder?props={"campaignId": 1, "campaignName": "Test Campaign", "campaignEndDate": "2025-01-01", "recommendationsCount": 3, "recommendationsNames": ["Bastien Formery", "John Doe", "Jane Doe"]}
  "compensation-review-campaign-reviewer-reminder": CompensationReviewCampaignReviewerReminderEmailProps;
  // http://localhost:3000/api/dev/emails/off-cycle-review-requested?props={"by": "Bastien Formery", "for": "John Doe", "reason": "LEVEL_CHANGE", "effectiveDate": "2025-01-01", "comment": "Great job!"}
  "off-cycle-review-requested": OffCycleReviewRequestedEmailProps;
  // http://localhost:3000/api/dev/emails/off-cycle-review-approved?props={"by": "Bastien Formery", "for": "John Doe", "reason": "LEVEL_CHANGE", "effectiveDate": "2025-01-01", "comment": "Great job!"}
  "off-cycle-review-approved": OffCycleReviewApprovedEmailProps;
  // http://localhost:3000/api/dev/emails/off-cycle-review-approved?props={"by": "Bastien Formery", "for": "John Doe", "reason": "LEVEL_CHANGE", "effectiveDate": "2025-01-01", "comment": "Great job!", "declineComment": "Not good enough"}
  "off-cycle-review-declined": OffCycleReviewDeclinedEmailProps;
  // http://localhost:3000/api/dev/emails/off-cycle-review-pending?props={"for": "John Doe", "reason": "LEVEL_CHANGE", "effectiveDate": "2025-01-01", "comment": "Great job!"}
  "off-cycle-review-pending": OffCycleReviewPendingEmailProps;
  // http://localhost:3000/api/dev/emails/off-cycle-review-completed?props={"for": "John Doe", "reason": "LEVEL_CHANGE", "effectiveDate": "2025-01-01", "comment": "Great job!"}
  "off-cycle-review-completed": OffCycleReviewCompletedEmailProps;
};

export type TemplatePath = keyof Templates;

export type TemplateProps<Path extends TemplatePath> = Templates[Path];

export type Sender = {
  email: string;
  name?: string;
  locale: UserLocale;
};

export type Recipient = {
  id?: number;
  email: string;
  locale: UserLocale;
  name?: string;
  company?: {
    id: number;
    name: string;
  };
};

export type Attachment = {
  url: string;
};

export type Template<Path extends TemplatePath, Props extends TemplateProps<Path>> = {
  path: Path;
  props: Props;
};

export type BaseEmailPayload = {
  sender: Sender;
  subject: string;
  replyTo?: Recipient;
  tags: string[];
  attachment?: Attachment[];
};

export type EmailPayload = BaseEmailPayload & {
  to: Recipient[];
  bcc?: Recipient[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ssrHandler: (schema: OptionalObjectSchema<any, any, any>) => GetServerSideProps = (schema) => {
  const handler: GetServerSideProps = async (ctx) => {
    const rawProps = (ctx.query.props as string) || "{}";
    const props = JSON.parse(rawProps);

    try {
      const input = schema.validateSync(props);

      return {
        props: input,
      };
    } catch (error) {
      let message: string = error.message;
      if (error instanceof ValidationError) {
        message = translateYupError(ctx.req.t, error).message;
      }

      logError(ctx.req, "[brevo] Validation error", { message, props });

      return { notFound: true };
    }
  };

  return ssr(handler, {
    authentication: { optional: true },
  });
};

export const buildEmail = async <Props extends JsonObject>(
  ctx: AppContext,
  params: {
    path: string;
    props: Props;
    locale: UserLocale;
  }
): Promise<string> => {
  const fullHtml = await value(async () => {
    // Using the URL class guarantees the payload will be properly encoded for URL
    const url = new URL(
      `${config.app.url}/emails/${params.path}?locale=${params.locale}&props=${safeEmailProps(params.props)}`
    );

    const res = await fetch(url);

    if (!res.ok) {
      logError(ctx, "[email] Email template compilation failed", {
        url: url.toString(),
        httpResponse: await res.text(),
        httpStatus: res.status,
      });

      throw new Error("Error during email template compilation - Most likely related to failing input");
    }

    return res.text();
  });

  const extraCss = await ctx.remember(CacheKeys.EmailsCss, async () => {
    const res = await fetch(`${config.app.url}/styles/emails/index.css`);

    return res.text();
  });

  const html = value(() => {
    const $ = ctx.cheerio.load(fullHtml);

    $("link,style,noscript,script").remove();
    $("#app").removeClass();

    return ctx.juice($.html(), { extraCss });
  });

  return html;
};

export const sendEmailToSendinblue = async <Path extends TemplatePath, Props extends TemplateProps<Path>>(
  ctx: AppContext,
  template: Template<Path, Props>,
  payload: EmailPayload,
  locale: UserLocale
) => {
  if (config.app.isJest) return;

  if (!config.app.isProduction) {
    payload.to = [{ name: config.dev.nameOverride, email: config.dev.emailOverride, locale: UserLocale.FR }];
    payload.bcc = undefined;
  }

  logInfo(ctx, "[email] Preparing email", { payload });

  const safeProps = safeEmailProps(template.props);
  const url = new URL(`${config.app.url}/api/dev/emails/${template.path}?props=${safeProps}`);
  logInfo(ctx, `[email] Preview email: ${url.toString()}`);

  const htmlContent = await buildEmail(ctx, {
    path: template.path,
    props: template.props,
    locale: locale,
  });

  logInfo(ctx, "[email] Sending email", { payload });

  const res = await fetch("https://api.sendinblue.com/v3/smtp/email", {
    method: "POST",
    headers: { "Content-Type": "application/json", "api-key": config.sendinblue.apiKey },
    body: JSON.stringify({ ...payload, htmlContent }),
  });

  if (res.ok) {
    await trackEmailSent(ctx, { template: template.path, recipients: payload.to });
  } else {
    const error = await res.json();

    logError(ctx, "[email] Error while sending email", { error });
  }
};

import { SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { randomUUID } from "crypto";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { auditLogConfigurationChange, type AuditLogOptions } from "~/services/salary-bands/audit-logs/index";
import { type SalaryRangeState } from "~/services/salary-bands/audit-logs/schemas";

export const auditLogUpdateRange = async (
  ctx: AppContext,
  params: {
    rangeId: number;
    bandId: number;
    gridId: number;
    initialState: SalaryRangeState;
    newState: SalaryRangeState;
    reason?: string | null;
  },
  options?: AuditLogOptions
) => {
  const input = {
    userId: getRequiredUser(ctx).id,
    actionId: options?.actionId ?? randomUUID(),
    label: SalaryGridConfigurationChangeLabel.UPDATE_RANGE,
    gridId: params.gridId,
    bandId: params.bandId,
    rangeId: params.rangeId,
    initialState: params.initialState,
    newState: params.newState,
    reason: params.reason,
  };

  await auditLogConfigurationChange(ctx, input);
};

import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logError } from "~/lib/logger";

export class KomboError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, KomboError.prototype);
  }
}

export const handleKomboError = (ctx: AppContext, res: NextApiResponse, error: KomboError) => {
  logError(ctx, "[error] Kombo error", { error });

  return res.status(HttpStatus.BAD_REQUEST).json({ error: error.message });
};

import { type ExternalEmployeeSource } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { traceBusinessService } from "~/lib/datadog/tracing";
import { KomboTimeoutError } from "~/lib/errors/komboTimeoutError";
import { notifyIntegrationDisabled, notifyIntegrationError } from "~/lib/external/slack/notifications";
import { getMappedEmployees, getStaticModelsForSync } from "~/lib/integration";
import { logError, logInfo, logWarn } from "~/lib/logger";
import { MAX_RETRY_COUNT } from "~/services/synchronization/fetchExternalEmployeesFromHrisMaxRetryCount";
import {
  type CompanyForSync,
  handleDeletedEmployees,
  type IntegrationSettingsForSync,
} from "~/services/synchronization/syncExternalEmployees";

export const fetchExternalEmployeesFromHRIS = async (
  ctx: AppContext,
  company: CompanyForSync,
  integrationSettings: IntegrationSettingsForSync
) => {
  return traceBusinessService(
    {
      tags: { "user.id": ctx.user?.id ?? "_cli_", "company.id": company.id },
      serviceName: "fetchExternalEmployeesFromHris",
    },
    async () => {
      const staticModels = await getStaticModelsForSync(ctx);

      logInfo(ctx, "[fetch] Fetching external employees from HRIS", {
        companyName: company.name,
        companyId: company.id,
        source: integrationSettings.source,
      });

      try {
        const mappedEmployees = await getMappedEmployees(integrationSettings.source)(
          ctx,
          company,
          integrationSettings,
          staticModels
        );

        if (mappedEmployees.length === 0) {
          logWarn(ctx, "[fetch] HRIS returned 0 employees. Assuming random hiccup and ignoring this sync.", {
            companyId: company.id,
            source: integrationSettings.source,
          });
          return { deleted: 0, mappedEmployees: [] };
        }

        const deleted = await handleDeletedEmployees(ctx, {
          companyId: company.id,
          externalIds: mappedEmployees.map((employee) => employee.input.externalId),
          source: integrationSettings.source as ExternalEmployeeSource,
        });

        return { deleted, mappedEmployees };
      } catch (error) {
        // A Kombo timeout error is a temporary error, we will retry later
        if (error instanceof KomboTimeoutError) {
          logInfo(ctx, "[fetch] Kombo timeout error, retry in a while", {
            companyName: company.name,
            companyId: company.id,
            source: integrationSettings.source,
          });

          throw error;
        }

        logError(ctx, "[fetch] Error while fetching employees from the HRIS.", {
          error,
          companyName: company.name,
          companyId: company.id,
          source: integrationSettings.source,
        });

        if (integrationSettings.retryCount === 0) {
          await notifyIntegrationError(ctx, { company, error });
        }

        if (integrationSettings.retryCount >= MAX_RETRY_COUNT) {
          logError(ctx, "[fetch] Disabling integration for company.", {
            error,
            companyName: company.name,
            companyId: company.id,
            source: integrationSettings.source,
          });

          await ctx.prisma.integrationSettings.update({
            where: { id: integrationSettings.id },
            data: { enabled: false, retryCount: 0 },
          });

          await notifyIntegrationDisabled(ctx, { company, lastError: error });
        } else {
          await ctx.prisma.integrationSettings.update({
            where: { id: integrationSettings.id },
            data: { retryCount: integrationSettings.retryCount + 1 },
          });
        }

        throw error;
      }
    }
  );
};

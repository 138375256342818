import { UserRole } from "@prisma/client";
import { type AppContext } from "~/lib/context";

export const disconnectExternalEmployeesFromPermissions = async (
  ctx: AppContext,
  { companyId, externalEmployeeIds }: { companyId: number; externalEmployeeIds: number[] }
) => {
  await ctx.prisma.userSession.deleteMany({
    where: {
      user: {
        companyId,
        permissions: {
          externalEmployeeId: { in: externalEmployeeIds },
          OR: [{ role: UserRole.EMPLOYEE }, { isManager: true }],
        },
      },
    },
  });

  await ctx.prisma.userPermissions.updateMany({
    where: {
      user: { companyId },
      externalEmployeeId: { in: externalEmployeeIds },
    },
    data: { externalEmployeeId: null, isManager: false },
  });
};

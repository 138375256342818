import { useRouter } from "next/router";
import type React from "react";
import { useEffect } from "react";
import { trackInteraction } from "~/lib/external/segment/web/events";

export const ClickTracker: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      const element = (event.target as HTMLElement).closest("button, a, label") as HTMLElement | null;

      if (!element || !element.innerText) {
        return;
      }

      const tagName = element.tagName.toLowerCase();
      const href = "href" in element ? (element as HTMLAnchorElement).href : undefined;
      const label = element.innerText;

      void trackInteraction({ router, tagName, label, href });
    };

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  });

  return null;
};

import { type Employee, EmployeeLevel, Gender } from "@prisma/client";
import { chain, sumBy } from "~/lib/lodash";
import { weightedMeanBy } from "~/lib/math";
import { hasGender, hasKnownGender } from "~/services/employee";

const ManagementLevels = [
  { level: EmployeeLevel.TEAM_LEAD, weight: 1.5 },
  { level: EmployeeLevel.HEAD_OF, weight: 2 },
  { level: EmployeeLevel.VP, weight: 2.5 },
  { level: EmployeeLevel.C_LEVEL, weight: 3 },
];

export const computeGenderEqualityManagementStats = (params: { employees: Employee[] }) => {
  const { employees } = params;

  const employeesWithGender = employees.filter(hasKnownGender());

  if (!employeesWithGender.length) {
    return null;
  }

  const managementDetails = chain(ManagementLevels)
    .map((weightedLevels) => {
      const employeesAtLevel = employeesWithGender.filter((employee) => {
        return employee.level === weightedLevels.level;
      });

      if (!employeesAtLevel.length) {
        return {
          score: null,
          womenProportion: null,
          level: weightedLevels.level,
          weight: weightedLevels.weight,
          menCount: 0,
          womenCount: 0,
          employeesCount: 0,
        };
      }

      const menCount = employeesAtLevel.filter(hasGender(Gender.MALE)).length;
      const womenCount = employeesAtLevel.filter(hasGender(Gender.FEMALE)).length;
      const womenProportion = womenCount / employeesAtLevel.length;

      if (womenProportion > 0.5) {
        return {
          score: 1,
          menCount,
          womenCount,
          womenProportion,
          level: weightedLevels.level,
          weight: weightedLevels.weight,
          employeesCount: employeesAtLevel.length,
        };
      }

      return {
        score: womenProportion * 2,
        menCount,
        womenCount,
        womenProportion,
        level: weightedLevels.level,
        weight: weightedLevels.weight,
        employeesCount: employeesAtLevel.length,
      };
    })
    .value();

  const score = weightedMeanBy(managementDetails, (weightedScore) => {
    return [weightedScore.score, weightedScore.weight];
  });

  const womenProportion =
    sumBy(managementDetails, (score) => {
      return score.womenCount;
    }) /
    sumBy(managementDetails, (score) => {
      return score.employeesCount;
    });

  return {
    score,
    womenProportion,
    levels: managementDetails,
  };
};

import { type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { trackSalaryRangesWidthEdited } from "~/lib/external/segment/server/events";
import { auditLogUpdateRange } from "~/services/salary-bands/audit-logs/updateRange";
import { computeSalaryRangeBounds } from "~/services/salary-bands/helpers/computeSalaryRangeBounds";
import { buildSalaryRangeWhereClause } from "~/services/salary-bands/helpers/salaryGridFilters";
import { type BulkUpdateSalaryRangeWidthsInput } from "~/workers/updateSalaryRangeSettings";

export const updateSalaryRangeWidths = async (
  ctx: AppContext,
  input: Omit<BulkUpdateSalaryRangeWidthsInput, "updateType" | "userId">
) => {
  const filtersWhereClause = buildSalaryRangeWhereClause(ctx, { salaryGridId: input.salaryGridId }, input.filters);
  const ranges = await ctx.prisma.salaryRange.findMany({
    where: { ...filtersWhereClause, ...(!input.allRanges && { id: { in: input.salaryRangeIds } }) },
    select: rangeSelect,
  });

  await mapSeries(ranges, async (range) => {
    const { min, max } = computeSalaryRangeBounds({ midpoint: range.midpoint, width: input.width });

    await ctx.prisma.salaryRange.update({ where: { id: range.id }, data: { min, max } });

    await auditLogUpdateRange(ctx, {
      rangeId: range.id,
      bandId: range.band.id,
      gridId: input.salaryGridId,
      initialState: range,
      newState: { ...range, min, max },
      reason: input.reason,
    });
  });

  await trackSalaryRangesWidthEdited(ctx, input);
};

const rangeSelect = {
  band: {
    select: {
      id: true,
      job: { select: { id: true, name: true } },
      location: { select: { id: true, name: true } },
    },
  },
  id: true,
  inferredPercentileRank: true,
  level: { select: { id: true, name: true } },
  max: true,
  midpoint: true,
  min: true,
  targetPercentileRank: true,
} satisfies Prisma.SalaryRangeSelect;

import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { ForbiddenError } from "~/lib/errors/forbiddenError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { logInfo } from "~/lib/logger";
import { getNatureByValues } from "~/services/additional-field/getAdditionalFieldsNature";

export const syncAdditionalFieldNatures = async (ctx: AppContext, companyId: number) => {
  const user = getRequiredUser(ctx);

  if (!user.isSuperAdmin && user.companyId !== companyId) {
    throw new ForbiddenError("Unauthorised access");
  }

  logInfo(ctx, "[additional-field] Start sync", { companyId });

  const additionalFieldValues = await ctx.prisma.additionalFieldValue.findMany({
    where: {
      companyId,
    },
    select: {
      stringValue: true,
      dateValue: true,
      numberValue: true,
      percentageValue: true,
      additionalFieldId: true,
    },
  });

  const transformedAdditionalFieldValues = additionalFieldValues.map((additionalFieldValue) => ({
    [additionalFieldValue.additionalFieldId]: {
      stringValue: additionalFieldValue.stringValue,
      ...(additionalFieldValue.dateValue && { dateValue: additionalFieldValue.dateValue }),
      ...(additionalFieldValue.numberValue && { numberValue: additionalFieldValue.numberValue }),
      ...(additionalFieldValue.percentageValue && { percentageValue: additionalFieldValue.percentageValue }),
    },
  }));

  const additionalFieldNatureByIds = getNatureByValues(transformedAdditionalFieldValues);

  await mapSeries(Object.entries(additionalFieldNatureByIds), async ([additionalFieldId, additionalFieldValues]) => {
    await ctx.prisma.additionalField.update({
      where: {
        id: parseInt(additionalFieldId),
        companyId: companyId,
      },
      data: {
        nature: additionalFieldValues,
      },
    });
  });
};

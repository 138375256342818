import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, LevelStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const DeleteLevelInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.DELETE_LEVEL>().required(),
    initialState: LevelStateSchema.required(),
  })
);

export type DeleteLevelChange = YupOutputType<typeof DeleteLevelInputSchema>;

import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";

export class ApiImpersonationMismatchError extends Error {
  public mismatchCompanyId: string | number;
  public impersonatedCompanyId: string | number;

  constructor(message: string, mismatchCompanyId: string | number, impersonatedCompanyId: string | number) {
    super(message);
    Object.setPrototypeOf(this, ApiImpersonationMismatchError.prototype);
    this.mismatchCompanyId = mismatchCompanyId;
    this.impersonatedCompanyId = impersonatedCompanyId;
  }
}

export const handleImpersonationMismatchError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: ApiImpersonationMismatchError
) => {
  logWarn(ctx, "[error] Impersonation Mismatch", { error });

  return res.status(HttpStatus.FORBIDDEN).json({
    key: "impersonation-mismatch",
    error: error.message,
    mismatchCompanyId: error.mismatchCompanyId,
    impersonatedCompanyId: error.impersonatedCompanyId,
  });
};

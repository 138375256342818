import { type AppContext } from "~/lib/context";
import { chain } from "~/lib/lodash";
import { initialSpreadsheet } from "~/lib/spreadsheet/templates";

export const initialImportColumnsMap = (ctx: AppContext) =>
  chain(initialSpreadsheet(ctx.t).availableColumns)
    .keyBy("key")
    .mapValues((value) => {
      return value.rsiField.label;
    })
    .value();

import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import {
  type FetchExternalEmployeeNextSalaryRangeResult,
  fetchExternalEmployeeNextSalaryRange,
} from "~/services/salary-bands/access/fetchExternalEmployeeNextSalaryRange";

export const FetchExternalEmployeeNextSalaryRangeSchema = object({
  externalEmployeeId: number().required(),
}).noUnknown();

export type FetchExternalEmployeeNextSalaryRangeInput = YupOutputType<
  typeof FetchExternalEmployeeNextSalaryRangeSchema
>;

const handler: NextApiHandler<FetchExternalEmployeeNextSalaryRangeResult> = async (req, res) => {
  const input = FetchExternalEmployeeNextSalaryRangeSchema.validateSync(req.body, { abortEarly: false });

  const nextSalaryRange = await fetchExternalEmployeeNextSalaryRange(req, input);

  return res.status(HttpStatus.OK).json(nextSalaryRange);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSalaryBands: true,
  },
});

export const FETCH_EXTERNAL_EMPLOYEE_NEXT_SALARY_RANGE_QUERY_KEY: ApiRoute["pathname"] =
  "/api/salary-bands/fetch-external-employee-next-salary-range";

export const useFetchExternalEmployeeNextSalaryRangeQuery = createQuery<
  typeof handler,
  typeof FetchExternalEmployeeNextSalaryRangeSchema
>({
  path: FETCH_EXTERNAL_EMPLOYEE_NEXT_SALARY_RANGE_QUERY_KEY,
  schema: FetchExternalEmployeeNextSalaryRangeSchema,
});

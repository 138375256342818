import { AbilityBuilder, PureAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { CompanyType, UserRole } from "@prisma/client";
import { createContext } from "react";
import { match } from "ts-pattern";
import { type AuthenticatedUser } from "~/lib/session";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { type SubscriptionStatusWithPermissions } from "~/services/subscriptions/validateSubscription";

export const enum Action {
  Access = "access",
  Update = "update",
}

export const enum Subject {
  Users = "users",
  Account = "account",
  RawData = "rawData",
  Settings = "settings",
  YouVsMarket = "youVsMarket",
  MarketData = "marketData",
  Integrations = "integrations",
  PeopleDashboard_MarketData = "peopleDashboard_marketData",
  PeopleDashboard_SalaryBands = "peopleDashboard_salaryBands",
  CompanyDashboard = "companyDashboard",
  CompensationReview = "compensationReview",
  SalaryBands = "salaryBands",
  ImportedEmployees = "importedEmployees",
  FileManager = "fileManager",
  GenderPayGapReport = "genderPayGapReport",
  UserImpersonation = "userImpersonation",
}

export type AppAbility = PureAbility<[Action, Subject]>;
type AbilityParams = {
  subscriptions: SubscriptionStatusWithPermissions;
};

export const createAbility = (user: NullableAuthenticatedUser, params: AbilityParams) => {
  if (!user) {
    return createAnonymousAbility();
  }

  return createAbilityForUser(user, params);
};

export const createAnonymousAbility = () => {
  return new AbilityBuilder<AppAbility>(PureAbility<[Action, Subject]>).build();
};

export const createAbilityForUser = (user: AuthenticatedUser, params: AbilityParams) => {
  const roleRules = match(user.permissions.role)
    .with(UserRole.EMPLOYEE, () => defineEmployeeRules(user, params))
    .with(UserRole.RECRUITER, () => defineRecruiterRules(user, params))
    .with(UserRole.HR, () => defineHrRules(user, params))
    .with(UserRole.ADMIN, () => defineAdminRules(params))
    .exhaustive();

  const superAdminRules = user.isSuperAdmin ? defineSuperAdminRules(user) : [];

  const companyRules = defineCompanyRules(user);

  // The order of rule concatenation is EXTREMELY important here!
  // The last one in takes precedence over the first one, so we have to put the
  // biggest restriction in the end.
  return new PureAbility<[Action, Subject]>([...roleRules, ...superAdminRules, ...companyRules]);
};

const defineSuperAdminRules = (user: AuthenticatedUser) => {
  const { can, rules } = new AbilityBuilder<AppAbility>(PureAbility);

  if (user.isSuperAdmin) {
    can(Action.Update, Subject.Users);
    can(Action.Access, Subject.Account);
    can(Action.Access, Subject.Settings);
    can(Action.Access, Subject.Integrations);
    can(Action.Access, Subject.CompanyDashboard);
    can(Action.Access, Subject.CompensationReview);
    can(Action.Access, Subject.SalaryBands);
    can(Action.Access, Subject.ImportedEmployees);
    can(Action.Access, Subject.UserImpersonation);
  }

  return rules;
};

const defineCompanyRules = (user: AuthenticatedUser) => {
  const { cannot, rules } = new AbilityBuilder<AppAbility>(PureAbility);

  if (user.company.type === CompanyType.PARTICIPANT && !user.company.liveSurveyId) {
    cannot(Action.Access, Subject.PeopleDashboard_MarketData);
    cannot(Action.Access, Subject.PeopleDashboard_SalaryBands);
    cannot(Action.Access, Subject.SalaryBands);
    cannot(Action.Access, Subject.CompanyDashboard);
    return rules;
  }

  if (user.company.type === CompanyType.VENTURE_CAPITAL) {
    cannot(Action.Access, Subject.RawData);
    cannot(Action.Access, Subject.Settings);
    cannot(Action.Access, Subject.YouVsMarket);
    cannot(Action.Access, Subject.Integrations);
    cannot(Action.Access, Subject.PeopleDashboard_MarketData);
    cannot(Action.Access, Subject.PeopleDashboard_SalaryBands);
    cannot(Action.Access, Subject.SalaryBands);

    if (!user.company.portfolioCompanies || user.company.portfolioCompanies.length === 0) {
      cannot(Action.Access, Subject.CompanyDashboard);
    }
  }

  return rules;
};

const defineRecruiterRules = (user: AuthenticatedUser, params: AbilityParams) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(PureAbility);

  can(Action.Access, Subject.MarketData);
  can(Action.Access, Subject.PeopleDashboard_MarketData);
  can(Action.Access, Subject.PeopleDashboard_SalaryBands);
  can(Action.Access, Subject.CompensationReview);
  can(Action.Access, Subject.SalaryBands);

  cannot(Action.Update, Subject.Users);
  cannot(Action.Access, Subject.Account);
  cannot(Action.Access, Subject.RawData);
  cannot(Action.Access, Subject.Settings);
  cannot(Action.Access, Subject.YouVsMarket);
  cannot(Action.Access, Subject.Integrations);
  cannot(Action.Access, Subject.CompanyDashboard);
  cannot(Action.Access, Subject.ImportedEmployees);
  cannot(Action.Access, Subject.FileManager);
  cannot(Action.Access, Subject.UserImpersonation);

  if (!user.permissions.isManager) {
    cannot(Action.Access, Subject.PeopleDashboard_MarketData);
    cannot(Action.Access, Subject.PeopleDashboard_SalaryBands);
  }

  if (!companyHasMarketDataAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.MarketData);
  }

  if (!companyHasSalaryBandsAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.SalaryBands);
  }

  if (!companyHasCompensationReviewAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.CompensationReview);
  }

  return rules;
};

const defineHrRules = (user: AuthenticatedUser, params: AbilityParams) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(PureAbility);

  can(Action.Access, Subject.Account);
  can(Action.Access, Subject.RawData);
  can(Action.Access, Subject.MarketData);
  can(Action.Access, Subject.YouVsMarket);
  can(Action.Access, Subject.PeopleDashboard_MarketData);
  can(Action.Access, Subject.PeopleDashboard_SalaryBands);
  can(Action.Access, Subject.CompanyDashboard);
  can(Action.Access, Subject.CompensationReview);
  can(Action.Access, Subject.SalaryBands);
  can(Action.Access, Subject.ImportedEmployees);
  can(Action.Access, Subject.FileManager);
  can(Action.Access, Subject.UserImpersonation);

  cannot(Action.Update, Subject.Users);
  cannot(Action.Access, Subject.Settings);
  cannot(Action.Access, Subject.Integrations);

  if (!user.isCompensationReviewCampaignAdmin) {
    cannot(Action.Access, Subject.UserImpersonation);
  }

  if (!companyHasMarketDataAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.MarketData);
    cannot(Action.Access, Subject.YouVsMarket);
    cannot(Action.Access, Subject.PeopleDashboard_MarketData);
  }

  if (!companyHasSalaryBandsAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.PeopleDashboard_SalaryBands);
    cannot(Action.Access, Subject.SalaryBands);
  }

  if (!companyHasCompensationReviewAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.CompensationReview);
  }

  return rules;
};

const defineEmployeeRules = (user: AuthenticatedUser, params: AbilityParams) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(PureAbility);

  can(Action.Access, Subject.MarketData);
  can(Action.Access, Subject.YouVsMarket);
  can(Action.Access, Subject.PeopleDashboard_MarketData);
  can(Action.Access, Subject.PeopleDashboard_SalaryBands);
  can(Action.Access, Subject.CompensationReview);
  can(Action.Access, Subject.SalaryBands);

  cannot(Action.Update, Subject.Users);
  cannot(Action.Access, Subject.Account);
  cannot(Action.Access, Subject.RawData);
  cannot(Action.Access, Subject.Settings);
  cannot(Action.Access, Subject.Integrations);
  cannot(Action.Access, Subject.CompanyDashboard);
  cannot(Action.Access, Subject.ImportedEmployees);
  cannot(Action.Access, Subject.FileManager);
  cannot(Action.Access, Subject.UserImpersonation);

  if (!user.permissions.isManager) {
    cannot(Action.Access, Subject.PeopleDashboard_MarketData);
    cannot(Action.Access, Subject.PeopleDashboard_SalaryBands);
    cannot(Action.Access, Subject.YouVsMarket);
  }

  if (!userHasMarketDataAccess(user, params.subscriptions)) {
    cannot(Action.Access, Subject.MarketData);
    cannot(Action.Access, Subject.YouVsMarket);
  }

  if (!companyHasSalaryBandsAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.SalaryBands);
  }

  if (!companyHasCompensationReviewAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.CompensationReview);
  }

  return rules;
};

const defineAdminRules = (params: AbilityParams) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(PureAbility);
  can(Action.Update, Subject.Users);
  can(Action.Access, Subject.Account);
  can(Action.Access, Subject.RawData);
  can(Action.Access, Subject.Settings);
  can(Action.Access, Subject.MarketData);
  can(Action.Access, Subject.YouVsMarket);
  can(Action.Access, Subject.Integrations);
  can(Action.Access, Subject.PeopleDashboard_MarketData);
  can(Action.Access, Subject.PeopleDashboard_SalaryBands);
  can(Action.Access, Subject.CompanyDashboard);
  can(Action.Access, Subject.CompensationReview);
  can(Action.Access, Subject.SalaryBands);
  can(Action.Access, Subject.ImportedEmployees);
  can(Action.Access, Subject.FileManager);
  can(Action.Access, Subject.GenderPayGapReport);
  can(Action.Access, Subject.UserImpersonation);

  if (!companyHasMarketDataAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.MarketData);
    cannot(Action.Access, Subject.YouVsMarket);
    cannot(Action.Access, Subject.PeopleDashboard_MarketData);
  }

  if (!companyHasSalaryBandsAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.PeopleDashboard_SalaryBands);
    cannot(Action.Access, Subject.SalaryBands);
  }

  if (!companyHasCompensationReviewAccess(params.subscriptions)) {
    cannot(Action.Access, Subject.CompensationReview);
  }

  return rules;
};

export const AbilityContext = createContext<AppAbility>(createAnonymousAbility());
export const Can = createContextualCan(AbilityContext.Consumer);

const companyHasMarketDataAccess = (subscriptions: SubscriptionStatusWithPermissions): boolean => {
  if (!subscriptions) {
    return false;
  }

  return subscriptions.CAN_ACCESS_BENCHMARK;
};

const companyHasSalaryBandsAccess = (subscriptions: SubscriptionStatusWithPermissions): boolean => {
  if (!subscriptions) {
    return false;
  }

  return subscriptions.CAN_ACCESS_SALARY_BANDS;
};

const companyHasCompensationReviewAccess = (subscriptions: SubscriptionStatusWithPermissions): boolean => {
  if (!subscriptions) {
    return false;
  }

  return subscriptions.CAN_ACCESS_COMPENSATION_REVIEW;
};

const userHasMarketDataAccess = (
  user: NullableAuthenticatedUser,
  subscriptions: SubscriptionStatusWithPermissions
): boolean => {
  if (!user) {
    return false;
  }

  if (!companyHasMarketDataAccess(subscriptions)) {
    return false;
  }

  return !!user.permissions.canAccessMarketData;
};

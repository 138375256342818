import { differenceInMilliseconds, fromUnixTime } from "date-fns";
import HttpStatus from "http-status-codes";
import { type RequestInfo, type RequestInit, type Response } from "node-fetch";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { isNumber } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { sleep } from "~/lib/utils";

type FetchWithRetryOptions = {
  /**
   * Number of milliseconds to wait before retrying when unable to get this information from the response headers.
   * Defaults to 5000
   **/
  defaultTimeoutMs?: number;

  /**
   * Max number of attempts before bailing.
   * Defaults to 10
   **/
  attempts?: number;

  /**
   * Header name to be used to get the timeout.
   * Defaults to the standard value: "RateLimit-Reset"
   **/
  resetHeaderName?: string;

  /**
   * How to interpret the timeout:
   * - "delta-seconds" follows the standard: the number of seconds until the quota resets
   * - "unix-epoch-time" is a custom mode where the header value is a unix timestamp
   **/
  resetHeaderMode?: "delta-seconds" | "unix-epoch-time";
};

export const fetchWithRetry = async (
  ctx: AppContext,
  url: RequestInfo,
  init?: RequestInit & { retry?: FetchWithRetryOptions }
): Promise<Response> => {
  const retryOptions = {
    defaultTimeoutMs: 5000,
    attempts: 10,
    resetHeaderName: "RateLimit-Reset",
    resetHeaderMode: "delta-seconds" as const,
    ...init?.retry,
  };

  const res = await fetch(url, init);

  if (res.status !== HttpStatus.TOO_MANY_REQUESTS) {
    return res;
  }

  if (retryOptions.attempts <= 0) {
    logWarn(ctx, `[fetch] Rate limit exceeded, no more attempts left`);
    return res;
  }

  const parseResetHeader = () => {
    const resetHeader = res.headers.get(retryOptions.resetHeaderName);
    if (!resetHeader) return retryOptions.defaultTimeoutMs;

    const resetValue = parseInt(resetHeader);
    if (!isNumber(resetValue)) return retryOptions.defaultTimeoutMs;

    if (retryOptions.resetHeaderMode === "delta-seconds") {
      return resetValue * 1000;
    } else {
      return differenceInMilliseconds(fromUnixTime(resetValue), new Date());
    }
  };

  const timeoutMs = parseResetHeader();

  logWarn(ctx, `[fetch] Rate limit exceeded, waiting before retrying...`, { timeoutMs });

  await sleep(timeoutMs);

  return fetchWithRetry(ctx, url, {
    ...init,
    retry: { ...retryOptions, attempts: retryOptions.attempts - 1 },
  });
};

import { type ExternalEmployeeForSync } from "~/services/synchronization/syncExternalEmployee";

export type ExternalEmployeeWithLocation = Pick<ExternalEmployeeForSync, "location" | "mappedEmployee">;

export const isFromNetherlands = (externalEmployee: ExternalEmployeeWithLocation) => {
  if (externalEmployee.mappedEmployee) {
    return externalEmployee.mappedEmployee.location.country.alpha2 === "NL";
  }

  if (
    externalEmployee.location?.country?.alpha2 === "NL" ||
    externalEmployee.location?.mappedLocation?.country?.alpha2 === "NL"
  ) {
    return true;
  }

  return false;
};

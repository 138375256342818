import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema } from "~/services/salary-bands/audit-logs/schemas";

export const ValidateBandInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.VALIDATE_BAND>().required(),
    bandId: number().required(),
  })
);

export type ValidateBandChange = YupOutputType<typeof ValidateBandInputSchema>;

import { type SpreadsheetImportRowStatus } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import type { AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type XlsxToJsonResult } from "~/services/xlsxToJson";

type Params = { importId: number; statuses: SpreadsheetImportRowStatus[]; take?: number; rowIds?: number[] };

const _findImportRows = async (ctx: AppContext, params: Params) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployeeSpreadsheetImportRow.findMany({
    where: {
      ...(params.rowIds && { id: { in: params.rowIds } }),
      companyId: user.companyId,
      importId: params.importId,
      status: { in: params.statuses },
    },
    select: { id: true, rowData: true, rowNumber: true, status: true, message: true },
    ...(params.take && { take: params.take }),
  });
};

type ImportRows = AsyncReturnType<typeof _findImportRows>;
type CastedImportRow = Omit<ImportRows[number], "rowData"> & { rowData: XlsxToJsonResult["rows"][number] };

export const findImportRows = async (ctx: AppContext, params: Params) => {
  const rows = await _findImportRows(ctx, params);

  return rows as unknown as CastedImportRow[];
};

import { config } from "~/config";
import { ImpersonateCompanyIdParam } from "~/lib/session";

const removeImpersonateQueryParamFromUrlAndReload = () => {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has(ImpersonateCompanyIdParam)) {
    searchParams.delete(ImpersonateCompanyIdParam);
    window.location.search = searchParams.toString();
    return;
  }

  window.location.reload();
};

export const handleExceptionalRightsChange = () => {
  if (config.dev.ignoreExceptionalRightsChangeRedirect) {
    removeImpersonateQueryParamFromUrlAndReload();
    return;
  }

  if (window.location.pathname.startsWith("/admin")) {
    window.location.href = "/admin";
    return;
  }

  if (window.location.pathname.startsWith("/compensation-review")) {
    removeImpersonateQueryParamFromUrlAndReload();
    return;
  }

  window.location.href = "/home";
};

import { CompanyMarketPositioningType, type EmployeeLevel, type SalaryBandMarketPositioning } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { getMarketDataEmployees } from "~/services/market-data/getMarketDataEmployees";
import { type GetMarketDataStatsEmployeesOptions } from "~/services/market-data/getMarketDataStats";

export const fetchLiveEmployeesForBenchmark = async (
  ctx: AppContext,
  params: {
    jobIds: number[];
    locationIds: number[];
    levels: EmployeeLevel[];
    marketPositioning: Pick<
      SalaryBandMarketPositioning,
      "type" | "headcount" | "minHeadcount" | "maxHeadcount" | "fundingRounds" | "industryId"
    > | null;
  }
) => {
  const { marketPositioning, jobIds, locationIds, levels } = params;
  if (!jobIds.length || !locationIds.length || !levels.length) {
    return [];
  }

  const input: GetMarketDataStatsEmployeesOptions = { jobIds, locationIds, levels };

  if (marketPositioning) {
    if (marketPositioning.type === CompanyMarketPositioningType.SIMILAR_HEADCOUNT) {
      if (!!marketPositioning.headcount) {
        input.headcount = marketPositioning.headcount;
      } else if (marketPositioning.minHeadcount || marketPositioning.maxHeadcount) {
        input.companySize = { min: marketPositioning.minHeadcount, max: marketPositioning.maxHeadcount };
      }
    }

    if (
      marketPositioning.type === CompanyMarketPositioningType.SIMILAR_FUNDING_STAGE &&
      marketPositioning.fundingRounds
    ) {
      input.fundingRounds = marketPositioning.fundingRounds;
    }

    if (marketPositioning.type === CompanyMarketPositioningType.SPECIFIC_INDUSTRY && marketPositioning.industryId) {
      const industry = await ctx.prisma.companyTag.findFirstOrThrow({
        where: { id: marketPositioning.industryId },
      });

      input.industries = { tagIds: [industry.id] };
    }
  }

  return getMarketDataEmployees(ctx, input);
};

import { EmployeeMarketPositioning } from "@prisma/client";
import { type TFunction } from "next-i18next";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { assertNotNil } from "~/lib/utils";
import { parseMarketPositioning } from "~/services/compensation-policy/parseMarketPositioning";
type MarketPositioning = {
  type: EmployeeMarketPositioning;
  min: number;
  max: number;
  color: "primary" | "secondary" | "info";
};

export const BELOW_TARGET_MAX_PERCENTAGE_DIFFERENCE = -0.05;

const Thresholds: MarketPositioning[] = [
  {
    type: EmployeeMarketPositioning.WAY_BELOW,
    min: -Infinity,
    max: -0.2,
    color: "secondary",
  },
  {
    type: EmployeeMarketPositioning.BELOW,
    min: -0.2,
    max: -0.05,
    color: "secondary",
  },
  {
    type: EmployeeMarketPositioning.ON_TARGET,
    min: -0.05,
    max: 0.05,
    color: "info",
  },
  {
    type: EmployeeMarketPositioning.ABOVE,
    min: 0.05,
    max: 0.2,
    color: "primary",
  },
  {
    type: EmployeeMarketPositioning.WAY_ABOVE,
    min: 0.2,
    max: Infinity,
    color: "primary",
  },
];

const getThresholdsIndex = (percentageDifference: number) => {
  return Thresholds.findIndex((rank) => {
    if (rank.max && percentageDifference > rank.max) {
      return false;
    }

    if (rank.min && percentageDifference <= rank.min) {
      return false;
    }

    return true;
  }) as number;
};

export const getMarketPositioningType = (percentageDifference: number) => {
  return Thresholds[getThresholdsIndex(percentageDifference)]?.type as EmployeeMarketPositioning;
};

export const getMarketPositioning = (t: TFunction, percentageDifference: number) => {
  return getMarketPositioningDetails(t, getMarketPositioningType(percentageDifference));
};

export const getMarketPositioningDetails = (t: TFunction, type: EmployeeMarketPositioning) => {
  const details = assertNotNil(Thresholds.find((item) => item.type === type));

  return {
    title: t(`enum.employee-market-positioning.${type}`),
    ...details,
  };
};

export const getMarketPositioningEvolution = (
  previousPercentageDifference: number,
  currentPercentageDifference: number
) => {
  const previousPositioningIndex = getThresholdsIndex(previousPercentageDifference);
  const currentPositioningIndex = getThresholdsIndex(currentPercentageDifference);

  return currentPositioningIndex - previousPositioningIndex;
};

export const fetchCompanyMarketPositioning = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);
  const marketPositioningId = user.company.marketPositioningId;

  if (!marketPositioningId) {
    return null;
  }

  const marketPositioning = await ctx.prisma.marketPositioning.findFirst({
    where: { id: marketPositioningId },
    include: { industry: true },
  });

  return parseMarketPositioning(ctx, marketPositioning);
};

export type FetchCompanyMarketPositioningResult = AsyncReturnType<typeof fetchCompanyMarketPositioning>;

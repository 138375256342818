import { UserLocale } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import {
  type BaseEmailPayload,
  type EmailPayload,
  type Recipient,
  sendEmailToSendinblue,
  type Template,
  type TemplatePath,
  type TemplateProps,
} from "~/lib/external/sendinblue/client";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { formatUserFullName } from "~/services/user/utils";
type EmailPayloadWithTo = BaseEmailPayload & { to: Recipient[] };

type EmailPayloadWithBcc = BaseEmailPayload & { bcc: Recipient[] };

export const sendEmailWithTo = async <Path extends TemplatePath, Props extends TemplateProps<Path>>(
  ctx: AppContext,
  template: Template<Path, Props>,
  payload: EmailPayloadWithTo
) => {
  await mapSeries(payload.to, async (recipient) => {
    await sendEmailToSendinblue(ctx, template, { ...payload, to: [recipient] }, recipient.locale);
  });
};

export const sendEmailWithBcc = async <Path extends TemplatePath, Props extends TemplateProps<Path>>(
  ctx: AppContext,
  template: Template<Path, Props>,
  payload: EmailPayloadWithBcc
) => {
  const user = getRequiredUser(ctx);
  const emailPayload: EmailPayload = {
    ...payload,
    to: [{ name: formatUserFullName(user), email: user.email, locale: UserLocale.EN }],
  };

  await sendEmailToSendinblue(ctx, template, emailPayload, UserLocale.EN);
};

import { type UserRole } from "@prisma/client";

export type SyncPayload =
  | CompanyImpersonationSyncPayload
  | UserImpersonationSyncPayload
  | SuperAdminSyncPayload
  | UserRoleSyncPayload;

type ExtractPayload<T extends SyncPayload["event"]> = T extends "company-impersonation"
  ? CompanyImpersonationSyncPayload["payload"]
  : T extends "user-impersonation"
  ? UserImpersonationSyncPayload["payload"]
  : T extends "super-admin"
  ? SuperAdminSyncPayload["payload"]
  : T extends "user-role"
  ? UserRoleSyncPayload["payload"]
  : never;

type SyncPayloadEvent = SyncPayload["event"];
export type SyncCallback<T extends SyncPayloadEvent> = (payload: ExtractPayload<T>) => void;

const BROADCAST_CHANNEL_NAME = "figures";
const BROADCAST_CHANNEL = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

export const broadcastTabSyncingEvent = (payload: SyncPayload) => {
  BROADCAST_CHANNEL.postMessage(payload);
};

const BROADCAST_CHANNEL_CALLBACK_MAP: Record<SyncPayloadEvent, SyncCallback<SyncPayloadEvent>[]> = {
  "user-role": [],
  "super-admin": [],
  "user-impersonation": [],
  "company-impersonation": [],
};

BROADCAST_CHANNEL.onmessage = (receivedEvent) => {
  const syncPayload = receivedEvent.data as SyncPayload;
  BROADCAST_CHANNEL_CALLBACK_MAP[syncPayload.event]?.forEach((callback) => {
    callback(syncPayload.payload as ExtractPayload<typeof syncPayload.event>);
  });
};

export const useTabSyncing = () => {
  return {
    send: (payload: SyncPayload) => {
      BROADCAST_CHANNEL.postMessage(payload);
    },
    listen: <Event extends SyncPayloadEvent>(eventToListenTo: Event, callback: SyncCallback<Event>) => {
      BROADCAST_CHANNEL_CALLBACK_MAP[eventToListenTo].push(callback);
    },
    stopListening: <Event extends SyncPayloadEvent>(eventToListenTo: Event, callback: SyncCallback<Event>) => {
      const index = BROADCAST_CHANNEL_CALLBACK_MAP[eventToListenTo].findIndex((cb) => cb === callback);
      if (index !== -1) {
        BROADCAST_CHANNEL_CALLBACK_MAP[eventToListenTo].splice(index, 1);
      }
    },
  };
};

type TabSyncEvent<EventName extends string, Payload extends Record<string, unknown>> = {
  event: EventName;
  payload: Payload;
};

type UserRoleSyncPayload = TabSyncEvent<"user-role", { role: UserRole }>;
type SuperAdminSyncPayload = TabSyncEvent<"super-admin", { isSuperAdmin: boolean }>;
type UserImpersonationSyncPayload = TabSyncEvent<"user-impersonation", { userId: number | null }>;
type CompanyImpersonationSyncPayload = TabSyncEvent<"company-impersonation", { companyId: number | null }>;

import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";

export const externalBenchmarkSelect = {
  id: true,
  name: true,
  colorHex: true,
  levels: {
    select: {
      id: true,
      name: true,
    },
    orderBy: { name: "asc" },
  },
  groups: {
    select: {
      id: true,
      jobName: true,
      locationName: true,
      benchmarkId: true,
    },
    orderBy: [{ jobName: "asc" }, { locationName: "asc" }],
  },
} satisfies Prisma.ExternalBenchmarkSelect;

export const fetchExternalBenchmark = async (
  ctx: AppContext,
  params: { salaryGridId: number; externalBenchmarkId: number }
) => {
  const externalBenchmark = await ctx.prisma.externalBenchmark.findFirstOrThrow({
    where: {
      id: params.externalBenchmarkId,
      grid: whereSalaryGridIs(params),
    },
    select: externalBenchmarkSelect,
  });

  return externalBenchmark;
};

export type FetchExternalBenchmarkResult = AsyncReturnType<typeof fetchExternalBenchmark>;

import { EmployeeStatsComparisonScope } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { meanBy, sumBy, uniqBy } from "~/lib/lodash";
import { isNotNull } from "~/lib/utils";
import { type EmployeesBucket } from "~/services/company-dashboard/generateEmployeesBuckets";
import { parseMarketPositioning } from "~/services/compensation-policy/parseMarketPositioning";
import { type CompanyWithCompensationSettings } from "~/services/employee-stats/fetchCompanyWithCompensationSettings";
import { computeEmployeeStats } from "../employee-stats/computeEmployeeStats";

export const computeBucketsCompensationStats = async (
  ctx: AppContext,
  params: {
    buckets: EmployeesBucket[];
    company: CompanyWithCompensationSettings;
  }
) => {
  const { buckets, company } = params;

  const companyEmployeesCount = sumBy(buckets, ({ companyEmployees }) => companyEmployees.length);
  const employeesCount = sumBy(buckets, ({ employees }) => employees.length);

  const bucketsStats = await getBucketsStats(ctx, { buckets, company });
  const employeesComparedToCountryCount = bucketsStats.filter(
    ({ comparison }) => comparison.comparisonScope === EmployeeStatsComparisonScope.COUNTRY
  ).length;
  const meanPercentageDifferenceFromTarget = getMeanPercentageDifferenceFromTarget(bucketsStats);
  const targetPercentile = getTargetPercentile(bucketsStats);

  return {
    companyEmployeesCount,
    employeesComparedToCountryCount,
    employeesCount,
    meanPercentageDifferenceFromTarget,
    targetPercentile,
  };
};

const getBucketsStats = async (
  ctx: AppContext,
  params: { buckets: EmployeesBucket[]; company: CompanyWithCompensationSettings }
) => {
  const { buckets, company } = params;

  const companyMarketPositioning = await parseMarketPositioning(ctx, company.marketPositioning);

  const employeeStats = await Promise.all(
    buckets.flatMap(({ companyEmployees, employees }) => {
      return companyEmployees.map((employee) =>
        computeEmployeeStats(ctx, { company, companyMarketPositioning, employee, marketEmployees: employees })
      );
    })
  );

  return employeeStats.filter((stats) => isNotNull(stats.totalCashMeasure.percentageDifference));
};

const getMeanPercentageDifferenceFromTarget = (bucketsStats: AsyncReturnType<typeof getBucketsStats>) => {
  if (!bucketsStats.length) {
    return null;
  }

  return meanBy(bucketsStats, ({ totalCashMeasure }) => totalCashMeasure.percentageDifference);
};

const getTargetPercentile = (bucketsStats: AsyncReturnType<typeof getBucketsStats>) => {
  const [uniquePercentile] = uniqBy(bucketsStats, ({ targetPercentile }) => targetPercentile);

  if (!uniquePercentile) {
    return null;
  }

  return uniquePercentile.targetPercentile;
};

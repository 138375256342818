import { type Prisma, type PrismaClient } from "@prisma/client";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";
import { protectPrismaClient } from "~/lib/prisma";
import { getPrismaRecursiveProtectionCount } from "~/lib/prismaTokens";

export const transaction = <T, Context extends AppContext>(
  ctx: Context,
  fn: (ctx: Context) => Promise<T>,
  options?: { maxWait?: number; timeout?: number; isolationLevel?: Prisma.TransactionIsolationLevel }
) => {
  // Avoid nesting transactions
  if (!ctx.prisma.$transaction) {
    logWarn(ctx, "[transaction] Warning: nested transaction detected, skipping transaction wrapper...");
    return fn(ctx);
  }

  return ctx.prisma.$transaction(async (transaction) => {
    const newCtx = { ...ctx, prisma: transaction as PrismaClient };

    if (config.app.isCliOrTest || getPrismaRecursiveProtectionCount(newCtx.prisma) === 0) {
      newCtx.prisma = await protectPrismaClient(newCtx);
    }

    return fn(newCtx);
  }, options);
};

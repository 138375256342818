import { type AppContext } from "~/lib/context";
import { handleGracefulExit } from "~/lib/handleGracefulExit";
import { logError, logInfo, logWarn, overrideNextLogger } from "~/lib/logger";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { makeQueueDriver } from "~/lib/queue/makeQueueDriver";
import { setupFeatureFlags } from "~/services/featureFlags";

declare const globalThis: {
  setupPromise: Promise<void> | null;
} & typeof global;

export const setupServer = async (ctx: MiddlewareContext) => {
  if (process.env.IS_JEST) return next(ctx);

  if (!globalThis.setupPromise) {
    globalThis.setupPromise = doSetupServer(ctx.req);
  }

  await globalThis.setupPromise;

  return next(ctx);
};

const doSetupServer = async (ctx: AppContext) => {
  logInfo(ctx, "[server] Setting up server on first request");

  process.on("unhandledRejection", (error) => {
    logError(ctx, "[server] Unhandled Rejection", { error });
  });

  process.on("uncaughtException", (error) => {
    logError(ctx, "[server] Uncaught exception", { error });
  });

  process.on("warning", (error) => {
    logWarn(ctx, "[server] Process warning", { error });
  });

  overrideNextLogger(ctx);
  handleGracefulExit(ctx);

  await setupFeatureFlags(ctx);

  await makeQueueDriver().start(ctx);
};

import { Gender } from "@prisma/client";
import { chain } from "~/lib/lodash";
import { ratioBy } from "~/lib/math";
import { computeEmployeeCompensation, hasGender } from "~/services/employee";
import { type EmployeeRow } from "~/services/employee/getLiveEmployees";

export const computeGenderEqualityTopEarnersStats = (params: { employees: EmployeeRow[]; anonymised?: boolean }) => {
  const { employees, anonymised = false } = params;

  const employeesWithDisclosedGender = employees.filter(
    (employee) => employee.gender === Gender.MALE || employee.gender === Gender.FEMALE
  );

  if (!employeesWithDisclosedGender.length) {
    return null;
  }

  const top10PercentCompensatedEmployees = chain(employeesWithDisclosedGender)
    .orderBy((employee) => {
      return computeEmployeeCompensation(employee);
    }, "desc")
    .take(Math.ceil(employees.length / 10))
    .map((employee) => {
      if (!anonymised) {
        return employee;
      }

      return {
        ...employee,
        externalJobTitle: "Hidden for confidentiality reasons",
      };
    })
    .value();

  const womenProportion = ratioBy(top10PercentCompensatedEmployees, hasGender(Gender.FEMALE));

  if (womenProportion > 0.5) {
    return {
      score: 1,
      womenProportion,
      top10PercentCompensatedEmployees,
    };
  }

  return {
    score: womenProportion * 2,
    womenProportion,
    top10PercentCompensatedEmployees,
  };
};

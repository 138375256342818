import { type TFunction } from "i18next";
import { type AppContext } from "~/lib/context";
import { type SpreadsheetRow } from "~/lib/external/google-sheets/client";
import { chain } from "~/lib/lodash";
import { formatMoney } from "~/lib/money";
import { OPTIONAL_NUMBER_FIELD_PATTERN, type ExternalEmployeeField } from "~/lib/spreadsheet/externalEmployeeFields";
import { type SpreadsheetSpec } from "~/lib/spreadsheet/templates";
import { getAllAdditionalFields } from "~/services/additional-field/getAllAdditionalFields";
import { getAllCustomRemunerationItem } from "~/services/custom-remuneration-item/getAllCustomRemunerationItem";
import {
  fetchPartialSpreadsheetExternalEmployees,
  type PartialSpreadsheetExternalEmployees,
} from "~/services/external-employee/fetchPartialSpreadsheetExternalEmployees";

export const ADDITIONAL_FIELD_KEY_PREFIX = "af_";
export const CUSTOM_REMUNERATION_ITEM_KEY_PREFIX = "cri_";

export const addPrefixToAdditionalFieldKey = (additionalFieldName: string) =>
  `${ADDITIONAL_FIELD_KEY_PREFIX}${additionalFieldName}`;

export const addPrefixToCustomRemunerationItemKey = (customRemunerationItemName: string) =>
  `${CUSTOM_REMUNERATION_ITEM_KEY_PREFIX}${customRemunerationItemName}`;

export const transformAdditionalFieldToSpreadsheetField = (
  additionalFieldName: string
): ExternalEmployeeField<string> => {
  const key = addPrefixToAdditionalFieldKey(additionalFieldName);

  return {
    key,
    rsiField: {
      label: additionalFieldName,
      key,
      fieldType: { type: "input" },
      example: `Example ${additionalFieldName}`,
      alternateMatches: [additionalFieldName],
    },
  };
};

export const transformCustomRemunerationItemToSpreadsheetField = (
  t: TFunction,
  customRemunerationItemName: string
): ExternalEmployeeField<string> => {
  const key = addPrefixToCustomRemunerationItemKey(customRemunerationItemName);

  return {
    key,
    toSpreadsheet: (externalEmployee) => {
      const externalRemunerationItem = externalEmployee.remunerationItems.find(
        (item) => item.customRemunerationItem?.name === customRemunerationItemName
      );

      return formatMoney(externalRemunerationItem?.amount ?? null);
    },
    rsiField: {
      label: customRemunerationItemName,
      key,
      fieldType: { type: "input" },
      example: "10000",
      alternateMatches: [customRemunerationItemName],
      validations: [
        {
          rule: "regex",
          value: OPTIONAL_NUMBER_FIELD_PATTERN,
          errorMessage: t("services.spreadsheet.external-employee-fields.positive-number", {
            field: customRemunerationItemName,
          }),
          level: "error",
        },
      ],
    },
  };
};

export const generateSpreadsheetData = async (
  ctx: AppContext,
  params: {
    spreadsheetSpec: SpreadsheetSpec;
    columns: string[];
  }
) => {
  const additionalFields = await getAllAdditionalFields(ctx);
  const columns = getSpreadsheetFieldsFromSpec(params.spreadsheetSpec, params.columns);
  const additionalFieldColumns = additionalFields
    .filter((field) => params.columns.includes(addPrefixToAdditionalFieldKey(field.name)))
    .map(({ name }) => transformAdditionalFieldToSpreadsheetField(name));

  const customRemunerationItems = await getAllCustomRemunerationItem(ctx);
  const customRemunerationItemColumns = customRemunerationItems
    .filter((item) => params.columns.includes(addPrefixToCustomRemunerationItemKey(item.name)))
    .map(({ name }) => transformCustomRemunerationItemToSpreadsheetField(ctx.t, name));

  const columnHeaders = columns.map((column) => column.rsiField.label);
  const additionalFieldColumnHeaders = additionalFieldColumns.map((column) => column.rsiField.label);
  const customRemunerationItemColumnHeaders = customRemunerationItemColumns.map((column) => column.rsiField.label);

  const spreadsheetHeaders = [
    ...columnHeaders,
    ...additionalFieldColumnHeaders,
    ...customRemunerationItemColumnHeaders,
  ] as unknown as SpreadsheetRow<number>;

  const notes = chain(columns)
    .map((column, index) => {
      if (!column.getHeaderNote) return null;

      return { column: index, note: column.getHeaderNote(ctx.t) };
    })
    .compact()
    .value();

  const externalEmployeesForSpreadsheet = await fetchPartialSpreadsheetExternalEmployees(ctx);

  const getColumnsFromExternalEmployee = (externalEmployee: PartialSpreadsheetExternalEmployees[number]) => {
    const fromColumns = columns.map((column) => {
      const value =
        column.toSpreadsheet?.(externalEmployee) ?? externalEmployee[column.key as keyof typeof externalEmployee] ?? "";
      return value as string;
    });

    const fromAdditionalFields = additionalFieldColumns.map((column) => {
      const columnKey = column.key.replace(ADDITIONAL_FIELD_KEY_PREFIX, "");
      const additionalFieldValue = externalEmployee.additionalFieldValues.find(
        (field) => field.additionalField.name === columnKey
      );

      return additionalFieldValue?.stringValue ?? "";
    });

    const fromCustomRemunerationItems = customRemunerationItemColumns.map((column) => {
      return column.toSpreadsheet?.(externalEmployee) ?? "";
    });

    return [
      ...fromColumns,
      ...fromAdditionalFields,
      ...fromCustomRemunerationItems,
    ] as unknown as SpreadsheetRow<number>;
  };

  return {
    data: [spreadsheetHeaders, ...externalEmployeesForSpreadsheet.map(getColumnsFromExternalEmployee)],
    notes,
  };
};

export const getSpreadsheetFieldsFromSpec = (spreadsheetSpec: SpreadsheetSpec, columns: string[]) => {
  const requiredKeys = spreadsheetSpec.requiredColumns.map((column) => column.key);

  return spreadsheetSpec.availableColumns.filter(
    (column) => columns.includes(column.key) || requiredKeys.includes(column.key)
  );
};

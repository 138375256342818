import classNames from "classnames";
import React, { type ComponentProps } from "react";
import { Ping } from "~/components/ui/core/Ping";

type Props = ComponentProps<typeof Ping> & {
  className?: string;
};

export const LoadingIndicator: React.FC<Props> = ({ size, color, className }) => {
  return (
    <div
      className={classNames(className, {
        "flex space-x-1 opacity-50": true,
      })}
    >
      <Ping size={size} color={color} />
      <Ping size={size} color={color} animationDelay={50} />
      <Ping size={size} color={color} animationDelay={100} />
    </div>
  );
};

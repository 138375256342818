import { Alert, Stack, Typography } from "@mui/material";
import { RemappingRequestStatus } from "@prisma/client";
import React, { useEffect, useState } from "react";
import { Button } from "~/components/ui/core/Button";
import { Modal } from "~/components/ui/core/Modal";
import { EmptyCell, Table } from "~/components/ui/core/Table";
import { EmployeeBadgeList } from "~/components/ui/employee/EmployeeBadgeList";
import { usePermissions } from "~/hooks/usePermissions";
import { useSession } from "~/hooks/useSession";
import { getId } from "~/lib/utils";
import { useFetchCompanyRemappingRequestQuery } from "~/pages/api/remapping-request/get-company-remapping-request";
import { useUpdateRemappingRequestMutation } from "~/pages/api/remapping-request/update-remapping-request";
import { type GetCompanyRemappingRequestResult } from "~/services/remapping-request/getCompanyRemappingRequest";

export const ConfirmNewMappingModal: React.FC = () => {
  const { permissions } = usePermissions();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSession();

  const { data: companyRemappingRequests } = useFetchCompanyRemappingRequestQuery({
    options: {
      enabled: !!user && permissions.canAccessSettings,
    },
  });

  const updateRemappingRequestMutation = useUpdateRemappingRequestMutation({
    successMessage: "Mappings updated successfully",
  });

  const allRemappingRequestsIds = companyRemappingRequests?.map(getId) ?? [];

  useEffect(() => {
    setIsOpen(!!companyRemappingRequests);
  }, [companyRemappingRequests]);

  type Row = GetCompanyRemappingRequestResult[number];

  const columns = [
    {
      id: "External Job Title",
      name: "Your Job title",
      sortable: true,
      selector: (row: Row) => row.externalJob.name,
    },
    {
      id: "Current Job Mapping",
      name: "Current Mapping",
      sortable: true,
      selector: (row: Row) => row.previousJob?.name ?? "Unmapped",
    },
    {
      id: "Recommended Job Mapping",
      name: "Recommended Mapping",
      sortable: true,
      selector: (row: Row) => row.newJobName,
    },
    {
      id: "Employees Impacted",
      name: "Team Members Affected",
      sortable: false,
      format: function Cell(row: Row) {
        if (row.impactedEmployees?.length === 0) {
          return <EmptyCell />;
        }

        return <EmployeeBadgeList firstFiveEmployees={row.impactedEmployees} count={row.impactedEmployeesCount} />;
      },
    },
    {
      id: "actions",
      name: "",
      sortable: false,
      format: function Cell(row: Row) {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              onClick={async () => {
                await updateRemappingRequestMutation.mutateAsync({
                  ids: [row.id],
                  status: RemappingRequestStatus.REJECTED,
                });
              }}
            >
              Decline
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={updateRemappingRequestMutation.isLoading}
              isLoading={updateRemappingRequestMutation.isLoading}
              onClick={async () => {
                await updateRemappingRequestMutation.mutateAsync({
                  ids: [row.id],
                  status: RemappingRequestStatus.ACCEPTED,
                });
              }}
            >
              Accept
            </Button>
          </Stack>
        );
      },
    },
  ];

  if (!companyRemappingRequests?.length) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      title="New mapping suggestions for your team"
      subtitle="Our job framework has been enhanced with new additional titles that may better fit your employees' roles."
      onClose={() => setIsOpen(false)}
      primaryAction={{
        label: "Accept All",
        onClick: async () => {
          if (!allRemappingRequestsIds.length) {
            return;
          }

          await updateRemappingRequestMutation.mutateAsync({
            ids: allRemappingRequestsIds,
            status: RemappingRequestStatus.ACCEPTED,
          });
        },
      }}
      secondaryAction={{
        label: "Decline All",
        onClick: async () => {
          if (!allRemappingRequestsIds.length) {
            return;
          }

          await updateRemappingRequestMutation.mutateAsync({
            ids: allRemappingRequestsIds,
            status: RemappingRequestStatus.REJECTED,
          });
        },
      }}
      tertiaryAction={{
        label: "Remind me later",
        onClick: () => setIsOpen(false),
      }}
    >
      <Stack spacing={8} className="m-4">
        <Alert severity="info" className="ml-auto mr-auto w-[800px]">
          <Typography variant="body1">
            We're continuously improving our job framework and existing mappings to more accurately reflect the evolving
            market and industry standards. We believe these new mappings will offer a better match for your employees'
            roles. If no action is taken, the new mappings will be automatically applied after 30 days but you can
            revert to previous mappings at any time from the mapping tab in your account settings.
          </Typography>
        </Alert>
        <Typography variant="h3"> Review the suggested mappings to ensure the best fit for your team.</Typography>
        <Table columns={columns} data={companyRemappingRequests} />
      </Stack>
    </Modal>
  );
};

import { type Currency, UserLocale } from "@prisma/client";
import { useLocale } from "~/hooks/useLocale";
import { roundTo } from "~/lib/math";

type MoneyFormatOptions = {
  roundTo?: number;
  unit?: "cents" | "millions";
};

export type Money = {
  format: (amount: number, options?: MoneyFormatOptions) => string;
};

export const PlaceholderCurrency: Currency = {
  id: 1,
  code: "EUR",
  symbol: "€",
  name: "Euro",
  euroExchangeRate: 1,
  createdAt: new Date(),
  updatedAt: new Date(),
  translations: { [UserLocale.FR]: "Euro", [UserLocale.EN]: "Euro" },
};

export const useMoney = (currency: Pick<Currency, "code">): Money => {
  const { locale } = useLocale();

  const intl = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency.code,
    minimumFractionDigits: 0,
  });

  return {
    format: (amount, options) => {
      let value = amount;

      if (options?.unit === "millions") {
        value *= 1_000_000;
      } else {
        value /= 100;
      }

      if (options?.roundTo) {
        value = roundTo(value, options.roundTo);
      }

      return intl.format(value);
    },
  };
};

import { ExternalEmployeeStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";

export const fetchExternalEmployeeChainManagerIds = async (ctx: AppContext, params: { externalEmployeeId: number }) => {
  return recursivelyFetchExternalEmployeeChainManagerIds(ctx, {
    ...params,
    managerChainIds: [],
  });
};

const recursivelyFetchExternalEmployeeChainManagerIds = async (
  ctx: AppContext,
  params: {
    externalEmployeeId: number;
    managerChainIds: number[];
  }
): Promise<number[]> => {
  const employee = await ctx.prisma.externalEmployee.findFirst({
    where: {
      id: params.externalEmployeeId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
    },
    select: { managerExternalEmployeeId: true },
  });

  if (!employee?.managerExternalEmployeeId) {
    return params.managerChainIds;
  }

  // in case of management cycle
  if (params.managerChainIds.includes(employee.managerExternalEmployeeId)) {
    return params.managerChainIds;
  }

  const newChain = [...params.managerChainIds, employee.managerExternalEmployeeId];

  return await recursivelyFetchExternalEmployeeChainManagerIds(ctx, {
    externalEmployeeId: employee.managerExternalEmployeeId,
    managerChainIds: newChain,
  });
};

import { KeyboardDoubleArrowUp } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CompensationReviewSalaryDisplay } from "@prisma/client";
import React from "react";
import { value } from "~/components/helpers";
import { RangePenetrationIndicator } from "~/components/salary-bands/RangePenetrationIndicator";
import { PercentageDifference } from "~/components/ui/PercentageDifference";
import { useMoney } from "~/hooks/useMoney";
import { useI18n } from "~/lib/i18n/useI18n";
import { useCompensationReview } from "~/services/compensation-review/campaigns/campaignHandler";
import { computeNewBandPositioning } from "~/services/compensation-review/shared/computeNewBandPositioning";
import { type CompensationReviewRecommendationEmployee } from "~/services/compensation-review/shared/fetchCompensationReviewRecommendations";
import { formatSalaryRangeName } from "~/services/salary-bands/helpers/formatSalaryBandName";

type Props = {
  employee: CompensationReviewRecommendationEmployee;
  previewValues?: {
    baseSalary: number;
    variablePay?: number;
    onTargetEarnings: number;
    annualPerformanceBonus?: number;
    exceptionalBonus?: number;
  };
};

export const RecommendationSummary: React.FC<Props> = ({ employee, previewValues }) => {
  const { t } = useI18n();
  const money = useMoney(employee.currency);
  const compensationReview = useCompensationReview();

  const newValues = {
    baseSalary: previewValues?.baseSalary ?? employee.baseSalary + (employee.baseSalaryIncrease ?? 0),
    variablePay: previewValues?.variablePay ?? employee.variablePay + (employee.variablePayIncrease ?? 0),
    onTargetEarnings:
      previewValues?.onTargetEarnings ?? employee.onTargetEarnings + (employee.onTargetEarningsIncrease ?? 0),
    annualPerformanceBonus: previewValues?.annualPerformanceBonus ?? employee.annualPerformanceBonus,
    exceptionalBonus: previewValues?.exceptionalBonus ?? employee.exceptionalBonus,
  };

  const showTotalAmount = !!newValues.variablePay || !!employee.otherBonus;

  const salaryBandData = value(() => {
    if (!employee.salaryRangeEmployee) return null;

    const targetRange = employee.targetRangeAfterPromotion ?? employee.salaryRangeEmployee.range;
    const measure = targetRange.band.measure;

    if (!previewValues) {
      return {
        measure,
        currentCompaRatio: employee.compaRatio,
        previewCompaRatio: employee.newCompaRatio,
        currentRangePenetration: employee.rangePenetration,
        previewRangePenetration: employee.newRangePenetration,
      };
    }

    const newBandPositioning = computeNewBandPositioning({
      employee,
      newValues,
    });

    return {
      measure,
      currentCompaRatio: employee.compaRatio,
      previewCompaRatio: newBandPositioning?.newCompaRatio ?? null,
      currentRangePenetration: employee.rangePenetration,
      previewRangePenetration: newBandPositioning?.newRangePenetration ?? null,
    };
  });

  return (
    <Stack>
      {employee.targetRangeAfterPromotion && (
        <Stack direction="row" alignItems="center" spacing={1} className="mb-4">
          <KeyboardDoubleArrowUp className="-ml-1" color="info" />

          <Typography variant="subtitle1" noWrap marginBottom={4}>
            {t("pages.compensation-review.recommendation-panel.promotion-to", {
              jobTitle: formatSalaryRangeName(
                employee.targetRangeAfterPromotion.band,
                employee.targetRangeAfterPromotion.level
              ),
            })}
          </Typography>
        </Stack>
      )}

      <Grid container alignItems="center" spacing={2}>
        <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
          {t("pages.compensation-review.recommendation-panel.base-salary")}
        </Grid>
        <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
          {money.format(employee.baseSalary, { roundTo: 1 })}
        </Grid>
        <Grid xs={1} component={Typography} className="text-center">
          →
        </Grid>
        <Grid xs={2} component={Typography} className="text-right font-medium">
          {money.format(newValues.baseSalary, { roundTo: 1 })}
        </Grid>
        <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
          <PercentageDifference compare={newValues.baseSalary} to={employee.baseSalary} decimals={2} />
        </Grid>
      </Grid>

      {!!newValues.variablePay && (
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
            {t("pages.compensation-review.recommendation-panel.variable-pay")}
          </Grid>
          <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
            {money.format(employee.variablePay, { roundTo: 1 })}
          </Grid>
          <Grid xs={1} component={Typography} className="text-center">
            →
          </Grid>
          <Grid xs={2} component={Typography} className="text-right font-medium">
            {money.format(newValues.variablePay, { roundTo: 1 })}
          </Grid>
          <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
            <PercentageDifference compare={newValues.variablePay} to={employee.variablePay} decimals={2} />
          </Grid>
        </Grid>
      )}

      {!!employee.otherBonus &&
        compensationReview.parameters.preferedSalaryDisplay === CompensationReviewSalaryDisplay.TOTAL_CASH && (
          <Grid container alignItems="center" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.other-bonus")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.otherBonus, { roundTo: 1 })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(employee.otherBonus, { roundTo: 1 })}
            </Grid>
          </Grid>
        )}

      {showTotalAmount &&
        compensationReview.parameters.preferedSalaryDisplay === CompensationReviewSalaryDisplay.ON_TARGET_EARNINGS && (
          <Grid container alignItems="center" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.on-target-earnings")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.onTargetEarnings, { roundTo: 1 })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(newValues.onTargetEarnings, { roundTo: 1 })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
              <PercentageDifference compare={newValues.onTargetEarnings} to={employee.onTargetEarnings} decimals={2} />
            </Grid>
          </Grid>
        )}

      {showTotalAmount &&
        compensationReview.parameters.preferedSalaryDisplay === CompensationReviewSalaryDisplay.TOTAL_CASH && (
          <Grid container alignItems="center" spacing={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.total-cash")}
            </Grid>
            <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
              {money.format(employee.totalCash, { roundTo: 1 })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-center">
              →
            </Grid>
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {money.format(newValues.onTargetEarnings + employee.otherBonus, { roundTo: 1 })}
            </Grid>
            <Grid xs={1} component={Typography} className="text-right" color="text.secondary" variant="caption">
              <PercentageDifference
                compare={newValues.onTargetEarnings + employee.otherBonus}
                to={employee.totalCash}
                decimals={2}
              />
            </Grid>
          </Grid>
        )}

      {!!newValues.annualPerformanceBonus && (
        <Grid container alignItems="center" marginTop={4}>
          <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
            {t("pages.compensation-review.recommendation-panel.annual-performance-bonus")}
          </Grid>
          <Grid xs={4} />
          <Grid xs={2} component={Typography} className="text-right font-medium">
            {money.format(newValues.annualPerformanceBonus, { roundTo: 1 })}
          </Grid>
        </Grid>
      )}

      {!!newValues.exceptionalBonus && (
        <Grid container alignItems="center" marginTop={!newValues.annualPerformanceBonus ? 4 : 0}>
          <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
            {t("pages.compensation-review.recommendation-panel.exceptional-bonus")}
          </Grid>
          <Grid xs={4} />
          <Grid xs={2} component={Typography} className="text-right font-medium">
            {money.format(newValues.exceptionalBonus, { roundTo: 1 })}
          </Grid>
        </Grid>
      )}

      {salaryBandData && employee.targetRangeAfterPromotion && salaryBandData.previewCompaRatio && (
        <>
          <Grid container marginTop={4}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.new-compa-ratio")}
            </Grid>
            <Grid xs={6} component={Typography} className="text-right font-medium">
              {salaryBandData.previewCompaRatio.toFixed(2)}
            </Grid>
          </Grid>
          <Grid container marginTop={2} alignItems="center">
            <Grid xs={5} component={Typography} variant="subtitle1">
              {t("pages.compensation-review.recommendation-panel.position-in-new-range")}
            </Grid>
            <Grid xs={6} component={Stack} alignItems="end">
              <RangePenetrationIndicator
                measure={salaryBandData.measure}
                salaryRangeEmployee={{
                  baseSalaryRangePenetration: salaryBandData.previewRangePenetration,
                  onTargetEarningsRangePenetration: salaryBandData.previewRangePenetration,
                }}
                tiersMode={compensationReview.salaryGrid?.tiersMode}
              />
            </Grid>
          </Grid>
        </>
      )}

      {salaryBandData && !employee.targetRangeAfterPromotion && salaryBandData.previewCompaRatio && (
        <>
          <Grid container marginTop={4}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              Compa-ratio
            </Grid>
            {salaryBandData.currentCompaRatio && (
              <>
                <Grid xs={3} component={Typography} color="text.secondary" className="text-right">
                  {salaryBandData.currentCompaRatio.toFixed(2)}
                </Grid>
                <Grid xs={1} component={Typography} className="text-center">
                  →
                </Grid>
              </>
            )}
            <Grid xs={2} component={Typography} className="text-right font-medium">
              {salaryBandData.previewCompaRatio.toFixed(2)}
            </Grid>
          </Grid>
          <Grid container marginTop={2}>
            <Grid xs={5} component={Typography} variant="subtitle1" noWrap>
              {t("pages.compensation-review.recommendation-panel.position-in-range")}
            </Grid>
            <Grid xs={6} component={Stack} alignItems="end">
              <RangePenetrationIndicator
                measure={salaryBandData.measure}
                salaryRangeEmployee={{
                  baseSalaryRangePenetration: salaryBandData.currentRangePenetration,
                  onTargetEarningsRangePenetration: salaryBandData.currentRangePenetration,
                }}
                newRangePenetration={salaryBandData.previewRangePenetration ?? undefined}
                tiersMode={compensationReview.salaryGrid?.tiersMode}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Stack>
  );
};

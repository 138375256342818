import { type ParsedUrlQuery } from "querystring";

export const TAB_QUERY_KEY = "tab";

export const SPREADSHEET_TAB = "spreadsheet";

export const SUCCESS_URL_QUERY_KEY = "successUrl";

export const getSpreadsheetFlowRoute = (params?: { successUrl?: string; query?: ParsedUrlQuery }) => {
  return {
    pathname: "/account/integrations",
    query: {
      [TAB_QUERY_KEY]: SPREADSHEET_TAB,
      ...(params?.successUrl && { [SUCCESS_URL_QUERY_KEY]: params.successUrl }),

      ...params?.query,
    },
  } as const;
};

import { SpreadsheetImportRowStatus } from "@prisma/client";
import type { AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { getId } from "~/lib/utils";

export const findNextImportRowIdsToImport = async (ctx: AppContext, params: { importId: number; take: number }) => {
  const user = getRequiredUser(ctx);

  return (
    await ctx.prisma.externalEmployeeSpreadsheetImportRow.findMany({
      where: {
        companyId: user.companyId,
        importId: params.importId,
        status: SpreadsheetImportRowStatus.INIT,
      },
      select: { id: true },
      take: params.take,
    })
  ).map(getId);
};

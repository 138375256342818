import { DataValidationFlagOrigin, EmployeeStatus, ExternalEmployeeSource } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { traceBusinessService } from "~/lib/datadog/tracing";
import { notifyTooManyCreatedDeletedEmployees } from "~/lib/external/slack/notifications";
import { chain, groupBy, isUndefined, sumBy } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { dangerouslyIncludeHistoricalExternalRemunerationItems } from "~/lib/prisma-restrictions/schemas/generateExternalRemunerationItemHistoricalSchema";
import { getId, type YupOutputType } from "~/lib/utils";
import { syncAdditionalFieldNatures } from "~/services/additional-field/syncAdditionalFieldNatures";
import { createCompanyEmployeesFlags } from "~/services/employee-data-validation-flag/dataFlagging";
import { updateEmployeesStats } from "~/services/employee-stats/updateEmployeesStats";
import {
  fetchUnmappedNotSkippedExternalLocations,
  mapExternalLocationsToSuggestedLocations,
} from "~/services/locations/location";
import { updateStatusForCompanyId } from "~/services/onboarding/setOnboardingState";
import { synchroniseCompanySalaryRangeEmployees } from "~/services/salary-bands/configuration/synchroniseCompanySalaryRangeEmployees";
import { suggestMappingForExternalJobs } from "~/services/synchronization/suggestMappingForExternalJobs";
import { suggestMappingForExternalLocations } from "~/services/synchronization/suggestMappingForExternalLocations";
import { type CompanyForSync } from "~/services/synchronization/syncExternalEmployees";
import {
  externalEmployeeSelectForSyncHierarchicalRelationships,
  syncHierarchicalRelationships,
} from "~/services/synchronization/syncHierarchicalRelationships";
import {
  type PostSyncExternalEmployeesDeletesReportDataSchema,
  type PostSyncExternalEmployeesHierarchyDataSchema,
  type PostSyncExternalEmployeesUpdatesReportDataSchema,
} from "~/workers/postSyncExternalEmployees";

type PostSyncExternalEmployeesParams = {
  company: CompanyForSync;
  deletes: YupOutputType<typeof PostSyncExternalEmployeesDeletesReportDataSchema>[];
  updates: YupOutputType<typeof PostSyncExternalEmployeesUpdatesReportDataSchema>[];
  hierarchy: YupOutputType<typeof PostSyncExternalEmployeesHierarchyDataSchema>[];
};

export const postSyncExternalEmployees = async (
  ctx: AppContext,
  params: Pick<PostSyncExternalEmployeesParams, "company">
) => {
  return traceBusinessService(
    {
      tags: { "user.id": ctx.user?.id ?? "_cli_", "company.id": params.company.id },
      serviceName: "postSyncExternalEmployees",
    },
    async () => {
      await cleanupExternalRemunerationNatures(ctx, params.company.id);

      await syncAdditionalFieldNatures(ctx, params.company.id);

      await suggestMappingForExternalJobs(ctx, params.company.id);

      await suggestMappingForExternalLocations(ctx, { companyId: params.company.id });

      const externalLocationsToMap = await fetchUnmappedNotSkippedExternalLocations(ctx, params.company.id);

      if (externalLocationsToMap.length) {
        await mapExternalLocationsToSuggestedLocations(ctx, externalLocationsToMap);
      }

      const employees = await ctx.prisma.employee.findMany({
        where: { companyId: params.company.id, status: EmployeeStatus.LIVE },
        select: { id: true },
      });

      await updateEmployeesStats(ctx, {
        companyId: params.company.id,
        employeesIds: employees.map((employee) => employee.id),
      });

      await createCompanyEmployeesFlags(ctx, params.company.id, DataValidationFlagOrigin.HRIS_SYNC);

      await synchroniseCompanySalaryRangeEmployees(ctx, {
        companyId: params.company.id,
      });
    }
  );
};

const cleanupExternalRemunerationNatures = async (ctx: AppContext, companyId: number) => {
  const remunerationNatures = await ctx.prisma.externalRemunerationNature.findMany({
    ...dangerouslyIncludeHistoricalExternalRemunerationItems(),
    where: { companyId },
    select: { id: true, externalRemunerationItems: { select: { id: true } } },
  });

  const remunerationNatureIdsToDelete = remunerationNatures
    .filter((remunerationNature) => remunerationNature.externalRemunerationItems.length === 0)
    .map(getId);

  if (remunerationNatureIdsToDelete.length > 0) {
    logInfo(ctx, "[sync] Deleting remuneration natures only linked to this employee", {
      companyId: companyId,
      remunerationNatureIdsToDelete,
    });

    await ctx.prisma.externalRemunerationNature.deleteMany({ where: { id: { in: remunerationNatureIdsToDelete } } });
  }
};

export const updateIntegrationCounters = async (
  ctx: AppContext,
  params: Pick<PostSyncExternalEmployeesParams, "deletes" | "updates" | "company">
) => {
  const deletedBySource = chain(params.deletes)
    .groupBy((row) => row.source)
    .mapValues((rows) => sumBy(rows, (row) => row.deleted))
    .value();

  const updatedBySource = chain(params.updates)
    .groupBy((row) => row.source)
    .mapValues((rows) => sumBy(rows, (row) => row.updated))
    .value();

  const createdBySource = chain(params.updates)
    .groupBy((row) => row.source)
    .mapValues((rows) => sumBy(rows, (row) => row.created))
    .value();

  await mapSeries(params.company.integrationSettings, async (integrationSettings) => {
    if (
      isUndefined(createdBySource[integrationSettings.source]) &&
      isUndefined(updatedBySource[integrationSettings.source]) &&
      isUndefined(deletedBySource[integrationSettings.source])
    ) {
      return;
    }

    const created = createdBySource[integrationSettings.source] ?? 0;
    const updated = updatedBySource[integrationSettings.source] ?? 0;
    const deleted = deletedBySource[integrationSettings.source] ?? 0;

    logInfo(ctx, "[sync] Updating integration counters", {
      companyId: params.company.id,
      source: integrationSettings.source,
      created,
      updated,
      deleted,
    });

    await ctx.prisma.integrationSettings.update({
      where: { id: integrationSettings.id },
      data: {
        lastSynchronisedAt: new Date(),
        lastSyncCreated: created,
        lastSyncUpdated: updated,
        lastSyncDeleted: deleted,
      },
    });

    if (created >= 5 || deleted >= 5) {
      await notifyTooManyCreatedDeletedEmployees(ctx, {
        companyName: params.company.name,
        companyId: params.company.id,
        countryAlpha2: params.company.defaultCountry.alpha2,
        lastSyncCreated: created,
        lastSyncUpdated: updated,
        lastSyncDeleted: deleted,
      });
    }

    if (created > 10) {
      await updateStatusForCompanyId(ctx, { companyId: params.company.id });
    }
  });
};

export const prepareAndSyncHierarchicalRelationships = async (
  ctx: AppContext,
  hierarchy: PostSyncExternalEmployeesParams["hierarchy"],
  company: Pick<CompanyForSync, "id" | "integrationSettings">
) => {
  const hierarchyBySource = groupBy(hierarchy, (row) => row.source);
  await mapSeries(
    [...company.integrationSettings, { source: ExternalEmployeeSource.SPREADSHEET }],
    async (settings) => {
      const hierarchy = hierarchyBySource[settings.source] ?? [];

      if (hierarchy.length === 0) {
        return;
      }

      logInfo(ctx, "[sync] Syncing hierarchical relationships", {
        companyId: company.id,
        source: settings.source,
        employeesWithManager: hierarchy.length,
      });

      const externalEmployees = await ctx.prisma.externalEmployee.findMany({
        where: { companyId: company.id, source: settings.source },
        select: externalEmployeeSelectForSyncHierarchicalRelationships,
      });

      const externalEmployeesWithManagerId = chain(externalEmployees)
        .map((externalEmployee) => {
          const hierarchyRow = hierarchy.find((row) => row.externalEmployeeId === externalEmployee.id);

          return {
            ...externalEmployee,
            managerExternalId: hierarchyRow?.managerExternalId,
          };
        })
        .value();

      await syncHierarchicalRelationships(ctx, externalEmployeesWithManagerId, company.id);
    }
  );
};

import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import {
  type FetchExternalEmployeeOffCycleReviewRequestsResult,
  fetchExternalEmployeeOffCycleReviewRequests,
} from "~/services/compensation-review/off-cycle-reviews/fetchExternalEmployeeOffCycleReviewRequests";

export const FetchExternalEmployeeOffCycleReviewRequestsSchema = object({
  externalEmployeeId: number().required(),
}).noUnknown();

export type FetchExternalEmployeeOffCycleReviewRequestsInput = YupOutputType<
  typeof FetchExternalEmployeeOffCycleReviewRequestsSchema
>;

const handler: NextApiHandler<FetchExternalEmployeeOffCycleReviewRequestsResult> = async (req, res) => {
  const input = FetchExternalEmployeeOffCycleReviewRequestsSchema.validateSync(req.body, { abortEarly: false });

  const requests = await fetchExternalEmployeeOffCycleReviewRequests(req, input);

  return res.status(HttpStatus.OK).json(requests);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessOffCycleReviews: true },
});

export const FETCH_EXTERNAL_EMPLOYEE_OFF_CYCLE_REVIEW_REQUESTS_KEY: ApiRoute["pathname"] =
  "/api/compensation-review/off-cycle-reviews/fetch-external-employee-off-cycle-review-requests";

export const useFetchExternalEmployeeOffCycleReviewRequestsQuery = createQuery<
  typeof handler,
  typeof FetchExternalEmployeeOffCycleReviewRequestsSchema
>({
  path: FETCH_EXTERNAL_EMPLOYEE_OFF_CYCLE_REVIEW_REQUESTS_KEY,
  schema: FetchExternalEmployeeOffCycleReviewRequestsSchema,
});

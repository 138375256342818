import {
  type Prisma,
  SalaryBandAccessLevel,
  SalaryGridStatus,
  type SalaryRange,
  type UserPermissionsPolicy,
  UserRole,
} from "@prisma/client";
import { all } from "bluebird";
import { match, P } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain, compact, flatten, orderBy, uniq } from "~/lib/lodash";
import { dangerouslyIgnorePrismaRestrictions } from "~/lib/prismaTokens";
import { type AuthenticatedUser } from "~/lib/session";
import { getId } from "~/lib/utils";
import { fetchExternalEmployeeChainManagerIds } from "~/services/external-employee/fetchExternalEmployeeChainManagers";
import { type UserExternalEmployeeManageeIds } from "~/services/external-employee/fetchExternalEmployeeManageeIds";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissionsSchema";

const EMPLOYEE_ROLE = "employee" as const;

const MANAGER_ROLE = "manager" as const;

type PermissionRole = typeof EMPLOYEE_ROLE | typeof MANAGER_ROLE;

export const generateSalaryBandsPermissionsSchema = async (ctx: AppContext, filters: UserPermissionsSchemaFilters) => {
  const { externalEmployeeManageeIds, isAdmin, ownedByCompany, inSameBusinessUnit } = filters;

  const salaryGridOwnedByCompany = { grid: ownedByCompany };

  const salaryBandPermissions = {
    read: salaryGridOwnedByCompany,
    update: isAdmin && salaryGridOwnedByCompany,
    delete: isAdmin && salaryGridOwnedByCompany,
  };

  const user = getRequiredUser(ctx);
  const allowedExternalEmployeeIds = getAllowedExternalEmployeeIds(user, externalEmployeeManageeIds);
  const { allowedSalaryBandIds, allowedSalaryRangeIds } = await fetchAllowedSalaryBandsAndRanges(ctx, {
    isAdmin,
    externalEmployeeManageeIds,
  });

  return {
    SalaryGrid: {
      read: ownedByCompany,
      update: isAdmin && { ...ownedByCompany, status: { not: SalaryGridStatus.ARCHIVED } },
      delete: isAdmin && ownedByCompany,
    },

    SalaryBandJob: salaryBandPermissions,
    SalaryBandLocation: salaryBandPermissions,
    SalaryBandLevel: salaryBandPermissions,
    SalaryBandMappedJob: salaryBandPermissions,
    SalaryBandMappedLocation: salaryBandPermissions,
    SalaryBandMappedLevel: salaryBandPermissions,
    SalaryBandBenchmarkedJob: salaryBandPermissions,
    SalaryBandBenchmarkedLocation: salaryBandPermissions,
    SalaryBandBenchmarkedLevel: salaryBandPermissions,
    SalaryGridConfigurationChange: salaryBandPermissions,
    ExternalBenchmark: salaryBandPermissions,
    ExternalBenchmarkEntry: salaryBandPermissions,
    ExternalBenchmarkGroup: salaryBandPermissions,
    ExternalBenchmarkLevel: salaryBandPermissions,

    SalaryBandMarketPositioning: {
      read: { band: salaryGridOwnedByCompany },
      update: isAdmin && { band: salaryGridOwnedByCompany },
      delete: isAdmin && { band: salaryGridOwnedByCompany },
    },

    SalaryBandShare: {
      read: { band: salaryGridOwnedByCompany },
      update: isAdmin && { band: salaryGridOwnedByCompany },
      delete: isAdmin && { band: salaryGridOwnedByCompany },
    },

    SalaryBand: {
      read: {
        ...salaryGridOwnedByCompany,
        ...(allowedSalaryBandIds && { id: { in: allowedSalaryBandIds } }),
      },
      update: isAdmin && salaryGridOwnedByCompany,
      delete: isAdmin && salaryGridOwnedByCompany,
    },

    SalaryRange: {
      read: {
        band: salaryGridOwnedByCompany,
        ...(allowedSalaryRangeIds && { id: { in: allowedSalaryRangeIds } }),
      },
      update: isAdmin && { band: salaryGridOwnedByCompany },
      delete: isAdmin && { band: salaryGridOwnedByCompany },
    },

    SalaryRangeEmployee: {
      read: {
        ...salaryGridOwnedByCompany,
        ...(allowedExternalEmployeeIds && { externalEmployeeId: { in: allowedExternalEmployeeIds } }),
        ...(allowedSalaryRangeIds && { rangeId: { in: allowedSalaryRangeIds } }),
        ...(inSameBusinessUnit && { externalEmployee: inSameBusinessUnit }),
      },
      update: isAdmin && salaryGridOwnedByCompany,
      delete: isAdmin && salaryGridOwnedByCompany,
    },
  } satisfies Partial<PermissionsSchema>;
};

export const fetchAllowedSalaryBandsAndRanges = async (
  ctx: AppContext,
  params: { isAdmin: boolean; externalEmployeeManageeIds?: number[] | null }
) => {
  if (params.isAdmin) {
    return { allowedSalaryBandIds: null, allowedSalaryRangeIds: null };
  }

  const salaryRangesForRole = await fetchSalaryRangesForPermissionsPolicy(ctx, params);
  const sharedSalaryBandIds = getSharedSalaryBandIds(ctx, params);
  const sharedSalaryRangeIds = await fetchSharedSalaryRangeIds(ctx, params);

  const salaryBandIdsForRole = salaryRangesForRole?.map(({ bandId }) => bandId) ?? [];
  const salaryRangeIdsForRole = salaryRangesForRole?.map(getId) ?? [];

  return {
    allowedSalaryBandIds: uniq(flatten(compact([salaryBandIdsForRole, sharedSalaryBandIds]))),
    allowedSalaryRangeIds: uniq(flatten(compact([salaryRangeIdsForRole, sharedSalaryRangeIds]))),
  };
};

const fetchSalaryRangesForPermissionsPolicy = async (
  ctx: AppContext,
  params: { externalEmployeeManageeIds?: number[] | null }
) => {
  const user = getRequiredUser(ctx);

  if (user.permissions.role !== UserRole.EMPLOYEE && !user.permissions.isManager) return [];

  const roles: PermissionRole[] = [];
  if (user.permissions.isManager) roles.push(MANAGER_ROLE);
  if (user.permissions.role === UserRole.EMPLOYEE) roles.push(EMPLOYEE_ROLE);

  const rangesForRoles = await Promise.all(
    roles.map(async (role) =>
      fetchSalaryRangesForRole(ctx, {
        role,
        externalEmployeeManageeIds: params.externalEmployeeManageeIds ?? [],
      })
    )
  );

  return rangesForRoles.flat();
};

const fetchSalaryRangesForRole = async (
  ctx: AppContext,
  params: { role: PermissionRole; externalEmployeeManageeIds: number[] }
): Promise<Pick<SalaryRange, "id" | "bandId">[]> => {
  const user = getRequiredUser(ctx);
  const salaryBandAccessLevel = getAccessLevelForRole(params.role, user.company.userPermissionsPolicy);

  if (!salaryBandAccessLevel) return [];

  const externalEmployeeIds =
    params.role === MANAGER_ROLE ? params.externalEmployeeManageeIds : compact([user.permissions.externalEmployeeId]);

  if (externalEmployeeIds.length === 0) return [];

  if (!user.company.defaultSalaryGridId) return [];

  const levelSelect = {
    id: true,
    position: true,
    name: true,
  } satisfies Prisma.SalaryBandLevelSelect;

  const employees = await ctx.prisma.salaryRangeEmployee.findMany({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: {
      externalEmployeeId: { in: externalEmployeeIds },
      range: {
        band: {
          isDraft: false,
          grid: { status: SalaryGridStatus.LIVE, id: user.company.defaultSalaryGridId },
        },
      },
    },
    select: {
      range: {
        select: {
          id: true,
          bandId: true,
          level: { select: levelSelect },
          band: {
            select: {
              ranges: {
                select: {
                  id: true,
                  bandId: true,
                  level: { select: levelSelect },
                },
              },
            },
          },
        },
      },
    },
  });

  const ranges = match(salaryBandAccessLevel)
    .with(SalaryBandAccessLevel.NONE, () => [])
    .with(SalaryBandAccessLevel.RANGE, () => employees.map(({ range }) => range))
    .with(SalaryBandAccessLevel.ENTIRE_BAND, () => employees.flatMap(({ range }) => range.band.ranges))
    .with(SalaryBandAccessLevel.ONE_LEVEL_ABOVE, () =>
      employees.flatMap(({ range }) => {
        // The positions of range levels are in decreasing order
        // Example: intermediate would be at index 1 and junior at index 2 for example
        // This means we need to order the range in decreasing order or level position, and then get the next index to get the next level
        // Example: [2, 1] => our junior range has a level with position 2 and if we want the intermediate range we need to get the level with position 1
        const orderedRanges = orderBy(range.band.ranges, ({ level }) => level.position, ["desc"]);
        const rangeIndex = orderedRanges.findIndex(({ id }) => range.id === id);
        const rangeAbove = orderedRanges[rangeIndex + 1];

        return rangeAbove ? [range, rangeAbove] : [range];
      })
    )
    .exhaustive();

  return ranges.map(({ id, bandId }) => ({ id, bandId }));
};

const getAccessLevelForRole = (role: PermissionRole, userPermissionsPolicy: UserPermissionsPolicy | null) => {
  if (!userPermissionsPolicy) return null;

  if (role === EMPLOYEE_ROLE) {
    return userPermissionsPolicy.employeeSalaryBandAccessLevel ?? null;
  }

  return userPermissionsPolicy.managerSalaryBandAccessLevel ?? null;
};

const getSharedSalaryBandIds = (ctx: AppContext, params: { isAdmin: boolean }) => {
  const user = getRequiredUser(ctx);

  if (params.isAdmin) return null;

  return user.permissions.salaryBandShares.map(({ bandId }) => bandId);
};

const fetchSharedSalaryRangeIds = async (ctx: AppContext, params: { isAdmin: boolean }) => {
  const user = getRequiredUser(ctx);

  if (params.isAdmin) return null;

  if (user.permissions.salaryBandShares.length === 0) return [];

  const orClauses = user.permissions.salaryBandShares.map(({ allowedLevels, bandId }) => {
    const allowedLevelIds = allowedLevels.map(getId);

    if (allowedLevelIds.length === 0) {
      return { bandId } satisfies Prisma.SalaryRangeWhereInput;
    }

    return { bandId, levelId: { in: allowedLevelIds } } satisfies Prisma.SalaryRangeWhereInput;
  });

  const ranges = await ctx.prisma.salaryRange.findMany({
    ...dangerouslyIgnorePrismaRestrictions(),
    where: { OR: orClauses },
    select: { id: true },
  });

  return ranges.map(getId);
};

const getAllowedExternalEmployeeIds = (
  user: AuthenticatedUser,
  externalEmployeeManageeIds: UserExternalEmployeeManageeIds
) => {
  if (user.permissions.role === UserRole.ADMIN || user.permissions.role === UserRole.HR) {
    return null;
  }

  // Destructuring to prevent data mutation data with `push`
  const allowedExternalEmployeeIds = [...(externalEmployeeManageeIds ?? [])];

  if (
    (user.permissions.role === UserRole.EMPLOYEE || user.permissions.isManager) &&
    user.permissions.externalEmployeeId
  ) {
    allowedExternalEmployeeIds.push(user.permissions.externalEmployeeId);
  }

  return uniq(allowedExternalEmployeeIds);
};

export const fetchExternalEmployeeIdsWhoCanAccessSalaryBand = async (
  ctx: AppContext,
  params: { salaryBandId: number }
) => {
  const user = getRequiredUser(ctx);

  const ranges = await ctx.prisma.salaryRange.findMany({
    where: { band: { id: params.salaryBandId } },
    select: {
      employees: {
        select: {
          externalEmployee: {
            select: {
              id: true,
              managerExternalEmployeeId: true,
            },
          },
        },
      },
    },
  });

  const externalEmployeesInTheRange = ranges.flatMap((range) =>
    range.employees.map((employee) => employee.externalEmployee)
  );

  if (externalEmployeesInTheRange.length === 0) {
    return [];
  }

  const employeeIds = match(user.company.userPermissionsPolicy?.employeeSalaryBandAccessLevel)
    .with(P.union(P.nullish, SalaryBandAccessLevel.NONE), () => {
      return [];
    })
    .with(
      P.union(SalaryBandAccessLevel.RANGE, SalaryBandAccessLevel.ENTIRE_BAND, SalaryBandAccessLevel.ONE_LEVEL_ABOVE),
      () => {
        return externalEmployeesInTheRange.map(getId);
      }
    )
    .exhaustive();

  const managerIds = await all(
    match(user.company.userPermissionsPolicy?.managerSalaryBandAccessLevel)
      .with(P.union(P.nullish, SalaryBandAccessLevel.NONE), () => {
        return [];
      })
      .with(
        P.union(SalaryBandAccessLevel.RANGE, SalaryBandAccessLevel.ENTIRE_BAND, SalaryBandAccessLevel.ONE_LEVEL_ABOVE),
        async () => {
          return flatten(
            await all(
              externalEmployeesInTheRange.map((externalEmployee) => {
                // avoid initial request
                if (!externalEmployee.managerExternalEmployeeId) {
                  return [];
                }

                return fetchExternalEmployeeChainManagerIds(ctx, {
                  externalEmployeeId: externalEmployee.id,
                });
              })
            )
          );
        }
      )
      .exhaustive()
  );

  return chain(employeeIds).concat(managerIds).flatten().concat().uniq().value();
};

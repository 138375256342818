import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, SalaryRangeStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const RecreateRangeInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.RECREATE_RANGE>().required(),
    bandId: number().required(),
    newState: SalaryRangeStateSchema.required(),
  })
);

export type RecreateRangeChange = YupOutputType<typeof RecreateRangeInputSchema>;

import {
  type Prisma,
  type SalaryBand,
  type SalaryGridConfigurationChange,
  SalaryGridConfigurationChangeLabel,
} from "@prisma/client";
import { type randomUUID } from "crypto";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { get } from "~/lib/lodash";
import { logError, logWarn } from "~/lib/logger";
import { type FetchSalaryGridConfigurationChangeInput } from "~/pages/api/salary-bands/fetch-salary-grid-configuration-changes";
import { type UpdateConfigurationChangeReasonInput } from "~/pages/api/salary-bands/update-configuration-change-reason";
import { whereSalaryBandIs, whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import {
  type ConvertBandToDraftChange,
  ConvertBandToDraftInputSchema,
} from "~/services/salary-bands/audit-logs/convertBandToDraftSchema";
import { type CreateBandChange, CreateBandInputSchema } from "~/services/salary-bands/audit-logs/createBandSchema";
import { type CreateJobChange, CreateJobInputSchema } from "~/services/salary-bands/audit-logs/createJobSchema";
import { type CreateLevelChange, CreateLevelInputSchema } from "~/services/salary-bands/audit-logs/createLevelSchema";
import {
  type CreateLocationChange,
  CreateLocationInputSchema,
} from "~/services/salary-bands/audit-logs/createLocationSchema";
import { type DeleteJobChange, DeleteJobInputSchema } from "~/services/salary-bands/audit-logs/deleteJobSchema";
import { type DeleteLevelChange, DeleteLevelInputSchema } from "~/services/salary-bands/audit-logs/deleteLevelSchema";
import {
  type DeleteLocationChange,
  DeleteLocationInputSchema,
} from "~/services/salary-bands/audit-logs/deleteLocationSchema";
import { type DeleteRangeChange, DeleteRangeInputSchema } from "~/services/salary-bands/audit-logs/deleteRangeSchema";
import {
  type DuplicateGridForNewVersionChange,
  DuplicateGridForNewVersionInputSchema,
} from "~/services/salary-bands/audit-logs/duplicateGridForNewVersionSchema";
import {
  type RecreateRangeChange,
  RecreateRangeInputSchema,
} from "~/services/salary-bands/audit-logs/recreateRangeSchema";
import { type RenameJobChange, RenameJobInputSchema } from "~/services/salary-bands/audit-logs/renameJobSchema";
import {
  type RenameLocationChange,
  RenameLocationInputSchema,
} from "~/services/salary-bands/audit-logs/renameLocationSchema";
import { type BaseConfigurationChangeSchema } from "~/services/salary-bands/audit-logs/schemas";
import {
  type UpdateBandMarketPositioningChange,
  UpdateBandMarketPositioningInputSchema,
} from "~/services/salary-bands/audit-logs/updateBandMarketPositioningSchema";
import {
  type UpdateBandsWidthChange,
  UpdateBandsWidthInputSchema,
} from "~/services/salary-bands/audit-logs/updateBandsWidthSchema";
import {
  type UpdateBenchmarkedJobsChange,
  UpdateBenchmarkedJobsInputSchema,
} from "~/services/salary-bands/audit-logs/updateBenchmarkedJobsSchema";
import {
  type UpdateBenchmarkedLocationsChange,
  UpdateBenchmarkedLocationsInputSchema,
} from "~/services/salary-bands/audit-logs/updateBenchmarkedLocationsSchema";
import { type UpdateLevelChange, UpdateLevelInputSchema } from "~/services/salary-bands/audit-logs/updateLevelSchema";
import { type UpdateRangeChange, UpdateRangeInputSchema } from "~/services/salary-bands/audit-logs/updateRangeSchema";
import { type UpdateTiersChange, UpdateTiersInputSchema } from "~/services/salary-bands/audit-logs/updateTiersSchema";
import {
  type ValidateBandChange,
  ValidateBandInputSchema,
} from "~/services/salary-bands/audit-logs/validateBandSchema";

export type AuditLogOptions = {
  actionId: ReturnType<typeof randomUUID>;
};

const selectConfigurationChangesForActivityFeed = {
  id: true,
  gridId: true,
  actionId: true,
  bandId: true,
  rangeId: true,
  userId: true,
  user: { select: { id: true, firstName: true, lastName: true, companyId: true } },
  grid: { select: { companyId: true, width: true } },
  label: true,
  reason: true,
  createdAt: true,
  updatedAt: true,
  initialState: true,
  newState: true,
} satisfies Prisma.SalaryGridConfigurationChangeSelect;

const selectSalaryBandForActivityFeed = {
  id: true,
  gridId: true,
  createdAt: true,
  jobId: true,
  locationId: true,
} satisfies Prisma.SalaryBandSelect;

export type ConfigurationChangeForActivityFeed = Prisma.SalaryGridConfigurationChangeGetPayload<{
  select: typeof selectConfigurationChangesForActivityFeed;
}>;

export type SalaryGridConfigurationChangeType =
  | CreateBandChange
  | UpdateRangeChange
  | DeleteRangeChange
  | RecreateRangeChange
  | CreateJobChange
  | RenameJobChange
  | DeleteJobChange
  | CreateLocationChange
  | RenameLocationChange
  | DeleteLocationChange
  | CreateLevelChange
  | UpdateLevelChange
  | DeleteLevelChange
  | ConvertBandToDraftChange
  | ValidateBandChange
  | UpdateBenchmarkedJobsChange
  | UpdateBenchmarkedLocationsChange
  | UpdateBandsWidthChange
  | UpdateBandMarketPositioningChange
  | DuplicateGridForNewVersionChange
  | UpdateTiersChange;

export const fetchSalaryGridConfigurationChangesForBand = async (
  ctx: AppContext,
  band: FetchSalaryGridConfigurationChangeInput
) => {
  const salaryBand = await ctx.prisma.salaryBand.findFirstOrThrow({
    where: whereSalaryBandIs({ salaryBandId: band.bandId, salaryGridId: band.gridId }),
    select: selectSalaryBandForActivityFeed,
  });

  const changes = await ctx.prisma.salaryGridConfigurationChange.findMany({
    where: {
      grid: whereSalaryGridIs({ salaryGridId: salaryBand.gridId }),
      OR: [{ band: { id: salaryBand.id } }, { band: null, createdAt: { gte: salaryBand.createdAt } }],
    },
    orderBy: { createdAt: "desc" },
    select: selectConfigurationChangesForActivityFeed,
  });

  return changes.filter((change) => {
    if (!isRelevantChangeForBand(salaryBand, change)) {
      return false;
    }

    if (isValidConfigurationChange(change)) {
      return true;
    }

    try {
      typeSafeConfigurationChange(change);
    } catch (error) {
      logWarn(ctx, "[sb-audit] A malformed configuration change was ignored", {
        salaryGridId: salaryBand.gridId,
        salaryBandId: salaryBand.id,
        salaryGridConfigurationChangeId: change.id,
        label: change.label,
        error,
      });
    }

    return false;
  });
};

const isRelevantChangeForBand = (
  band: Pick<SalaryBand, "id" | "jobId" | "locationId" | "gridId">,
  change: ConfigurationChangeForActivityFeed
) =>
  match(change.label)
    .with(SalaryGridConfigurationChangeLabel.CREATE_BAND, () => true)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_BENCHMARKED_JOBS, () => true)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_BENCHMARKED_LOCATIONS, () => true)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_RANGE, () => true)
    .with(SalaryGridConfigurationChangeLabel.DELETE_RANGE, () => true)
    .with(SalaryGridConfigurationChangeLabel.RECREATE_RANGE, () => true)
    .with(SalaryGridConfigurationChangeLabel.CREATE_JOB, () => get(change, "newState.id") === band.jobId)
    .with(SalaryGridConfigurationChangeLabel.DELETE_JOB, () => get(change, "initialState.id") === band.jobId)
    .with(SalaryGridConfigurationChangeLabel.RENAME_JOB, () => get(change, "initialState.id") === band.jobId)
    .with(SalaryGridConfigurationChangeLabel.CREATE_LOCATION, () => get(change, "newState.id") === band.locationId)
    .with(SalaryGridConfigurationChangeLabel.DELETE_LOCATION, () => get(change, "initialState.id") === band.locationId)
    .with(SalaryGridConfigurationChangeLabel.RENAME_LOCATION, () => get(change, "initialState.id") === band.locationId)
    .with(SalaryGridConfigurationChangeLabel.CREATE_LEVEL, () => true)
    .with(SalaryGridConfigurationChangeLabel.DELETE_LEVEL, () => true)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_LEVEL, () => true)
    .with(SalaryGridConfigurationChangeLabel.CONVERT_BAND_TO_DRAFT, () => true)
    .with(SalaryGridConfigurationChangeLabel.VALIDATE_BAND, () => true)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_BANDS_WIDTH, () => true)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_MARKET_POSITIONING, () => true)
    .with(
      SalaryGridConfigurationChangeLabel.DUPLICATE_GRID_FOR_NEW_VERSION,
      () => get(change, "gridId") === band.gridId
    )
    .with(SalaryGridConfigurationChangeLabel.UPDATE_TIERS, () => true)
    .exhaustive();

export type FetchSalaryGridConfigurationChangesForBandResult = AsyncReturnType<
  typeof fetchSalaryGridConfigurationChangesForBand
>;

const isValidConfigurationChange = (
  salaryGridConfigurationChange: Pick<ConfigurationChangeForActivityFeed, "label">
) => {
  const schema = getConfigurationChangeSchema(salaryGridConfigurationChange);

  return schema.isValidSync(salaryGridConfigurationChange);
};

export const typeSafeConfigurationChange = (
  salaryGridConfigurationChange: Pick<ConfigurationChangeForActivityFeed, "label">
) => {
  const schema = getConfigurationChangeSchema(salaryGridConfigurationChange);

  return schema.validateSync(salaryGridConfigurationChange, {
    stripUnknown: true,
  }) as SalaryGridConfigurationChangeType;
};

export const getConfigurationChangeSchema = (
  input: Pick<ConfigurationChangeForActivityFeed, "label">
):
  | typeof CreateBandInputSchema
  | typeof UpdateBenchmarkedJobsInputSchema
  | typeof UpdateBenchmarkedLocationsInputSchema
  | typeof BaseConfigurationChangeSchema
  | typeof UpdateRangeInputSchema
  | typeof CreateJobInputSchema
  | typeof RenameJobInputSchema
  | typeof DeleteJobInputSchema
  | typeof CreateLocationInputSchema
  | typeof RenameLocationInputSchema
  | typeof DeleteLocationInputSchema
  | typeof CreateLevelInputSchema
  | typeof UpdateLevelInputSchema
  | typeof DeleteLevelInputSchema
  | typeof ConvertBandToDraftInputSchema
  | typeof ValidateBandInputSchema
  | typeof UpdateBandsWidthInputSchema
  | typeof UpdateBandMarketPositioningInputSchema
  | typeof DuplicateGridForNewVersionInputSchema =>
  match(input.label)
    .with(SalaryGridConfigurationChangeLabel.CREATE_BAND, () => CreateBandInputSchema)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_BENCHMARKED_JOBS, () => UpdateBenchmarkedJobsInputSchema)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_BENCHMARKED_LOCATIONS, () => UpdateBenchmarkedLocationsInputSchema)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_RANGE, () => UpdateRangeInputSchema)
    .with(SalaryGridConfigurationChangeLabel.DELETE_RANGE, () => DeleteRangeInputSchema)
    .with(SalaryGridConfigurationChangeLabel.RECREATE_RANGE, () => RecreateRangeInputSchema)
    .with(SalaryGridConfigurationChangeLabel.CREATE_JOB, () => CreateJobInputSchema)
    .with(SalaryGridConfigurationChangeLabel.RENAME_JOB, () => RenameJobInputSchema)
    .with(SalaryGridConfigurationChangeLabel.DELETE_JOB, () => DeleteJobInputSchema)
    .with(SalaryGridConfigurationChangeLabel.CREATE_LOCATION, () => CreateLocationInputSchema)
    .with(SalaryGridConfigurationChangeLabel.RENAME_LOCATION, () => RenameLocationInputSchema)
    .with(SalaryGridConfigurationChangeLabel.DELETE_LOCATION, () => DeleteLocationInputSchema)
    .with(SalaryGridConfigurationChangeLabel.CREATE_LEVEL, () => CreateLevelInputSchema)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_LEVEL, () => UpdateLevelInputSchema)
    .with(SalaryGridConfigurationChangeLabel.DELETE_LEVEL, () => DeleteLevelInputSchema)
    .with(SalaryGridConfigurationChangeLabel.CONVERT_BAND_TO_DRAFT, () => ConvertBandToDraftInputSchema)
    .with(SalaryGridConfigurationChangeLabel.VALIDATE_BAND, () => ValidateBandInputSchema)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_BANDS_WIDTH, () => UpdateBandsWidthInputSchema)
    .with(SalaryGridConfigurationChangeLabel.UPDATE_MARKET_POSITIONING, () => UpdateBandMarketPositioningInputSchema)
    .with(
      SalaryGridConfigurationChangeLabel.DUPLICATE_GRID_FOR_NEW_VERSION,
      () => DuplicateGridForNewVersionInputSchema
    )
    .with(SalaryGridConfigurationChangeLabel.UPDATE_TIERS, () => UpdateTiersInputSchema)
    .exhaustive();

export const auditLogConfigurationChange = async (
  ctx: AppContext,
  input: Prisma.SalaryGridConfigurationChangeUncheckedCreateInput
) => {
  const user = getRequiredUser(ctx);

  try {
    const data = typeSafeConfigurationChange(input);

    await ctx.prisma.salaryGridConfigurationChange.create({ data });
  } catch (error) {
    logError(ctx, "[sb-audit] Could not save audit log event", {
      label: input.label,
      error,
      companyId: user.companyId,
    });
  }
};

const checkConfigurationChangeOwnership = async (
  ctx: AppContext,
  configurationChange: Pick<SalaryGridConfigurationChange, "bandId" | "gridId">
) => {
  const { bandId, gridId } = configurationChange;

  const ownershipParams = bandId
    ? whereSalaryBandIs({ salaryBandId: bandId, salaryGridId: gridId })
    : whereSalaryGridIs({ salaryGridId: gridId });

  return ctx.prisma.salaryBand.findFirstOrThrow({
    where: ownershipParams,
    select: { id: true },
  });
};

export const updateConfigurationChangeReason = async (ctx: AppContext, input: UpdateConfigurationChangeReasonInput) => {
  const configurationChange = await ctx.prisma.salaryGridConfigurationChange.findUniqueOrThrow({
    where: { id: input.configurationChangeId },
    select: { id: true, gridId: true, bandId: true },
  });

  await checkConfigurationChangeOwnership(ctx, configurationChange);

  return ctx.prisma.salaryGridConfigurationChange.update({
    where: { id: input.configurationChangeId },
    data: { reason: input.reason },
    select: selectConfigurationChangesForActivityFeed,
  });
};

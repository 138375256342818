import { createReadStream, createWriteStream, type ReadStream, statSync } from "fs";
import sizeOf from "image-size";
import { tmpdir } from "os";
import { join } from "path";
import { fetch } from "~/lib/fetch";

export type ClearbitImage = {
  content: ReadStream;
  height: number;
  path: string;
  size: number;
  width: number;
};

export const downloadClearbitImage = async (companyId: number, companyUrl: string): Promise<ClearbitImage | null> => {
  const res = await fetch(`https://logo.clearbit.com/${companyUrl}?size=512`);

  const path: string = await new Promise((resolve, reject) => {
    const imagePath = join(tmpdir(), `company-logo-${companyId}.png`);
    const fileStream = createWriteStream(imagePath);

    res.body.pipe(fileStream);

    res.body.on("error", (err) => {
      reject(err);
    });

    fileStream.on("finish", () => {
      resolve(imagePath);
    });
  });

  const { size } = statSync(path);
  // Clearbit image is broken
  if (size === 1) {
    return null;
  }

  const content = createReadStream(path);
  const { width, height } = sizeOf(path) as { width: number; height: number };

  return {
    content,
    height,
    path,
    size,
    width,
  };
};

import { Stack, Typography } from "@mui/material";
import { type Prisma } from "@prisma/client";
import React, { type PropsWithChildren, type ReactNode } from "react";
import CountryFlag from "react-country-flag";
import { value } from "~/components/helpers";
import { Icon } from "~/components/ui/core/Icon";
import { type IconName } from "~/components/ui/Icons";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { isString } from "~/lib/lodash";
import { formatLevel } from "~/services/employee/employeeLevel";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { formatGender } from "~/services/external-employee/gender";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";

export const externalEmployeeSelectForCard = {
  id: true,
  gender: true,
  firstName: true,
  lastName: true,
  externalId: true,
  employeeNumber: true,
  level: {
    select: {
      id: true,
      name: true,
      mappedLevel: true,
    },
  },
  job: {
    select: {
      id: true,
      name: true,
      mappedJob: {
        select: {
          id: true,
          name: true,
        },
      },
    },
  },
  location: {
    select: {
      id: true,
      name: true,
      country: {
        select: {
          id: true,
          alpha2: true,
          name: true,
        },
      },
      mappedLocation: {
        select: {
          id: true,
          country: {
            select: {
              id: true,
              alpha2: true,
              name: true,
            },
          },
        },
      },
    },
  },
  ...selectExternalEmployeeUserPicture,
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeeForCard = Prisma.ExternalEmployeeGetPayload<{
  select: typeof externalEmployeeSelectForCard;
}>;

type Props = {
  employee: ExternalEmployeeForCard;
};

export const ExternalEmployeeCard: React.FC<Props> = ({ employee }) => {
  const { t } = useI18n();
  const { user } = useSession();

  const formattedLevel = value(() => {
    if (!employee.level) {
      return "";
    }
    if (employee.level.mappedLevel) {
      return formatLevel(t, employee.level.mappedLevel, user?.company.useAdvancedLevels ?? true);
    }
    return employee.level.name;
  });

  const formattedJob = value(() => {
    if (!employee.job) {
      return "";
    }
    if (employee.job.mappedJob) {
      return employee.job.mappedJob.name;
    }
    return employee.job.name;
  });

  const levelJob = value(() => {
    if (!formattedLevel && !formattedJob) {
      return "No job details provided";
    }
    return [formattedLevel, formattedJob]
      .filter((string) => {
        return !!string;
      })
      .join(" ");
  });

  const formattedLocation = value(() => {
    if (!employee.location) {
      return null;
    }

    if (employee.location.mappedLocation) {
      return (
        <Line icon={<CountryFlag svg className="ml-1" countryCode={employee.location.mappedLocation.country.alpha2} />}>
          {employee.location.name}, {employee.location.mappedLocation.country.name}
        </Line>
      );
    }

    if (employee.location.country) {
      return (
        <Line icon={<CountryFlag svg className="ml-1" countryCode={employee.location.country.alpha2} />}>
          {employee.location.name}, {employee.location.country.name}
        </Line>
      );
    }

    return <Line icon="building">{employee.location.name}</Line>;
  });

  return (
    <Stack className="flex w-96 flex-col">
      <div className="mb-0 rounded-t-sm bg-gradient-to-br from-primary-400 to-primary-600 py-3 px-2">
        <Typography variant="h3" className="leading-none !text-gray-50">
          {formatExternalEmployeeName(employee)}
        </Typography>
      </div>

      <Stack gap={1} className="mt-1.5 px-2 pb-2">
        <Line icon="hammer-wrench">{levelJob}</Line>

        <Line icon="gender">
          {employee.gender && formatGender(t, employee.gender)}
          {!employee.gender && <span className="text-gray-400">Not provided</span>}
        </Line>

        {formattedLocation}
      </Stack>
    </Stack>
  );
};

const Line: React.FC<PropsWithChildren<{ icon: IconName | ReactNode }>> = ({ children, icon }) => {
  return (
    <Stack direction="row" gap={1.5} className="items-center">
      {isString(icon) ? <Icon name={icon as IconName} className="text-gray-400" /> : icon}
      <Typography variant="subtitle1" className="text-gray-700">
        {children}
      </Typography>
    </Stack>
  );
};

import { type Company } from "@prisma/client";
import uniqid from "uniqid";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { downloadClearbitImage } from "~/lib/external/clearbit/client";
import { getPublicAssetFilePath } from "~/lib/files/getPublicAssetFilePath";
import { uploadFile } from "~/lib/files/uploadFile";
import { logWarn } from "~/lib/logger";

export const syncCompanyLogo = async (
  ctx: AppContext,
  company: Pick<Company, "id" | "url" | "name">
): Promise<void> => {
  const image = await downloadClearbitImage(company.id, company.url);
  if (!image) {
    return;
  }

  const { content, height, size, width } = image;
  const path = `${config.files.prefixes.companiesLogos}/${uniqid()}.png`;

  await uploadFile(ctx, {
    Bucket: config.files.bucket,
    Key: getPublicAssetFilePath(path),
    ContentType: "image/png",
    ContentLength: size,
    Body: content,
  });

  try {
    await ctx.prisma.company.update({
      data: {
        logo: {
          create: {
            bucket: config.files.bucket,
            height,
            mimeType: "image/png",
            originalName: `${company.name}.png`,
            path,
            size,
            width,
          },
        },
      },
      where: {
        id: company.id,
      },
    });
  } catch (error) {
    logWarn(ctx, "[logo-sync] error on logo sync", { error });
    return;
  }
};

import {
  type Company,
  type CompanyFile,
  type CompanyImpersonationAccess,
  type Country,
  type IntegrationSource,
  type JobRequest,
  type PerformanceReviewIntegrationSource,
  type User,
  UserLocale,
} from "@prisma/client";
import { addHours, subHours } from "date-fns";
import { SUMMARIZING_SEPARATOR } from "~/cli/ops:release/release-notes-prompt";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { formatAppMetadataForCsm } from "~/hooks/useAppMetadataForCsm";
import { type AppContext } from "~/lib/context";
import { DateFormats, formatDate } from "~/lib/dates";
import { type KomboIntegrationDetailsStatus } from "~/lib/hris/kombo";
import { capitalize, compact, flatten, groupBy } from "~/lib/lodash";
import { type AuthenticatedUser, ImpersonateCompanyIdParam } from "~/lib/session";
import { fireAndForget } from "~/lib/utils";
import { type NotifyMetadataSupportInput } from "~/pages/api/dev/notify-metadata-support";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { MAX_RETRY_COUNT } from "~/services/synchronization/fetchExternalEmployeesFromHrisMaxRetryCount";
import { type CompanyForSync } from "~/services/synchronization/syncExternalEmployees";

const Channels = {
  "feature-job-creation": "C04JSUAFYKC",
  "notif-sign-ups": "C01P1ED5VMX",
  "notif-tech-support": "C01QZ679K7C",
  "notif-data-validation": "C01USKRA6SJ",
  "notif-tech-positioning": "C03GU5VV8J0",
  "notif-tech-monitoring": "C01Q28SJ031",
  "notif-access-granted-revoked": "C03SHGUQS8Y",
  "csm": "C03B4GEB07R",
  "notif-external-invitation-requests": "C06MZURUF0E",
} as const;

const Users = {
  Guillaume: "U038JQGCJ67",
} as const;

const channel = (channel: keyof typeof Channels) => {
  if (!config.app.isProduction) {
    return config.dev.slackChannelOverride;
  }

  return Channels[channel];
};

const slackUser = (user: keyof typeof Users) => {
  if (!config.app.isProduction) {
    return user;
  }

  return Users[user];
};

const postSlackMessage = async (ctx: AppContext, params: Parameters<typeof ctx.slack.chat.postMessage>[0]) => {
  return fireAndForget(ctx.slack.chat.postMessage(params));
};

type NewFileUploaded = {
  user: Pick<User, "id" | "firstName" | "lastName"> & { company: Pick<Company, "id" | "name"> };
  file: Pick<CompanyFile, "id" | "name" | "createdAt">;
};

export const notifyNewFileUploaded = async (ctx: AppContext, event: NewFileUploaded) => {
  const { user, file } = event;

  await postSlackMessage(ctx, {
    channel: channel("csm"),
    text: "New File Uploaded",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "A new file has been uploaded!",
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `By: *${user.firstName} ${user.lastName}* #${user.id} @ *${user.company.name}* #${user.company.id}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `File: *${file.name}* #${file.id} on *${formatDate(
            file.createdAt,
            DateFormats.DATE_TIME,
            UserLocale.EN
          )}*`,
        },
      },
    ],
  });
};

type NewUserSignUp = {
  user: User & {
    company: Company;
  };
};

export const notifyNewUserSignUp = async (ctx: AppContext, event: NewUserSignUp) => {
  const { user } = event;

  await postSlackMessage(ctx, {
    channel: channel("notif-sign-ups"),
    text: `New user sign-up`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `A new user signed up!`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: user.company
            ? `*${user.firstName} ${user.lastName}* @ *${user.company.name}* · ${user.email}`
            : `*${user.firstName} ${user.lastName}* · ${user.email}`,
        },
        accessory: {
          type: "image",
          image_url: user.profilePictureUrl,
          alt_text: `${user.firstName} ${user.lastName}`,
        },
      },
    ],
  });
};

type FactorialMigrationPayload = {
  user: RequiredAuthenticatedUser;
};

export const notifyFactorialMigration = async (ctx: AppContext, payload: FactorialMigrationPayload) => {
  const { user } = payload;

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: `New Factorial migration`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*${user.firstName} ${user.lastName}* @ *${user.company.name} #${user.company.id}* just added their Kombo Factorial integration!`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `<@${slackUser("Guillaume")}> Go to their backoffice page to migrate their data.`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Migrate their data",
          },
          url: `${config.app.url}/admin/companies/${user.company.id}`,
        },
      },
    ],
  });
};

export type KomboMonitoringPayload = {
  integrations: {
    status: keyof typeof KomboIntegrationDetailsStatus;
    integrationName: string;
    company: Pick<Company, "name">;
  }[];
};

export const notifyKomboMonitoring = async (ctx: AppContext, payload: KomboMonitoringPayload) => {
  const { integrations } = payload;

  const integrationByStatus = groupBy(integrations, "status");

  const shouldNotify =
    (integrationByStatus.INVALID && integrationByStatus.INVALID.length > 0) ||
    (integrationByStatus.INACTIVE && integrationByStatus.INACTIVE.length > 0);

  if (!shouldNotify) {
    return;
  }

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: `Kombo monitoring digest`,
    blocks: compact([
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `:kombo: *Kombo integration monitoring update!* :kombo:`,
        },
      },
      {
        type: "divider",
      },
      ...(integrationByStatus.INVALID && integrationByStatus.INVALID.length > 0
        ? [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `🚨 *Invalid integrations:* ${integrationByStatus.INVALID?.length}`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `${integrationByStatus.INVALID.map((i) => `• ${i.company.name}`).join("\n")}`,
              },
            },
          ]
        : []),
      ...(integrationByStatus.INACTIVE && integrationByStatus.INACTIVE.length > 0
        ? [
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `😴 *Inactive integrations:* ${integrationByStatus.INACTIVE?.length}`,
              },
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `${integrationByStatus.INACTIVE.map((i) => `• ${i.company.name}`).join("\n")}`,
              },
            },
          ]
        : []),
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `@teamcsm Please contact the customers to reconnect their integration :pray:.`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Go to Kombo dashboard",
          },
          url: `https://app.kombo.dev/env/figureshr-prod/integrations`,
        },
      },
    ]),
  });
};

type EmailDeliveryError = {
  messageId: string;
  email: string;
  error: string;
};

export const notifyEmailDeliveryError = async (ctx: AppContext, event: EmailDeliveryError) => {
  const { messageId, email } = event;

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: `💌 Email delivery error`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `💌 Error delivering email to ${email} (Message ID : \`${messageId}\`)`,
        },
      },
    ],
  });
};

// The codepoint for a country's flag emoji can be calculated from the codepoints of its 2-letter code (FR, GB, etc...)
// Trick explained => https://dev.to/jorik/country-code-to-flag-emoji-a21
export const getFlagEmoji = (countryCode: Country["alpha2"]) => {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

type TooManyCreatedDeletedEmployees = {
  companyName: string;
  companyId: number;
  countryAlpha2: string;
  lastSyncCreated: number;
  lastSyncUpdated: number;
  lastSyncDeleted: number;
};

const formatTooManyCreatedDeletedEmployeesLine = (payload: TooManyCreatedDeletedEmployees): string => {
  const flagEmoji = getFlagEmoji(payload.countryAlpha2);
  const companyLink = `<${config.app.url}/account/imported-employees?${ImpersonateCompanyIdParam}=${payload.companyId}|${payload.companyName}>`;

  return `${flagEmoji} ${companyLink} · ${payload.lastSyncCreated} employees created, ${payload.lastSyncUpdated} employees updated, ${payload.lastSyncDeleted} employees deleted on last synchronisation`;
};

export const notifyTooManyCreatedDeletedEmployees = async (ctx: AppContext, event: TooManyCreatedDeletedEmployees) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-data-validation"),
    text: "Attention required ⚠️",
    unfurl_links: false,
    unfurl_media: false,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "Attention required: an integration synchronisation affected many employees:",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: formatTooManyCreatedDeletedEmployeesLine(event),
        },
      },
    ],
  });
};

type IntegrationError = {
  company: CompanyForSync;
  error: string;
};

export const notifyIntegrationError = async (ctx: AppContext, event: IntegrationError) => {
  const { company, error } = event;

  const flag = getFlagEmoji(company.defaultCountry.alpha2);

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: "Integration error",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${flag} The integration for company ${company.name} (${company.id}) has errored. It will be disabled after ${MAX_RETRY_COUNT} retry attempts.`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `>${error}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Fix it",
          },
          url: `${config.app.url}/admin/companies/${company.id}`,
        },
      },
    ],
  });
};

type HrisSyncFailedDueToFailedEmployees = {
  company: { id: number; name: string; defaultCountry: { alpha2: string } };
  hrisSyncId: number;
  hrisSyncCampaignId: number | null;
  failedEmployeeNumbers: string[];
  campaignMessageTimestamp?: string | null;
};

export const notifyHrisSyncFailedDueToFailedEmployees = async (
  ctx: AppContext,
  event: HrisSyncFailedDueToFailedEmployees
) => {
  const { company, failedEmployeeNumbers, hrisSyncId, hrisSyncCampaignId, campaignMessageTimestamp } = event;

  const flag = getFlagEmoji(company.defaultCountry.alpha2);
  const datadogUrl = makeDatadogUrl({
    fromInHours: 12,
    toInHours: 12,
    query: `service:("@figures/web") cluster_name:production @hrisSyncId:${hrisSyncId}`,
  });

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: "HRIS Sync Failed",
    ...(campaignMessageTimestamp && { thread_ts: campaignMessageTimestamp }),
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${flag} The integration for company ${company.name} (#${company.id}) has failed to complete correctly.`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `HRIS Sync ID: ${hrisSyncId}
 HRIS Sync Campaign ID: ${hrisSyncCampaignId ?? "-"}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Employee numbers at fault: ${failedEmployeeNumbers.join(", ")}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Check Datadog",
          },
          url: datadogUrl.toString(),
        },
      },
    ],
  });
};

type HrisSyncFailedDueToError = {
  company: { id: number; name: string; defaultCountry: { alpha2: string } };
  hrisSyncId: number;
  hrisSyncCampaignId: number | null;
  error: unknown;
  campaignMessageTimestamp?: string | null;
};

export const notifyHrisSyncFailedDueToError = async (ctx: AppContext, event: HrisSyncFailedDueToError) => {
  const { company, error, hrisSyncId, hrisSyncCampaignId, campaignMessageTimestamp } = event;

  const flag = getFlagEmoji(company.defaultCountry.alpha2);
  const datadogUrl = makeDatadogUrl({
    fromInHours: 12,
    toInHours: 12,
    query: `service:("@figures/web") cluster_name:production @hrisSyncId:${hrisSyncId}`,
  });

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: "HRIS Sync Failed With Error",
    ...(campaignMessageTimestamp && { thread_ts: campaignMessageTimestamp }),
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${flag} The integration for company ${company.name} (#${company.id}) has failed to complete with the following error.`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `HRIS Sync ID: ${hrisSyncId}
 HRIS Sync Campaign ID: ${hrisSyncCampaignId ?? "-"}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Error: ${error}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Check Datadog",
          },
          url: datadogUrl.toString(),
        },
      },
    ],
  });
};

type HrisSyncCampaignStarted = {
  companyCount: number;
  hrisSyncCampaignId: number;
};

export const notifyHrisSyncCampaignStarted = async (ctx: AppContext, event: HrisSyncCampaignStarted) => {
  const { companyCount, hrisSyncCampaignId } = event;

  const datadogUrl = makeDatadogUrl({
    fromInHours: 12,
    toInHours: 12,
    query: `service:("@figures/web") cluster_name:production @hrisSyncCampaignId:${hrisSyncCampaignId}`,
  });

  return postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: "HRIS Sync Campaign Started",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `▶️ HRIS Sync Campaign (#${hrisSyncCampaignId}) started with ${companyCount} companies.`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Check Datadog",
          },
          url: datadogUrl.toString(),
        },
      },
    ],
  });
};

type HrisSyncCampaignFinished = {
  isSuccessful: boolean;
  hrisSyncCampaignId: number;
  totalFailedSyncs: number;
  totalFailedEmployeeCount: number;
  totalProcessedEmployeeCount: number;
  campaignMessageTimestamp?: string | null;
};

export const notifyHrisSyncCampaignFinished = async (ctx: AppContext, event: HrisSyncCampaignFinished) => {
  const {
    isSuccessful,
    hrisSyncCampaignId,
    totalFailedEmployeeCount,
    totalProcessedEmployeeCount,
    totalFailedSyncs,
    campaignMessageTimestamp,
  } = event;

  const datadogUrl = makeDatadogUrl({
    fromInHours: 12,
    toInHours: 12,
    query: `service:("@figures/web") cluster_name:production @hrisSyncCampaignId:${hrisSyncCampaignId}`,
  });

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: "HRIS Sync Campaign Finished",
    ...(campaignMessageTimestamp && { thread_ts: campaignMessageTimestamp }),
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `⏹️ HRIS Sync Campaign (#${hrisSyncCampaignId}) finished.`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Status: ${isSuccessful ? "✅ SUCCESS" : "❌ FAILURE"}
 Total Failed Syncs: ${totalFailedSyncs}
 Total Failed Employees: ${totalFailedEmployeeCount}
 Total Processed Employees: ${totalProcessedEmployeeCount}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Check Datadog",
          },
          url: datadogUrl.toString(),
        },
      },
    ],
  });
};

type ImportSpreadsheetNotSuccessful = {
  importId: number;
  totalRowsCount: number;
  successRowsCount: number;
  warningRowsCount: number;
  errorRowsCount: number;
};

export const notifyImportSpreadsheetNotSuccessful = async (ctx: AppContext, event: ImportSpreadsheetNotSuccessful) => {
  const { importId, totalRowsCount, successRowsCount, warningRowsCount, errorRowsCount } = event;

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: successRowsCount > 0 ? "Spreadsheet import partially failed" : "Spreadsheet import failed",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `📄 *Spreadsheet import (#${importId})${successRowsCount > 0 ? " partially" : ""} failed*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Total of *${totalRowsCount}* rows :
 - success : ${successRowsCount}
 - error : ${errorRowsCount}
 - warning : ${warningRowsCount}`,
        },
      },
    ],
  });
};

type IntegrationDisabled = {
  company: CompanyForSync;
  lastError: string;
};

export const notifyIntegrationDisabled = async (ctx: AppContext, event: IntegrationDisabled) => {
  const { company, lastError } = event;

  const flag = getFlagEmoji(company.defaultCountry.alpha2);

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: "Integration disabled",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `${flag} The integration for company ${company.name} (${company.id}) has been disabled after erroring ${MAX_RETRY_COUNT} consecutive times.`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `>${lastError}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Fix it",
          },
          url: `${config.app.url}/admin/companies/${company.id}`,
        },
      },
    ],
  });
};

type IntegrationSetup = {
  user: AuthenticatedUser;
  integration: IntegrationSource;
};

export const notifyIntegrationSetup = async (ctx: AppContext, event: IntegrationSetup) => {
  const { user, integration } = event;
  const integrationName = capitalize(integration);

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: `🚰 New Integration Setup`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🚰 *${user.firstName} ${user.lastName}* @ *${user.company.name}* just setup their ${integrationName} integration!`,
        },
      },
    ],
  });
};

type PerformanceReviewIntegrationError = {
  error: string;
  source: PerformanceReviewIntegrationSource;
  companyId: number;
  companyName: string;
};

export const notifyPerformanceReviewIntegrationError = async (
  ctx: AppContext,
  event: PerformanceReviewIntegrationError
) => {
  const { companyId, companyName, source, error } = event;
  const integrationName = capitalize(source);

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: "🚱 Performance Review Integration error",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Due to the following error, the integration ${integrationName} for company ${companyName} (${companyId}) has been disabled.`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `>${error}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Fix it",
          },
          url: `${config.app.url}/admin/companies/${companyId}`,
        },
      },
    ],
  });
};

type PerformanceReviewIntegrationSetup = {
  user: AuthenticatedUser;
  integration: PerformanceReviewIntegrationSource;
};

export const notifyPerformanceReviewIntegrationSetup = async (
  ctx: AppContext,
  event: PerformanceReviewIntegrationSetup
) => {
  const { user, integration } = event;
  const integrationName = capitalize(integration);

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: `🚰 New Performance Review Integration Setup`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🚰 *${user.firstName} ${user.lastName}* @ *${user.company.name}* just setup their ${integrationName} performance review integration!`,
        },
      },
    ],
  });
};

type MarketMovementRecap = {
  companiesCount: number;
  usersCount: number;
  belowTargetCount: number;
  wayBelowTargetCount: number;
};

export const notifyMarketMovementRecap = async (ctx: AppContext, event: MarketMovementRecap) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-tech-positioning"),
    text: `Market movement recap 📉`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `:chart_with_downwards_trend: Market movement digest :chart_with_downwards_trend::`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🏢 ${event.companiesCount} companies`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `📩 ${event.usersCount} users`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🔽 ${event.belowTargetCount} new below target`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `⏬ ${event.wayBelowTargetCount} new way below target`,
        },
      },
    ],
  });
};

type ImpersonationAccessApproval = {
  company: Company;
  accessData: CompanyImpersonationAccess;
};

export const notifyImpersonationAccessApproval = async (
  ctx: AppContext,
  { company, accessData }: ImpersonationAccessApproval
) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-access-granted-revoked"),
    text: `New access approved`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `✅ A company has granted us an access!`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*${company.name}* (#${company.id})`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Expires at: *${
            accessData.expiresAt ? formatDate(accessData.expiresAt, DateFormats.DATE_TIME, UserLocale.EN) : "Never"
          }*`,
        },
      },
    ],
  });
};

type ImpersonationAccessRevokation = {
  company: Company;
};

export const notifyImpersonationAccessRevokation = async (
  ctx: AppContext,
  { company }: ImpersonationAccessRevokation
) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-access-granted-revoked"),
    text: `Impersonation access revoked`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `❌ A company has revoked our access!`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `➡️ *${company.name}* (#${company.id})`,
        },
      },
    ],
  });
};

type CliRunFailure = {
  scriptName: string;
  args: Record<string, unknown>;
  error: Error;
};

export const notifyCliRunFailure = async (ctx: AppContext, event: CliRunFailure) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: `🚨 CLI run failure`,
    blocks: [
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🚨 CLI run failure!`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Script:* \`${event.scriptName}\``,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Args:* \`\`\`${JSON.stringify(event.args, null, 2)}\`\`\``,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Stack:* \`\`\`${event.error.stack}\`\`\``,
        },
      },
    ],
  });
};

export const notifyNewJobRequest = async (
  ctx: AppContext,
  jobRequest: Pick<JobRequest, "id" | "name"> & { company: Pick<Company, "id" | "name"> }
) => {
  await postSlackMessage(ctx, {
    channel: channel("feature-job-creation"),
    text: `Job request created`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `:mailbox_with_mail: A new job request has been created!`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*${jobRequest.name}* (request #${jobRequest.id})`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Requested by: ${jobRequest.company.name} (#${jobRequest.company.id})`,
        },
      },
    ],
  });
};

export const notifyExternalInvitationRequest = async (
  ctx: AppContext,
  invitedUser: Pick<User, "id" | "email"> & {
    company: Pick<Company, "id" | "name">;
    invitedBy: Pick<User, "email" | "firstName" | "lastName"> | null;
  }
) => {
  const authorName = value(() => {
    if (!invitedUser.invitedBy) {
      return "Unknown";
    }

    return `${invitedUser.invitedBy.firstName} ${invitedUser.invitedBy.lastName}`;
  });

  await postSlackMessage(ctx, {
    channel: channel("notif-external-invitation-requests"),
    text: `External invitation request created`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `:mailbox_with_mail: A new external invitation has been requested!`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*${invitedUser.email}*`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `Requested by: *${authorName}* (${invitedUser.invitedBy?.email ?? "Unknown"}) for ${
            invitedUser.company.name
          } (#${invitedUser.company.id})`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Review it",
          },
          url: `${config.app.url}/admin/companies/${invitedUser.company.id}`,
        },
      },
    ],
  });
};

export const notifyReleaseNotes = async (ctx: AppContext, releaseTag: string, releaseNotes: string) => {
  const categories = releaseNotes
    .split(SUMMARIZING_SEPARATOR)
    .map((category) => {
      const lines = category.split("\n").filter(String);

      const [title, ...items] = lines;
      return { title, items };
    })
    .map(({ title, items }) => [
      { type: "divider" },
      {
        type: "section",
        text: { type: "mrkdwn", text: `*${title}*` },
      },
      ...items.map((item) => ({
        type: "section",
        text: { type: "mrkdwn", text: item },
      })),
    ]);

  await postSlackMessage(ctx, {
    channel: Channels["csm"],
    text: `Release notes for ${releaseTag}`,
    blocks: [
      {
        type: "section",
        text: {
          type: "plain_text",
          text: `:rocket: Here are the release notes for release v${releaseTag}`,
          emoji: true,
        },
        accessory: {
          type: "image",
          image_url:
            "https://assets-global.website-files.com/6287a930d7aa5b6eec975969/62c32be6bae11758cb4a778d_Capture%20d%E2%80%99e%CC%81cran%202022-07-04%20a%CC%80%2020.05.16.png",
          alt_text: "cute CEO",
        },
      },
      ...flatten(categories),
    ],
  });
};

type SuperAdminUsers = {
  users: Pick<User, "firstName" | "lastName" | "id" | "email">[];
};

export const notifySuperAdminWarning = async (ctx: AppContext, event: SuperAdminUsers) => {
  const { users } = event;

  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: "Super Admins Warning",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "⚠️ The following users are super admins without associated audit logs",
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: users.map((user) => `- *${user.firstName} ${user.lastName}* @ *${user.id}* · ${user.email}`).join("\n"),
        },
      },
    ],
  });
};

export const notifySupportMetadataReceived = async (ctx: AppContext, metadata: NotifyMetadataSupportInput) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-tech-support"),
    text: "Navigation Metadata Received",
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: ":inbox_tray:  A client sent us their navigation metadata",
        },
      },
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: formatAppMetadataForCsm(metadata),
        },
      },
    ],
  });
};

type DeleteEmployeesError = {
  company: Pick<Company, "id" | "name">;
  error: Error;
};

export const notifyDeleteEmployeesError = async (ctx: AppContext, event: DeleteEmployeesError) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: `🚨 Delete employees error`,
    blocks: [
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🚨 Delete employees error!`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Company:* ${event.company.name} #${event.company.id}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Error:* \`\`\`${JSON.stringify(event.error, null, 2)}\`\`\``,
        },
      },
    ],
  });
};

type DeleteCompanyError = {
  company: Pick<Company, "id" | "name">;
  error: Error;
};

export const notifyDeleteCompanyError = async (ctx: AppContext, event: DeleteCompanyError) => {
  await postSlackMessage(ctx, {
    channel: channel("notif-tech-monitoring"),
    text: `🚨 Delete company error`,
    blocks: [
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🚨 Delete company error!`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Company:* ${event.company.name} #${event.company.id}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Error:* \`\`\`${JSON.stringify(event.error, null, 2)}\`\`\``,
        },
      },
    ],
  });
};

const makeDatadogUrl = (params: { fromInHours: number; toInHours: number; query: string }) => {
  const now = new Date();
  const from = subHours(now, params.fromInHours);
  const to = addHours(now, params.toInHours);
  const datadogUrl = new URL("https://app.datadoghq.eu/logs");

  datadogUrl.searchParams.set("query", params.query);
  datadogUrl.searchParams.set("from_ts", from.getTime().toString());
  datadogUrl.searchParams.set("to_ts", to.getTime().toString());

  return datadogUrl.toString();
};

type MasterUserAddedEvent = {
  company: Pick<Company, "id" | "name">;
  masterUser: Pick<AuthenticatedUser, "firstName" | "lastName" | "id" | "email" | "company">;
  addedByUser: Pick<AuthenticatedUser, "firstName" | "lastName" | "id" | "email" | "company">;
};

export const notifyMasterUserAddedToCompany = async (ctx: AppContext, event: MasterUserAddedEvent) => {
  await postSlackMessage(ctx, {
    channel: channel("csm"),
    text: `🎉 Master User Added To a Company`,
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🎉 A new master user has been added to the company!`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Company:* ${event.company.name} #${event.company.id}`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Master User:* ${event.masterUser.firstName} ${event.masterUser.lastName} (#${event.masterUser.id}) · ${event.masterUser.email} from ${event.masterUser.company.name} (#${event.masterUser.company.id})`,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*Added by:* ${event.addedByUser.firstName} ${event.addedByUser.lastName} (#${event.addedByUser.id}) · ${event.addedByUser.email} from ${event.addedByUser.company.name} (#${event.addedByUser.company.id})`,
        },
      },
    ],
  });
};

import { type CompensationReviewRecommendation, CompensationReviewRecommendationStatus } from "@prisma/client";
import { isIn } from "~/lib/utils";

export const canUpdateRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
    CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
    CompensationReviewRecommendationStatus.SUBMITTED,
  ]);
};

export const isPendingRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.PENDING_FOR_REVIEW,
    CompensationReviewRecommendationStatus.PENDING_FOR_SUBMISSION,
  ]);
};

export const isCompletedRecommendation = (recommendation: Pick<CompensationReviewRecommendation, "status">) => {
  return isIn(recommendation.status, [
    CompensationReviewRecommendationStatus.SUBMITTED,
    CompensationReviewRecommendationStatus.LOCKED,
  ]);
};

export const getLatestValidRecommendation = <
  T extends Pick<CompensationReviewRecommendation, "status" | "baseSalaryIncrease">,
>(
  recommendations: T[]
) => {
  return recommendations.findLast((recommendation) => {
    return (
      isIn(recommendation.status, [
        CompensationReviewRecommendationStatus.SUBMITTED,
        CompensationReviewRecommendationStatus.LOCKED,
      ]) && recommendation.baseSalaryIncrease !== null
    );
  });
};

import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { computeImpersonationStatus, type ImpersonationStatus } from "~/services/impersonationStatus";

const handler: NextApiHandler<ImpersonationStatus> = async (req, res) => {
  const impersonationStatus = computeImpersonationStatus(req);

  res.status(HttpStatus.OK).json(impersonationStatus);
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const IMPERSONATION_STATUS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/auth/company-impersonation/impersonation-status";

// Note: Do not move to react-query — the createQuery helper cannot be called so early on and will break.
// export const useImpersonationStatusQuery = createQuery<typeof handler>({
//   path: IMPERSONATION_STATUS_QUERY_KEY,
// });

import { OffCycleReviewRequestReason } from "@prisma/client";
import { type TFunction } from "i18next";
import { match } from "ts-pattern";
import { isIn } from "~/lib/utils";

export const formatOffCycleReviewRequestReason = (t: TFunction, reason: OffCycleReviewRequestReason) => {
  return match(reason)
    .with(OffCycleReviewRequestReason.LEVEL_CHANGE, () => ({
      label: t("enum.off-cycle-review-request-reason.level-change"),
      description: t("enum.off-cycle-review-request-reason.level-change-details"),
    }))
    .with(OffCycleReviewRequestReason.INTERNAL_MOBILITY, () => ({
      label: t("enum.off-cycle-review-request-reason.internal-mobility"),
      description: t("enum.off-cycle-review-request-reason.internal-mobility-details"),
    }))
    .with(OffCycleReviewRequestReason.MARKET_ADJUSTMENTS, () => ({
      label: t("enum.off-cycle-review-request-reason.market-adjustments"),
      description: t("enum.off-cycle-review-request-reason.market-adjustments-details"),
    }))
    .with(OffCycleReviewRequestReason.PAY_INEQUALITIES_CORRECTION, () => ({
      label: t("enum.off-cycle-review-request-reason.pay-inequalities-correction"),
      description: t("enum.off-cycle-review-request-reason.pay-inequalities-correction-details"),
    }))
    .with(OffCycleReviewRequestReason.TEAM_REORGANISATION, () => ({
      label: t("enum.off-cycle-review-request-reason.team-reorganisation"),
      description: t("enum.off-cycle-review-request-reason.team-reorganisation-details"),
    }))
    .with(OffCycleReviewRequestReason.EXCEPTIONAL_PERFORMANCE, () => ({
      label: t("enum.off-cycle-review-request-reason.exceptional-performance"),
      description: t("enum.off-cycle-review-request-reason.exceptional-performance-details"),
    }))
    .with(OffCycleReviewRequestReason.OTHER, () => ({
      label: t("enum.off-cycle-review-request-reason.other"),
      description: t("enum.off-cycle-review-request-reason.other-details"),
    }))
    .exhaustive();
};

export const reasonRequiresSalaryRangeUpdate = (reason: OffCycleReviewRequestReason | null) => {
  return isIn(reason, [OffCycleReviewRequestReason.LEVEL_CHANGE, OffCycleReviewRequestReason.INTERNAL_MOBILITY]);
};

import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";

export class ApiImpersonationForbiddenError extends Error {
  public impersonatedCompanyId: string | number;

  constructor(message: string, impersonatedCompanyId: string | number) {
    super(message);
    Object.setPrototypeOf(this, ApiImpersonationForbiddenError.prototype);
    this.impersonatedCompanyId = impersonatedCompanyId;
  }
}

export const handleImpersonationForbiddenError = (
  ctx: AppContext,
  res: NextApiResponse,
  error: ApiImpersonationForbiddenError
) => {
  logWarn(ctx, "[error] Impersonation Forbidden", { error });

  return res.status(HttpStatus.FORBIDDEN).json({
    key: "impersonation-forbidden",
    error: error.message,
    impersonatedCompanyId: error.impersonatedCompanyId,
  });
};

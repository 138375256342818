import { SalaryGridMeasure } from "@prisma/client";
import { type TFunction } from "next-i18next";

type SalaryGridMeasureDetails = {
  label: string;
  calculationBase: string;
  marketDataMeasure: "baseSalary" | "onTargetEarnings";
};

export const getSalaryGridMeasureDetail = <T extends keyof SalaryGridMeasureDetails>(
  t: TFunction,
  measure: SalaryGridMeasure,
  detail: T
): (typeof salaryGridMeasureDetails)[SalaryGridMeasure][T] => {
  const salaryGridMeasureDetails: Record<SalaryGridMeasure, SalaryGridMeasureDetails> = {
    [SalaryGridMeasure.BASE_SALARY]: {
      label: t("enum.salary-grid-measure.base-salary"),
      calculationBase: t("enum.salary-grid-measure.base-salary-calculation-base"),
      marketDataMeasure: "baseSalary",
    },
    [SalaryGridMeasure.ON_TARGET_EARNINGS]: {
      label: t("enum.salary-grid-measure.on-target-earnings"),
      calculationBase: t("enum.salary-grid-measure.on-target-earnings-calculation-base"),
      marketDataMeasure: "onTargetEarnings",
    },
  };

  const details = salaryGridMeasureDetails[measure];

  return details[detail];
};

import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { type UpdateMappingSchemaInput } from "~/pages/api/update-mapping";

export const mapExternalJobs = async (
  ctx: AppContext,
  params: { mapping: UpdateMappingSchemaInput["externalJobs"]; companyId: number; autoMapped?: boolean }
) => {
  logInfo(ctx, "[mapping] Handling external jobs", params);

  const impactedEmployeeIds: number[] = [];

  await mapSeries(params.mapping ?? [], async (jobMapping) => {
    // Find the impacted employees first
    const originalJob = await ctx.prisma.externalJob.findFirst({
      where: { id: jobMapping.externalJobId, companyId: params.companyId },
      include: { employees: { include: { mappedEmployee: true, job: true } } },
    });

    if (!originalJob) return;

    originalJob.employees.forEach((employee) => {
      // Unmapped employees & employees with unmapped jobs are all impacted
      if (!originalJob.mappedJobId || !employee.mappedEmployee) {
        impactedEmployeeIds.push(employee.id);
        return;
      }

      // Employees mapped to the original job are all impacted
      // (meaning we exclude employees who might have been remapped manually to another job)
      if (employee.mappedEmployee.jobId === originalJob.mappedJobId) {
        impactedEmployeeIds.push(employee.id);
      }
    });

    await ctx.prisma.externalJob.update({
      where: { id: jobMapping.externalJobId },
      data: {
        mappedJob: { connect: { id: jobMapping.mappedJobId } },
        skippedAt: null,
        autoMappedAt: !!params.autoMapped ? new Date() : null,
      },
    });
  });

  logInfo(ctx, "[mapping] Handled external jobs", params);
  return impactedEmployeeIds;
};

import { type PerformanceReviewRating, type Prisma } from "@prisma/client";
import { convertCurrency } from "~/lib/money";
import { compensationReviewEmployeeSelectForReviewersChain } from "~/services/compensation-review/campaigns/admin/compensationReviewEmployeeSelectForReviewers";
import { buildReviewersUpdatePayload } from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { prismaCompensationReviewScope } from "~/services/compensation-review/compensationReviewScope";
import { findOrCreateCompensationReviewReviewers } from "~/services/compensation-review/shared/findOrCreateCompensationReviewReviewer";
import { computeExternalEmployeeCompensation } from "~/services/external-employee/computeCompensation";

export const externalEmployeeSelectForCompensationReviewEmployeeCreation = {
  id: true,
  currency: true,
  performanceReviewRating: {
    select: {
      name: true,
      position: true,
    },
  },
  remunerationItems: {
    select: {
      amount: true,
      nature: {
        select: { mappedType: true },
      },
    },
  },
  mappedEmployee: {
    select: {
      baseSalary: true,
      fixedBonus: true,
      fixedBonusPercentage: true,
      onTargetBonus: true,
      onTargetBonusPercentage: true,
      currency: {
        select: {
          code: true,
          euroExchangeRate: true,
        },
      },
    },
  },
  liveSalaryRangeEmployee: {
    select: {
      id: true,
      orderingCompaRatio: true,
      orderingRangePenetration: true,
      range: {
        select: {
          midpoint: true,
          min: true,
          max: true,
          band: { select: { measure: true, isDraft: true } },
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

export const getPublishedSalaryRangeEmployee = (
  externalEmployee: Prisma.ExternalEmployeeGetPayload<{
    select: typeof externalEmployeeSelectForCompensationReviewEmployeeCreation;
  }>
) => {
  return externalEmployee.liveSalaryRangeEmployee && !externalEmployee.liveSalaryRangeEmployee.range.band.isDraft
    ? externalEmployee.liveSalaryRangeEmployee
    : null;
};

export const createCompensationReviewEmployee = async (
  ctx: CompensationReviewContext,
  params: {
    externalEmployee: Prisma.ExternalEmployeeGetPayload<{
      select: typeof externalEmployeeSelectForCompensationReviewEmployeeCreation;
    }>;
    compensationReviewCurrencyId: number;
    performanceRatings: PerformanceReviewRating[];
    promotion?: {
      targetRangeId: number | null;
    };
  }
) => {
  const { compensation: baseSalary } = computeExternalEmployeeCompensation(params.externalEmployee, {
    measure: "baseSalary",
  });
  const { compensation: variablePay } = computeExternalEmployeeCompensation(params.externalEmployee, {
    measure: "onTargetBonus",
  });
  const { compensation: otherBonus } = computeExternalEmployeeCompensation(params.externalEmployee, {
    measure: "fixedBonus",
  });
  const convertedBaseSalary = convertCurrency(baseSalary, params.externalEmployee.currency, ctx.parameters.currency);
  const convertedVariablePay = convertCurrency(variablePay, params.externalEmployee.currency, ctx.parameters.currency);
  const convertedOtherBonus = convertCurrency(otherBonus, params.externalEmployee.currency, ctx.parameters.currency);

  const onTargetEarnings = baseSalary + variablePay;
  const convertedOnTargetEarnings = convertedBaseSalary + convertedVariablePay;
  const totalCash = onTargetEarnings + otherBonus;
  const convertedTotalCash = convertedOnTargetEarnings + convertedOtherBonus;

  const performanceRating = params.performanceRatings.find(
    (rating) => rating?.name === params.externalEmployee.performanceReviewRating?.name
  );

  const salaryRangeEmployee = getPublishedSalaryRangeEmployee(params.externalEmployee);

  const reviewers = await findOrCreateCompensationReviewReviewers(ctx, {
    externalEmployeeId: params.externalEmployee.id,
  });

  return ctx.prisma.compensationReviewEmployee.create({
    data: {
      ...prismaCompensationReviewScope(ctx.scope),
      ...buildReviewersUpdatePayload(reviewers),
      companyId: ctx.companyId,
      currencyId: params.compensationReviewCurrencyId,
      externalEmployeeId: params.externalEmployee.id,
      isEligible: true,
      isEligibleForExceptionalBonus: false,
      isPromoted: !!params.promotion,
      hasCustomReviewChain: false,
      baseSalary,
      convertedBaseSalary,
      variablePay,
      convertedVariablePay,
      otherBonus,
      convertedOtherBonus,
      onTargetEarnings,
      convertedOnTargetEarnings,
      totalCash,
      convertedTotalCash,
      isIncreaseDifferentFromRecommendation: false,
      ...(performanceRating && { performanceRatingId: performanceRating.id }),
      ...(salaryRangeEmployee && {
        compaRatio: salaryRangeEmployee.orderingCompaRatio,
        rangePenetration: salaryRangeEmployee.orderingRangePenetration,
        salaryBandMeasure: salaryRangeEmployee.range.band.measure,
      }),
      ...(params.promotion?.targetRangeId && {
        targetRangeAfterPromotionId: params.promotion.targetRangeId,
      }),
    },
    select: compensationReviewEmployeeSelectForReviewersChain,
  });
};

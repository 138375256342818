import { UserLocale } from "@prisma/client";
import { type GetServerSidePropsResult } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { type GetServerSideProps, type GetServerSidePropsContext, type Route } from "nextjs-routes";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type ApiHandlerOptions } from "~/lib/api";
import { ApiFeatureAccessError } from "~/lib/errors/apiFeatureAccessError";
import { ApiImpersonationForbiddenError } from "~/lib/errors/apiImpersonationForbiddenError";
import { ApiValidationError } from "~/lib/errors/apiValidationError";
import { AuthenticationError } from "~/lib/errors/authenticationError";
import { ForbiddenError } from "~/lib/errors/forbiddenError";
import { trackPageRequested } from "~/lib/external/segment/server/events";
import { initContext } from "~/lib/initContext";
import { pick } from "~/lib/lodash";
import { logError, logWarn } from "~/lib/logger";
import { assignRequestId } from "~/lib/middleware/assignRequestId";
import { cloneContextToRequest } from "~/lib/middleware/cloneContextToRequest";
import { fetchUserFromSession } from "~/lib/middleware/fetchUserFromSession";
import { fetchUserIdFromSession } from "~/lib/middleware/fetchUserIdFromSession";
import { generateUserHash } from "~/lib/middleware/generateUserHash";
import { createMiddlewareChain, MiddlewareRuntime } from "~/lib/middleware/middleware";
import { parseSessionCookies } from "~/lib/middleware/parseSessionCookies";
import { protectRouteFromBlockedUsers } from "~/lib/middleware/protectRouteFromBlockedUsers";
import { protectRouteWithAccess } from "~/lib/middleware/protectRouteWithAccess";
import { protectRouteWithFeatureFlag } from "~/lib/middleware/protectRouteWithFeatureFlag";
import { protectRouteWithPermissions } from "~/lib/middleware/protectRouteWithPermissions";
import { protectRouteWithSubscription } from "~/lib/middleware/protectRouteWithSubscription";
import { protectSuperAdminRoutes } from "~/lib/middleware/protectSuperAdminRoutes";
import { redirectToOnboarding } from "~/lib/middleware/redirectToOnboarding";
import { resolveCompanyImpersonation } from "~/lib/middleware/resolveCompanyImpersonation";
import { resolveSubsidiaryImpersonation } from "~/lib/middleware/resolveSubsidiaryImpersonation";
import { resolveUserImpersonation } from "~/lib/middleware/resolveUserImpersonation";
import { setupContext } from "~/lib/middleware/setupContext";
import { setupServer } from "~/lib/middleware/setupServer";
import { updateImpersonationContext } from "~/lib/middleware/updateImpersonationContext";
import { updateLastActivity } from "~/lib/middleware/updateLastActivity";
import { NEXT_PROPS_ERROR_KEY_NAME } from "~/lib/nextPropsErrorKeyName";
import { parseString } from "~/lib/queryParams";
import { notFound, redirect } from "~/lib/redirect";
import { getServerContext } from "~/lib/serverContext";
import { SessionKey } from "~/lib/session";
import { computeImpersonationStatus } from "~/services/impersonationStatus";

export const isServer = typeof window === "undefined";

export const isClient = !isServer;

export const ssr = <T extends Record<string, unknown>, R extends Route["pathname"]>(
  handler?: GetServerSideProps<T, R>,
  options?: ApiHandlerOptions & {
    i18nNamespace?: string;
  }
): GetServerSideProps<T, R> => {
  return async (ctx: GetServerSidePropsContext<R>) => {
    const authenticationOptions = options?.authentication ?? {};

    try {
      const res = await value(async () => {
        try {
          await createMiddlewareChain({
            req: ctx.req,
            res: ctx.res,
            query: ctx.query,
            resolvedUrl: ctx.resolvedUrl,
            runtime: MiddlewareRuntime.SSR,
          })
            .use(assignRequestId)
            .use(cloneContextToRequest, { context: getServerContext() })
            .use(setupServer)
            .use(parseSessionCookies)
            .use(fetchUserIdFromSession, { optional: authenticationOptions.optional ?? false })
            .use(fetchUserFromSession, { optional: authenticationOptions.optional ?? false })
            .use(protectRouteFromBlockedUsers)
            .use(protectSuperAdminRoutes, { routeRequiresSuperAdmin: authenticationOptions.superAdmin ?? false })
            .use(resolveUserImpersonation)
            .use(resolveSubsidiaryImpersonation)
            .use(resolveCompanyImpersonation)
            .use(setupContext, { ctx })
            .use(generateUserHash)
            .use(redirectToOnboarding, { getServerSidePropsContext: ctx })
            .use(protectRouteWithAccess, { access: options?.access })
            .use(protectRouteWithPermissions, { permissions: authenticationOptions })
            .use(protectRouteWithFeatureFlag, { feature: options?.feature })
            .use(protectRouteWithSubscription, { product: options?.product })
            .use(updateLastActivity)
            .use(updateImpersonationContext)
            .run();

          if (!ctx.req.user) {
            await initContext(ctx.req, null);
          }

          if (!handler) {
            return { props: {} };
          }

          return await handler(ctx);
        } catch (error) {
          if (error.name === "NotFoundError") {
            return notFound();
          }

          if (error instanceof AuthenticationError && error.redirect) {
            return redirect(error.redirect);
          }

          if (error instanceof ForbiddenError && error.redirect) {
            return redirect(error.redirect);
          }

          if (error instanceof ApiFeatureAccessError) {
            return redirect({ pathname: "/home", query: { error: "forbidden" } });
          }

          if (error instanceof ApiImpersonationForbiddenError) {
            return redirect({
              pathname: "/impersonation-forbidden",
              query: { impersonatedCompanyId: error.impersonatedCompanyId.toString() },
            });
          }

          throw error;
        } finally {
          await trackPageRequested(ctx.req, { ctx });
        }
      });

      const locale = (parseString(ctx.query, "locale") ?? ctx.req.user?.locale ?? UserLocale.EN).toLowerCase();

      const translations = await serverSideTranslations(locale);
      if (translations._nextI18Next) {
        translations._nextI18Next.initialI18nStore = pick(translations._nextI18Next?.initialI18nStore, locale);
      }

      if ("props" in res) {
        const props = await res.props;

        const impersonationStatus = computeImpersonationStatus(ctx.req);

        // `user` and `impersonating` are globally injected within props but never accessible through their type.
        // They're extracted inside a React context in _app.tsx

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props.user = ctx.req.user;
        // eslint-disable-next-line
        // @ts-ignore
        props.userHash = ctx.req.session.get<string>(SessionKey.USER_HASH) ?? null;
        // eslint-disable-next-line
        // @ts-ignore
        props.impersonationStatus = impersonationStatus;
        // eslint-disable-next-line
        // @ts-ignore
        props.featureFlags = ctx.req.featureFlags;
        // eslint-disable-next-line
        // @ts-ignore
        props.subscriptions = ctx.req.subscriptions;
        // eslint-disable-next-line
        // @ts-ignore
        props._permissions = ctx.req.globalPermissionsContext;
        // eslint-disable-next-line
        // @ts-ignore
        Object.assign(props, translations);

        // In development, to avoid a known issue with Next.js + Prisma,
        // serialize everything returned by queries to JSON, to avoid
        // passing `Date` instances to `getServerSideProps`.
        // @see https://github.com/vercel/next.js/issues/11993
        if (config.app.env === "development") {
          res.props = JSON.parse(JSON.stringify(props));
        }
      }

      return res as GetServerSidePropsResult<T>;
    } catch (error) {
      if (error instanceof ApiValidationError) {
        logWarn(ctx.req, "[ssr] Query Parameter Validation error", { error });
      } else {
        logError(ctx.req, `[ssr] Generic error: ${error.message ?? "Unknown"}`, { error });
      }

      if (config.app.env === "development") {
        throw error;
      }

      return {
        props: {
          [NEXT_PROPS_ERROR_KEY_NAME]: true,
          error: {
            statusCode: error.statusCode,
            message: value(() => {
              if (error.statusCode === 500 || error.statusCode === "500") {
                return null;
              }

              return error.message;
            }),
          },
        } as unknown as T,
      };
    }
  };
};

import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema } from "~/services/salary-bands/audit-logs/schemas";

export const ConvertBandToDraftInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.CONVERT_BAND_TO_DRAFT>().required(),
    bandId: number().required(),
  })
);

export type ConvertBandToDraftChange = YupOutputType<typeof ConvertBandToDraftInputSchema>;

import { type User, UserProvider, UserRole } from "@prisma/client";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";

export const formatUserRole = (t: TFunction, role: UserRole) => {
  return match(role)
    .with(UserRole.ADMIN, () => t("enum.user-role.administrator"))
    .with(UserRole.HR, () => t("enum.user-role.hr"))
    .with(UserRole.RECRUITER, () => t("enum.user-role.recruiter"))
    .with(UserRole.EMPLOYEE, () => t("enum.user-role.employee"))
    .exhaustive();
};

export const formatUserProvider = (t: TFunction, provider: UserProvider) => {
  return match(provider)
    .with(UserProvider.GOOGLE, () => t("enum.user-provider.google"))
    .with(UserProvider.MICROSOFT, () => t("enum.user-provider.microsoft"))
    .with(UserProvider.EMAIL_AND_TOKEN, () => t("enum.user-provider.login-link"))
    .with(UserProvider.SAML, () => t("enum.user-provider.saml-sso"))
    .exhaustive();
};

export const formatUserFullName = (user: Pick<User, "firstName" | "lastName">) => {
  return `${user.firstName} ${user.lastName}`;
};

import { mean } from "~/lib/lodash";

export const computeGenderEqualityIndex = (params: {
  payGapScore: number | null;
  topEarnersScore: number | null;
  managementScore: number | null;
  marketFairnessScore: number | null;
}) => {
  const { payGapScore, topEarnersScore, managementScore, marketFairnessScore } = params;

  if (payGapScore === null || topEarnersScore === null || managementScore === null || marketFairnessScore === null) {
    return null;
  }

  return mean([payGapScore, topEarnersScore, managementScore, marketFairnessScore]);
};

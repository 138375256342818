/**
 * Everything you need to know (and more !) about integrations is in Thee Holy Bible :
 * https://www.notion.so/figures-hr/Integrations-Bible-e574387e1c64417082dd90f34f6c4334
 */

import {
  type Company,
  CompensationFrequency,
  type Currency,
  EmployeeMappingSkipReason,
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  Gender,
  IntegrationSource,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { parseISO } from "date-fns";
import { type ParsedUrlQueryInput } from "querystring";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { fetch } from "~/lib/fetch";
import { fetchWithRetry } from "~/lib/fetchWithRetry";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { buildCustomBaseSalaryRemunerationItem } from "~/lib/hris/helpers/buildCustomBaseSalaryRemunerationItem";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { getEmployeeProfilePicture } from "~/lib/hris/helpers/getEmployeeProfilePicture";
import { getMissingCustomFields, type IntegrationCustomFields } from "~/lib/hris/helpers/getMissingCustomFields";
import { getNumberOfMonth } from "~/lib/hris/helpers/getNumberOfMonth";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { type IntegrationConfig, type SourceConfig, type StaticModels } from "~/lib/integration";
import { chain, compact, get, isArray, isNil, isNumber, isString, toPairs } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { convertCurrency } from "~/lib/money";
import { buildExternalUrl } from "~/lib/url";
import { assertProps, isIn } from "~/lib/utils";
import { type IntegrationDiagnostic } from "~/services/synchronization/fetchCompanyIntegrationDiagnostics";
import { type EmployeeData, type IntegrationSettingsForSync } from "~/services/synchronization/syncExternalEmployees";

const base: IntegrationConfig = {
  source: IntegrationSource.HIBOB,
  companyId: 0,
  domain: null,
  clientId: config.hibob.clientId,
  clientSecret: config.hibob.clientSecret,
  anonymous: false,
  enabled: true,
  retryCount: 0,
  fteCustomFieldName: null,
  levelCustomFieldName: null,
  variableCustomFieldName: null,
  externalIdCustomFieldName: null,
  variableCustomFieldFrequency: null,
  holidayAllowanceCustomFieldName: null,
  holidayAllowanceCustomFieldFrequency: null,
  isCustomFieldOnlyHolidayAllowance: null,
  isHolidayAllowanceIncludedInBaseSalary: null,
  businessUnitCustomFieldName: null,
  locationCustomFieldName: null,
};

export const hibobConfigs: SourceConfig = {
  "default": base,
  "anonymous": { ...base, anonymous: true },
  "custom fields": {
    ...base,
    anonymous: true,
    externalIdCustomFieldName: "employment.custom.field_1658130293335",
    fteCustomFieldName: "personal.custom.field_1657552920782",
    levelCustomFieldName: "personal.custom.field_1657552920781",
    variableCustomFieldFrequency: "YEARLY",
    variableCustomFieldName: "payroll.custom.field_1658134080429",
    holidayAllowanceCustomFieldName: "holidayAllowance",
    businessUnitCustomFieldName: "department",
  },
};

type Credentials = {
  clientId: string;
  clientSecret: string;
  anonymous: boolean;
};

export type HibobIntegrationSettingsInput = Credentials & IntegrationCustomFields;

type HibobAmount = {
  value: number;
  currency: string;
};

export type HibobCompanyField = {
  id: string;
  name: string;
  jsonPath: string;
  type: string;
  typeData?: { listId?: string };
};

type HibobListValues = Array<{ id: string; value: string; children: HibobListValues[] }>;

export type HibobCompanyList = Record<string, { name: string; values: HibobListValues }>;

type CustomListValues = Record<string, HibobListValues>;

export type HibobEmployee = {
  id: string;
  firstName?: string;
  surname?: string;
  avatarUrl?: string;
  email?: string;
  home?: {
    legalGender: "Male" | "Female";
  };
  personal?: {
    birthDate: string;
  };
  work: {
    employeeIdInCompany: number;
    startDate: string;
    title: string;
    site: string;
    reportsTo?: {
      displayName: string;
      email: string;
      surname: string;
      firstname: string;
      id: string;
    };
  };
  humanReadable?: {
    work?: { title?: string };
    custom?: Record<string, string | number>;
  };
  payroll?: {
    employment?: {
      type?: "Permanent" | string;
      contract?: "Part time" | "Shifts" | "Full time";
    };
    salary?: {
      activeEffectiveDate?: string;
      monthlyPayment?: HibobAmount | null;
      yearlyPayment?: HibobAmount | null;
    };
    variable?: Record<
      "Executive bonus" | "Bonus" | "Commission" | "Annual Perf Bonus" | string,
      {
        amount: HibobAmount | null;
        paymentPeriod: "Monthly" | "Annual" | "Half-Yearly" | "Quarterly" | string | null;
      }
    >;
  };
  custom?: Record<string, string | number>;
};

export type HibobEmployeeWithPayrollHistory = HibobEmployee & {
  historicalBaseSalaries?: HibobPayrollHistoryValue[];
};

export type HibobPayrollHistoryValue = {
  canBeDeleted?: boolean;
  change?: {
    reason: string | null;
    changedBy: string | null;
    changedById: string;
  };
  payFrequency?: string;
  creationDate?: string | null;
  isCurrent: boolean;
  modificationDate?: string;
  payPeriod: "Monthly" | "Annual" | "Half-Yearly" | "Quarterly" | "Hourly" | string | null;
  id: string;
  endEffectiveDate?: string | null;
  activeEffectiveDate?: string;
  effectiveDate?: string;
  base: HibobAmount | null;
};

export type HibobPayrollHistory = {
  employeeId: string;
  values: HibobPayrollHistoryValue[];
};

const hibobFetch = async (
  ctx: AppContext,
  credentials: Credentials,
  company: Pick<Company, "name">,
  endpoint: string,
  {
    method = "GET",
    query,
    body,
  }: {
    method?: "GET" | "POST";
    query?: ParsedUrlQueryInput;
    body?: Record<string, unknown>;
  } = {}
) => {
  const url = buildExternalUrl(`https://api.hibob.com/v1/${endpoint}`, query);
  const authHeader = Buffer.from(`${credentials.clientId}:${credentials.clientSecret}`).toString("base64");

  const res = await fetchWithRetry(ctx, url, {
    method,
    headers: {
      accept: "application/json",
      authorization: `Basic ${authHeader}`,
      ["content-type"]: "application/json",
      ...(config.app.isProduction && {
        ["Partner-Token"]: `${config.hibob.hibobPartnerToken}/${company.name}`,
      }),
    },
    ...(body && { body: JSON.stringify(body) }),
    retry: {
      resetHeaderName: "x-ratelimit-reset",
      resetHeaderMode: "unix-epoch-time",
    },
  });

  if (!res.ok) {
    throw new Error(`Hibob error : [${res.status}] - ${res.statusText}`);
  }

  return res;
};

const fetchCompanyFields = async (
  ctx: AppContext,
  credentials: Credentials,
  company: Pick<Company, "name">
): Promise<HibobCompanyField[]> => {
  const res = await hibobFetch(ctx, credentials, company, "company/people/fields");

  return res.json();
};

const fetchCompanyNamedLists = async (
  ctx: AppContext,
  credentials: Credentials,
  company: Pick<Company, "name">
): Promise<HibobCompanyList> => {
  const res = await hibobFetch(ctx, credentials, company, "company/named-lists", {
    query: {
      includeArchived: false,
    },
  });

  return res.json();
};

const fetchPayrollHistory = async (
  ctx: AppContext,
  credentials: Credentials,
  company: Pick<Company, "name">,
  cursor?: string
): Promise<HibobPayrollHistory[]> => {
  const res = await hibobFetch(ctx, credentials, company, "bulk/people/salaries", {
    query: {
      ...(!!cursor && {
        cursor,
      }),
      limit: 200,
    },
  });

  const json = await res.json();

  const isLastPageOfData = isNil(json?.response_metadata?.next_cursor);

  // If we have reached the end of paginated data just return what we have
  if (isLastPageOfData) {
    return json.results;
  }

  const nextPage = await fetchPayrollHistory(ctx, credentials, company, json?.response_metadata?.next_cursor);

  return [...json.results, ...nextPage];
};

const fetchEmployees = async (
  ctx: AppContext,
  credentials: Credentials,
  company: Pick<Company, "name">,
  fields: string[]
): Promise<HibobEmployee[]> => {
  const res = await hibobFetch(ctx, credentials, company, "people/search", {
    method: "POST",
    body: {
      showInactive: false,
      humanReadable: "APPEND",
      fields,
    },
  });

  const json: { employees: HibobEmployee[] } = await res.json();

  return json.employees;
};

const anonymise = (user: HibobEmployee): HibobEmployee => {
  delete user.firstName;
  delete user.surname;
  delete user.avatarUrl;
  delete user.personal;
  delete user.email;
  return user;
};

export const getHibobEmployees = async (
  ctx: AppContext,
  credentials: Credentials,
  company: Pick<Company, "name">
): Promise<HibobEmployeeWithPayrollHistory[]> => {
  const hibobFields = await fetchCompanyFields(ctx, credentials, company);

  const fields = hibobFields.map((field) => field.id);

  const employees = await fetchEmployees(ctx, credentials, company, fields);

  const payrollHistories = await fetchPayrollHistory(ctx, credentials, company);

  return employees.map((employee) => {
    if (credentials.anonymous) {
      return anonymise(employee);
    }

    const historicalBaseSalaries = payrollHistories
      .filter((history) => history.employeeId === employee.id)
      .map((history) => history.values)
      .flatMap((values) => values)
      .filter((value) => !!value && !value.isCurrent);

    return {
      ...(credentials.anonymous ? anonymise(employee) : employee),
      historicalBaseSalaries,
    };
  });
};

const getHibobProfilePicture = async (
  ctx: AppContext,
  options: { integrationSettings: SafeIntegrationSettings; apiEmployee: HibobEmployee }
) => {
  if (!options.apiEmployee.avatarUrl) {
    return undefined;
  }

  return getEmployeeProfilePicture(ctx, {
    apiEmployeeId: options.apiEmployee.id,
    source: IntegrationSource.HIBOB,
    integrationSettings: options.integrationSettings,
    fetch: () =>
      fetch(options.apiEmployee.avatarUrl as string, { headers: { "User-Agent": "Figures-Integration-Bot" } }),
  });
};

const getCustomField = (
  ctx: AppContext,
  apiEmployee: HibobEmployee,
  fieldId: string | null,
  customListValues: CustomListValues
): string | null => {
  if (!fieldId) {
    return null;
  }

  const customFieldValue = value(() => {
    const rawValue = get(apiEmployee, fieldId);
    if (!!rawValue?.value) {
      return rawValue.value;
    }

    if (isArray(rawValue)) {
      return rawValue[0];
    }

    return rawValue;
  });

  if (!(isString(customFieldValue) || isNumber(customFieldValue))) {
    return null;
  }

  const listValues = customListValues[fieldId];
  if (listValues) {
    const listValue = listValues.find(({ id }) => id === customFieldValue.toString() || id === customFieldValue);

    if (listValue) {
      return listValue.value;
    } else {
      logWarn(ctx, "[hibob] Could find value in custom list", { customFieldValue, fieldId });
    }
  }

  const humanReadableValue = get(apiEmployee.humanReadable, fieldId);

  if (!(isString(humanReadableValue) || isNumber(humanReadableValue))) {
    return customFieldValue.toString();
  }

  return humanReadableValue.toString();
};

const computeBaseSalaryItem = (company: Company, apiEmployee: HibobEmployee, numberMonths: number) => {
  const baseSalary = value(() => {
    if (apiEmployee.payroll?.salary?.yearlyPayment) {
      return apiEmployee.payroll.salary.yearlyPayment.value;
    }

    if (apiEmployee.payroll?.salary?.monthlyPayment) {
      return Math.round(apiEmployee.payroll.salary.monthlyPayment.value * numberMonths);
    }

    return null;
  });

  if (!baseSalary) {
    return [];
  }

  const date = apiEmployee.payroll?.salary?.activeEffectiveDate
    ? parseISO(apiEmployee.payroll.salary.activeEffectiveDate)
    : null;

  return [
    {
      source: ExternalEmployeeSource.HIBOB,
      externalId: "fix-salary",
      amount: Math.round(baseSalary * 100),
      status: ExternalRemunerationStatus.LIVE,
      date,
      numberMonths,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.HIBOB,
              externalId: "fix-salary",
            },
          },
          create: {
            source: ExternalEmployeeSource.HIBOB,
            externalId: "fix-salary",
            name: "Fixed salary",
            mappedType: ExternalRemunerationType.FIXED_SALARY,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    },
  ];
};

const computeHistoricalBaseSalaryItem = (
  company: Company,
  payrollItem: HibobPayrollHistoryValue,
  numberMonths: number
) => {
  const { base: item } = payrollItem;

  if (!item) {
    return null;
  }

  const numberMonthsFactor = numberMonths / 12;

  const amount = value(() => {
    if (payrollItem?.payPeriod === "Annual") {
      return item.value * numberMonthsFactor;
    }
    if (payrollItem?.payPeriod === "Half-Yearly") {
      return item.value * 2 * numberMonthsFactor;
    }
    if (payrollItem?.payPeriod === "Quarterly") {
      return item.value * 4 * numberMonthsFactor;
    }
    if (payrollItem?.payPeriod === "Monthly") {
      return item.value * 12 * numberMonthsFactor;
    }

    return item.value;
  });

  return {
    source: ExternalEmployeeSource.HIBOB,
    externalId: `historical-fix-salary-${payrollItem.id}`,
    amount: Math.round(amount * 100),
    numberMonths,
    date: payrollItem?.activeEffectiveDate ? parseISO(payrollItem.activeEffectiveDate) : null,
    status: ExternalRemunerationStatus.HISTORICAL,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: company.id,
            source: ExternalEmployeeSource.HIBOB,
            externalId: "fix-salary",
          },
        },
        create: {
          source: ExternalEmployeeSource.HIBOB,
          externalId: "fix-salary",
          name: "Fixed salary",
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: company.id,
            },
          },
        },
      },
    },
  };
};

export const mapHibobEmployee = async (
  ctx: AppContext,
  company: Company,
  apiEmployee: HibobEmployeeWithPayrollHistory,
  integrationSettings: SafeIntegrationSettings,
  staticModels: StaticModels,
  customListValues: CustomListValues,
  ignoreProfilePicture?: boolean
): Promise<EmployeeData> => {
  // Handle custom fields
  const {
    fteCustomFieldName,
    externalIdCustomFieldName,
    levelCustomFieldName,
    baseSalaryCustomFieldName,
    baseSalaryCustomFieldFrequency,
    variableCustomFieldFrequency,
    variableCustomFieldName,
    holidayAllowanceCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    jobCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = integrationSettings;

  const customFte = getCustomField(ctx, apiEmployee, fteCustomFieldName, customListValues);
  const customLevel = getCustomField(ctx, apiEmployee, levelCustomFieldName, customListValues);
  const customEmployeeNumber = getCustomField(ctx, apiEmployee, externalIdCustomFieldName, customListValues);
  const customBaseSalary = getCustomField(ctx, apiEmployee, baseSalaryCustomFieldName, customListValues);
  const customVariable = getCustomField(ctx, apiEmployee, variableCustomFieldName, customListValues);
  const holidayAllowanceValue = getCustomField(ctx, apiEmployee, holidayAllowanceCustomFieldName, {});
  const businessUnit = getCustomField(ctx, apiEmployee, businessUnitCustomFieldName, customListValues);
  const customLocation = getCustomField(ctx, apiEmployee, locationCustomFieldName, customListValues);
  const customJob = getCustomField(ctx, apiEmployee, jobCustomFieldName, customListValues);

  const additionalFieldValues = additionalFieldMappings.map(({ hrisFieldName, additionalFieldId, id }) => ({
    additionalFieldId,
    additionalFieldMappingId: id,
    value: getCustomField(ctx, apiEmployee, hrisFieldName, customListValues),
  }));

  const customRemunerationItemsValues = customRemunerationItemMappings.map((customRemunerationItemMapping) => ({
    ...customRemunerationItemMapping,
    value: getCustomField(ctx, apiEmployee, customRemunerationItemMapping.hrisFieldName, customListValues),
  }));

  const currency = value(() => {
    const salary = apiEmployee.payroll?.salary?.monthlyPayment;

    // Try to get the currency of the employee from their salary
    if (!!salary) {
      const salaryCurrency = staticModels.currencies.find((currency) => currency.code === salary.currency);

      if (!!salaryCurrency) {
        return salaryCurrency;
      }

      logWarn(ctx, "[hibob] Unhandled salary currency", { currency: salary.currency });
    }

    // Otherwise get the currency of the default country of the company
    const countryWithCurrency = staticModels.countries.find((country) => country.id === company.defaultCountryId);

    if (!!countryWithCurrency?.defaultCurrencyId) {
      const countryCurrency = staticModels.currencies.find(({ id }) => id === countryWithCurrency.defaultCurrencyId);
      if (countryCurrency) {
        return countryCurrency;
      }
    }

    const euro = staticModels.currencies.find((currency) => currency.code === "EUR");

    return euro as Currency;
  });

  const location = value(() => {
    if (customLocation) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: customLocation,
              },
            },
            create: {
              externalId: customLocation,
              name: customLocation,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      };
    }

    if (apiEmployee.work.site) {
      return {
        location: {
          connectOrCreate: {
            where: {
              companyId_externalId: {
                companyId: company.id,
                externalId: apiEmployee.work.site,
              },
            },
            create: {
              externalId: apiEmployee.work.site,
              name: apiEmployee.work.site,
              autoMappingEnabled: true,
              company: {
                connect: { id: company.id },
              },
            },
          },
        },
      };
    }

    return null;
  });

  const job = value(() => {
    // Sometimes on custom job we don't get the name but an internal HiBob ID for the job
    // The humanReadable section always contains those IDs translated into display values
    const jobTitle = customJob ?? apiEmployee.humanReadable?.work?.title ?? apiEmployee.work.title;

    if (!jobTitle) return;

    return {
      job: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: jobTitle,
            },
          },
          create: {
            name: jobTitle,
            externalId: jobTitle,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    };
  });

  // If the employee is not a permanent, we create them with a SKIPPED status
  const contract = apiEmployee.payroll?.employment?.contract;
  const isPartTime = contract ? isIn(contract, ["Shifts", "Part time"]) : false;
  const hasCustomFte = customFte !== null;
  const isAutoSkipped = isPartTime && !hasCustomFte;

  const managerExternalId = apiEmployee.work.reportsTo?.id;

  const input: EmployeeData["input"] = {
    source: ExternalEmployeeSource.HIBOB,
    externalId: apiEmployee.id,
    status: isAutoSkipped ? ExternalEmployeeStatus.SKIPPED : ExternalEmployeeStatus.UNMAPPED,
    mappingSkipReason: isAutoSkipped ? EmployeeMappingSkipReason.NOT_PERMANENT_EMPLOYEE : null,
    firstName: apiEmployee.firstName,
    lastName: apiEmployee.surname,
    email: apiEmployee.email,
    employeeNumber: customEmployeeNumber ?? apiEmployee.work.employeeIdInCompany?.toString() ?? apiEmployee.id,
    gender: value(() => {
      if (apiEmployee.home?.legalGender === "Female") {
        return Gender.FEMALE;
      }
      if (apiEmployee.home?.legalGender === "Male") {
        return Gender.MALE;
      }

      return null;
    }),
    ...(apiEmployee.personal?.birthDate && { birthDate: parseISO(apiEmployee.personal.birthDate) }),

    hireDate: parseISO(apiEmployee.work.startDate),
    company: {
      connect: { id: company.id },
    },
    currency: {
      connect: { code: currency?.code },
    },
    ...location,
    ...job,
    ...(customLevel && {
      level: {
        connectOrCreate: {
          where: {
            companyId_externalId: {
              companyId: company.id,
              externalId: customLevel,
            },
          },
          create: {
            externalId: customLevel,
            name: customLevel,
            company: {
              connect: { id: company.id },
            },
          },
        },
      },
    }),
    ...(businessUnit && { businessUnit }),
  };

  const numberMonths = getNumberOfMonth({
    externalId: location?.location?.connectOrCreate?.create?.externalId,
    additionalMonthRules: staticModels.additionalMonthRules,
    externalLocations: staticModels.externalLocations,
  });

  const customBaseSalaryRemunerationItem = buildCustomBaseSalaryRemunerationItem({
    customBaseSalary,
    baseSalaryCustomFieldFrequency,
    numberMonths,
    company,
    source: ExternalEmployeeSource.HIBOB,
  });

  const historicalBaseSalaries = apiEmployee.historicalBaseSalaries ?? [];

  const remunerationItems: EmployeeData["remunerationItems"] = customBaseSalaryRemunerationItem
    ? [customBaseSalaryRemunerationItem]
    : compact([
        ...computeBaseSalaryItem(company, apiEmployee, numberMonths),
        ...historicalBaseSalaries.map((item) => computeHistoricalBaseSalaryItem(company, item, numberMonths)),
      ]);

  const variableItems: EmployeeData["remunerationItems"] = await mapSeries(
    toPairs(apiEmployee.payroll?.variable).filter(([, item]) => !!item.amount),
    async ([name, item]) => {
      const itemAmount = item.amount as HibobAmount;

      const amount = value(() => {
        if (item.paymentPeriod === "Annual") {
          return itemAmount.value;
        }
        if (item.paymentPeriod === "Half-Yearly") {
          return itemAmount.value * 2;
        }
        if (item.paymentPeriod === "Quarterly") {
          return itemAmount.value * 4;
        }
        if (item.paymentPeriod === "Monthly") {
          return itemAmount.value * 12;
        }
        logWarn(ctx, "[hibob] Unhandled payment period : treating as annual", { paymentPeriod: item.paymentPeriod });
        return itemAmount.value;
      });

      // If the variable is (for some ungodly reason) not in the same currency as the fixed, we have to convert it
      const convertedAmount = await value(async () => {
        if (itemAmount.currency === currency?.code) {
          return amount;
        }

        const sourceCurrency = await ctx.prisma.currency.findUnique({ where: { code: itemAmount.currency } });

        if (!sourceCurrency) {
          logWarn(ctx, "[hibob] Unhandled currency : treating as salary currency", {
            unhandledCurrency: itemAmount.currency,
            salaryCurrency: currency.code,
          });
          return amount;
        }

        return convertCurrency(amount, sourceCurrency, currency);
      });

      return {
        source: ExternalEmployeeSource.HIBOB,
        externalId: name,
        amount: Math.round(convertedAmount * 100),
        status: ExternalRemunerationStatus.LIVE,
        nature: {
          connectOrCreate: {
            where: {
              companyId_source_externalId: {
                companyId: company.id,
                source: ExternalEmployeeSource.HIBOB,
                externalId: `variable-${name}`,
              },
            },
            create: {
              source: ExternalEmployeeSource.HIBOB,
              externalId: `variable-${name}`,
              name: name,
              mappedType: ExternalRemunerationType.VARIABLE_BONUS,
              company: {
                connect: {
                  id: company.id,
                },
              },
            },
          },
        },
      };
    }
  );

  if (customVariable && !isNaN(parseInt(customVariable)) && variableCustomFieldFrequency) {
    const multiplier = match(variableCustomFieldFrequency)
      .with(CompensationFrequency.MONTHLY, () => 12)
      .with(CompensationFrequency.QUARTERLY, () => 4)
      .with(CompensationFrequency.YEARLY, () => 1)
      .exhaustive();

    const amount = Math.round(parseInt(customVariable) * multiplier * 100);

    variableItems.push({
      source: ExternalEmployeeSource.HIBOB,
      externalId: "variable-bonus",
      status: ExternalRemunerationStatus.LIVE,
      amount,
      nature: {
        connectOrCreate: {
          where: {
            companyId_source_externalId: {
              companyId: company.id,
              source: ExternalEmployeeSource.HIBOB,
              externalId: "variable-bonus",
            },
          },
          create: {
            source: ExternalEmployeeSource.HIBOB,
            externalId: "variable-bonus",
            name: "Variable bonus",
            mappedType: ExternalRemunerationType.VARIABLE_BONUS,
            company: {
              connect: {
                id: company.id,
              },
            },
          },
        },
      },
    });
  }

  remunerationItems.push(...variableItems);

  if (customRemunerationItemsValues.length > 0) {
    const customItems = compact(
      customRemunerationItemsValues.map((customRemunerationItem) =>
        mapCustomRemunerationItem(integrationSettings, customRemunerationItem)
      )
    );
    remunerationItems.push(...customItems);
  }

  return {
    input,
    picturePath: !ignoreProfilePicture
      ? await getHibobProfilePicture(ctx, { integrationSettings, apiEmployee })
      : undefined,
    remunerationItems,
    additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
    managerExternalId,
    holidayAllowanceValue,
    // If one day they support hourly rates, we just have to update this (cf. bamboo)
    ...(customFte && { fte: customFte, ignoreFte: false }),
  };
};

const assertSafeIntegrationSettings = (integrationSettings: IntegrationSettingsForSync) =>
  assertProps(integrationSettings, ["clientSecret", "clientId"]);
export type SafeIntegrationSettings = ReturnType<typeof assertSafeIntegrationSettings>;

export const getMappedHibobEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync,
  staticModels: StaticModels,
  ignoreProfilePicture = false
): Promise<EmployeeData[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  const hibobEmployees = await getHibobEmployees(ctx, safeIntegrationSettings, company);

  const customListValues = await getCustomListValues(ctx, safeIntegrationSettings, company);

  return mapSeries(hibobEmployees, (hibobEmployee) =>
    mapHibobEmployee(
      ctx,
      company,
      hibobEmployee,
      safeIntegrationSettings,
      staticModels,
      customListValues,
      ignoreProfilePicture
    )
  );
};

export const getHibobDiagnostic = async (
  ctx: AppContext,
  input: HibobIntegrationSettingsInput
): Promise<IntegrationDiagnostic> => {
  const { companyId } = await getRequiredUser(ctx);

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    select: { name: true },
  });

  try {
    const [employee, companyFields] = await Promise.all([
      getHibobEmployees(ctx, input, company),
      fetchCompanyFields(ctx, input, company),
    ]);

    const availableFields = companyFields.map(({ jsonPath, name }) => ({ id: jsonPath, name }));

    const missingFields = getMissingCustomFields(input, availableFields);

    if (!employee) {
      return {
        connection: false,
        connectionError: "We could not find any employees within your HiBob account",
        missingFields,
        availableFields,
      };
    }

    return { connection: true, connectionError: "", missingFields, availableFields };
  } catch (error) {
    return { connection: false, connectionError: error.message, missingFields: [], availableFields: [] };
  }
};

export const getCustomListValues = async (
  ctx: AppContext,
  safeIntegrationSettings: SafeIntegrationSettings,
  company: Pick<Company, "name">
): Promise<CustomListValues> => {
  const {
    fteCustomFieldName,
    levelCustomFieldName,
    externalIdCustomFieldName,
    holidayAllowanceCustomFieldName,
    variableCustomFieldName,
    businessUnitCustomFieldName,
    locationCustomFieldName,
    baseSalaryCustomFieldName,
    jobCustomFieldName,
    additionalFieldMappings = [],
    customRemunerationItemMappings = [],
  } = safeIntegrationSettings;

  const hibobFields = await fetchCompanyFields(ctx, safeIntegrationSettings, company);
  const hibobLists = await fetchCompanyNamedLists(ctx, safeIntegrationSettings, company);

  const additionalFieldNames = additionalFieldMappings.map(({ hrisFieldName }) => hrisFieldName);

  const customListFields = hibobFields.filter(
    ({ id, type }) =>
      [
        fteCustomFieldName,
        levelCustomFieldName,
        externalIdCustomFieldName,
        holidayAllowanceCustomFieldName,
        variableCustomFieldName,
        businessUnitCustomFieldName,
        locationCustomFieldName,
        baseSalaryCustomFieldName,
        jobCustomFieldName,
        ...additionalFieldNames,
        ...customRemunerationItemMappings.map(({ hrisFieldName }) => hrisFieldName),
      ].includes(id) &&
      (type === "list" || type === "hierarchy-list")
  );

  return chain(customListFields)
    .keyBy("id")
    .mapValues(({ typeData, type }) => {
      if (!typeData?.listId) {
        return { values: [] };
      }

      if (type === "list") {
        return hibobLists[typeData.listId];
      }

      if (type === "hierarchy-list") {
        return {
          ...hibobLists[typeData.listId],
          values: hibobLists[typeData.listId]?.values.flatMap((category) => category.children),
        };
      }

      return { values: [] };
    })
    .mapValues("values")
    .value();
};

export const getRawHibobEmployees = async (
  ctx: AppContext,
  company: Company,
  integrationSettings: IntegrationSettingsForSync
): Promise<HibobEmployee[]> => {
  const safeIntegrationSettings = assertSafeIntegrationSettings(integrationSettings);

  return getHibobEmployees(ctx, safeIntegrationSettings, company);
};

import { type Image as ImageModel } from "@prisma/client";
import classNames from "classnames";
import React from "react";
import { Image } from "~/components/ui/core/Image";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { getEmployeePictureProps } from "~/services/employee";

export const Sizes = {
  "sm": 16,
  "md": 24,
  "lg": 32,
  "xl": 48,
  "3xl": 96,
  "4xl": 144,
};

export type PictureForDisplay = Pick<ImageModel, "path" | "width" | "height">;

type Props = {
  className?: string;
  picture?: PictureForDisplay | null;
  pictureUrl?: string;
  placeholderId: number;
  size?: keyof typeof Sizes;
};

export const EmployeePicture: React.FC<Props> = ({ className, picture, pictureUrl, placeholderId, size = "md" }) => {
  const pictureSize = Sizes[size];
  const { FORCE_FIGURES_PROFILE_PICTURES } = useFeatureFlags();
  const { height, src, width } = getEmployeePictureProps({
    picture: FORCE_FIGURES_PROFILE_PICTURES ? null : picture,
    pictureUrl: FORCE_FIGURES_PROFILE_PICTURES ? pictureUrl : undefined,
    placeholderNumber: placeholderId,
    size: pictureSize,
  });

  return (
    <div
      className={classNames(className, {
        "flex items-center justify-center overflow-hidden rounded-full border drop-shadow": true,
        // The following styles are used as a fallback when the picture is not "available".
        // This is the case on local environment with the missing "fixtures/employee-picture.jpeg".
        "bg-gradient-to-br from-secondary-300 to-secondary-400": true,
        "h-6 w-6": size === "md",
        "h-8 w-8": size === "lg",
        "h-12 w-12": size === "xl",
      })}
    >
      <Image alt="employee picture" height={height} src={src} width={width} containerClassName="flex-shrink-0" />
    </div>
  );
};

import classNames from "classnames";
import React from "react";

type Props = {
  color?: keyof typeof Colors;
  size?: keyof typeof Sizes;
  className?: string;
  animationDelay?: number;
};

const Colors = {
  primary: "bg-primary-400",
  white: "bg-white",
  secondary: "bg-secondary-500",
  info: "bg-blue-500",
};

const Sizes = {
  "xs": "w-2 h-2",
  "sm": "w-3 h-3",
  "md": "w-4 h-4",
  "lg": "w-5 h-5",
  "xl": "w-6 h-6",
  "2xl": "w-7 h-7",
  "3xl": "w-10 h-10",
};

export const Ping: React.FC<Props> = ({ color = "primary", size = "sm", className, animationDelay }) => {
  return (
    <span className={classNames(className, "relative flex", Sizes[size])}>
      <span
        style={{ animationDelay: `${animationDelay}ms` }}
        className={classNames(Colors[color], "absolute inset-0 inline-flex animate-ping rounded-full opacity-75")}
      ></span>
      <span className={classNames(Colors[color], Sizes[size], "relative inline-flex rounded-full")}></span>
    </span>
  );
};

import {
  CompensationFrequency,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
  type IntegrationSettings,
  type IntegrationSource,
} from "@prisma/client";
import { match } from "ts-pattern";
import { value } from "~/components/helpers";
import { cleanHolidayAllowanceValue } from "~/lib/hris/helpers/cleanHolidayAllowanceValue";
import { chain, isNull, maxBy } from "~/lib/lodash";
import { type EmployeeData } from "~/services/synchronization/syncExternalEmployees";

export type HolidayAllowanceRemunerationItem = EmployeeData["remunerationItems"][number];

type IntegrationSourceWithoutLedgy = Exclude<IntegrationSource, "LEDGY">;

export const STANDARD_HOLIDAY_ALLOWANCE = 0.08;

const buildRemunerationItem = (
  { name, externalId, amount, date }: { name: string; externalId: string; amount: number; date: Date | null },
  { integrationSettings, companyId }: { integrationSettings: Pick<IntegrationSettings, "source">; companyId: number }
): HolidayAllowanceRemunerationItem => {
  const source = integrationSettings.source as IntegrationSourceWithoutLedgy;

  return {
    source,
    externalId,
    amount: Math.round(amount * 100),
    date,
    status: ExternalRemunerationStatus.LIVE,
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId,
            source,
            externalId,
          },
        },
        create: {
          source,
          externalId,
          name,
          mappedType: ExternalRemunerationType.FIXED_SALARY,
          company: {
            connect: {
              id: companyId,
            },
          },
        },
      },
    },
  };
};

export const buildHolidayAllowanceRemunerationItem = (
  holidayAllowanceValue: EmployeeData["holidayAllowanceValue"],
  {
    companyId,
    integrationSettings,
    remunerationItems,
    isNLEmployee,
  }: {
    companyId: number;
    integrationSettings?: Pick<
      IntegrationSettings,
      | "isHolidayAllowanceIncludedInBaseSalary"
      | "holidayAllowanceCustomFieldFrequency"
      | "isCustomFieldOnlyHolidayAllowance"
      | "source"
      | "applyFlatHolidayAllowance"
    >;
    remunerationItems: EmployeeData["remunerationItems"];
    isNLEmployee: boolean;
  }
) => {
  if (!integrationSettings) {
    return null;
  }

  if (
    isNull(integrationSettings.isHolidayAllowanceIncludedInBaseSalary) ||
    integrationSettings.isHolidayAllowanceIncludedInBaseSalary
  ) {
    return null;
  }

  const salaryWithoutAllowance = chain(remunerationItems)
    .filter((item) => item.status === ExternalRemunerationStatus.LIVE)
    .filter((item) => item.nature.connectOrCreate?.create?.mappedType === ExternalRemunerationType.FIXED_SALARY)
    .sumBy(({ amount }) => amount)
    .value();

  const remunerationItemDate = value(() => {
    const date = maxBy(remunerationItems, "date")?.date;

    if (!date) {
      return null;
    }

    return new Date(date);
  });

  const cleanedHolidayAllowanceValue = cleanHolidayAllowanceValue(holidayAllowanceValue);

  if (!cleanedHolidayAllowanceValue) {
    if (!isNLEmployee || !integrationSettings.applyFlatHolidayAllowance) {
      return null;
    }

    const yearlyHolidayAllowance = Math.round((salaryWithoutAllowance * STANDARD_HOLIDAY_ALLOWANCE) / 100);

    return buildRemunerationItem(
      {
        externalId: "holiday-allowance",
        name: "Yearly holiday allowance",
        amount: yearlyHolidayAllowance,
        date: remunerationItemDate,
      },
      { companyId, integrationSettings }
    );
  }

  const multiplier = match(integrationSettings.holidayAllowanceCustomFieldFrequency)
    .with(CompensationFrequency.MONTHLY, () => 12)
    .with(CompensationFrequency.QUARTERLY, () => 4)
    .with(CompensationFrequency.YEARLY, () => 1)
    .otherwise(() => 1);

  const yearlyHolidayAllowance = Math.round(cleanedHolidayAllowanceValue * multiplier);

  if (integrationSettings.isCustomFieldOnlyHolidayAllowance) {
    return buildRemunerationItem(
      {
        externalId: "holiday-allowance",
        name: "Yearly holiday allowance",
        amount: yearlyHolidayAllowance,
        date: remunerationItemDate,
      },
      { companyId, integrationSettings }
    );
  }

  if (!salaryWithoutAllowance) {
    return buildRemunerationItem(
      {
        externalId: "fix-salary+holiday-allowance",
        name: "Yearly salary (holiday allowance incl.)",
        amount: yearlyHolidayAllowance,
        date: remunerationItemDate,
      },
      { companyId, integrationSettings }
    );
  }

  const estimatedHolidayAllowance = Math.round(yearlyHolidayAllowance - salaryWithoutAllowance / 100);

  if (estimatedHolidayAllowance <= 0) {
    return null;
  }

  return buildRemunerationItem(
    {
      externalId: "holiday-allowance",
      name: "Yearly holiday allowance (est.)",
      amount: estimatedHolidayAllowance,
      date: remunerationItemDate,
    },
    { companyId, integrationSettings }
  );
};

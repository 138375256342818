import { assertNotNil } from "~/lib/utils";
import { type ClassificationResult, type SimilarityResult } from "~/services/job/ai/classifyJobTitlesWithEmbeddings";

const ruleFirstResultIsFarFromOthers = (results: SimilarityResult[]) => {
  const MINIMUM_SIMILARITY_DIFFERENCE = 0.25;

  if (results.length < 2) {
    return false;
  }

  const firstResult = assertNotNil(results[0]);
  const otherResults = results.slice(1);

  const minimumExpectedSimilarityDifference = Math.max(firstResult.similarity / 2, MINIMUM_SIMILARITY_DIFFERENCE);
  const similarityToFirstResult = otherResults.map((result) => result.similarity);

  return similarityToFirstResult.every((similarity) => similarity < minimumExpectedSimilarityDifference);
};

const ruleFirstResultIsAboveThreshold = (results: SimilarityResult[]) => {
  const AUTOMAP_THRESHOLD = 0.8;
  const AUTOMAP_DIFF_THRESHOLD = 0.15;

  if (results.length === 0) {
    return false;
  }

  const firstResult = assertNotNil(results[0]);

  if (firstResult.similarity < AUTOMAP_THRESHOLD) {
    return false;
  }

  if (results.length < 2) {
    return true;
  }

  const secondResult = assertNotNil(results[1]);

  // make sure there is a significant difference between the first and second result
  return secondResult.similarity < firstResult.similarity - AUTOMAP_DIFF_THRESHOLD;
};

const ruleFirstResultIsSameThanSuggestedStringSimilarityJob = (
  results: ClassificationResult[],
  suggestedJobIdFromStringSimilarity: number
) => {
  const AUTOMAP_THRESHOLD = 0.7;

  if (results.length === 0) {
    return false;
  }

  const firstResult = assertNotNil(results[0]);
  return firstResult.job.id === suggestedJobIdFromStringSimilarity && firstResult.similarity >= AUTOMAP_THRESHOLD;
};

export const shouldAutomaticallyMapExternalJobAfterClassification = (params: {
  classificationResults: ClassificationResult[];
  suggestedJobIdFromStringSimilarity?: number | undefined;
}): boolean => {
  if (params.suggestedJobIdFromStringSimilarity) {
    const firstResultAndStringSimilarityTop1 = ruleFirstResultIsSameThanSuggestedStringSimilarityJob(
      params.classificationResults,
      params.suggestedJobIdFromStringSimilarity
    );

    if (firstResultAndStringSimilarityTop1) {
      return true;
    }
  }

  return (
    ruleFirstResultIsFarFromOthers(params.classificationResults) ||
    ruleFirstResultIsAboveThreshold(params.classificationResults)
  );
};

import { ExternalRemunerationStatus, type Prisma } from "@prisma/client";
import { type PermissionsSchema } from "~/services/permissions/permissionsSchema";

export const DangerouslyIncludeHistoricalExternalRemunerationItemsToken = Symbol(
  "dangerouslyIncludeHistoricalExternalRemunerationItems"
) as unknown as string;

export const dangerouslyIncludeHistoricalExternalRemunerationItems = (): Record<string, boolean> => {
  return { [DangerouslyIncludeHistoricalExternalRemunerationItemsToken]: true };
};

export const generateExternalRemunerationItemHistoricalSchema = (): Partial<PermissionsSchema> => {
  const condition = { status: ExternalRemunerationStatus.LIVE } satisfies Prisma.ExternalRemunerationItemWhereInput;

  return {
    ExternalRemunerationItem: {
      read: condition,
      update: condition,
      delete: condition,
    },
  };
};

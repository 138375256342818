/* eslint-disable-next-line no-restricted-imports */
import { CircularProgress, Button as MuiButton, type ButtonProps } from "@mui/material";
import { forwardRef, useState, type ComponentProps } from "react";
import { ConfirmationModal } from "~/components/ui/core/ConfirmationModal";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";

type ButtonType = <C extends React.ElementType>(
  props: ButtonProps<
    C,
    {
      component?: C;
      isLoading?: boolean;
      confirmation?: Omit<ComponentProps<typeof ConfirmationModal>, "isOpen" | "onClose" | "onConfirm">;
      tooltip?: ComponentProps<typeof TitledTooltip>;
    }
  >,
  ref?: React.Ref<HTMLButtonElement>
) => React.ReactElement;

type OnClickEvent = Parameters<NonNullable<ComponentProps<"button">["onClick"]>>[0];

const CastedButton: ButtonType = (props, ref) => {
  const { children, isLoading, disabled, endIcon, onClick, confirmation, tooltip, ...rest } = props;
  const [confirmationEvent, setConfirmationEvent] = useState<OnClickEvent | null>(null);

  const button = (
    <MuiButton
      ref={ref}
      disabled={disabled || isLoading}
      endIcon={isLoading ? <CircularProgress size={14} color="inherit" /> : endIcon}
      onClick={(event) => {
        if (confirmation) {
          setConfirmationEvent(event);
        } else {
          onClick?.(event);
        }
      }}
      {...rest}
    >
      {children}
      {confirmation && (
        <ConfirmationModal
          {...confirmation}
          isOpen={!!confirmationEvent}
          onClose={() => setConfirmationEvent(null)}
          onConfirm={() => {
            setConfirmationEvent(null);
            onClick?.(confirmationEvent);
          }}
        />
      )}
    </MuiButton>
  );

  return tooltip ? <TitledTooltip {...tooltip}>{button}</TitledTooltip> : button;
};

export const Button = forwardRef(CastedButton) as ButtonType;

import { QueueJobName } from "@prisma/client";
import { array, mixed, number, object, string } from "yup";
import { type AppContext } from "~/lib/context";
import { type SpreadsheetRow } from "~/lib/external/google-sheets/client";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";

const GenerateSpreadsheetJobDataSchema = BaseJobDataSchema.concat(
  object({
    googleSheetId: number().required(),
    values: array().of(mixed<SpreadsheetRow<number>>().required()).ensure(),
    notes: array()
      .of(object({ column: number().required(), note: string().required() }))
      .optional(),
    rangePattern: string().required(),
    startingRow: number().required(),
  })
);

export type GenerateSpreadsheetJobData = YupOutputType<typeof GenerateSpreadsheetJobDataSchema>;

export const generateSpreadsheetWorkerService = async (ctx: AppContext, data: GenerateSpreadsheetJobData) => {
  const input = GenerateSpreadsheetJobDataSchema.validateSync(data, { abortEarly: false });

  const googleSheet = await ctx.prisma.googleSheet.findUniqueOrThrow({
    where: { id: input.googleSheetId },
  });

  await ctx.gsheet.fillSpreadsheet({
    spreadsheetId: googleSheet.fileId,
    values: input.values,
    notes: input.notes,
    rangePattern: input.rangePattern,
    startingRow: input.startingRow,
  });
};

export const sendGenerateSpreadsheetJob = async (ctx: AppContext, data: GenerateSpreadsheetJobData) => {
  return sendJob(ctx, {
    jobName: QueueJobName.GENERATE_SPREADSHEET,
    data,
    options: {
      singletonKey: makeSingletonKey(
        { companyId: data.companyId, googleSheetId: data.googleSheetId },
        { unique: false }
      ),
    },
  });
};

import { CompanyMarketPositioningType, type MarketPositioning, type SalaryBandMarketPositioning } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { getHeadcountRange, mapRangeToHeadcount } from "~/services/headcount";

export const parseMarketPositioning = async (
  ctx: AppContext,
  marketPositioning: Omit<MarketPositioning | SalaryBandMarketPositioning, "createdAt" | "updatedAt"> | null
) => {
  if (!marketPositioning) return null;

  return {
    id: marketPositioning.id,
    type: marketPositioning.type,
    industry: await value(() => {
      if (marketPositioning.type !== CompanyMarketPositioningType.SPECIFIC_INDUSTRY) {
        return null;
      }

      return ctx.prisma.companyTag.findUnique({
        where: { id: marketPositioning.industryId as number },
      });
    }),
    headcount: value(() => {
      if (marketPositioning.type !== CompanyMarketPositioningType.SIMILAR_HEADCOUNT) return null;

      if (ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2) return marketPositioning.headcount;

      return mapRangeToHeadcount({ min: marketPositioning.minHeadcount, max: marketPositioning.maxHeadcount });
    }),
    min: value(() => {
      if (marketPositioning.type !== CompanyMarketPositioningType.SIMILAR_HEADCOUNT) return null;

      if (!ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2) return marketPositioning.minHeadcount;

      const { min } = getHeadcountRange(marketPositioning.headcount);

      return min;
    }),
    max: value(() => {
      if (marketPositioning.type !== CompanyMarketPositioningType.SIMILAR_HEADCOUNT) return null;

      if (!ctx.featureFlags.CAN_ACCESS_FIGURES_AI_V2) return marketPositioning.maxHeadcount;

      const { max } = getHeadcountRange(marketPositioning.headcount);

      return max;
    }),
    fundingRounds: value(() => {
      if (marketPositioning.type !== CompanyMarketPositioningType.SIMILAR_FUNDING_STAGE) {
        return null;
      }

      return marketPositioning.fundingRounds;
    }),
  };
};

export type ParsedMarketPositioning = AsyncReturnType<typeof parseMarketPositioning>;

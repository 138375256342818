import { StatusCodes } from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";
import type { RowValidationError } from "~/services/xlsxToJson";

export class XlsxImportError extends Error {
  rowErrors: RowValidationError[];

  constructor(message: string, rowErrors: RowValidationError[] = []) {
    super(message);
    this.rowErrors = rowErrors;

    Object.setPrototypeOf(this, XlsxImportError.prototype);
  }
}

export const handleSpreadsheetImportError = (ctx: AppContext, res: NextApiResponse, error: XlsxImportError) => {
  logWarn(ctx, "[error] Spreadsheet import error", { error });

  return res.status(StatusCodes.UNPROCESSABLE_ENTITY).json({
    error: error.message,
    fields: error.rowErrors
      ? error.rowErrors.map((item) => {
          return {
            id: item.id ?? `-`,
            value: item.value,
            error: item.message,
            meta: {
              count: item.count,
            },
          };
        })
      : [],
  });
};

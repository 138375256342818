import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, SalaryRangeStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const DeleteRangeInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.DELETE_RANGE>().required(),
    bandId: number().required(),
    initialState: SalaryRangeStateSchema.required(),
  })
);

export type DeleteRangeChange = YupOutputType<typeof DeleteRangeInputSchema>;

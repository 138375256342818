import { SalaryGridConfigurationStep } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain } from "~/lib/lodash";
import { transaction } from "~/lib/transaction";
import { type CreateSalaryBandsInput } from "~/pages/api/salary-bands/create-salary-bands";
import { whereSalaryGridIs } from "~/services/salary-bands/access/helpers";
import { computeSalaryBandTemplates } from "~/services/salary-bands/configuration/computeSalaryBandTemplates";
import { getGeographicStrategyLocationMapper } from "~/services/salary-bands/configuration/getGeographicStrategyLocationMapper";
import { createSalaryRanges, selectCreatedSalaryBand } from "~/services/salary-bands/creation/createNewSalaryBands";
import { selectCompanyTargetPercentiles } from "~/services/salary-bands/creation/targetPercentilesHelpers";
import { getSalaryBandCurrencyIdFromLocation } from "~/services/salary-bands/helpers/getSalaryBandCurrencyIdFromLocation";

export const createSalaryBands = async (ctx: AppContext, input: CreateSalaryBandsInput) => {
  const user = getRequiredUser(ctx);

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: user.companyId },
    select: selectCompanyTargetPercentiles,
  });

  const salaryGrid = await ctx.prisma.salaryGrid.findFirstOrThrow({
    where: { ...whereSalaryGridIs(input) },
    select: {
      id: true,
      width: true,
      measure: true,
    },
  });

  const salaryBandTemplates = await computeSalaryBandTemplates(ctx, input);

  const mapGeographicStrategyLocation = await getGeographicStrategyLocationMapper(ctx);

  await transaction(
    ctx,
    async (ctx) => {
      await mapSeries(salaryBandTemplates, async (salaryBandTemplate) => {
        const jobIds = chain(salaryBandTemplate.salaryBandJob.mappedJobs)
          .map((salaryBandJob) => salaryBandJob.externalJob.mappedJobId)
          .compact()
          .uniq()
          .value();

        const locationIds = chain(salaryBandTemplate.salaryBandLocation.mappedLocations)
          .map((salaryBandLocation) => salaryBandLocation.externalLocation?.mappedLocation)
          .flatMap((location) => (!!location ? mapGeographicStrategyLocation(location).locations : null))
          .compact()
          .map((location) => location.id)
          .uniq()
          .value();

        const existingSalaryBand = await ctx.prisma.salaryBand.findUnique({
          where: {
            gridId_jobId_locationId: {
              gridId: input.salaryGridId,
              jobId: salaryBandTemplate.salaryBandJob.id,
              locationId: salaryBandTemplate.salaryBandLocation.id,
            },
          },
          select: { id: true },
        });

        if (existingSalaryBand) {
          return;
        }

        const salaryBandMarketPositioning = company.marketPositioning
          ? await ctx.prisma.salaryBandMarketPositioning.create({
              data: {
                industryId: company.marketPositioning.industryId,
                headcount: company.marketPositioning.headcount,
                minHeadcount: company.marketPositioning.minHeadcount,
                maxHeadcount: company.marketPositioning.maxHeadcount,
                fundingRounds: company.marketPositioning.fundingRounds,
                type: company.marketPositioning.type,
              },
            })
          : null;

        const currencyId = await getSalaryBandCurrencyIdFromLocation(ctx, salaryBandTemplate.salaryBandLocation);

        const salaryBand = await ctx.prisma.salaryBand.create({
          data: {
            gridId: input.salaryGridId,
            jobId: salaryBandTemplate.salaryBandJob.id,
            locationId: salaryBandTemplate.salaryBandLocation.id,
            currencyId,
            isDraft: true,
            measure: salaryGrid.measure,
            benchmarkedJobs: {
              createMany: {
                data: jobIds.map((jobId) => ({
                  gridId: input.salaryGridId,
                  jobId,
                })),
              },
            },
            benchmarkedLocations: {
              createMany: {
                data: locationIds.map((locationId) => ({
                  gridId: input.salaryGridId,
                  locationId,
                })),
              },
            },
            marketPositioningId: salaryBandMarketPositioning?.id ?? null,
          },
          select: selectCreatedSalaryBand,
        });

        await createSalaryRanges(ctx, {
          salaryGrid,
          salaryBand,
          salaryBandLevels: salaryBandTemplate.salaryBandLevels,
          company,
          measure: salaryGrid.measure,
        });
      });

      await ctx.prisma.salaryGrid.update({
        where: { id: salaryGrid.id },
        data: { configurationStep: SalaryGridConfigurationStep.DONE },
      });
    },
    {
      timeout: 60000_00 * 15,
    }
  );
};

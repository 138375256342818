import { Product } from "@prisma/client";
import { match } from "ts-pattern";
import { ProductPermission } from "~/services/subscriptions/validateSubscription";

export const productToPermission = (product: Product): ProductPermission => {
  return match(product)
    .with(Product.COMPENSATION_REVIEW, () => ProductPermission.CAN_ACCESS_COMPENSATION_REVIEW)
    .with(Product.BENCHMARK, () => ProductPermission.CAN_ACCESS_BENCHMARK)
    .with(Product.SALARY_BANDS, () => ProductPermission.CAN_ACCESS_SALARY_BANDS)
    .exhaustive();
};

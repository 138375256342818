import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { chunk, compact } from "~/lib/lodash";

export const createChatCompletion = async (ctx: AppContext, params: { systemPrompt?: string; prompt: string }) => {
  const chatCompletion = await ctx.openai.chat.completions.create({
    model: "gpt-3.5-turbo",
    messages: compact([
      params.systemPrompt && { role: "system", content: params.systemPrompt },
      { role: "user", content: params.prompt },
    ] as const),
  });

  const answer = chatCompletion.choices[0]?.message?.content;

  if (!answer) {
    throw new Error("No answer found in OpenAI completion");
  }

  return answer;
};

export const OPENAI_MAX_EMBEDDINGS_LENGTH = 8000;

export const createEmbeddings = async (ctx: AppContext, params: { texts: string[] }) => {
  const model = "text-embedding-3-small";

  const encoder = ctx.tiktoken.getEncoding(ctx.tiktoken.getEncodingNameForModel(model));
  const totalTokens = params.texts.map((text) => encoder.encode(text)).reduce((acc, tokens) => acc + tokens.length, 0);

  const groupedResults = await mapSeries(
    chunk(params.texts, Math.ceil(totalTokens / OPENAI_MAX_EMBEDDINGS_LENGTH)),
    async (chunkedTexts) => {
      const response = await ctx.openai.embeddings.create({
        model,
        input: chunkedTexts,
        encoding_format: "float",
        dimensions: 512,
      });

      return response.data.map((responseData) => responseData.embedding);
    }
  );

  return groupedResults.flat();
};

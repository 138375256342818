import { type Product } from "@prisma/client";
import { ApiFeatureAccessError } from "~/lib/errors/apiFeatureAccessError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { productToPermission } from "~/services/subscriptions/utils";

export const protectRouteWithSubscription = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { product?: Product }
) => {
  if (!options.product || ctx.req.subscriptions[productToPermission(options.product)]) {
    return next(ctx);
  }

  throw new ApiFeatureAccessError(`User cannot access the product "${options.product}"`);
};

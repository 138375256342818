import React, { createContext, useContext, useMemo } from "react";
import { isString, pick } from "~/lib/lodash";
import { type FeatureFlagName, type FeatureFlagsStatus } from "~/services/featureFlags";

type FeatureFlagsProps = React.PropsWithChildren<{
  featureFlags: FeatureFlagsStatus;
}>;

const FeatureFlagsContext = createContext<FeatureFlagsProps>({
  featureFlags: {} as FeatureFlagsStatus,
});

export const useFeatureFlags = (featureFlagNames?: FeatureFlagName | FeatureFlagName[]): FeatureFlagsStatus => {
  const { featureFlags } = useContext(FeatureFlagsContext);

  return useMemo(() => {
    // No name provided, return all feature flags
    if (!featureFlagNames) {
      return featureFlags;
    }

    const flags = isString(featureFlagNames) ? [featureFlagNames] : featureFlagNames;

    return pick(featureFlags, flags);
  }, [featureFlags, featureFlagNames]);
};

export const FeatureFlagsProvider: React.FC<FeatureFlagsProps> = ({ featureFlags, children }) => {
  const flags = useMemo(
    () => ({
      featureFlags,
    }),
    [featureFlags]
  );

  return <FeatureFlagsContext.Provider value={flags}>{children}</FeatureFlagsContext.Provider>;
};

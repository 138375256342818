import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { type UpdateMappingSchemaInput } from "~/pages/api/update-mapping";

export const mapExternalLocations = async (
  ctx: AppContext,
  externalLocations: UpdateMappingSchemaInput["externalLocations"],
  companyId: number
) => {
  logInfo(ctx, "[mapping] Handling external locations", { companyId, externalLocations });

  const impactedEmployeeIds: number[] = [];

  await mapSeries(externalLocations ?? [], async (location) => {
    // Find the impacted employees first
    const originalLocation = await ctx.prisma.externalLocation.findFirst({
      where: { id: location.externalLocationId, companyId: companyId },
      include: { employees: { include: { mappedEmployee: true, location: true } } },
    });

    if (!originalLocation) {
      return;
    }

    originalLocation.employees.forEach((employee) => {
      // Unmapped employees & employees with unmapped locations are all impacted
      if (!originalLocation.mappedLocationId || !employee.mappedEmployee) {
        impactedEmployeeIds.push(employee.id);
        return;
      }

      // Employees mapped to the original location are all impacted
      // (meaning we exclude employees who might have been remapped manually to another location)
      if (employee.mappedEmployee.locationId === originalLocation.mappedLocationId) {
        impactedEmployeeIds.push(employee.id);
      }
    });

    await ctx.prisma.externalLocation.update({
      where: { id: location.externalLocationId },
      data: {
        mappedLocation: { connect: { id: location.mappedLocationId } },
        skippedAt: null,
      },
    });
  });

  logInfo(ctx, "[mapping] Handled external locations", { companyId, externalLocations });

  return impactedEmployeeIds;
};

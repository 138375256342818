import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";

export class BusinessLogicError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, BusinessLogicError.prototype);
  }
}

export const handleBusinessLogicError = (ctx: AppContext, res: NextApiResponse, error: BusinessLogicError) => {
  logWarn(ctx, "[error] Business logic error", { error });

  return res.status(HttpStatus.BAD_REQUEST).json({ error: error.message });
};

import { generateExternalEmployeesSoftDeleteSchema } from "~/lib/prisma-restrictions/schemas/generateExternalEmployeesSoftDeleteSchema";
import { generateExternalRemunerationItemHistoricalSchema } from "~/lib/prisma-restrictions/schemas/generateExternalRemunerationItemHistoricalSchema";

export const generatePrismaRestrictionsSchemasFn = () => {
  return (options: { includeSoftDeletedExternalEmployees?: boolean; includeHistoricalRemunerationItems?: boolean }) => {
    const schemas = [];

    if (!options.includeSoftDeletedExternalEmployees) {
      schemas.push(generateExternalEmployeesSoftDeleteSchema());
    }

    if (!options.includeHistoricalRemunerationItems) {
      schemas.push(generateExternalRemunerationItemHistoricalSchema());
    }

    return schemas;
  };
};

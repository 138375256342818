import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { boolean, number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import {
  fetchExternalEmployeeForPanel,
  type FetchExternalEmployeeForPanelResult,
} from "~/services/external-employee/fetchExternalEmployeeForPanel";

export const FetchExternalEmployeeForPanelSchema = object({
  id: number().required(),
  fromCompensationReview: boolean().optional().default(false),
}).noUnknown();

export type FetchExternalEmployeeForPanelInput = YupOutputType<typeof FetchExternalEmployeeForPanelSchema>;

const handler: NextApiHandler<FetchExternalEmployeeForPanelResult> = async (req, res) => {
  const input = FetchExternalEmployeeForPanelSchema.validateSync(req.body, { abortEarly: false });

  const externalEmployee = await fetchExternalEmployeeForPanel(req, input);

  return res.status(HttpStatus.OK).json(externalEmployee);
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const FETCH_EXTERNAL_EMPLOYEE_FOR_PANEL_QUERY_KEY: ApiRoute["pathname"] =
  "/api/external-employee/fetch-external-employee-for-panel";

export const useFetchExternalEmployeeForPanelQuery = createQuery<
  typeof handler,
  typeof FetchExternalEmployeeForPanelSchema
>({
  path: FETCH_EXTERNAL_EMPLOYEE_FOR_PANEL_QUERY_KEY,
  schema: FetchExternalEmployeeForPanelSchema,
});

import { Action, createAbility, Subject } from "~/services/user/caslPermissions";
import { type PermissionsParams } from "~/services/user/permissions/authenticationOptions";

export const canAccessMarketData = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;
  if (!subscriptions.CAN_ACCESS_BENCHMARK) {
    return false;
  }

  return createAbility(user, params).can(Action.Access, Subject.MarketData);
};

export const canAccessYouVsMarket = (params: PermissionsParams): boolean => {
  const { user } = params;
  return createAbility(user, params).can(Action.Access, Subject.YouVsMarket);
};

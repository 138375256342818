import {
  type BenchmarkLevel,
  EmployeeLevel,
  ExternalEmployeeStatus,
  ExternalRemunerationType,
  type Prisma,
} from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { chain, isNil, isNull } from "~/lib/lodash";
import { createMissingBenchmarkLevel } from "~/services/benchmark-level/createMissingBenchmarkLevel";

export const selectExternalEmployeeForPartialCheck = {
  id: true,
  gender: true,
  status: true,
  deletedAt: true,
  jobId: true,
  levelId: true,
  locationId: true,
  remunerationItems: { select: { amount: true, nature: { select: { mappedType: true } } } },
} satisfies Prisma.ExternalEmployeeSelect;

export const selectExternalEmployeeForMappedCheck = {
  ...selectExternalEmployeeForPartialCheck,
  job: { select: { mappedJobId: true } },
  location: { select: { mappedLocationId: true } },
  level: { select: { mappedLevel: true, mappedBenchmarkLevelId: true } },
} satisfies Prisma.ExternalEmployeeSelect;

export type ExternalEmployeeForMappedCheck = Prisma.ExternalEmployeeGetPayload<{
  select: typeof selectExternalEmployeeForMappedCheck;
}>;

export type ExternalEmployeeForPartialCheck = Prisma.ExternalEmployeeGetPayload<{
  select: typeof selectExternalEmployeeForPartialCheck;
}>;

export const isValidPartialEmployee = (externalEmployee: ExternalEmployeeForPartialCheck) => {
  if (externalEmployee.status === ExternalEmployeeStatus.SKIPPED) {
    return false;
  }

  return (
    !isNil(externalEmployee.gender) &&
    !isNil(externalEmployee.jobId) &&
    !isNil(externalEmployee.levelId) &&
    !isNil(externalEmployee.locationId) &&
    externalEmployee.remunerationItems.length > 0 &&
    chain(externalEmployee.remunerationItems)
      .filter((remunerationItem) => remunerationItem.nature.mappedType === ExternalRemunerationType.FIXED_SALARY)
      .sumBy((remunerationItem) => remunerationItem.amount)
      .value() > 0
  );
};

export const isValidMappedEmployee = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => {
  if (externalEmployee.status === ExternalEmployeeStatus.SKIPPED) {
    return false;
  }

  const hasMappedJob = !isNull(getMappedJobId(externalEmployee, individualMapping));
  const hasMappedLevel = !isNull(getMappedLevel(externalEmployee, individualMapping));
  const hasMappedLocation = !isNull(getMappedLocationId(externalEmployee, individualMapping));

  return (
    !isNil(externalEmployee.gender) &&
    externalEmployee.remunerationItems.length > 0 &&
    chain(externalEmployee.remunerationItems)
      .filter((remunerationItem) => remunerationItem.nature.mappedType === ExternalRemunerationType.FIXED_SALARY)
      .sumBy((remunerationItem) => remunerationItem.amount)
      .value() > 0 &&
    hasMappedJob &&
    hasMappedLevel &&
    hasMappedLocation
  );
};

export type IndividualMapping = {
  jobId?: number | null;
  level?: EmployeeLevel | null;
  benchmarkLevelId?: number | null;
  locationId?: number | null;
};

export const getMappedJobId = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => individualMapping?.jobId ?? externalEmployee.job?.mappedJobId ?? null;

export const getMappedLevel = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => individualMapping?.level ?? externalEmployee.level?.mappedLevel ?? null;

export const getMappedBenchmarkLevel = async (
  ctx: AppContext,
  params: {
    externalEmployee: ExternalEmployeeForMappedCheck;
    individualMapping: IndividualMapping | null;
    level?: EmployeeLevel | null;
  }
) => {
  const { externalEmployee, individualMapping } = params;
  if (!externalEmployee.level?.mappedBenchmarkLevelId && !individualMapping?.benchmarkLevelId) {
    let defaultBenchmarkLevel: BenchmarkLevel | null = null;
    let createdBenchmarkLevel: BenchmarkLevel | undefined;

    if (individualMapping?.level) {
      //fallback to the default benchmark level
      defaultBenchmarkLevel = await ctx.prisma.benchmarkLevel.findFirst({
        where: { name: individualMapping?.level },
      });

      createdBenchmarkLevel = await createMissingBenchmarkLevel(ctx, {
        figuresLevel: individualMapping?.level,
      });
    }

    if (!defaultBenchmarkLevel && !createdBenchmarkLevel) {
      const fallback = await createMissingBenchmarkLevel(ctx, {
        figuresLevel: params.level ?? EmployeeLevel.JUNIOR,
      });

      return fallback?.id;
    }

    return defaultBenchmarkLevel?.id ?? createdBenchmarkLevel?.id;
  }

  return individualMapping?.benchmarkLevelId ?? externalEmployee.level?.mappedBenchmarkLevelId;
};

export const getMappedLocationId = (
  externalEmployee: ExternalEmployeeForMappedCheck,
  individualMapping: IndividualMapping | null
) => individualMapping?.locationId ?? externalEmployee.location?.mappedLocationId ?? null;

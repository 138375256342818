import { type Prisma } from "@prisma/client";
import { array, lazy, mixed, object } from "yup";
import { mapValues } from "~/lib/lodash";
import { type FilterOptionsInput } from "~/services/employee-filter";

const FiltersSchema = lazy((filters) => object(mapValues(filters, () => array().of(mixed()).ensure())));

export const validatePopulationFilters = (filters: Prisma.JsonValue) => {
  return FiltersSchema.validateSync(filters) as FilterOptionsInput;
};

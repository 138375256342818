import { EmployeeStatus, ExternalEmployeeStatus } from "@prisma/client";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { logInfo } from "~/lib/logger";
import { getId } from "~/lib/utils";
import {
  isValidPartialEmployee,
  selectExternalEmployeeForPartialCheck,
} from "~/services/external-employee/statusCheckers";
import { safeDeleteImage } from "~/services/image";

export type EmployeeDeleteReason = "not-an-employee-anymore" | "delete-from-dataset";

export const deleteEmployee = async (
  ctx: AppContext,
  employeeId: number,
  options: { reason: EmployeeDeleteReason; shouldDisconnectExternalEmployee?: boolean }
): Promise<void> => {
  const user = getRequiredUser(ctx);
  const employee = await ctx.prisma.employee.findFirstOrThrow({
    where: {
      id: employeeId,
      ...(!user.isSuperAdmin && {
        companyId: user.companyId,
      }),
    },
    select: {
      id: true,
      companyId: true,
      picture: { select: { id: true, bucket: true, path: true } },
      externalEmployee: {
        select: {
          salaryRangeEmployees: { select: { id: true } },
          ...selectExternalEmployeeForPartialCheck,
        },
      },
    },
  });

  logInfo(ctx, "[employee] Deleting employee", { employeeId, options, companyId: employee.companyId });

  const employeeStatus = match<EmployeeDeleteReason, EmployeeStatus>(options.reason)
    .with("not-an-employee-anymore", () => {
      return EmployeeStatus.HISTORICAL;
    })
    .with("delete-from-dataset", () => {
      return EmployeeStatus.DELETED;
    })
    .exhaustive();

  const employeeFlags = await ctx.prisma.employeeDataValidationFlag.findMany({
    where: {
      employeeId: employee.id,
      isLive: true,
    },
    select: {
      id: true,
    },
  });

  if (employeeFlags.length > 0) {
    await ctx.prisma.employeeDataValidationFlag.updateMany({
      where: {
        id: {
          in: employeeFlags.map(getId),
        },
      },
      data: {
        isLive: false,
        closedAt: new Date(),
      },
    });
  }

  await ctx.prisma.employee.update({
    where: { id: employee.id },
    data: {
      status: employeeStatus,
      picture: { disconnect: true },
    },
  });

  await safeDeleteImage(ctx, employee.picture);

  const shouldDisconnectExternalEmployee = options.shouldDisconnectExternalEmployee ?? true;

  if (employee.externalEmployee && !employee.externalEmployee.deletedAt && shouldDisconnectExternalEmployee) {
    logInfo(ctx, "[employee] Disconnecting external employee", {
      employeeId,
      options,
      companyId: employee.companyId,
      externalEmployeeId: employee.externalEmployee.id,
    });

    const isPartial = isValidPartialEmployee(employee.externalEmployee);

    await ctx.prisma.externalEmployee.update({
      where: { id: employee.externalEmployee.id },
      data: {
        mappedEmployee: { disconnect: true },
        status: isPartial ? ExternalEmployeeStatus.PARTIAL : ExternalEmployeeStatus.UNMAPPED,
      },
    });
  }

  logInfo(ctx, "[employee] Successfully deleted employee", { employeeId, options, companyId: employee.companyId });
};

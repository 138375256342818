import { CompanyImpersonationLogAction } from "@prisma/client";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { findImpersonationAccessRequest } from "~/services/impersonation/findImpersonationAccessRequest";
export const declineImpersonationAccessRequest = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  if (config.app.isProduction && user.isSuperAdmin) {
    throw new Error("Figures Administrators are not allowed to decline access request on behalf of a company.");
  }

  const impersonationAccessRequest = await findImpersonationAccessRequest(ctx, { companyId: user.companyId });

  if (!impersonationAccessRequest) {
    return;
  }

  await ctx.prisma.companyImpersonationAccess.update({
    where: { id: impersonationAccessRequest.id },
    data: {
      expiresAt: null,
      declinedAt: new Date(),
      logs: {
        create: {
          userId: user.id,
          message: "Successfully declined impersonation access request",
          action: CompanyImpersonationLogAction.DECLINE,
        },
      },
    },
  });
};

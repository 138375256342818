import { type Prisma } from "@prisma/client";
import { compact } from "~/lib/lodash";

type Bucket = {
  countryId: number;
  jobId: number;
  levelId: number;
};

export function generateEmployeeWhere(buckets: Bucket[]): Prisma.EmployeeWhereInput {
  const whereClause: Prisma.EmployeeWhereInput = {
    OR: buckets.map((bucket) => {
      const conditions: Prisma.EmployeeWhereInput[] = [
        { location: { country: { id: { in: [bucket.countryId] } } } },
        { job: { id: { in: [bucket.jobId] } } },
        { benchmarkLevel: { id: { in: [bucket.levelId] } } },
      ];

      return {
        AND: compact(conditions),
      };
    }),
  };

  return whereClause;
}

import { type User, UserLocale } from "@prisma/client";
import React, { useContext } from "react";

type LocaleProps = React.PropsWithChildren<{
  locale: UserLocale;
}>;

const LocaleContext = React.createContext<LocaleProps>({
  locale: UserLocale.EN,
});

export const useLocale = (): LocaleProps => {
  return useContext(LocaleContext);
};

type Props = React.PropsWithChildren<{
  user: User | null;
  queryLocale?: UserLocale;
}>;

export const LocaleProvider: React.FC<Props> = ({ queryLocale, user, children }) => {
  const locale = (queryLocale ?? user?.locale ?? UserLocale.EN) as UserLocale;

  return <LocaleContext.Provider value={{ locale }}>{children}</LocaleContext.Provider>;
};

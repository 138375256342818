import { ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { prismaPaginationParams } from "~/lib/pagination";
import { validateRequestAuthorised } from "~/lib/security";
import { type AutocompleteCompanyExternalEmployeesInput } from "~/pages/api/external-employee/fetch-autocomplete-external-employees-for-company";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";
import { searchExternalEmployee } from "~/services/users/search";

export const externalEmployeeSelectForAutocomplete = {
  _count: {
    select: {
      managees: { where: { status: { not: ExternalEmployeeStatus.SKIPPED } } },
    },
  },
  id: true,
  externalId: true,
  employeeNumber: true,
  firstName: true,
  lastName: true,
  picture: true,
  email: true,
  status: true,
  deletedAt: true,
  job: { select: { name: true } },
  level: { select: { name: true } },
  businessUnit: true,
  extraBusinessUnits: true,
  ...selectExternalEmployeeUserPicture,
} satisfies Prisma.ExternalEmployeeSelect;

export const autocompleteCompanyExternalEmployees = async (
  ctx: AppContext,
  params: AutocompleteCompanyExternalEmployeesInput
) => {
  validateRequestAuthorised(ctx, { companyId: params.companyId });

  return ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: params.companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
      ...(params.query && { AND: searchExternalEmployee(params.query) }),
    },
    select: externalEmployeeSelectForAutocomplete,
    orderBy: [{ firstName: "asc" }, { lastName: "asc" }, { employeeNumber: "asc" }],
    ...(params.pagination && {
      ...prismaPaginationParams(params.pagination),
    }),
  });
};

export type AutocompleteCompanyExternalEmployees = AsyncReturnType<typeof autocompleteCompanyExternalEmployees>;

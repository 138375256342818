import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { type FetchPerformanceReviewRatingsInput } from "~/pages/api/performance-review/fetch-performance-review-ratings";

export type FetchPerformanceReviewRatings = AsyncReturnType<typeof fetchPerformanceReviewRatings>;

export const fetchPerformanceReviewRatings = async (ctx: AppContext, input: FetchPerformanceReviewRatingsInput) => {
  return ctx.prisma.performanceReviewRating.findMany({
    where: {
      performanceReviewCycle: { companyId: input.companyId, scope: "COMPANY" },
    },
    select: { id: true, name: true },
    orderBy: { position: "asc" },
  });
};

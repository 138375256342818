import { type AppContext } from "~/lib/context";
import { cloneDeep } from "~/lib/lodash";
import { resetContextInitialisedFlag } from "~/lib/prismaTokens";

export const cloneContext = (ctx: AppContext) => {
  const newCtx = Object.assign({}, cloneDeep(ctx), {
    // Can't clone logger
    log: ctx.log,
  });

  resetContextInitialisedFlag(newCtx);

  return newCtx;
};

export const cloneObject = <T>(obj: T): T => {
  return Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
};

import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";

export class UserNotFoundError extends Error {
  public userId: number;

  constructor(message: string, userId: number) {
    super(`User not found: ${message}`);
    Object.setPrototypeOf(this, UserNotFoundError.prototype);
    this.userId = userId;
  }
}

export const handleUserNotFoundError = (ctx: AppContext, res: NextApiResponse, error: UserNotFoundError) => {
  logInfo(ctx, "[error] User not found", { error, userId: error.userId });

  return res.status(HttpStatus.BAD_REQUEST).json({ error: "User not found" });
};

import { type GetServerSidePropsContext } from "next";
import { ForbiddenError } from "~/lib/errors/forbiddenError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { getOnboardingRedirection } from "~/services/onboarding/getOnboardingRedirection";

export const redirectToOnboarding = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { getServerSidePropsContext: GetServerSidePropsContext }
) => {
  const onboardingRedirection = getOnboardingRedirection(options.getServerSidePropsContext);
  if (!onboardingRedirection) {
    return next(ctx);
  }

  throw new ForbiddenError("Must complete onboarding", onboardingRedirection);
};

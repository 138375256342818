import tracer from "dd-trace";
import { applySession } from "next-iron-session";
import { getCookieConfig } from "~/config";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";
import { SessionKey, validateSession } from "~/lib/session";

export const parseSessionCookies = async (ctx: MiddlewareContext) => {
  if (process.env.IS_JEST) return next(ctx);

  await applySession(ctx.req, ctx.res, getCookieConfig());

  const userId = ctx.req.session.get<string>(SessionKey.USER_ID);

  if (userId) {
    // This makes no sense but fixes a weird compilation issue.
    if (typeof window === "undefined") {
      tracer.setUser({ id: userId });
    }

    await validateSession(ctx.req, userId);
  }

  return next(ctx);
};

import { Action, createAbility, Subject } from "~/services/user/caslPermissions";
import { type PermissionsParams } from "~/services/user/permissions/authenticationOptions";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";

export const canAccessCompensationReview = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;
  if (!subscriptions.CAN_ACCESS_COMPENSATION_REVIEW || !user?.company.hasImportedEmployees) {
    return false;
  }
  return createAbility(user, params).can(Action.Access, Subject.CompensationReview);
};

export const canAccessSalaryBands = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;
  if (!user?.company.hasImportedEmployees || !subscriptions.CAN_ACCESS_SALARY_BANDS) {
    return false;
  }

  return createAbility(user, params).can(Action.Access, Subject.SalaryBands);
};

export const canAccessMarketData = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;

  if (!subscriptions.CAN_ACCESS_BENCHMARK) return false;

  return createAbility(user, params).can(Action.Access, Subject.MarketData);
};

export const canAccessCompanyDashboard = (params: PermissionsParams): boolean => {
  const { user, featureFlags } = params;

  if (featureFlags?.CANNOT_ACCESS_COMPANY_DASHBOARD) {
    return false;
  }

  if (!user?.company.hasImportedEmployees) {
    return false;
  }

  return createAbility(user, params).can(Action.Access, Subject.CompanyDashboard);
};

export const canAccessCompanyDashboardDetails = (params: PermissionsParams): boolean => {
  if (!canAccessCompanyDashboard(params)) {
    return false;
  }

  const roles = getUserRoles(params.user);
  if (!roles.isAdmin) {
    return false;
  }

  return true;
};

export const canAccessAllPeopleDashboard = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;

  if (!subscriptions.CAN_ACCESS_BENCHMARK && !subscriptions.CAN_ACCESS_SALARY_BANDS) {
    return false;
  }

  const roles = getUserRoles(user);

  if (!roles.isHr && !roles.isAdmin) {
    return false;
  }

  return canAccessMarketDataPeopleDashboard(params) && canAccessSalaryBandsPeopleDashboard(params);
};

export const canAccessMarketDataPeopleDashboard = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;
  if (!subscriptions.CAN_ACCESS_BENCHMARK) {
    return false;
  }

  return createAbility(user, params).can(Action.Access, Subject.PeopleDashboard_MarketData);
};

export const canAccessSalaryBandsPeopleDashboard = (params: PermissionsParams): boolean => {
  const { subscriptions, user } = params;
  if (
    (!subscriptions.CAN_ACCESS_SALARY_BANDS && !user?.company.defaultSalaryGridId) ||
    !user?.company.hasImportedEmployees
  ) {
    return false;
  }

  return createAbility(user, params).can(Action.Access, Subject.PeopleDashboard_SalaryBands);
};

export const canAccessRawData = (params: PermissionsParams): boolean => {
  const { user } = params;
  return createAbility(user, params).can(Action.Access, Subject.RawData);
};

export const canAccessIntegrations = (params: PermissionsParams): boolean => {
  const { user } = params;
  return createAbility(user, params).can(Action.Access, Subject.Integrations);
};

export const canAccessSettings = (params: PermissionsParams): boolean => {
  const { user } = params;
  return createAbility(user, params).can(Action.Access, Subject.Settings);
};

// noinspection JSUnusedGlobalSymbols
export const canAccessImportedEmployees = (params: PermissionsParams): boolean => {
  const { user } = params;

  return createAbility(user, params).can(Action.Access, Subject.ImportedEmployees);
};

export const canAccessAccount = (params: PermissionsParams): boolean => {
  const { user } = params;
  return createAbility(user, params).can(Action.Access, Subject.Account);
};

export const canAccessBusinessUnits = (params: PermissionsParams): boolean => {
  const { featureFlags } = params;

  return !!featureFlags?.CAN_ACCESS_BUSINESS_UNITS;
};

export const canAccessFileManager = (params: PermissionsParams): boolean => {
  const { user } = params;
  return createAbility(user, params).can(Action.Access, Subject.FileManager);
};

export const canAccessGenderPayGapReport = (params: PermissionsParams): boolean => {
  const { featureFlags, user } = params;

  if (!featureFlags.CAN_ACCESS_GENDER_PAY_GAP_REPORT) {
    return false;
  }

  return createAbility(user, params).can(Action.Access, Subject.GenderPayGapReport);
};

// noinspection JSUnusedGlobalSymbols
export const canAccessSubsidiaries = (params: PermissionsParams): boolean => {
  const { featureFlags } = params;

  return featureFlags.CAN_ACCESS_SUBSIDIARIES ?? false;
};

export const canAccessPermissionsCenter = (params: PermissionsParams): boolean => {
  if (!canAccessSettings(params)) {
    return false;
  }

  return params.subscriptions.CAN_ACCESS_SALARY_BANDS || (params.featureFlags?.CAN_ACCESS_BUSINESS_UNITS ?? false);
};

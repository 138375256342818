import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, SalaryGridStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const DuplicateGridForNewVersionInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.DUPLICATE_GRID_FOR_NEW_VERSION>().required(),
    initialState: SalaryGridStateSchema.required(),
    newState: SalaryGridStateSchema.required(),
  })
);

export type DuplicateGridForNewVersionChange = YupOutputType<typeof DuplicateGridForNewVersionInputSchema>;

import { CompensationReviewConfigurationStep, UserRole } from "@prisma/client";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { type AuthenticatedUser } from "~/lib/session";
import { type FetchCampaignResult } from "~/services/compensation-review/campaigns/fetchCampaign";
import { type FetchAuthenticatedCompensationReviewReviewerResult } from "~/services/compensation-review/fetchAuthenticatedCompensationReviewReviewer";
import { type FetchOffCycleReviewConfigurationResult } from "~/services/compensation-review/off-cycle-reviews/fetchOffCycleReviewConfiguration";

type CampaignCheck = (params: {
  user: AuthenticatedUser;
  campaign: FetchCampaignResult;
  authenticatedReviewer: FetchAuthenticatedCompensationReviewReviewerResult;
  impersonation?: AppContext["impersonation"];
}) => boolean;

type OffCycleCheck = (params: {
  user: AuthenticatedUser;
  configuration: FetchOffCycleReviewConfigurationResult;
}) => boolean;

const isCampaignAdministrator: CampaignCheck = (params) => {
  return (
    params.campaign.admins.map(({ userId }) => userId).includes(params.user.id) ||
    params.user.permissions.role === UserRole.ADMIN ||
    params.user?.isSuperAdmin
  );
};

export const canSeeYourReviews: CampaignCheck = (params) => {
  return !!params.authenticatedReviewer?.recommendationsCount;
};

export const canAccessConfiguration: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canAccessYourReviews: CampaignCheck = (params) => {
  if (!canSeeYourReviews(params)) return false;

  if (params.campaign.isSandbox) {
    return !!params.impersonation?.user;
  }

  return params.campaign.isInProgress || params.campaign.isClosed;
};

export const canSeeOverview: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canAccessOverview: CampaignCheck = (params) => {
  return canSeeOverview(params) && !params.campaign.isConfiguring;
};

export const canSeeReviewers: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

export const canAccessReviewers: CampaignCheck = (params) => {
  return canSeeReviewers(params) && !params.campaign.isConfiguring;
};

export const canSeeAnalytics: CampaignCheck = (params) => {
  return isCampaignAdministrator(params) || !!params.authenticatedReviewer?.canSeeAnalytics;
};

export const canAccessAnalytics: CampaignCheck = (params) => {
  return canSeeAnalytics(params) && !params.campaign.isConfiguring;
};

export const canAccessBudgets: CampaignCheck = (params) => {
  return (
    (isCampaignAdministrator(params) || !!params.authenticatedReviewer?.canSeeBudgets) && !params.campaign.isConfiguring
  );
};

export const canConfigureCampaign = (
  params: Parameters<CampaignCheck>[0] & {
    step: CompensationReviewConfigurationStep;
  }
) => {
  const devOverride = config.app.isLocal && params.user.isSuperAdmin;
  if (devOverride) return true;

  const canConfigure = canAccessConfiguration(params) && (params.campaign.isConfiguring || params.campaign.isSandbox);

  const currentStepIndex = Object.values(CompensationReviewConfigurationStep).indexOf(
    params.campaign.configurationStep
  );
  const stepIndex = Object.values(CompensationReviewConfigurationStep).indexOf(params.step);
  const canConfigureStep = currentStepIndex >= stepIndex;

  return canConfigure && canConfigureStep;
};

export const canReviewCampaign: CampaignCheck = (params) => {
  return canSeeYourReviews(params) && (params.campaign.isSandbox || params.campaign.isInProgress);
};

export const canOverrideReviewersRecommendation: CampaignCheck = (params) => {
  return (
    canReviewCampaign(params) &&
    (isCampaignAdministrator(params) || params.campaign.allowReviewersRecommendationOverride)
  );
};

export const canReviewExceptionalBonus: CampaignCheck = (params) => {
  return (
    canReviewCampaign(params) &&
    params.campaign.exceptionalBonusReviewEnabled &&
    !!params.authenticatedReviewer?.canReviewExceptionalBonus
  );
};

export const canReviewAnnualPerformanceBonus: CampaignCheck = (params) => {
  return (
    canReviewCampaign(params) &&
    params.campaign.annualPerformanceBonusReviewEnabled &&
    (params.campaign.reviewerAnnualPerformanceBonusReviewEnabled || isCampaignAdministrator(params))
  );
};

export const canBypassRules: CampaignCheck = (params) => {
  return isCampaignAdministrator(params);
};

const isOffCycleAdministrator: OffCycleCheck = (params) => {
  return (
    params.configuration.admins.map(({ userId }) => userId).includes(params.user.id) ||
    params.user.permissions.role === UserRole.ADMIN ||
    params.user?.isSuperAdmin
  );
};

export const canAccessOffCycleConfiguration: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canAccessOffCycleOverview: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canOverrideOffCycleReviewersRecommendation: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params) && params.configuration.allowReviewersRecommendationOverride;
};

export const canBypassOffCycleRules: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

export const canApproveOffCycleReview: OffCycleCheck = (params) => {
  return isOffCycleAdministrator(params);
};

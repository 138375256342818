import { PerformanceReviewCycleScope } from "@prisma/client";
import { number, object } from "yup";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { type YupOutputType } from "~/lib/utils";

export const PerformanceReviewScopeSchema = object({
  compensationReviewCampaignId: number().nullable(),
}).nullable();

export type PerformanceReviewScopeInput = YupOutputType<typeof PerformanceReviewScopeSchema>;

export const findOrCreatePerformanceReviewCycle = async (
  ctx: AppContext,
  params: {
    companyId: number;
    performanceReviewScope: PerformanceReviewScopeInput;
  }
) => {
  const scope = params.performanceReviewScope?.compensationReviewCampaignId
    ? PerformanceReviewCycleScope.COMPENSATION_REVIEW_CAMPAIGN
    : PerformanceReviewCycleScope.COMPANY;

  const existingPerformanceCycle = await value(async () => {
    if (params.performanceReviewScope?.compensationReviewCampaignId) {
      const campaign = await ctx.prisma.compensationReviewCampaign.findUniqueOrThrow({
        where: { id: params.performanceReviewScope.compensationReviewCampaignId, companyId: params.companyId },
        select: { performanceReviewCycleId: true },
      });

      if (campaign.performanceReviewCycleId) {
        return ctx.prisma.performanceReviewCycle.findUniqueOrThrow({
          where: { id: campaign.performanceReviewCycleId },
          select: { id: true, selectedCycleIds: true },
        });
      } else {
        return null;
      }
    }

    return ctx.prisma.performanceReviewCycle.findFirst({
      where: { companyId: params.companyId, scope },
      select: { id: true, selectedCycleIds: true },
    });
  });

  if (existingPerformanceCycle) {
    return existingPerformanceCycle;
  }

  const newCycle = await ctx.prisma.performanceReviewCycle.create({
    data: { companyId: params.companyId, scope },
    select: { id: true, selectedCycleIds: true },
  });

  if (params.performanceReviewScope?.compensationReviewCampaignId) {
    await ctx.prisma.compensationReviewCampaign.update({
      where: { id: params.performanceReviewScope.compensationReviewCampaignId },
      data: { performanceReviewCycleId: newCycle.id },
    });
  }

  return newCycle;
};

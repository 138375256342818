import { compact, omit } from "~/lib/lodash";

export const makeSingletonKey = (
  params: { companyId: number } & Record<string, string | number>,
  options: { unique: boolean }
) => {
  // If you update this piece, make sure to check that the frontend can
  // actually still fetch the job queue status through useJobQueueStatus
  return compact([...Object.values(omit(params, "companyId")), params.companyId, options?.unique && Date.now()]).join(
    "-"
  );
};

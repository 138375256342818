import { type FormikErrors } from "formik";
import { type TFunction, type TOptions } from "i18next";
import type { ParseKeys } from "i18next/typescript/t.d.ts";
import { type ValidationError, setLocale } from "yup";
import { value } from "~/components/helpers";
import { isArray, isObject, isString, mapValues } from "~/lib/lodash";

const TRANSLATION_ARGS_KEY = "args";

/**
 * Fake t function that will just return the key and its args to be translated by `translateYupError`
 * It still ensures key type safety and it picked-up by i18n ally
 */
type TKey = ParseKeys<"common", TOptions> & `common.errors.${string}`;
const t = (key: TKey, args?: Record<string, unknown>) => JSON.stringify({ key, [TRANSLATION_ARGS_KEY]: args });

export const configureYupErrors = () => {
  const interpolateArray = (values: unknown) => (isArray(values) ? values.join(", ") : values);

  setLocale({
    mixed: {
      default: t("common.errors.mixed.default"),
      required: t("common.errors.mixed.required"),
      defined: t("common.errors.mixed.required"),
      oneOf: ({ values }) => t("common.errors.mixed.one-of", { values: interpolateArray(values) }),
      notOneOf: ({ values }) => t("common.errors.mixed.not-one-of", { values: interpolateArray(values) }),
      notType: t("common.errors.mixed.default"),
    },
    string: {
      length: ({ length }) => t("common.errors.string.length", { length }),
      min: ({ min }) => t("common.errors.string.min", { min }),
      max: ({ max }) => t("common.errors.string.max", { max }),
      matches: t("common.errors.string.matches"),
      email: t("common.errors.string.email"),
      url: t("common.errors.string.url"),
      uuid: t("common.errors.string.uuid"),
      trim: t("common.errors.string.trim"),
      lowercase: t("common.errors.string.lowercase"),
      uppercase: t("common.errors.string.uppercase"),
    },
    number: {
      min: ({ min }) => t("common.errors.number.min", { min }),
      max: ({ max }) => t("common.errors.number.max", { max }),
      lessThan: ({ less: max }) => t("common.errors.number.min", { max }),
      moreThan: ({ more: min }) => t("common.errors.number.max", { min }),
      positive: t("common.errors.number.positive"),
      negative: t("common.errors.number.negative"),
      integer: t("common.errors.number.integer"),
    },
    date: {
      min: ({ min }) => t("common.errors.date.min", { min }),
      max: ({ max }) => t("common.errors.date.max", { max }),
    },
    boolean: {
      isValue: ({ value }) => t("common.errors.boolean.is-value", { value }),
    },
    object: {
      noUnknown: t("common.errors.object.no-unknown"),
    },
    array: {
      min: ({ min }) => t("common.errors.array.min", { min }),
      max: ({ max }) => t("common.errors.array.max", { max }),
      length: ({ length }) => t("common.errors.array.length", { length }),
    },
  });
};

export const translateYupError = (t: TFunction, error: ValidationError): ValidationError => {
  return {
    ...error,
    message: translateFieldError(t, error.message),
    errors: error.errors.map((error) => translateFieldError(t, error)),
    inner: error.inner.map((error) => translateYupError(t, error)),
  };
};

export const translateFormikErrors = <FormValues>(t: TFunction, errors: FormikErrors<FormValues>) => {
  const innerTranslateFormikErrors = (rawErrors: unknown): unknown => {
    const errors = value(() => {
      if (!isString(rawErrors)) return rawErrors;

      try {
        return JSON.parse(rawErrors);
      } catch {
        return rawErrors;
      }
    });

    if (isObject(errors) && "key" in errors) {
      return translateFieldError(t, errors);
    }

    if (isArray(errors)) {
      return errors.map((error) => innerTranslateFormikErrors(error));
    }

    if (isObject(errors)) {
      return mapValues(errors, (value) => innerTranslateFormikErrors(value));
    }

    return errors;
  };

  return innerTranslateFormikErrors(errors) as FormikErrors<FormValues>;
};

const translateFieldError = (t: TFunction, rawField: unknown): string => {
  const field = value(() => {
    if (!isString(rawField)) return rawField;

    try {
      return JSON.parse(rawField);
    } catch {
      return rawField;
    }
  });

  if (isObject(field) && "key" in field) {
    const key = field.key;
    const args = TRANSLATION_ARGS_KEY in field ? field[TRANSLATION_ARGS_KEY] : {};

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return t(key, args);
  }

  if (isString(field)) {
    const match = field.match(/(\d+) errors occurred/);
    const count = match?.[1];

    if (count) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return t("common.errors.multiple", { count });
    }
  }

  return rawField as string;
};

import { Global, css } from "@emotion/react";
import { theme } from "~/lib/theme";

export const CommandPaletteStyle: React.FC = () => (
  <Global
    styles={css`
      [cmdk-dialog] {
        width: 100%;
        max-width: 600px;
        z-index: 1301;
        position: fixed;
        left: 50%;
        top: 80px;
        border-radius: 4px;
        transform: translateX(-50%);
        background: white;

        [cmdk] {
          width: 600px;
          transform-origin: center center;
        }

        &:focus {
          outline: none;
        }
      }

      [cmdk-root] {
        width: 100%;
        padding: 8px;
        background: #ffffff;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid ${theme.colors.gray[200]};
        box-shadow:
          0 1px 3px 0 rgb(0 0 0 / 0.1),
          0 1px 2px -1px rgb(0 0 0 / 0.1);
        transition: transform 100ms ease;
        outline: none;
      }

      [cmdk-input] {
        border: none;
        font-size: 17px;
        outline: none;
        background: white;
        color: ${theme.colors.gray[900]};
        border-radius: 0;

        &::placeholder {
          color: ${theme.colors.gray[400]};
        }
      }

      [cmdk-item] {
        content-visibility: auto;

        cursor: pointer;
        height: 48px;
        border-radius: 8px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 8px;
        color: ${theme.colors.primary[900]};
        user-select: none;
        will-change: background, color;
        transition: all 150ms ease;
        transition-property: none;

        &[data-selected="true"] {
          background: ${theme.colors.primary[200]};
          color: ${theme.colors.gray[900]};
        }

        &[data-disabled="true"] {
          color: ${theme.colors.gray[500]};
          cursor: not-allowed;
        }

        &:active {
          transition-property: background;
          background: ${theme.colors.primary[300]};
        }

        & + [cmdk-item] {
          margin-top: 4px;
        }
      }

      [cmdk-list] {
        max-height: 400px;
        overflow: auto;
        overscroll-behavior: contain;
        transition: 100ms ease;
        transition-property: height;
        padding-top: 8px;
      }

      [cmdk-group-heading] {
        user-select: none;
        font-size: 12px;
        color: ${theme.colors.gray[600]};
        padding: 0 8px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        margin-top: 8px;
        font-weight: 500;
      }

      [cmdk-empty] {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        margin-bottom: 8px;
        white-space: pre-wrap;
        color: ${theme.colors.gray[600]};
      }
    `}
  />
);

import { EmployeeStatus } from "@prisma/client";
import { differenceInCalendarDays } from "date-fns";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { normalizeForSearch } from "~/lib/utils";
import { fillEmployeeFromExternalEmployee } from "~/services/employee-aggregate/fillEmployeeFromExternalEmployee";
import { mapToEmployee } from "~/services/external-employee/externalEmployeeMapping";

export type ReconciliationExternalEmployee = {
  employeeNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  hireDate: Date | null;

  job: { name: string | null; mappedJobId: number | null } | null;
};

export type ReconciliationEmployee = {
  employeeNumber: string | null;
  firstName: string | null;
  lastName: string | null;
  hireDate: Date | null;

  externalJobTitle: string | null;
  jobId: number | null;
};

type MatchForReconciliationFunc = (params: {
  externalEmployee: ReconciliationExternalEmployee;
  suggestedEmployee: ReconciliationEmployee;
}) => boolean;

export const matchForReconciliation: MatchForReconciliationFunc = ({ externalEmployee, suggestedEmployee }) => {
  // Reconcile by employee number
  if (!!externalEmployee.employeeNumber && suggestedEmployee.employeeNumber === externalEmployee.employeeNumber) {
    return true;
  }

  // Reconcile by name
  if (
    !!externalEmployee.firstName &&
    !!externalEmployee.lastName &&
    normalizeForSearch(suggestedEmployee.firstName) === normalizeForSearch(externalEmployee.firstName) &&
    normalizeForSearch(suggestedEmployee.lastName) === normalizeForSearch(externalEmployee.lastName)
  ) {
    return true;
  }

  // Reconcile by hire date & job (either job title, or an external job mapped to the same job)
  if (
    !!externalEmployee.hireDate &&
    !!suggestedEmployee.hireDate &&
    Math.abs(differenceInCalendarDays(externalEmployee.hireDate as Date, suggestedEmployee.hireDate as Date)) < 5 &&
    ((!!suggestedEmployee.externalJobTitle && suggestedEmployee.externalJobTitle === externalEmployee.job?.name) ||
      (!!suggestedEmployee.jobId && suggestedEmployee.jobId === externalEmployee.job?.mappedJobId))
  ) {
    return true;
  }

  return false;
};

// for now we reconciliate an external employee vs. employees
// because most of the data are duplicated between these 2 entities
// in the future (when firstname/lastname/etc fields will be removed from employee) we might want
// to reconciliate external employee vs. external employee
export const fetchReconciliationSuggestions = async (ctx: AppContext, params: { externalEmployeeId: number }) => {
  const externalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
    where: { id: params.externalEmployeeId },
    select: {
      employeeNumber: true,
      firstName: true,
      lastName: true,
      companyId: true,
      hireDate: true,
      job: { select: { name: true, mappedJobId: true } },
    },
  });

  const employees = await ctx.prisma.employee.findMany({
    where: {
      companyId: externalEmployee.companyId,
      status: EmployeeStatus.LIVE,
    },
    include: {
      currency: true,
      job: {
        include: {
          family: true,
        },
      },
      location: {
        include: {
          country: true,
        },
      },
    },
    take: 2000, // temporary limit to avoid potential memory issues. Waiting reconciliation rework
  });

  return employees.filter((employee) => matchForReconciliation({ externalEmployee, suggestedEmployee: employee }));
};

// TODO @florian: https://github.com/figures-hr/figures/pull/5048#discussion_r1703857216
export const reconcileExternalEmployee = async (
  ctx: AppContext,
  params: { externalEmployeeId: number; employeeId: number }
) => {
  const user = getRequiredUser(ctx);

  const externalEmployee = await ctx.prisma.externalEmployee.findFirstOrThrow({
    where: {
      id: params.externalEmployeeId,
      companyId: user.companyId,
    },
    include: {
      mappedEmployee: true,
    },
  });

  if (externalEmployee.mappedEmployee) {
    throw new Error("External employee is already mapped");
  }

  const employee = await ctx.prisma.employee.findFirstOrThrow({
    where: {
      id: params.employeeId,
      companyId: user.companyId,
    },
  });

  await mapToEmployee(ctx, { externalEmployeeId: externalEmployee.id, employeeId: employee.id });

  await fillEmployeeFromExternalEmployee(ctx, { externalEmployeeId: externalEmployee.id, employeeId: employee.id });
};

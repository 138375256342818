import { type Prisma } from "@prisma/client";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { SelectInput } from "~/components/ui/core/BetterSelectInput";
import { LocationList } from "~/components/ui/LocationList";
import { useListLocationsQuery } from "~/hooks/useQueries";
import { trackSelectorError } from "~/lib/external/segment/web/events";
import { chain, differenceBy, find, findIndex } from "~/lib/lodash";
import { type ListLocationsOrderBy, type LocationRow } from "~/pages/api/list-locations";

export const locationSelectForSelector = {
  id: true,
  name: true,
  isRemote: true,
  country: {
    select: { id: true, name: true, alpha2: true },
  },
} satisfies Prisma.EmployeeLocationSelect;

export type LocationForSelector = Prisma.EmployeeLocationGetPayload<{ select: typeof locationSelectForSelector }>;

type Event = {
  target: {
    name?: string;
    value: LocationForSelector[];
  };
};

type Props = {
  name?: string;
  multiple?: boolean;
  value: LocationForSelector[];
  excludedItems?: LocationForSelector[];
  hideRemote?: boolean;
  hideOther?: boolean;
  hideFallbackedLocation?: boolean;
  onlyHighlighted?: boolean;
  orderBy?: ListLocationsOrderBy;
  disabled?: boolean;
  dense?: boolean;
  formatLabel?: (values: LocationForSelector[]) => JSX.Element;
  onChange: (event: Event) => void;
  onListUpdate?: (values: LocationForSelector[]) => void;
};

const COUNTRY_ALIASES = {
  GB: ["UK"],
  US: ["US", "USA"],
} as const;

export const LocationSelector: React.FC<Props> = ({
  name,
  value,
  multiple = false,
  disabled = false,
  dense = true,
  hideRemote,
  hideOther,
  hideFallbackedLocation = true,
  onlyHighlighted = false,
  orderBy,
  onChange,
  formatLabel,
  excludedItems = [],
  onListUpdate,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: locationData } = useListLocationsQuery(
    { hideRemote, hideOther, hideFallbackedLocation, orderBy },
    {
      onError: (error) => trackSelectorError({ name: "LocationSelector", error }),
    }
  );

  const otherLocations = differenceBy(
    locationData?.locations ?? [],
    locationData?.highlightedLocations ?? [],
    (location) => {
      return location.id;
    }
  );

  const highlightedLocations = locationData?.highlightedLocations ?? [];
  const locations = onlyHighlighted ? highlightedLocations : [...highlightedLocations, ...otherLocations];

  const groups = useMemo(() => {
    return chain(locations)
      .groupBy((location) => location.countryId)
      .map((locations) => {
        const row = locations[0] as LocationRow;
        const otherIndex = locations.findIndex((location) => location.name === "Other");
        const remoteIndex = locations.findIndex((location) => location.isRemote);
        const options = locations.filter((location) => !location.isRemote && location.name !== "Other");

        if (remoteIndex > -1) options.push(locations[remoteIndex] as LocationRow);
        if (otherIndex > -1) options.push(locations[otherIndex] as LocationRow);

        return { label: row.country.name, options, canSelectAll: true };
      })
      .orderBy((groupedLocation) => {
        return findIndex(groupedLocation.options, (location) => {
          const isHighlighted = locationData?.highlightedLocations
            ? !!find(locationData?.highlightedLocations, (loc) => loc.countryId === location.countryId)
            : false;
          const isSelected = !!find(value, (selectedLoc) => selectedLoc.country.id === location.countryId);
          return isHighlighted || isSelected;
        });
      }, "desc")
      .value();
  }, [isOpen === false]);

  useEffect(() => {
    onListUpdate?.(locationData?.locations ?? []);
  }, [locationData]);

  return (
    <SelectInput
      dense={dense}
      disabled={disabled}
      excludedItems={excludedItems}
      accordion={!hideFallbackedLocation}
      inline
      width="xl"
      formatLabel={
        formatLabel ??
        ((locations) => {
          return (
            <div
              className={classNames({
                "relative flex h-10 w-full items-center overflow-hidden rounded border border-gray-300 outline-none focus-within:ring hover:border-gray-800":
                  true,
                "bg-gray-50 text-gray-400": disabled,
                "py-1 px-2 text-sm": dense,
                "py-2 px-3": !dense,
              })}
            >
              <LocationList locations={locations} />
            </div>
          );
        })
      }
      formatOption={(location, { selected }) => (
        <span
          className={classNames({
            "text-primary-500 group-hover:!text-gray-900": selected,
            "group-hover:text-primary-500": !selected,
          })}
        >
          {location.name}
        </span>
      )}
      groups={groups}
      multiple={multiple}
      name={name}
      optionToId={(location) => {
        return location.id;
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      placeholder={multiple ? "Employee locations" : "Employee location"}
      searchOptions={{
        optionToSearchableStrings: (location) => {
          const strings = [location.name, location.country.name];

          if (location.isRemote) {
            strings.push("Remote");
          }
          const countryAliases = COUNTRY_ALIASES[location.country.alpha2 as keyof typeof COUNTRY_ALIASES];
          if (countryAliases) {
            strings.push(...countryAliases);
          }

          return strings;
        },
        placeholder: "Search for locations",
      }}
      value={value}
      onChange={(event) => {
        onChange(event);
      }}
    />
  );
};

import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import {
  type FetchExternalEmployeeCompensationHistoryResult,
  fetchExternalEmployeeCompensationHistory,
} from "~/services/external-employee/fetchExternalEmployeeCompensationHistory";

export const FetchExternalEmployeeCompensationHistorySchema = object({
  externalEmployeeId: number().required(),
  excludedCampaignId: number().nullable(),
}).noUnknown();

export type FetchExternalEmployeeCompensationHistoryInput = YupOutputType<
  typeof FetchExternalEmployeeCompensationHistorySchema
>;

const handler: NextApiHandler<FetchExternalEmployeeCompensationHistoryResult> = async (req, res) => {
  const input = FetchExternalEmployeeCompensationHistorySchema.validateSync(req.body, { abortEarly: false });

  const history = await fetchExternalEmployeeCompensationHistory(req, input);

  return res.status(HttpStatus.OK).json(history);
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessCompensationReview: true },
});

export const FETCH_EXTERNAL_EMPLOYEE_COMPENSATION_HISTORY_QUERY_KEY: ApiRoute["pathname"] =
  "/api/external-employee/fetch-external-employee-compensation-history";

export const useFetchExternalEmployeeCompensationHistoryQuery = createQuery<
  typeof handler,
  typeof FetchExternalEmployeeCompensationHistorySchema
>({
  path: FETCH_EXTERNAL_EMPLOYEE_COMPENSATION_HISTORY_QUERY_KEY,
  schema: FetchExternalEmployeeCompensationHistorySchema,
});

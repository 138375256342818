import { type PrismaClient } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { omit } from "~/lib/lodash";
import { DangerouslyIncludeSoftDeletedExternalEmployeesToken } from "~/lib/prisma-restrictions/schemas/generateExternalEmployeesSoftDeleteSchema";
import { type PrismaPayload } from "~/lib/prismaTypes";

const InitContextProtectionToken = Symbol("initContextProtectionToken") as unknown as string;
export const flagContextAsInitialised = (ctx: AppContext) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ctx as any)[InitContextProtectionToken] = true;
};
export const isContextInitialised = (ctx: AppContext) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(ctx as any)[InitContextProtectionToken];
};
export const resetContextInitialisedFlag = (ctx: AppContext) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (ctx as any)[InitContextProtectionToken] = false;
};

const PrismaRecursiveProtectionToken = Symbol("prismaRecursiveProtectionToken") as unknown as string;
export const getPrismaRecursiveProtectionCount = (prisma: PrismaClient): number => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (prisma as any)[PrismaRecursiveProtectionToken] ?? 0;
};
export const incrementPrismaRecursiveProtectionCount = (prisma: PrismaClient) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (prisma as any)[PrismaRecursiveProtectionToken] = getPrismaRecursiveProtectionCount(prisma) + 1;
};
export const resetPrismaRecursiveProtectionCount = (prisma: PrismaClient) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (prisma as any)[PrismaRecursiveProtectionToken] = 0;
};

export const ApplyPrismaRestrictionsToken = Symbol("applyPrismaRestrictions") as unknown as string;
export const applyPrismaRestrictions = (): Record<string, boolean> => {
  return { [ApplyPrismaRestrictionsToken]: true };
};

export const DangerouslyIgnorePrismaRestrictionsToken = Symbol(
  "dangerouslyIgnorePrismaRestrictions"
) as unknown as string;
export const dangerouslyIgnorePrismaRestrictions = (): Record<string, boolean> => {
  return { [DangerouslyIgnorePrismaRestrictionsToken]: true };
};

export const DebugQueryToken = Symbol("debugQueryToken") as unknown as string;
// noinspection JSUnusedGlobalSymbols
export const debugQuery = (): Record<string, boolean> => {
  return { [DebugQueryToken]: true };
};

export const removeTokensFromPayload = (args: PrismaPayload) => {
  return omit(args, [
    ApplyPrismaRestrictionsToken,
    DangerouslyIgnorePrismaRestrictionsToken,
    DangerouslyIncludeSoftDeletedExternalEmployeesToken,
    DebugQueryToken,
    PrismaRecursiveProtectionToken,
  ]);
};

import { QueueJobName } from "@prisma/client";
import { number, object } from "yup";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { createCampaignEmployees } from "~/services/compensation-review/campaigns/admin/createCampaignEmployees";
import { fetchCompensationReviewCampaignContext } from "~/services/compensation-review/compensationReviewContext";

const CreateCompensationReviewCampaignEmployeesJobDataSchema = BaseJobDataSchema.concat(
  object({ campaignId: number().required() })
);

export type CreateCompensationReviewCampaignEmployeesJobData = YupOutputType<
  typeof CreateCompensationReviewCampaignEmployeesJobDataSchema
>;

export const createCompensationReviewCampaignEmployeesWorkerService = async (
  ctx: AppContext,
  data: CreateCompensationReviewCampaignEmployeesJobData
) => {
  const context = await fetchCompensationReviewCampaignContext(ctx, data);

  await createCampaignEmployees(context);
};

export const sendCreateCompensationReviewCampaignEmployeesJob = async (
  ctx: AppContext,
  data: CreateCompensationReviewCampaignEmployeesJobData
) => {
  await sendJob(ctx, {
    jobName: QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES,
    data,
    options: {
      singletonKey: makeSingletonKey({ companyId: data.companyId, campaignId: data.campaignId }, { unique: false }),
    },
  });
};

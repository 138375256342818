import { type Route, route } from "nextjs-routes";

type Redirect = {
  permanent: boolean;
  destination: string;
};

export const redirect = (to: Route): { redirect: Redirect } => {
  return {
    redirect: {
      permanent: false,
      destination: route(to),
    },
  };
};

export const notFound = (): { notFound: true } => {
  return {
    notFound: true,
  };
};

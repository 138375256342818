import { type QueueJobName, QueueJobStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { omit } from "~/lib/lodash";
import { logError, logInfo } from "~/lib/logger";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { makeQueueDriver, type SendOptions } from "~/lib/queue/makeQueueDriver";
import { updateQueueJobStatus } from "~/lib/queue/updateQueueJobStatus";

const DEFAULT_JOB_OPTIONS: SendOptions = {
  retryLimit: 1,
  retryDelay: 2, // in seconds
  retryBackoff: true,
} as const;

type JobParams<T extends BaseJobData> = {
  data: T;
  jobName: QueueJobName;
  options: SendOptions & { singletonKey: string };
};

export const sendJob = async <T extends BaseJobData>(ctx: AppContext, params: JobParams<T>) => {
  const { data, jobName, options } = params;

  const defaultedOptions = { ...DEFAULT_JOB_OPTIONS, ...options };

  try {
    const jobId = await makeQueueDriver().sendJob(ctx, jobName, data, defaultedOptions);

    if (jobId) {
      logInfo(ctx, "[worker] Job created", { jobId, jobName, data: omit(data, "rows") });

      return updateQueueJobStatus(ctx, {
        name: jobName,
        singletonKey: options.singletonKey,
        status: QueueJobStatus.SCHEDULED,
        externalJobId: jobId,
      });
    }

    if (!options.singletonKey) {
      throw new Error(`Could not create job "${jobName}"`);
    }
  } catch (error) {
    logError(ctx, "[worker] Unexpected error", { error, data: omit(data, "rows") });

    throw error;
  }
};

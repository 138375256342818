import { UserLocale } from "@prisma/client";
import {
  differenceInMilliseconds as baseDifferenceInMilliseconds,
  format as baseFormat,
  parseISO as baseParseISO,
  formatRelative,
  isValid,
  isWithinInterval,
  type Locale,
  subYears,
} from "date-fns";
import { enGB, fr } from "date-fns/locale";
import { match } from "ts-pattern";
import { isString } from "~/lib/lodash";

const DateFormatsEnum = {
  FULL_DATE: "dd-MM-yyyy",
  MONTH_YEAR: "MMMM yyyy",
  DATE_TIME: "dd/MM/yyyy HH:mm",
  DATE_PICKER: "yyyy-MM-dd",
} as const;

export const DateFormats = {
  FULL_DATE: "FULL_DATE",
  MONTH_YEAR: "MONTH_YEAR",
  DATE_TIME: "DATE_TIME",
  DATE_PICKER: "DATE_PICKER",
} as const;

export type DateFormats = (typeof DateFormats)[keyof typeof DateFormats];

const getDateFnsLocale = (locale: UserLocale): Locale =>
  match(locale)
    .with(UserLocale.EN, () => enGB)
    .with(UserLocale.FR, () => fr)
    .exhaustive();

export const formatDate = (date: Date | string, format: DateFormats, locale: UserLocale): string => {
  const parsedDate = isString(date) ? parseISO(date) : date;

  return baseFormat(parsedDate, DateFormatsEnum[format], { locale: getDateFnsLocale(locale) });
};

export const formatRelativeDate = (date: Date | string, locale?: UserLocale): string => {
  const parsedDate = isString(date) ? baseParseISO(date) : date;

  return formatRelative(parsedDate, new Date(), { ...(locale && { locale: getDateFnsLocale(locale) }) });
};

// https://github.com/date-fns/date-fns/blob/master/docs/upgradeGuide.md#string-arguments
export const parseISO = (date: Date | string): Date => {
  return isString(date) ? baseParseISO(date) : date;
};

// https://stackoverflow.com/questions/66985321/date-is-appearing-in-number-format-while-upload-import-excel-sheet-in-angular
export const parseExcelDate = (date: number | string): Date | null => {
  if (typeof date === "number") {
    const timestamp = (date - 25569) * 24 * 60 * 60 * 1000;

    return new Date(timestamp);
  }

  // From here on, we parse the date and only input it to the Date() constructor as year-month-day
  // to ensure timezone consistency
  if (!!date.match(/^\d{4}\-(0[1-9]|1[012])\-([012][0-9]|3[01])$/)) {
    return new Date(date);
  }

  const matchWithSlashesDayFirst = date.match(/^([012][0-9]|3[01])\/(0[1-9]|1[012])\/(\d{4})$/) ?? [];
  if (matchWithSlashesDayFirst !== null) {
    const [day, month, year] = matchWithSlashesDayFirst.slice(1);
    if (day && month && year) {
      return new Date(`${year}-${month}-${day}`);
    }
  }

  const matchWithSlashesYearFirst = date.match(/^(\d{4})\/(0[1-9]|1[012])\/([012][0-9]|3[01])$/) ?? [];
  if (matchWithSlashesYearFirst !== null) {
    const [year, month, day] = matchWithSlashesYearFirst.slice(1);
    if (day && month && year) {
      return new Date(`${year}-${month}-${day}`);
    }
  }

  const matchWithDashes = date.match(/^([012][0-9]|3[01])\-(0[1-9]|1[012])\-(\d{4})$/) ?? [];
  if (matchWithDashes !== null) {
    const [day, month, year] = matchWithDashes.slice(1);
    if (day && month && year) {
      return new Date(`${year}-${month}-${day}`);
    }
  }

  return null;
};

export const isFromOneYear = (date: Date) => {
  const now = new Date();
  const lastYear = subYears(now, 1);

  return isWithinInterval(date, { start: lastYear, end: now });
};

export const differenceInMilliseconds = (dateA: Date | string, dateB: Date | string): number => {
  const parsedDateA = isString(dateA) ? parseISO(dateA) : dateA;
  const parsedDateB = isString(dateB) ? parseISO(dateB) : dateB;

  return baseDifferenceInMilliseconds(parsedDateA, parsedDateB);
};

export const isValidParsableDate = (potentialDate: unknown) =>
  isString(potentialDate) && isValid(parseISO(potentialDate));

export const dateInputFormat = "yyyy/MM/dd";

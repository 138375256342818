import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";

export const findImpersonationAccessRequest = async (ctx: AppContext, { companyId }: { companyId: number }) => {
  return ctx.prisma.companyImpersonationAccess.findFirst({
    where: {
      companyId,
      requestedAt: { not: null },
      declinedAt: null,
    },
  });
};

export type ImpersonationAccessRequest = AsyncReturnType<typeof findImpersonationAccessRequest>;

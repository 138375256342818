import { OffCycleReviewRequestStatus } from "@prisma/client";
import { type TFunction } from "i18next";
import { match } from "ts-pattern";
import { isIn } from "~/lib/utils";

export const OffCycleReviewRequestStatusFilter = {
  PAST: "PAST",
  ONGOING: "ONGOING",
} as const;

export type OffCycleReviewRequestStatusFilter =
  (typeof OffCycleReviewRequestStatusFilter)[keyof typeof OffCycleReviewRequestStatusFilter];

export const formatOffCycleReviewRequestStatus = (t: TFunction, status: OffCycleReviewRequestStatus) => {
  return match(status)
    .with(OffCycleReviewRequestStatus.PENDING, () => ({
      label: t("enum.off-cycle-review-request-status.pending"),
      description: t("enum.off-cycle-review-request-status.pending-details"),
    }))
    .with(OffCycleReviewRequestStatus.DECLINED, () => ({
      label: t("enum.off-cycle-review-request-status.declined"),
      description: t("enum.off-cycle-review-request-status.declined-details"),
    }))
    .with(OffCycleReviewRequestStatus.ONGOING, () => ({
      label: t("enum.off-cycle-review-request-status.ongoing"),
      description: t("enum.off-cycle-review-request-status.ongoing-details"),
    }))
    .with(OffCycleReviewRequestStatus.COMPLETED, () => ({
      label: t("enum.off-cycle-review-request-status.completed"),
      description: t("enum.off-cycle-review-request-status.completed-details"),
    }))
    .with(OffCycleReviewRequestStatus.CANCELLED, () => ({
      label: t("enum.off-cycle-review-request-status.cancelled"),
      description: t("enum.off-cycle-review-request-status.cancelled-details"),
    }))
    .exhaustive();
};

export const CurrentOffCycleReviewRequestStatuses = [
  OffCycleReviewRequestStatus.PENDING,
  OffCycleReviewRequestStatus.ONGOING,
];

export const isOffCycleReviewRequestCurrent = (status: OffCycleReviewRequestStatus) => {
  return isIn(status, CurrentOffCycleReviewRequestStatuses);
};

import { type ExternalEmployeeSpreadsheetImport, SpreadsheetImportRowStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";
import { getId } from "~/lib/utils";
import { findImportRows } from "~/services/external-employee/import/findImportRows";
import { getImportRowsCount } from "~/services/external-employee/import/getImportRowsCount";
import { IMPORT_SPREADSHEET_ROW_BATCH_SIZE, sendImportSpreadsheetRowJob } from "~/workers/import/importSpreadsheetRow";

type ImportPartialInput = {
  companyId: number;
  import: ExternalEmployeeSpreadsheetImport;
};

export const startImportPartial = async (ctx: AppContext, params: ImportPartialInput): Promise<void> => {
  const totalRowsToImport = await getImportRowsCount(ctx, {
    importId: params.import.id,
    statuses: [SpreadsheetImportRowStatus.INIT],
  });

  logInfo(ctx, `[spreadsheet-import][partial] Starting an import of ${totalRowsToImport} rows`, {
    companyId: params.companyId,
    importId: params.import.id,
  });

  const firstRows = await findImportRows(ctx, {
    importId: params.import.id,
    statuses: [SpreadsheetImportRowStatus.INIT],
    take: IMPORT_SPREADSHEET_ROW_BATCH_SIZE,
  });

  await sendImportSpreadsheetRowJob(ctx, {
    companyId: params.companyId,
    importId: params.import.id,
    rowIds: firstRows.map(getId),
  });
};

import { UserLocale } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { type AuthenticatedUser } from "~/lib/session";

export const updateUserLocale = async (ctx: AppContext, user: AuthenticatedUser) => {
  await ctx.prisma.user.update({
    where: { id: user.id },
    data: { locale: user.locale === UserLocale.EN ? UserLocale.FR : UserLocale.EN },
  });
};

import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, JobStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const RenameJobInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.RENAME_JOB>().required(),
    initialState: JobStateSchema.required(),
    newState: JobStateSchema.required(),
  })
);

export type RenameJobChange = YupOutputType<typeof RenameJobInputSchema>;

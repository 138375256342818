import { type Prisma } from "@prisma/client";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { type PermissionsSchema, type UserPermissionsSchemaFilters } from "~/services/permissions/permissionsSchema";

export const generateMarketDataPermissionsSchema = (ctx: AppContext, filters: UserPermissionsSchemaFilters) => {
  const { allowedCountryIds, allowedLocationIds, allowedJobFamilyIds, allowedJobIds } = filters;

  return {
    Country: {
      read: value(() => {
        if (allowedCountryIds?.length) {
          return { id: { in: allowedCountryIds } };
        }

        return true;
      }),
      update: false,
      delete: false,
    },

    CompanyBenchmarkRestriction: {
      read: true,
      update: true,
      delete: true,
    },

    EmployeeLocation: {
      read: value(() => {
        const hasAllowedCountryIds = (allowedCountryIds?.length ?? 0) > 0;
        const hasAllowedLocationIds = (allowedLocationIds?.length ?? 0) > 0;

        if (!hasAllowedCountryIds && !hasAllowedLocationIds) {
          return true;
        }

        return {
          ...(hasAllowedCountryIds && { countryId: { in: allowedCountryIds } }),
          ...(hasAllowedLocationIds && { id: { in: allowedLocationIds } }),
        } as Prisma.EmployeeLocationWhereInput;
      }),
      update: false,
      delete: false,
    },

    JobFamily: {
      read: value(() => {
        if (allowedJobFamilyIds?.length) {
          return { id: { in: allowedJobFamilyIds } };
        }

        return true;
      }),
      update: false,
      delete: false,
    },

    Job: {
      read: value(() => {
        const hasAllowedJobIds = (allowedJobIds?.length ?? 0) > 0;
        const hasAllowedJobFamilyIds = (allowedJobFamilyIds?.length ?? 0) > 0;

        if (!hasAllowedJobIds && !hasAllowedJobFamilyIds) {
          return true;
        }

        return {
          ...(hasAllowedJobIds && { id: { in: allowedJobIds } }),
          ...(hasAllowedJobFamilyIds && { familyId: { in: allowedJobFamilyIds } }),
        } as Prisma.JobWhereInput;
      }),
      update: false,
      delete: false,
    },
  } satisfies Partial<PermissionsSchema>;
};

import { type Country, type EmployeeLocation, Prisma } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { words } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { fetchCountriesByRestrictions } from "~/services/country";
import { fetchActualLocations } from "~/services/locations/location";

export type ClassifyLocationResponse = {
  country: Pick<Country, "id" | "name">;
  location?: Pick<EmployeeLocation, "id" | "name">;
};

/**
 * Split a string into a list of words, using the following separator characters: ",", ";", ".", ":", and " ".
 */
export const splitByWord = (initialString: string) => words(initialString, /[^,;\.: ]+/g);

export const classifyLocation = async (
  ctx: AppContext,
  locationString: string
): Promise<ClassifyLocationResponse | null> => {
  const query = locationString.toLowerCase();
  const words = splitByWord(query);

  const locations = await fetchActualLocations(ctx);
  const countries = await fetchCountriesByRestrictions(ctx);

  const locationByExactNameRegEx = new RegExp(locations.map(({ name }) => name).join("|"), "gim");
  const matchedLocation = locationByExactNameRegEx.exec(query);
  const locationByExactName =
    matchedLocation && locations.find(({ name }) => matchedLocation[0] === name.toLowerCase());

  if (locationByExactName) {
    return { country: locationByExactName.country, location: locationByExactName };
  }

  const locationAliases = await fetchLocationAliases(ctx, { query, words });
  if (locationAliases.length > 0) {
    const aliasWithLocation = locationAliases.find(({ location }) => !!location);

    if (aliasWithLocation?.location) {
      return { country: aliasWithLocation.country, location: aliasWithLocation.location };
    }

    const aliasedLocation = locationAliases.find(({ country }) =>
      country.aliases_.some(({ alias }) => words.includes(alias.toLowerCase()))
    );
    const aliasWithoutLocation = aliasedLocation ?? locationAliases[0];

    if (aliasWithoutLocation) {
      return { country: aliasWithoutLocation.country };
    }

    return null;
  }

  const countryByExactNameRegEx = new RegExp(countries.map(({ name }) => name).join("|"), "gim");
  const matchedCountry = countryByExactNameRegEx.exec(query);
  const countryByExactName = countries.find(
    ({ name }) => name.toLowerCase().includes(query) || matchedCountry?.[0] === name.toLowerCase()
  );

  if (countryByExactName) {
    return { country: countryByExactName };
  }

  const countryAlias = await fetchFirstCountryAlias(ctx, { query, words });

  if (countryAlias) {
    return { country: countryAlias.country };
  }

  logInfo(ctx, "[location-classification] Couldn't classify location", { locationString });
  return null;
};

const fetchLocationAliases = async (ctx: AppContext, { query, words }: { query: string; words: string[] }) =>
  ctx.prisma.employeeLocationAlias.findMany({
    select: {
      id: true,
      alias: true,
      country: { select: { id: true, name: true, aliases_: { select: { id: true, alias: true } } } },
      location: { select: { id: true, name: true } },
    },
    where: {
      OR: [
        { alias: { equals: query, mode: Prisma.QueryMode.insensitive } },
        ...words.map((word) => ({ alias: { equals: word, mode: Prisma.QueryMode.insensitive } })),
      ],
    },
  });

const fetchFirstCountryAlias = async (ctx: AppContext, { query, words }: { query: string; words: string[] }) =>
  await ctx.prisma.countryAlias.findFirst({
    select: { id: true, alias: true, country: { select: { id: true, name: true } } },
    where: {
      OR: [
        { alias: { equals: query, mode: Prisma.QueryMode.insensitive } },
        ...words.map((word) => ({ alias: { equals: word, mode: Prisma.QueryMode.insensitive } })),
      ],
    },
  });

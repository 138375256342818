import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { boolean, number, object, string } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { OrderingSchema, PaginationSchema } from "~/lib/pagination";
import { createQuery } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import { FilterOptionsSchema } from "~/services/employee-filter";
import { fetchCompanyEmployees, type FetchCompanyEmployeesResult } from "~/services/employee/employeeRead";

export const FetchCompanyEmployeesSchema = object({
  query: string().nullable(),
  pagination: PaginationSchema.nullable(),
  order: OrderingSchema.nullable(),
  onlyFlagged: boolean().notRequired(),
  inBackoffice: boolean().notRequired(),
  extraWhere: object({
    companyId: number().notRequired(),
  })
    .nullable(true)
    .when("inBackoffice", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  filters: FilterOptionsSchema.notRequired(),
}).noUnknown();

export type FetchCompanyEmployeesInput = YupOutputType<typeof FetchCompanyEmployeesSchema>;

const handler: NextApiHandler<FetchCompanyEmployeesResult> = async (req, res) => {
  const input = FetchCompanyEmployeesSchema.validateSync(req.body, { abortEarly: false });

  const data = await fetchCompanyEmployees(req, input);

  return res.status(HttpStatus.OK).json(data);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessRawData: true,
  },
});

export const FETCH_COMPANY_EMPLOYEES_QUERY_KEY: ApiRoute["pathname"] = "/api/employee/fetch-company-employees";

export const useFetchCompanyEmployeesQuery = createQuery<typeof handler, typeof FetchCompanyEmployeesSchema>({
  path: FETCH_COMPANY_EMPLOYEES_QUERY_KEY,
  schema: FetchCompanyEmployeesSchema,
});

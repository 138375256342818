import { TextField } from "@mui/material";
import { DesktopDatePicker, type DesktopDatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { type ComponentProps } from "react";
import { type Merge } from "type-fest";
import { dateInputFormat } from "~/lib/dates";

type LocalizationProviderProps = Omit<ComponentProps<typeof LocalizationProvider>, "dateAdapter">;

type Props<TInputDate> = Merge<
  Omit<DesktopDatePickerProps<TInputDate, Date>, "renderInput">,
  { LocalizationProviderProps?: LocalizationProviderProps; TextFieldProps?: ComponentProps<typeof TextField> }
>;

export const DatePicker = <TInputDate,>({
  LocalizationProviderProps,
  TextFieldProps,
  ...props
}: Props<TInputDate>): JSX.Element => (
  <LocalizationProvider dateAdapter={AdapterDateFns} {...LocalizationProviderProps}>
    <DesktopDatePicker
      renderInput={(params) => <TextField {...params} {...TextFieldProps} size="small" />}
      inputFormat={dateInputFormat}
      {...props}
    />
  </LocalizationProvider>
);

import { Gender } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { chain, clamp, meanBy, sumBy } from "~/lib/lodash";
import { weightedMeanBy } from "~/lib/math";
import { type SetNonNullable } from "~/lib/utils";
import { compileMatchedJobs } from "~/services/company-dashboard/compileMatchedJobs";
import { type EmployeesBucket } from "~/services/company-dashboard/generateEmployeesBuckets";
import { computeEmployeeCompensation, hasGender } from "~/services/employee";
import { formatLevel, getLevelMetaData } from "~/services/employee/employeeLevel";
import { type EmployeeRow } from "~/services/employee/getLiveEmployees";

const computeGenderCompensationGap = (params: { employees: EmployeeRow[] }): number | null => {
  const { employees } = params;

  const men = employees.filter(hasGender(Gender.MALE));
  const women = employees.filter(hasGender(Gender.FEMALE));

  if (!men.length || !women.length) {
    return null;
  }

  const meanMenTotalCash = meanBy(men, (employee) => {
    return computeEmployeeCompensation(employee);
  });

  const meanWomenTotalCash = meanBy(women, (employee) => {
    return computeEmployeeCompensation(employee);
  });

  if (meanMenTotalCash === 0 || meanWomenTotalCash === 0) {
    return null;
  }

  const diff = meanMenTotalCash - meanWomenTotalCash;

  return 0.5 - diff / Math.min(meanMenTotalCash, meanWomenTotalCash);
};

const computeWeightedPayGap = (payGap: number | null) => {
  if (payGap === null || payGap < 0.5) {
    return payGap;
  }

  return 0.5 + (payGap - 0.5) / 2;
};

const computeBucketGenderPayGapStats = (params: { bucket: EmployeesBucket }) => {
  const { bucket } = params;

  const employeesCount = bucket.employees.length;
  const companyEmployeesCount = bucket.companyEmployees.length;

  const menCount = bucket.employees.filter(hasGender(Gender.MALE)).length;
  const womenCount = bucket.employees.filter(hasGender(Gender.FEMALE)).length;
  const marketGenderEqualityGap = computeGenderCompensationGap({ employees: bucket.employees });
  const weightedMarketGenderEqualityGap = computeWeightedPayGap(marketGenderEqualityGap);

  const companyMenCount = bucket.companyEmployees.filter(hasGender(Gender.MALE)).length;
  const companyWomenCount = bucket.companyEmployees.filter(hasGender(Gender.FEMALE)).length;
  const companyGenderEqualityGap = computeGenderCompensationGap({ employees: bucket.companyEmployees });
  const weightedCompanyGenderEqualityGap = computeWeightedPayGap(companyGenderEqualityGap);

  return {
    employeesCount,
    menCount,
    womenCount,
    marketGenderEqualityGap,
    weightedMarketGenderEqualityGap,

    companyEmployeesCount,
    companyMenCount,
    companyWomenCount,
    companyGenderEqualityGap,
    weightedCompanyGenderEqualityGap,
  };
};

export const computeBucketsGenderPayGapStats = (params: { buckets: EmployeesBucket[] }) => {
  const { buckets } = params;

  const bucketsWithStats = buckets.map((bucket) => {
    return {
      ...bucket,
      stats: computeBucketGenderPayGapStats({ bucket }),
    };
  });

  const isApplicable = bucketsWithStats.some((bucket) => bucket.stats.companyGenderEqualityGap !== null);

  if (!isApplicable) {
    return null;
  }

  const meanGenderPayGap = weightedMeanBy(bucketsWithStats, (bucket) => {
    return [bucket.stats.companyGenderEqualityGap, bucket.stats.companyEmployeesCount];
  });

  const weightedMeanGenderPayGap = weightedMeanBy(bucketsWithStats, (bucket) => {
    return [bucket.stats.weightedCompanyGenderEqualityGap, bucket.stats.companyEmployeesCount];
  });

  const marketMeanGenderPayGap = weightedMeanBy(bucketsWithStats, (bucket) => {
    return [bucket.stats.marketGenderEqualityGap, bucket.stats.employeesCount];
  });

  // Starting at 100%, remove 20% for each point of pay gap
  const diff = Math.abs(0.5 - weightedMeanGenderPayGap);
  const score = clamp(1 - diff * 20, 0, 1);

  return {
    score,
    meanGenderPayGap,
    marketMeanGenderPayGap,
  };
};

export const computeGenderEqualityPayGapByJobFamily = (params: { buckets: EmployeesBucket[] }) => {
  const { buckets } = params;

  const bucketsWithStats = chain(buckets)
    .groupBy((bucket) => bucket.job.familyId)
    .values()
    .map((buckets) => {
      const templateBucket = buckets[0] as (typeof buckets)[number];

      const employeesCount = sumBy(buckets, (bucket) => bucket.employees.length);
      const companyEmployeesCount = sumBy(buckets, (bucket) => bucket.companyEmployees.length);
      const menCount = sumBy(buckets, (bucket) => bucket.employees.filter(hasGender(Gender.MALE)).length);
      const companyMenCount = sumBy(buckets, (bucket) => bucket.companyEmployees.filter(hasGender(Gender.MALE)).length);
      const womenCount = sumBy(buckets, (bucket) => bucket.employees.filter(hasGender(Gender.FEMALE)).length);
      const companyWomenCount = sumBy(
        buckets,
        (bucket) => bucket.companyEmployees.filter(hasGender(Gender.FEMALE)).length
      );

      return {
        jobFamily: templateBucket.job.family,
        stats: computeBucketsGenderPayGapStats({ buckets }),
        matches: compileMatchedJobs({ buckets }),

        employeesCount,
        companyEmployeesCount,
        menCount,
        companyMenCount,
        womenCount,
        companyWomenCount,
      };
    })
    .filter((bucket) => bucket.stats !== null)
    .value();

  return bucketsWithStats as SetNonNullable<(typeof bucketsWithStats)[number], "stats">[];
};

export const computeGenderEqualityPayGapByLevel = (ctx: AppContext, params: { buckets: EmployeesBucket[] }) => {
  const { buckets } = params;
  const user = getRequiredUser(ctx);

  const bucketsWithStats = chain(buckets)
    .groupBy((bucket) => bucket.level)
    .values()
    .map((buckets) => {
      const templateBucket = buckets[0] as (typeof buckets)[number];

      const key =
        templateBucket.levelScope === "external" ? templateBucket.level : getLevelMetaData(templateBucket.level).index;
      const label =
        templateBucket.levelScope === "external"
          ? templateBucket.level
          : formatLevel(ctx.t, templateBucket.level, user.company.useExternalLevels);
      const employeesCount = sumBy(buckets, (bucket) => bucket.employees.length);
      const companyEmployeesCount = sumBy(buckets, (bucket) => bucket.companyEmployees.length);
      const menCount = sumBy(buckets, (bucket) => bucket.employees.filter(hasGender(Gender.MALE)).length);
      const companyMenCount = sumBy(buckets, (bucket) => bucket.companyEmployees.filter(hasGender(Gender.MALE)).length);
      const womenCount = sumBy(buckets, (bucket) => bucket.employees.filter(hasGender(Gender.FEMALE)).length);
      const companyWomenCount = sumBy(
        buckets,
        (bucket) => bucket.companyEmployees.filter(hasGender(Gender.FEMALE)).length
      );

      return {
        key,
        label,
        level: templateBucket.level,
        stats: computeBucketsGenderPayGapStats({ buckets }),
        matches: compileMatchedJobs({ buckets }),

        employeesCount,
        companyEmployeesCount,
        menCount,
        companyMenCount,
        womenCount,
        companyWomenCount,
      };
    })
    .filter((bucket) => bucket.stats !== null)
    .orderBy((bucket) => bucket.key)
    .value();

  return bucketsWithStats as SetNonNullable<(typeof bucketsWithStats)[number], "stats">[];
};

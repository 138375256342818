import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { Route } from "nextjs-routes";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";

export class ForbiddenError extends Error {
  constructor(
    message: string,
    public redirect?: Route
  ) {
    super(message);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export const handleForbiddenError = (ctx: AppContext, res: NextApiResponse, error: ForbiddenError) => {
  logWarn(ctx, "[error] Forbidden", { error });

  return res.status(HttpStatus.FORBIDDEN).json({ error: error.message });
};

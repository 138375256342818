// eslint-disable-next-line no-restricted-imports
import { useTranslation } from "next-i18next";
import { useLocale } from "~/hooks/useLocale";
import { type DateFormats, formatDate } from "~/lib/dates";
import { formatPercentile } from "~/lib/math";

export const useI18n = () => {
  const { locale } = useLocale();

  const formatDateWithLocale = (date: Date | string, format: DateFormats) => {
    return formatDate(date, format, locale);
  };

  const formatPercentileWithLocale = (value: number, decimals = 0): string => {
    return formatPercentile(value, locale, decimals);
  };

  return {
    locale,
    ...useTranslation(),
    formatDate: formatDateWithLocale,
    formatPercentile: formatPercentileWithLocale,
  };
};

import { AbilityBuilder } from "@casl/ability";
import { createPrismaAbility } from "@casl/prisma";
import { type AppContext } from "~/lib/context";
import { getKeys } from "~/lib/utils";
import { type AppAbility } from "~/services/permissions/appAbility";
import { generatePermissionsSchema } from "~/services/permissions/permissionsSchema";

export const createAbility = async (ctx: AppContext) => {
  const abilityBuilder = new AbilityBuilder<AppAbility>(createPrismaAbility);

  if (!ctx.user) {
    return null;
  }

  const schema = await generatePermissionsSchema(ctx);

  getKeys(schema).forEach((subject) => {
    const actions = schema[subject];

    getKeys(actions).forEach((action) => {
      const spec = actions[action];

      if (spec === true) {
        abilityBuilder.can(action, subject);
        return;
      }

      if (spec === false) {
        abilityBuilder.cannot(action, subject);
        return;
      }

      abilityBuilder.can(action, subject, spec);
    });

    // NOTE: So far, we decided to allow all users to create any entity.
    abilityBuilder.can("create", subject);
  });

  ctx.permissionsSchema = schema;

  return abilityBuilder.build();
};

export const createAuthenticatedAbility = async (ctx: AppContext) => {
  const ability = await createAbility(ctx);

  if (!ability) {
    throw new Error("User is not authenticated");
  }

  return ability;
};

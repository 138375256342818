import { AnalyticsBrowser } from "@segment/analytics-next";
import { type JsonObject } from "type-fest";
import { config } from "~/config";
import { FIGURES, mapSegmentGroupTraits, mapSegmentUser, mapSegmentUserTraits } from "~/lib/external/segment/context";
import type { NotifyMetadataSupportInput } from "~/pages/api/dev/notify-metadata-support";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import packageJson from "../../../../../package.json";

const clientSegment = AnalyticsBrowser.load({
  writeKey: config.segment.writeKey,
});

let trackingEnabled = !config.app.isLocal && !!clientSegment;
let segmentUser: NullableAuthenticatedUser = null;

export const disableSegmentTracking = async () => {
  await clientSegment.reset();

  segmentUser = null;
  trackingEnabled = false;
};

export const setSegmentUser = async (
  user: NullableAuthenticatedUser,
  params: { impersonatingCompany: boolean; metadata: NotifyMetadataSupportInput | null }
) => {
  if (!trackingEnabled) {
    return;
  }

  segmentUser = user;

  if (user) {
    await clientSegment.identify(`${user.id}`, mapSegmentUserTraits(user, params));
    await clientSegment.group(
      `${params.impersonatingCompany ? FIGURES.id : user.companyId}`,
      mapSegmentGroupTraits(user, params)
    );
  }
};

export const trackClientPage = async (path: string, properties: JsonObject) => {
  if (!trackingEnabled) {
    return;
  }

  await clientSegment.page(path, properties, {
    context: mapSegmentClientContext(),
  });
};

export const trackClientEvent = async (name: string, properties: JsonObject) => {
  if (!trackingEnabled) {
    return;
  }

  await clientSegment.track(name, properties, {
    context: mapSegmentClientContext(),
  });
};

const mapSegmentClientContext = () => {
  return {
    appVersion: packageJson.version,
    ...mapSegmentUser(segmentUser),
    ...(segmentUser && { groupId: segmentUser.isSuperAdmin ? FIGURES.id : segmentUser.companyId }),
  };
};

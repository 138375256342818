import { QueueJobName } from "@prisma/client";
import { type JobWithMetadata } from "pg-boss";
import { type AppContext } from "~/lib/context";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { createCompensationReviewCampaignEmployeesWorkerService } from "~/workers/createCompensationReviewCampaignEmployees";
import { createSalaryBandsWorkerService } from "~/workers/createSalaryBands";
import { duplicateSalaryGridForNewVersionWorkerService } from "~/workers/duplicateSalaryGridForNewVersion";
import { generateSpreadsheetWorkerService } from "~/workers/generateSpreadsheet";
import { importSpreadsheetWorkerService } from "~/workers/import/importSpreadsheet";
import { importSpreadsheetRowWorkerService } from "~/workers/import/importSpreadsheetRow";
import { postImportSpreadsheetWorkerService } from "~/workers/import/postImportSpreadsheet";
import { postSyncExternalEmployeesWorkerService } from "~/workers/postSyncExternalEmployees";
import { preSyncExternalEmployeesWorkerService } from "~/workers/preSyncExternalEmployees";
import { suggestJobsMappingWorkerService } from "~/workers/suggestJobsMapping";
import { syncExternalEmployeesWorkerService } from "~/workers/syncExternalEmployees";
import { syncSalaryRangeEmployeesWorkerService } from "~/workers/synchroniseSalaryRangeEmployees";
import { updateCompanyStatsWorkerService } from "~/workers/updateCompanyStats";
import { updateSalaryRangeSettingsWorkerService } from "~/workers/updateSalaryRangeSettings";

export type JobPayload<T> = Pick<
  JobWithMetadata<T>,
  "id" | "singletonkey" | "data" | "retrycount" | "retrylimit" | "output"
>;

export type WorkerParams<T extends BaseJobData> = {
  jobName: QueueJobName;
  service: WorkerService<T>;
};

export type WorkerService<T extends BaseJobData> = (ctx: AppContext, data: T) => Promise<void>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Workers = { jobName: QueueJobName; service: WorkerService<any> }[];

export const getWorkers = () => {
  const workers: Workers = [];

  const register = <T extends BaseJobData>(params: WorkerParams<T>) => {
    workers.push(params);
  };

  register({
    jobName: QueueJobName.CREATE_COMPENSATION_REVIEW_CAMPAIGN_EMPLOYEES,
    service: createCompensationReviewCampaignEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.CREATE_SALARY_BANDS,
    service: createSalaryBandsWorkerService,
  });

  register({
    jobName: QueueJobName.POST_SYNC_EXTERNAL_EMPLOYEES,
    service: postSyncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.PRE_SYNC_EXTERNAL_EMPLOYEES,
    service: preSyncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_EXTERNAL_EMPLOYEES,
    service: syncExternalEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.SYNC_SALARY_RANGE_EMPLOYEES,
    service: syncSalaryRangeEmployeesWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_SALARY_RANGE_SETTINGS,
    service: updateSalaryRangeSettingsWorkerService,
  });

  register({
    jobName: QueueJobName.DUPLICATE_SALARY_GRID_FOR_NEW_VERSION,
    service: duplicateSalaryGridForNewVersionWorkerService,
  });

  register({
    jobName: QueueJobName.IMPORT_SPREADSHEET,
    service: importSpreadsheetWorkerService,
  });

  register({
    jobName: QueueJobName.IMPORT_SPREADSHEET_ROW,
    service: importSpreadsheetRowWorkerService,
  });

  register({
    jobName: QueueJobName.POST_IMPORT_SPREADSHEET,
    service: postImportSpreadsheetWorkerService,
  });

  register({
    jobName: QueueJobName.GENERATE_SPREADSHEET,
    service: generateSpreadsheetWorkerService,
  });

  register({
    jobName: QueueJobName.SUGGEST_JOBS_MAPPING,
    service: suggestJobsMappingWorkerService,
  });

  register({
    jobName: QueueJobName.UPDATE_COMPANY_STATS,
    service: updateCompanyStatsWorkerService,
  });

  return workers;
};

import { SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { randomUUID } from "crypto";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { auditLogConfigurationChange, type AuditLogOptions } from "~/services/salary-bands/audit-logs";
import { type SalaryGridState } from "~/services/salary-bands/audit-logs/schemas";

export const auditLogDuplicateGridForNewVersion = async (
  ctx: AppContext,
  params: { gridId: number; initialState: SalaryGridState; newState: SalaryGridState },
  options?: AuditLogOptions
) => {
  const input = {
    actionId: options?.actionId ?? randomUUID(),
    gridId: params.gridId,
    initialState: params.initialState,
    label: SalaryGridConfigurationChangeLabel.DUPLICATE_GRID_FOR_NEW_VERSION,
    newState: params.newState,
    userId: getRequiredUser(ctx).id,
  };

  await auditLogConfigurationChange(ctx, input);
};

import { type Prisma } from "@prisma/client";

export const selectExternalEmployeeUserPicture = {
  userPermissions: {
    select: {
      user: {
        select: {
          profilePictureUrl: true,
        },
      },
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

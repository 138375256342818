import { UserRole } from "@prisma/client";
import { isNil } from "~/lib/lodash";
import { getId, isIn } from "~/lib/utils";
import { type PermissionsParams } from "~/services/user/permissions/authenticationOptions";

export type PermissionFunction<T> = (params: PermissionsParams, input: T) => boolean;

export const canAccessAdditionalField: PermissionFunction<{ additionalFieldId: number }> = ({ user }, input) => {
  if (!user) {
    return false;
  }

  if (user.isSuperAdmin) {
    return true;
  }

  if (isIn(user.permissions.role, [UserRole.ADMIN, UserRole.HR])) {
    return true;
  }

  if (!user.permissions.canAccessAdditionalFields) {
    return false;
  }

  if (user.permissions.allowedAdditionalFields.length === 0) {
    return true;
  }

  return user.permissions.allowedAdditionalFields.map(getId).some((id) => id === input.additionalFieldId);
};

const canAccessSalaryBandMarketData: PermissionFunction<number> = ({ user }, salaryBandId) => {
  //starts to check for user
  if (!user) {
    return false;
  }

  if (user.isSuperAdmin || user.permissions.role === UserRole.ADMIN) {
    return true;
  }

  //if the band is explicitely shared
  const share = user.permissions.salaryBandShares.find(({ bandId }) => bandId === salaryBandId);
  if (share) {
    return share.canAccessSalaryBandBenchmarkData;
  }

  //if the user has rights
  if (user.permissions.canAccessMarketData) {
    return true;
  }

  //if the policy allows it
  const policy = user?.company.userPermissionsPolicy;

  if (
    !policy ||
    (user.permissions.role !== UserRole.EMPLOYEE && !user.permissions.isManager) ||
    (user.permissions.isManager && isNil(policy.managerSalaryBandCanAccessMarketData)) ||
    (!user.permissions.isManager && isNil(policy.employeeSalaryBandCanAccessMarketData))
  ) {
    return false;
  }

  return user.permissions.isManager
    ? !!policy.managerSalaryBandCanAccessMarketData
    : !!policy.employeeSalaryBandCanAccessMarketData;
};

export const canAccessFiguresSalaryBandMarketData: PermissionFunction<number> = (params, salaryBandId) => {
  const { user, subscriptions } = params;

  if (user && user.isSuperAdmin) {
    return true;
  }

  if (!user || !subscriptions.CAN_ACCESS_BENCHMARK) {
    return false;
  }

  return canAccessSalaryBandMarketData(params, salaryBandId);
};

export const canAccessExternalSalaryBandMarketData: PermissionFunction<number> = (params, salaryBandId) => {
  return canAccessSalaryBandMarketData(params, salaryBandId);
};

export const canUpdateCompany: PermissionFunction<{ companyId?: number | null }> = ({ user }, input) => {
  if (user?.isSuperAdmin) {
    return true;
  }

  if (!input.companyId) {
    return true;
  }

  return input.companyId === user?.companyId;
};

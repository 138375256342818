import { EmployeeStatsStatus, type Prisma } from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AppContext } from "~/lib/context";
import { chain } from "~/lib/lodash";
import { logError } from "~/lib/logger";
import { getId } from "~/lib/utils";
import { parseMarketPositioning } from "~/services/compensation-policy/parseMarketPositioning";
import { computeEmployeeCompensation } from "~/services/employee";
import { enrichEmployeesWithCapitalLocationId } from "~/services/locations/location";
import { computeEmployeeStats } from "./computeEmployeeStats";
import { fetchCompanyWithCompensationSettings } from "./fetchCompanyWithCompensationSettings";
import { employeeForStatsSelect, fetchComparableEmployeesForStats } from "./fetchComparableEmployeesForStats";

export const updateEmployeesStats = async (ctx: AppContext, params: { companyId: number; employeesIds: number[] }) => {
  if (params.employeesIds.length === 0) {
    return;
  }

  const company = await fetchCompanyWithCompensationSettings(ctx, { companyId: params.companyId });

  const marketEmployees = await fetchComparableEmployeesForStats(ctx, { company, employeesIds: params.employeesIds });

  const employees = await ctx.prisma.employee.findMany({
    where: { id: { in: params.employeesIds } },
    select: { ...employeeForStatsSelect, liveEmployeeStats: true },
  });

  const employeesWithCapitalLocationId = await enrichEmployeesWithCapitalLocationId(ctx, {
    employeesWithLocation: employees,
  });

  const companyMarketPositioning = await parseMarketPositioning(ctx, company.marketPositioning);

  await mapSeries(employeesWithCapitalLocationId, async (employee) => {
    const employeeStats = await computeEmployeeStats(ctx, {
      company,
      companyMarketPositioning,
      employee,
      marketEmployees,
    });

    const totalCash = computeEmployeeCompensation(employee, {
      measure: "totalCash",
      targetCurrency: employee.currency,
    });

    const onTargetEarnings = computeEmployeeCompensation(employee, {
      measure: "onTargetEarnings",
      targetCurrency: employee.currency,
    });

    const distinctEmployeesCount = chain(employeeStats.comparison.employees)
      .concat(employee)
      .map(getId)
      .uniq()
      .size()
      .value();

    const comparedLocations = employeeStats.comparison.comparedLocationIds.map((id) => ({ id }));
    const comparedCountries = employeeStats.comparison.comparedCountryIds.map((id) => ({ id }));

    const payload = {
      targetPercentile: employeeStats.targetPercentile,
      comparisonScope: employeeStats.comparison.comparisonScope,
      comparedLevels: employeeStats.comparison.comparedLevels,
      comparedCompaniesCount: employeeStats.comparison.companiesCount,
      comparedEmployeesCount: distinctEmployeesCount,
      totalCash,
      onTargetEarnings,
      totalCashForPercentile: employeeStats.totalCashMeasure.amountForPercentile,
      totalCashDifference: employeeStats.totalCashMeasure.difference,
      totalCashPercentageDifference: employeeStats.totalCashMeasure.percentageDifference,
      totalCashMarketPositioning: employeeStats.totalCashMeasure.marketPositioning,
      totalCashDataQuality: employeeStats.totalCashMeasure.dataQuality,
      baseSalaryForPercentile: employeeStats.baseSalaryMeasure.amountForPercentile,
      baseSalaryDifference: employeeStats.baseSalaryMeasure.difference,
      baseSalaryPercentageDifference: employeeStats.baseSalaryMeasure.percentageDifference,
      baseSalaryMarketPositioning: employeeStats.baseSalaryMeasure.marketPositioning,
      baseSalaryDataQuality: employeeStats.baseSalaryMeasure.dataQuality,
      onTargetEarningsForPercentile: employeeStats.onTargetEarningsMeasure.amountForPercentile,
      onTargetEarningsDifference: employeeStats.onTargetEarningsMeasure.difference,
      onTargetEarningsPercentageDifference: employeeStats.onTargetEarningsMeasure.percentageDifference,
      onTargetEarningsMarketPositioning: employeeStats.onTargetEarningsMeasure.marketPositioning,
      onTargetEarningsDataQuality: employeeStats.onTargetEarningsMeasure.dataQuality,
      status: EmployeeStatsStatus.LIVE,
    } satisfies Prisma.EmployeeStatsCreateWithoutEmployeeInput;

    try {
      // If there is not yet an employeeStats just create a LIVE one
      if (!employee.liveEmployeeStats?.id) {
        const createPayload = {
          ...payload,
          comparedLocations: { connect: comparedLocations },
          comparedCountries: { connect: comparedCountries },
        } satisfies Prisma.EmployeeStatsCreateWithoutEmployeeInput;

        const liveEmployeeStats = await ctx.prisma.employeeStats.create({
          data: {
            ...createPayload,
            employee: { connect: { id: employee.id } },
          },
        });

        await ctx.prisma.employee.update({
          where: { id: employee.id },
          data: { liveEmployeeStatsId: liveEmployeeStats.id },
        });

        return;
      }

      const updatePayload = {
        ...payload,
        comparedLocations: { set: comparedLocations },
        comparedCountries: { set: comparedCountries },
      } satisfies Prisma.EmployeeStatsUpdateWithoutEmployeeInput;

      await ctx.prisma.employeeStats.update({
        where: { id: employee.liveEmployeeStats.id },
        data: updatePayload,
      });
    } catch (error) {
      logError(ctx, "[sync] Error while updating employee stats", { employeeId: employee.id, error });
    }
  });
};

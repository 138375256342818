import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { mixed, object, string } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { OrderingSchema, PaginationSchema } from "~/lib/pagination";
import { createQuery } from "~/lib/reactQuery";
import { getKeys } from "~/lib/utils";
import { FilterOptionsSchema } from "~/services/employee-filter";
import {
  fetchExternalEmployees,
  type FetchExternalEmployeesPaginationResult,
  type ImportedEmployeeStatus,
  ImportedEmployeeStatusEnum,
} from "~/services/external-employee/externalEmployeeRead";

export const FetchAllImportedEmployeesSchema = object({
  query: string().nullable().defined(),
  status: mixed<ImportedEmployeeStatus>().oneOf(getKeys(ImportedEmployeeStatusEnum)).nullable().defined(),
  pagination: PaginationSchema.required(),
  order: OrderingSchema.required(),
  filters: FilterOptionsSchema.required(),
});

const handler: NextApiHandler<FetchExternalEmployeesPaginationResult> = async (req, res) => {
  const input = FetchAllImportedEmployeesSchema.validateSync(req.body, { abortEarly: false });

  const externalEmployees = await fetchExternalEmployees(req, input);

  return res.status(HttpStatus.OK).json(externalEmployees);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessImportedEmployees: true,
  },
});

export const FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY: ApiRoute["pathname"] =
  "/api/imported-employees/fetch-all-imported-employees";

export const useFetchAllImportedEmployeesQuery = createQuery<typeof handler, typeof FetchAllImportedEmployeesSchema>({
  path: FETCH_ALL_IMPORTED_EMPLOYEES_QUERY_KEY,
  schema: FetchAllImportedEmployeesSchema,
});

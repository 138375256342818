import { type ExternalLevel } from "@prisma/client";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { createMissingBenchmarkLevel } from "~/services/benchmark-level/createMissingBenchmarkLevel";
import { fetchAllowedCompanyBenchmarkLevels } from "~/services/benchmark-level/fetchAllowedCompanyBenchmarkLevels";

export const matchLevelWithBenchmarkLevel = async (
  ctx: AppContext,
  params: { externalLevel: Pick<ExternalLevel, "mappedLevel" | "mappedBenchmarkLevelId"> | null }
) => {
  const { externalLevel } = params;

  if (!externalLevel?.mappedLevel) {
    return;
  }

  const benchmarkLevels = await fetchAllowedCompanyBenchmarkLevels(ctx);

  const benchmarkLevel = benchmarkLevels.find((benchmarkLevel) => {
    return value(() => {
      if (externalLevel.mappedBenchmarkLevelId) {
        return externalLevel.mappedBenchmarkLevelId === benchmarkLevel.id;
      }
      return externalLevel.mappedLevel === benchmarkLevel.name;
    });
  });

  if (externalLevel.mappedLevel && !benchmarkLevel) {
    return createMissingBenchmarkLevel(ctx, { figuresLevel: externalLevel.mappedLevel });
  }

  return benchmarkLevel;
};

const WORDS_TO_REMOVE = ["Junior", "Intermediate", "Senior", "Werkstudent"];
const PATTERNS_TO_REMOVE = [
  /\bremote/i,
  /\bfull-time/i,
  /\{/,
  /}/,
  /–/,
  /-/,
  /f\/h/i,
  /h\/f/i,
  /\//,
  /\?/,
  /\|/,
  /I\+D\+i/,
  /\d+%/,
  /\d+h/g,
  /\d{2,4}/g,
];

export const sanitizeJobNameForEmbedding = (jobName: string) => {
  let name = jobName.replace(/\(.*?\)/g, "");

  for (const pattern of PATTERNS_TO_REMOVE) {
    name = name.replace(pattern, " ");
  }

  name = name.replace(new RegExp("\\b" + WORDS_TO_REMOVE.join("|\\b"), "i"), "");

  name = name.replace(/\s+/g, " ");

  return name.trim();
};

import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import {
  BaseConfigurationChangeSchema,
  SalaryBandMarketPositioningStateSchema,
} from "~/services/salary-bands/audit-logs/schemas";

export const UpdateBandMarketPositioningInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.UPDATE_MARKET_POSITIONING>().required(),
    bandId: number().required(),
    initialState: SalaryBandMarketPositioningStateSchema.required(),
    newState: SalaryBandMarketPositioningStateSchema.required(),
  })
);

export type UpdateBandMarketPositioningChange = YupOutputType<typeof UpdateBandMarketPositioningInputSchema>;

import type xlsx from "xlsx";
import packageJson from "~/../package.json";
import { type AppContext } from "~/lib/context";
import tracer from "~/lib/datadog/tracing";
import { getRequiredUser } from "~/lib/getRequiredUser";

export const readXlsxFile = (ctx: AppContext, filename: string, options?: xlsx.ParsingOptions) => {
  const tags = { "span.kind": "server", "user.id": getRequiredUser(ctx).id, filename };

  return tracer.trace("xlsx", { tags, service: `${packageJson.name}.xlsx`, resource: "readFile" }, () =>
    ctx.xlsx.readFile(filename, options)
  );
};

export const xlsxSheetToJson = <T>(ctx: AppContext, worksheet: xlsx.WorkSheet, options?: xlsx.Sheet2JSONOpts) => {
  const tags = { "span.kind": "server", "user.id": getRequiredUser(ctx).id };

  return tracer.trace("xlsx", { tags, service: `${packageJson.name}.xlsx`, resource: "sheet_to_json" }, () =>
    ctx.xlsx.utils.sheet_to_json<T>(worksheet, options)
  );
};

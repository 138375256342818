import { type DeleteObjectCommandInput } from "@aws-sdk/client-s3";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { logWarn } from "~/lib/logger";

export const deleteFile = async (ctx: AppContext, input: DeleteObjectCommandInput) => {
  const isFixture = input.Key?.includes("fixture");
  if (isFixture) {
    return;
  }

  const isProductionFile = input.Bucket?.includes("-production-");
  if (!config.app.isProduction && isProductionFile) {
    return;
  }

  try {
    await ctx.files.deleteObject(input);
  } catch (error) {
    logWarn(ctx, "[delete-file] Failed to delete file", { error, input });

    throw new BusinessLogicError(`Failed to delete file [${error.message}]`);
  }
};

import { ExternalEmployeeStatus } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";

export type PartialSpreadsheetExternalEmployees = AsyncReturnType<typeof fetchPartialSpreadsheetExternalEmployees>;

export const fetchPartialSpreadsheetExternalEmployees = async (ctx: AppContext, employeeNumbers?: string[]) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: user.companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
      ...(employeeNumbers && { employeeNumber: { in: employeeNumbers } }),
    },
    orderBy: [{ employeeNumber: "asc" }, { firstName: "asc" }, { lastName: "asc" }],
    select: {
      id: true,
      externalId: true,
      employeeNumber: true,
      gender: true,
      firstName: true,
      lastName: true,
      email: true,
      hireDate: true,
      birthDate: true,
      isFounder: true,
      status: true,
      driftFields: true,
      fteDivider: true,
      businessUnit: true,
      manager: { select: { employeeNumber: true } },
      currency: {
        select: {
          code: true,
          name: true,
        },
      },
      location: {
        select: {
          name: true,
          country: {
            select: {
              name: true,
            },
          },
        },
      },
      level: {
        select: {
          name: true,
        },
      },
      job: {
        select: {
          name: true,
        },
      },
      remunerationItems: {
        select: {
          amount: true,
          asPercentage: true,
          date: true,
          status: true,
          customRemunerationItem: {
            select: {
              name: true,
            },
          },
          nature: {
            select: {
              name: true,
              mappedType: true,
            },
          },
        },
      },
      performanceReviewRating: {
        select: {
          name: true,
        },
      },
      mappedEmployee: {
        select: {
          level: true,
          externalJobTitle: true,
          externalLevel: true,
          currency: { select: { code: true, name: true } },
          baseSalary: true,
          onTargetBonus: true,
          fixedBonus: true,
          isFounder: true,
          location: {
            select: {
              name: true,
              country: {
                select: {
                  name: true,
                },
              },
            },
          },
          job: {
            select: {
              family: {
                select: {
                  name: true,
                },
              },
            },
          },
        },
      },
      additionalFieldValues: {
        select: {
          additionalField: {
            select: {
              id: true,
              name: true,
            },
          },
          // TODO: see if we need the other values as well
          stringValue: true,
        },
      },
    },
  });
};

import { type PermissionsParams } from "~/services/user/permissions/authenticationOptions";
import { canAccessCompensationReview } from "~/services/user/permissions/featureAccessPermissions";
import { getUserRoles } from "~/services/user/permissions/utils/getUserRoles";

export const canAccessOffCycleReviews = (params: PermissionsParams): boolean => {
  if (!canAccessCompensationReview(params)) return false;

  return true;
};

export const canCreateCompensationReviewCampaign = (params: PermissionsParams): boolean => {
  const { user } = params;

  if (!canAccessCompensationReview(params)) return false;

  if (!user) return false;

  return getUserRoles(user).isAdmin;
};

export const canDeleteCompensationReviewCampaign = (params: PermissionsParams): boolean => {
  const { user } = params;

  if (!canAccessCompensationReview(params)) return false;

  if (!user) return false;

  return getUserRoles(user).isAdmin;
};

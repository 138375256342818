import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import { type HasImpersonationAccess, hasImpersonationAccess } from "~/services/impersonation/helper";

export const HasImpersonationAccessSchema = object({
  companyId: number().required(),
});

export type HasImpersonationAccessSchemaInput = YupOutputType<typeof HasImpersonationAccessSchema>;

const handler: NextApiHandler<HasImpersonationAccess> = async (req, res) => {
  const { companyId }: HasImpersonationAccessSchemaInput = HasImpersonationAccessSchema.validateSync(req.body, {
    abortEarly: false,
  });

  const validImpersonationAccess = await hasImpersonationAccess(req, { companyId });

  res.status(HttpStatus.OK).json(validImpersonationAccess);
};

export default api(handler, {
  method: "POST",
  authentication: {
    canAccessSettings: true,
  },
});

export const HAS_IMPERSONATION_ACCESS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/auth/company-impersonation/has-impersonation-access";

export const useHasImpersonationAccessQuery = createQuery<typeof handler, typeof HasImpersonationAccessSchema>({
  path: HAS_IMPERSONATION_ACCESS_QUERY_KEY,
  schema: HasImpersonationAccessSchema,
});

import classNames from "classnames";
import NextImage from "next/image";
import React, { type ComponentProps } from "react";

type Props = ComponentProps<typeof NextImage> & {
  containerClassName?: string;
};

export const Image: React.FC<Props> = ({ containerClassName, ...imageProps }) => {
  return (
    <div
      className={classNames(containerClassName, {
        flex: true,
      })}
      style={{
        width: `${imageProps.width ?? 0}px`,
        height: `${imageProps.height ?? 0}px`,
      }}
    >
      <NextImage {...imageProps} unoptimized />
    </div>
  );
};

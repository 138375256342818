import { createContext, type PropsWithChildren, useContext, useState } from "react";
import { trackCommandPaletteClosed, trackCommandPaletteOpened } from "~/lib/external/segment/web/events";
import { noop } from "~/lib/lodash";
import { type CommandPaletteEmployee } from "~/services/command-palette/fetchCommandPaletteCommands";

const CommandPaletteContext = createContext<{
  showPalette: boolean;
  selectedExternalEmployee: CommandPaletteEmployee | null;
  openPalette: () => void;
  closePalette: () => void;
  togglePalette: () => void;
  setSelectedExternalEmployee: (employee: CommandPaletteEmployee | null) => void;
}>({
  showPalette: false,
  selectedExternalEmployee: null,
  openPalette: noop,
  closePalette: noop,
  togglePalette: noop,
  setSelectedExternalEmployee: noop,
});

export const CommandPaletteProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [showPalette, setShowPalette] = useState(false);
  const [selectedExternalEmployee, setSelectedExternalEmployee] = useState<CommandPaletteEmployee | null>(null);

  const openPalette = () => {
    setShowPalette(true);

    void trackCommandPaletteOpened();
  };

  const closePalette = () => {
    setShowPalette(false);
    setSelectedExternalEmployee(null);

    void trackCommandPaletteClosed();
  };

  const togglePalette = () => {
    showPalette ? closePalette() : openPalette();
  };

  return (
    <CommandPaletteContext.Provider
      value={{
        showPalette,
        selectedExternalEmployee,
        openPalette,
        closePalette,
        togglePalette,
        setSelectedExternalEmployee,
      }}
    >
      {children}
    </CommandPaletteContext.Provider>
  );
};

export const useCommandPalette = () => useContext(CommandPaletteContext);

import { SpreadsheetImportTemplate } from "@prisma/client";
import { match } from "ts-pattern";
import { type AsyncReturnType } from "type-fest";
import type { WorkSheet } from "xlsx";
import { ValidationError } from "yup";
import type ObjectSchema from "yup/lib/object";
import { type ObjectShape } from "yup/lib/object";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { XlsxImportError } from "~/lib/errors/xlsxImportError";
import { translateYupError } from "~/lib/i18n/yupErrors";
import { chain, mapValues, omit, pickBy } from "~/lib/lodash";
import { logWarn } from "~/lib/logger";
import { readXlsxFile, xlsxSheetToJson } from "~/lib/xlsx";
import { castStringAsMultipleTypes } from "~/services/additional-field/parseAdditionalFieldsSpreadsheet";
import { partialImportColumnsMap } from "~/services/external-employee/import/process/partial/partialImportColumnsMap";
import { ADDITIONAL_FIELD_KEY_PREFIX, CUSTOM_REMUNERATION_ITEM_KEY_PREFIX } from "~/services/generateGlobalSpreadsheet";
import { initialImportColumnsMap } from "~/services/initialImportColumnsMap";

export type RowValidationError = ValidationError & {
  rowNumber: number;
  id?: string;
  count: number;
};

type JsonRow = {
  [key: string]: string;
};

export type ColumnMap = {
  [key: string]: string;
};

export type EntityRow = {
  id: number;
  rowData: JsonRow;
  rowNumber: number;
};

const getColumnMap = (ctx: AppContext, type: SpreadsheetImportTemplate): ColumnMap => {
  return match(type)
    .with(SpreadsheetImportTemplate.PARTIAL, () => partialImportColumnsMap(ctx))
    .with(SpreadsheetImportTemplate.INITIAL, () => initialImportColumnsMap(ctx))
    .exhaustive();
};

const remapKeys = (row: JsonRow, columnMap: ColumnMap) => {
  return mapValues(columnMap, (inputKey) => {
    return row[inputKey] ?? "";
  });
};

const getAdditionalFields = (ctx: AppContext, row: JsonRow, columnMap: ColumnMap) => {
  const rows = pickBy(row, (value, key) => {
    return !Object.values(columnMap).includes(key) && key.startsWith(ADDITIONAL_FIELD_KEY_PREFIX);
  });

  return chain(rows)
    .mapKeys((_, key) => key.replace(ADDITIONAL_FIELD_KEY_PREFIX, ""))
    .mapValues(castStringAsMultipleTypes)
    .value();
};

const getCustomRemunerationItems = (ctx: AppContext, row: JsonRow, columnMap: ColumnMap) => {
  const rows = pickBy(row, (value, key) => {
    return !Object.values(columnMap).includes(key) && key.startsWith(CUSTOM_REMUNERATION_ITEM_KEY_PREFIX);
  });

  return chain(rows)
    .mapKeys((_, key) => key.replace(CUSTOM_REMUNERATION_ITEM_KEY_PREFIX, ""))
    .mapValues((value) => value ?? "")
    .value();
};

export const validateRowsWithSchema = <T extends ObjectShape>(
  ctx: AppContext,
  params: {
    entityRows: EntityRow[];
    columnMap: ColumnMap;
    schema: ObjectSchema<T>;
  }
) => {
  const { entityRows, columnMap, schema } = params;

  const errors: RowValidationError[] = [];

  const validateRow = (row: JsonRow, index: number, entityId: number) => {
    try {
      const schemaRow = schema.validateSync(row, { abortEarly: false });
      const additionalFields = getAdditionalFields(ctx, row, columnMap);
      const customRemunerationItems = getCustomRemunerationItems(ctx, row, columnMap);

      return { ...schemaRow, additionalFields, customRemunerationItems, id: entityId };
    } catch (error) {
      if (error instanceof ValidationError) {
        const errorPath = value(() => {
          if (error.path) {
            return error.path;
          }

          if (error.inner?.[0]?.path) {
            return error.inner?.[0]?.path;
          }

          return "unknown";
        });

        errors.push({
          rowNumber: index,
          id: columnMap[errorPath as keyof typeof columnMap] ?? "unknown",
          count: 1,
          value: error.inner?.[0]?.value?.toString() ?? "",
          ...omit(error, "value"),
        });
      } else if (error instanceof Error) {
        throw new XlsxImportError(
          ctx.t("services.xlsx-to-json.aborted-import-due-to-validation-error-error-message", {
            message: error.message,
          })
        );
      }
    }
  };

  const rows = entityRows.map((entityRow) => {
    return validateRow(entityRow.rowData, entityRow.rowNumber, entityRow.id);
  });

  if (errors.length) {
    const groupedErrors = chain(errors)
      .map((error) => translateYupError(ctx.t, error))
      .groupBy((error) => error.path)
      .mapValues((errors) => {
        return {
          ...(errors[0] as RowValidationError),
          count: errors.length,
        };
      })
      .values()
      .orderBy((error) => error.count, "desc")
      .value();

    throw new XlsxImportError(ctx.t("services.xlsx-to-json.aborted-import-due-to-malformed-rows"), groupedErrors);
  }

  return rows.filter((row): row is NonNullable<typeof row> => {
    return row !== undefined;
  });
};

export const xlsxToJson = async (
  ctx: AppContext,
  params: {
    spreadsheetPath: string;
    sheetName: string;
    type: SpreadsheetImportTemplate;
  }
) => {
  const spreadsheet = readXlsxFile(ctx, params.spreadsheetPath);

  const worksheet = value(() => {
    if (spreadsheet.Sheets[params.sheetName]) {
      return spreadsheet.Sheets[params.sheetName];
    }

    if (!spreadsheet.SheetNames[0]) {
      throw new XlsxImportError(ctx.t("services.xlsx-to-json.no-worksheets"));
    }

    logWarn(
      ctx,
      `[xlsx] Provided XLSX sheet doesn't have a ${params.sheetName} worksheet. Defaulting to first worksheet ${spreadsheet.SheetNames[0]}.`
    );
    return spreadsheet.Sheets[spreadsheet.SheetNames[0]];
  }) as WorkSheet;

  const baseRows = xlsxSheetToJson(ctx, worksheet, { range: 1 }) as { [key: string]: string }[];

  const columnMap = getColumnMap(ctx, params.type);
  const rows = baseRows.map((row) => remapKeys(row as JsonRow, columnMap));

  return { rows };
};

export type XlsxToJsonResult = AsyncReturnType<typeof xlsxToJson>;

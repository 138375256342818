import { Close } from "@mui/icons-material";
import {
  Snackbar as MuiSnackBar,
  type SnackbarProps as MuiSnackbarProps,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { type MouseEventHandler } from "react";

type SnackbarProps = React.PropsWithChildren<
  Omit<MuiSnackbarProps, "onClose"> & {
    title?: string;
    message?: string;
    onClose?: MouseEventHandler<HTMLButtonElement>;
  }
>;

export const SnackBar: React.FC<SnackbarProps> = ({
  title,
  message,
  children,
  onClose,
  action,
  anchorOrigin = { vertical: "bottom", horizontal: "right" },
  ...props
}) => {
  return (
    <MuiSnackBar {...props} anchorOrigin={anchorOrigin} className="">
      <Paper variant="outlined" className="relative !mr-16 -mb-2 w-[340px] rounded-lg p-3">
        <Stack spacing={1} className="mr-7">
          {title && <Typography variant="subtitle2">{title}</Typography>}

          {message && (
            <Typography variant="caption" color="text.secondary">
              {message}
            </Typography>
          )}
        </Stack>

        {children}

        <div
          className={classNames("absolute top-2.5", {
            "right-4": !onClose,
            "right-12": onClose,
          })}
        >
          {action}
        </div>

        {onClose && (
          <button className="absolute right-3 top-2.5 flex" onClick={onClose}>
            <Close fontSize="small" className="my-auto text-gray-400" />
          </button>
        )}
      </Paper>
    </MuiSnackBar>
  );
};

import { Stack, styled, Typography } from "@mui/material";
import Tooltip, { tooltipClasses, type TooltipProps } from "@mui/material/Tooltip";
import { type Merge } from "type-fest";

type CleanedTooltipProps = Omit<TooltipProps, "title">;

type Props = Merge<
  CleanedTooltipProps,
  {
    heading: string;
    text: string | JSX.Element;
  }
>;

export const TitledTooltip = styled(({ className, heading, text, ...props }: Props) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    title={
      <Stack spacing={1}>
        <Typography variant="h5">{heading}</Typography>

        <Typography variant="caption" color="text.secondary" className="pre-line">
          {text}
        </Typography>
      </Stack>
    }
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "12px",
  },
}));

import { type QueueJobName } from "@prisma/client";
import { match } from "ts-pattern";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { type BaseJobData } from "~/lib/queue/baseJobDataSchema";
import { AsyncDriver } from "~/lib/queue/drivers/asyncDriver";
import { PgBossDriver } from "~/lib/queue/drivers/pgBossDriver";
import { isIn } from "~/lib/utils";

export type SendOptions = {
  singletonKey?: string;
  retryLimit?: number;
  retryDelay?: number;
  retryBackoff?: boolean;
};

export interface QueueDriver {
  start(ctx: AppContext): Promise<void>;

  initWorkers: (ctx: AppContext) => Promise<void>;

  sendJob: <T extends BaseJobData>(
    ctx: AppContext,
    jobName: QueueJobName,
    data: T,
    options: SendOptions
  ) => Promise<string | null>;
}

const driverCache = new Map<string, QueueDriver>();

export const makeQueueDriver = (): QueueDriver => {
  const driverName = getQueueDriverName();

  const cachedDriver = driverCache.get(driverName);
  if (cachedDriver) {
    return cachedDriver;
  }

  const driver = match(driverName)
    .with("async", () => new AsyncDriver())
    .with("pg-boss", () => new PgBossDriver())
    .exhaustive();

  driverCache.set(driverName, driver);

  return driver;
};

type QueueDriverName = "async" | "pg-boss";

const getQueueDriverName = () => {
  if (config.queue.driver) {
    if (!isIn(config.queue.driver, ["async", "pg-boss"])) {
      throw new Error(`Invalid queue driver: ${config.queue.driver}`);
    }

    return config.queue.driver as QueueDriverName;
  }

  return config.app.isLocal ? "async" : "pg-boss";
};

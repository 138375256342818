import { Skeleton as MuiSkeleton } from "@mui/material";
import { type ComponentProps, useEffect, useState } from "react";

type Props = ComponentProps<typeof MuiSkeleton> & {
  delayed?: boolean;
};

const DELAY = 200;

export const Skeleton: React.FC<Props> = ({ delayed = false, ...props }) => {
  const [isDisplayed, setIsDisplayed] = useState(!delayed);

  useEffect(() => {
    if (!delayed) return;

    const timeout = setTimeout(() => {
      setIsDisplayed(true);
    }, DELAY);

    return () => {
      return clearTimeout(timeout);
    };
  }, [delayed]);

  return <MuiSkeleton sx={!isDisplayed ? { bgcolor: "transparent" } : undefined} {...props} />;
};

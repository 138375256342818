import { Stack, Typography } from "@mui/material";
import React, { type PropsWithChildren } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { type IntercomContextValues } from "react-use-intercom/src/types";
import { config } from "~/config";
import { isServer } from "~/lib/ssr";
import { isIn } from "~/lib/utils";

export const useIntercomForFigures = () => {
  const intercom = useIntercom();

  if (config.app.isLocal) {
    return new Proxy(intercom, {
      get: (target, prop: keyof IntercomContextValues) => {
        return (args: unknown) => {
          const displayAsAlert = isIn(prop, ["hide", "show", "showMessages", "showNewMessages"]);
          const message = `[LOCAL] Intercom command received:
          
intercom.${String(prop)}(${args ? JSON.stringify(args, null, 2) : ""})`;

          if (isServer) {
            globalThis.console.log(message);
            return;
          }

          if (displayAsAlert) {
            window.alert(message);
            return;
          }

          window.console.groupCollapsed(`intercom-messages`);
          window.console.log(message);
          window.console.groupEnd();
        };
      },
    });
  }

  return intercom;
};

export const IntercomForFiguresProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <IntercomProvider appId={config.intercom.appId} apiBase={config.intercom.apiBase}>
      {config.app.isLocal && (
        <Stack
          className="fixed bottom-[20px] right-[20px] z-[1500] h-[48px] w-[48px] cursor-pointer rounded-full bg-primary-500 text-center"
          justifyContent="center"
          onClick={() => alert("[LOCAL] Intercom button clicked")}
        >
          <Typography color="text.white">🧑‍💻</Typography>
        </Stack>
      )}

      {children}
    </IntercomProvider>
  );
};

import { SpreadsheetImportStatus } from "@prisma/client";
import type { AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";

export const updateImportStatus = async (
  ctx: AppContext,
  params: { importId: number; status: SpreadsheetImportStatus }
) => {
  const user = getRequiredUser(ctx);
  const { importId, status } = params;

  const now = new Date();

  return ctx.prisma.externalEmployeeSpreadsheetImport.update({
    data: {
      status,
      ...(status === SpreadsheetImportStatus.PREPROCESSING && { preprocessingStartedAt: now }),
      ...(status === SpreadsheetImportStatus.PREPROCESSED && { preprocessingFinishedAt: now }),
      ...(status === SpreadsheetImportStatus.IMPORTING && { importStartedAt: now }),
      ...(status === SpreadsheetImportStatus.IMPORTED && { importFinishedAt: now }),
    },
    where: {
      id: importId,
      companyId: user.companyId,
    },
  });
};

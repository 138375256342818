import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logWarn } from "~/lib/logger";

export class ApiValidationError extends Error {
  error: string;
  fields: Record<string, string>;

  constructor(error: string, fields: Record<string, string>) {
    super("API Validation error");

    this.error = error;
    this.fields = fields;

    Object.setPrototypeOf(this, ApiValidationError.prototype);
  }
}

export const handleApiValidationError = (ctx: AppContext, res: NextApiResponse, error: ApiValidationError) => {
  logWarn(ctx, "[error] API Validation error", { error });

  return res.status(HttpStatus.UNPROCESSABLE_ENTITY).json({
    error: error.error,
    fields: error.fields,
  });
};

import { type Prisma, SalaryGridMeasure } from "@prisma/client";
import { map } from "bluebird";
import type { AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { getRelevantPositionings, getSalaryBandPositioningDetails } from "~/services/salaryBandsPositioning";

export const fetchSalaryRangeEmployeeRangePositionings = async (
  ctx: AppContext,
  salaryGridId: number,
  scope: Prisma.SalaryRangeEmployeeWhereInput
) => {
  const user = getRequiredUser(ctx);
  const salaryGrid = await ctx.prisma.salaryGrid.findUniqueOrThrow({
    where: { id: salaryGridId },
    select: { tiersMode: true, tiersNames: true },
  });

  const positionings = [...getRelevantPositionings(salaryGrid.tiersMode)].reverse();

  return map(positionings, async (positioning) => {
    const count = await ctx.prisma.salaryRangeEmployee.count({
      where: {
        OR: [
          {
            range: {
              band: { measure: SalaryGridMeasure.BASE_SALARY },
            },
            baseSalaryRangePositioning: positioning,
          },
          {
            range: {
              band: { measure: SalaryGridMeasure.ON_TARGET_EARNINGS },
            },
            onTargetEarningsRangePositioning: positioning,
          },
        ],
        ...scope,
        range: {
          band: {
            grid: {
              id: salaryGridId,
              companyId: user.companyId,
            },
          },
        },
      },
    });

    return {
      id: positioning,
      name: getSalaryBandPositioningDetails(ctx.t, {
        tiersMode: salaryGrid.tiersMode,
        tiersNames: salaryGrid.tiersNames,
        positioning,
      }).title,
      count,
    };
  });
};

export const CacheKeys = {
  Changelogs: "changelogs",
  CompanyTags: "company-tags",
  Countries: "countries",
  CountriesWithAliases: "countries-with-aliases",
  Currencies: "currencies",
  EmailsCss: "emails-css",
  ExternalPartnerBenchmarks: "external-partner-benchmarks",
  ExternalPartnerCountries: "external-partner-countries",
  ExternalPartnerJobs: "external-partner-jobs",
  JobFamilies: "job-families",
  Jobs: "jobs",
  JobsForClassification: "jobs-for-classification",
  JobsWithEmployeesCount: "jobsWithEmployeesCount",
  ClassifiedJobs: `classified-jobs`,
  LandingStats: "landing-stats",
  Locations: "locations",
  ActualLocations: "actual-locations",
  LocationsWithAliases: "locations-with-aliases",
  OrderedParticipants: "ordered-participants",
  SurveyGrowth: "survey-growth",
  IntercomArticles: "intercom-articles",
};

import { type ExternalEmployee, type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type EmployeeAutocompleteRow } from "~/components/ui/ExternalEmployeeAutocomplete";
import { dangerouslyIncludeSoftDeletedExternalEmployees } from "~/lib/prisma-restrictions/schemas/generateExternalEmployeesSoftDeleteSchema";
import { type FetchExternalEmployeeManagersInput } from "~/pages/api/compensation-review/fetch-external-employee-managers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { externalEmployeeSelectForAutocomplete } from "~/services/external-employee/autocompleteListCompanyExternalEmployees";

export const fetchExternalEmployeeManagers = async (
  ctx: CompensationReviewContext,
  params: FetchExternalEmployeeManagersInput
) => {
  const externalEmployee = await ctx.prisma.externalEmployee.findUniqueOrThrow({
    ...dangerouslyIncludeSoftDeletedExternalEmployees(),
    where: { id: params.externalEmployeeId },
    select: buildRecursiveManagersSelect(externalEmployeeSelectForAutocomplete, ctx.parameters.maxReviewersCount),
  });

  const managers = flattenManagers(externalEmployee as EmployeeWithManager<EmployeeAutocompleteRow>);

  const finalReviewer = ctx.parameters.finalReviewerId
    ? await ctx.prisma.compensationReviewReviewer.findUniqueOrThrow({
        where: { id: ctx.parameters.finalReviewerId },
        select: { externalEmployee: { select: externalEmployeeSelectForAutocomplete } },
      })
    : null;

  return {
    managers,
    finalReviewerExternalEmployee: finalReviewer?.externalEmployee ?? null,
  };
};

export type FetchExternalEmployeeManagersResult = AsyncReturnType<typeof fetchExternalEmployeeManagers>;

const buildRecursiveManagersSelect = (
  select: Prisma.ExternalEmployeeSelect,
  depth: number
): Prisma.ExternalEmployeeSelect => {
  if (!depth) return select;

  return {
    ...select,
    id: true,
    manager: {
      select: buildRecursiveManagersSelect(select, depth - 1),
    },
  };
};

type EmployeeWithManager<T> = T & Pick<ExternalEmployee, "id"> & { manager: EmployeeWithManager<T> | null };

const flattenManagers = <T>(employee: EmployeeWithManager<T>): EmployeeWithManager<T>[] => {
  if (!employee.manager) return [];

  return [employee.manager, ...flattenManagers(employee.manager)];
};

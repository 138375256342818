import { AdditionalFieldNature, type AdditionalFieldValue, UserLocale } from "@prisma/client";
import { DateFormats, formatDate } from "~/lib/dates";
import { formatPercent } from "~/lib/math";

type PartialAdditionalFieldValue = Pick<
  AdditionalFieldValue,
  "stringValue" | "numberValue" | "dateValue" | "percentageValue"
>;
export const formatAdditionalFieldValue = (
  additionalFieldvalue: PartialAdditionalFieldValue,
  nature: AdditionalFieldNature
) => {
  if (nature === AdditionalFieldNature.DATE && !!additionalFieldvalue.dateValue) {
    return formatDate(additionalFieldvalue.dateValue, DateFormats.FULL_DATE, UserLocale.EN);
  }
  if (nature === AdditionalFieldNature.NUMBER && !!additionalFieldvalue.numberValue) {
    return additionalFieldvalue.numberValue;
  }
  if (nature === AdditionalFieldNature.PERCENTAGE && !!additionalFieldvalue.percentageValue) {
    return formatPercent(additionalFieldvalue.percentageValue, 1);
  }
  return additionalFieldvalue.stringValue;
};

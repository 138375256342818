import { type GetServerSidePropsContext } from "next";

import { CompanyType, OnboardingStep } from "@prisma/client";
import { type Route } from "nextjs-routes";

type OnboardingRoutePath = (Route["pathname"] & `/get-started/${string}`) | "/home";

export const ONBOARDING_STEP_PATHS: Record<OnboardingStep, OnboardingRoutePath> = {
  [OnboardingStep.LETS_GET_TO_KNOW_EACH_OTHER]: "/get-started/get-to-know-each-other",
  [OnboardingStep.CONNECT_YOUR_HRIS]: "/get-started/connect-your-hris",
  [OnboardingStep.DATA_REVIEW]: "/get-started/data-review",
  [OnboardingStep.MAP_YOUR_LEVELS]: "/get-started/levels-mapping",
  [OnboardingStep.MAP_YOUR_LOCATIONS]: "/get-started/locations-mapping",
  [OnboardingStep.MAP_YOUR_JOBS]: "/get-started/jobs-mapping",
  [OnboardingStep.MAP_YOUR_EMPLOYEES]: "/get-started/unmapped-employees",
  [OnboardingStep.CHECK_YOUR_DATA]: "/get-started/check-your-data",
  [OnboardingStep.ENJOY_FIGURES]: "/get-started/enjoy-figures",
  [OnboardingStep.ONBOARDING_FINISHED]: "/home",
} as const;

export type OnboardingStepPath = (typeof ONBOARDING_STEP_PATHS)[keyof typeof ONBOARDING_STEP_PATHS];

const shouldRedirectToOnboarding = (ctx: GetServerSidePropsContext) => {
  if (ctx.req.featureFlags.CAN_ACCESS_ASSISTED_ONBOARDING_FLOW) {
    return false;
  }

  if (ctx.resolvedUrl.startsWith("/get-started") || ctx.resolvedUrl.startsWith("/oauth")) {
    return false;
  }

  if (!ctx.req.user) {
    return false;
  }

  if (ctx.req.user.isSuperAdmin) {
    return false;
  }

  if (ctx.req.user.company.type === CompanyType.VENTURE_CAPITAL) {
    return false;
  }

  return ctx.req.user.company.onboardingStep !== OnboardingStep.ONBOARDING_FINISHED;
};

export const getOnboardingRedirection = (ctx: GetServerSidePropsContext) => {
  if (!ctx.req.user) {
    return null;
  }

  // handle error cases (404 etc)
  if (!shouldRedirectToOnboarding(ctx)) {
    return null;
  }

  if (!ctx.req.globalPermissionsContext.canAccessRawData) {
    return { pathname: "/get-started/pending" } as const;
  }

  return { pathname: ONBOARDING_STEP_PATHS[ctx.req.user.company.onboardingStep] } as const;
};

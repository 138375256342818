import { Card, Stack, Typography } from "@mui/material";
import { type Currency, ExternalRemunerationStatus, ExternalRemunerationType } from "@prisma/client";
import React from "react";
import { value } from "~/components/helpers";
import { CardHeader } from "~/components/ui/core/CardHeader";
import { Money } from "~/components/ui/Money";
import { formatAdditionalFieldValue } from "~/lib/additionalField";
import { DateFormats } from "~/lib/dates";
import { useI18n } from "~/lib/i18n/useI18n";
import { chain, compact } from "~/lib/lodash";
import { type GetAllAdditionalFieldsResult } from "~/services/additional-field/getAllAdditionalFields";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { type ExternalEmployeePanelSelect } from "~/services/external-employee/fetchExternalEmployeeForPanel";
import { formatGender } from "~/services/external-employee/gender";

type Props = {
  currency: Pick<Currency, "code" | "euroExchangeRate">;
  externalEmployee: ExternalEmployeePanelSelect;
  additionalFields: GetAllAdditionalFieldsResult;
};

export const ExternalEmployeeOverview: React.FC<Props> = ({ currency, externalEmployee, additionalFields }) => {
  const { t, formatDate } = useI18n();

  const location = value(() => {
    if (!externalEmployee.location) return;

    if (externalEmployee.location.country)
      return `${externalEmployee.location.name}, ${externalEmployee.location.country.name}`;

    return externalEmployee.location.name;
  });

  const mappedAdditionalFields = compact(
    externalEmployee.additionalFieldValues.map((value) => {
      const field = additionalFields.find((field) => field.id === value.additionalFieldId);
      if (!field) return null;

      return { label: field.name, value: formatAdditionalFieldValue(value, field.nature) };
    })
  );

  return (
    <Stack spacing={4}>
      <OverviewCard title={t("components.external-employee-panel.overview.basics")}>
        <OverviewItem
          label={t("components.external-employee-panel.overview.name")}
          value={formatExternalEmployeeName(externalEmployee)}
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.job-title")}
          value={externalEmployee.job?.name}
        />
        <OverviewItem label={t("components.external-employee-panel.overview.location")} value={location} />
        <OverviewItem
          label={t("components.external-employee-panel.overview.gender")}
          value={externalEmployee.gender ? formatGender(t, externalEmployee.gender) : undefined}
        />
      </OverviewCard>

      <OverviewCard title={t("components.external-employee-panel.overview.compensation")}>
        <Stack direction="row" spacing={4}>
          <Typography variant="subtitle1" className="w-48 shrink-0">
            {t("components.external-employee-panel.overview.base-salary")}
          </Typography>
        </Stack>
        {externalEmployee.remunerationItems
          .filter((item) => item.nature.mappedType === ExternalRemunerationType.FIXED_SALARY)
          .map((item, index) => (
            <OverviewItem
              titleSecondaryColor={true}
              key={`base-salary-${index}`}
              label={
                item.date
                  ? t("components.external-employee-panel.overview.since", {
                      date: formatDate(item.date, DateFormats.FULL_DATE),
                    })
                  : t("components.external-employee-panel.overview.today")
              }
              value={<Money amount={item.amount} currency={externalEmployee.currency} convertToCurrency={currency} />}
            />
          ))}
        {externalEmployee.remunerationItems.filter(
          (item) => item.nature.mappedType === ExternalRemunerationType.FIXED_SALARY
        ).length === 0 && (
          <Typography variant="subtitle1" color="text.secondary">
            -
          </Typography>
        )}
        <OverviewItem
          label={t("components.external-employee-panel.overview.on-target-bonus")}
          value={
            externalEmployee.remunerationItems.filter(
              (item) => item.nature.mappedType === ExternalRemunerationType.VARIABLE_BONUS
            ).length > 0 ? (
              <Money
                amount={chain(externalEmployee.remunerationItems)
                  .filter((item) => item.nature.mappedType === ExternalRemunerationType.VARIABLE_BONUS)
                  .sumBy((item) => item.amount)
                  .value()}
                currency={externalEmployee.currency}
                convertToCurrency={currency}
              />
            ) : (
              <Typography variant="subtitle1" color="text.secondary">
                -
              </Typography>
            )
          }
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.other-bonuses")}
          fieldName="other-bonuses"
          value={
            externalEmployee.remunerationItems.filter(
              (item) => item.nature.mappedType === ExternalRemunerationType.FIXED_BONUS
            ).length > 0 ? (
              <Money
                amount={chain(externalEmployee.remunerationItems)
                  .filter((item) => item.nature.mappedType === ExternalRemunerationType.FIXED_BONUS)
                  .sumBy((item) => item.amount)
                  .value()}
                currency={externalEmployee.currency}
                convertToCurrency={currency}
              />
            ) : (
              <Typography variant="subtitle1" color="text.secondary">
                -
              </Typography>
            )
          }
        />

        <OverviewItem
          label={t("components.external-employee-panel.overview.total-cash")}
          value={
            <Money
              amount={chain(externalEmployee.remunerationItems)
                .filter((item) => item.status === ExternalRemunerationStatus.LIVE)
                .sumBy((item) => item.amount)
                .value()}
              currency={externalEmployee.currency}
              convertToCurrency={currency}
            />
          }
        />
      </OverviewCard>

      <OverviewCard title={t("components.external-employee-panel.overview.work")}>
        <OverviewItem
          label={t("components.external-employee-panel.overview.hire-date")}
          value={externalEmployee.hireDate ? formatDate(externalEmployee.hireDate, DateFormats.FULL_DATE) : undefined}
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.manager")}
          value={externalEmployee.manager ? formatExternalEmployeeName(externalEmployee.manager) : undefined}
        />
        <OverviewItem
          label={t("components.external-employee-panel.overview.business-unit")}
          value={externalEmployee.businessUnit ?? undefined}
        />
      </OverviewCard>

      {mappedAdditionalFields.length > 0 && (
        <OverviewCard title={t("components.external-employee-panel.overview.additional-fields")}>
          {mappedAdditionalFields.map((field) => {
            return <OverviewItem key={field.label} label={field.label} value={field.value} />;
          })}
        </OverviewCard>
      )}
    </Stack>
  );
};

const OverviewCard: React.FC<React.PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <Card className="w-full">
      <CardHeader title={title} />
      <Stack className="pt-4" spacing={4}>
        {children}
      </Stack>
    </Card>
  );
};

const OverviewItem: React.FC<{
  label: string;
  fieldName?: string;
  value: string | number | JSX.Element | undefined;
  titleSecondaryColor?: boolean;
}> = ({ label, fieldName, value, titleSecondaryColor = false }) => {
  return (
    <Stack direction="row" spacing={4}>
      <Typography
        variant="subtitle1"
        color={titleSecondaryColor ? "text.secondary" : ""}
        className="w-48 shrink-0"
        data-field={fieldName}
      >
        {label}
      </Typography>
      <Typography variant="subtitle1">{value ?? "-"}</Typography>
    </Stack>
  );
};

import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object, string } from "yup";
import { api } from "~/lib/api";
import { notifySupportMetadataReceived } from "~/lib/external/slack/notifications";
import { createMutation } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";

export const NotifyMetadataSupportSchema = object({
  userId: number().required(),
  companyId: number().required(),
  sessionId: string().required(),
  sessionReplayUrl: string().required(),
  url: string().required(),
  view: string().required(),
  params: string().required(),
}).noUnknown();

export type NotifyMetadataSupportInput = YupOutputType<typeof NotifyMetadataSupportSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = NotifyMetadataSupportSchema.validateSync(req.body, { abortEarly: false });

  await notifySupportMetadataReceived(req, input);

  return res.status(HttpStatus.OK).json({ message: "OK" });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const useNotifyMetadataSupportMutation = createMutation<typeof handler, typeof NotifyMetadataSupportSchema>({
  path: "/api/dev/notify-metadata-support",
  schema: NotifyMetadataSupportSchema,
});

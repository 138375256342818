import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { DEFAULT_MAX_REVIEWERS_COUNT } from "~/services/compensation-review/campaigns/admin/createCampaign";
import { externalEmployeeSelectForAutocomplete } from "~/services/external-employee/autocompleteListCompanyExternalEmployees";

const select = {
  id: true,
  companyId: true,
  maxReviewersCount: true,
  allowReviewersRecommendationOverride: true,
  finalReviewer: {
    select: {
      id: true,
      externalEmployee: { select: externalEmployeeSelectForAutocomplete },
    },
  },
  company: {
    select: {
      defaultSalaryGrid: {
        select: {
          tiersMode: true,
          tiersNames: true,
        },
      },
    },
  },
  admins: { select: { userId: true } },
  reviewerGuidelines: true,
  rules: {
    select: {
      id: true,
      position: true,
      target: true,
      populationId: true,
      type: true,
      unit: true,
      amount: true,
      hardLimit: true,
    },
    orderBy: { position: "asc" },
  },
} satisfies Prisma.OffCycleReviewConfigurationSelect;

export const fetchOffCycleReviewConfiguration = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const offCycleReviewConfiguration = await ctx.prisma.offCycleReviewConfiguration.findFirst({
    where: { companyId: user.companyId },
    select,
  });

  if (!offCycleReviewConfiguration) {
    return ctx.prisma.offCycleReviewConfiguration.create({
      data: {
        companyId: user.companyId,
        maxReviewersCount: DEFAULT_MAX_REVIEWERS_COUNT,
        allowReviewersRecommendationOverride: true,
      },
      select,
    });
  }

  return offCycleReviewConfiguration;
};

export type FetchOffCycleReviewConfigurationResult = AsyncReturnType<typeof fetchOffCycleReviewConfiguration>;

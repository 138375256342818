import { Gender } from "@prisma/client";
import { type TFunction } from "next-i18next";
import { match } from "ts-pattern";

export const formatGender = (t: TFunction, gender: Gender): string => {
  return match(gender)
    .with(Gender.MALE, () => t("enum.gender.male"))
    .with(Gender.FEMALE, () => t("enum.gender.female"))
    .with(Gender.UNDISCLOSED, () => t("enum.gender.undisclosed"))
    .exhaustive();
};

export const formatGenderCopy = (t: TFunction, gender: Gender): string => {
  return match(gender)
    .with(Gender.MALE, () => t("common.men"))
    .with(Gender.FEMALE, () => t("common.women"))
    .with(Gender.UNDISCLOSED, () => t("common.non-binary"))
    .exhaustive();
};

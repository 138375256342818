import type { Currency } from "@prisma/client";
import {
  ExternalEmployeeSource,
  ExternalEmployeeStatus,
  ExternalRemunerationStatus,
  ExternalRemunerationType,
} from "@prisma/client";
import { mapSeries } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import uniqid from "uniqid";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { computeAdditionalFieldValuePayloads } from "~/lib/hris/helpers/computeAdditionalFieldValuePayloads";
import { mapCustomRemunerationItem } from "~/lib/hris/helpers/mapCustomRemunerationItem";
import { getStaticModelsForSync } from "~/lib/integration";
import { chain, compact } from "~/lib/lodash";
import { logError, logInfo } from "~/lib/logger";
import { assertNotNil, getId } from "~/lib/utils";
import { type findImportRows } from "~/services/external-employee/import/findImportRows";
import { importInitialExternalEmployeesSchema } from "~/services/external-employee/import/process/initial/startImportInitial";
import { initialImportColumnsMap } from "~/services/initialImportColumnsMap";
import { findOrCreatePerformanceReviewCycle } from "~/services/performance-review/findOrCreatePerformanceReviewCycle";
import { savePerformanceReviewRatings } from "~/services/performance-review/savePerformanceReviewRatings";
import { prepareAndSyncHierarchicalRelationships } from "~/services/synchronization/postSyncExternalEmployees";
import { syncExternalEmployee } from "~/services/synchronization/syncExternalEmployee";
import {
  type EmployeeData,
  guessFteFactor,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/syncExternalEmployees";
import { validateRowsWithSchema } from "~/services/xlsxToJson";

type Params = {
  rows: AsyncReturnType<typeof findImportRows>;
  companyId: number;
  importId: number;
};

export const importInitialRows = async (ctx: AppContext, params: Params) => {
  const { rows } = params;

  logInfo(ctx, `[spreadsheet-import][initial] Importing ${rows.length} rows`, {
    companyId: params.companyId,
    importId: params.importId,
    rowIds: rows.map(getId).join(","),
    rowsCount: rows.length,
  });

  const company = await ctx.prisma.company.findFirstOrThrow({
    where: { id: params.companyId },
    include: {
      integrationSettings: {
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  const companyId = company.id;

  const fteFactor = guessFteFactor(
    rows.map((row) => ({
      ignoreFte: row.rowData.fteDivider === null || row.rowData.fteDivider === undefined,
      fte: row.rowData.fteDivider?.toString() ?? undefined,
    }))
  );

  const staticModels = await getStaticModelsForSync(ctx);

  const commonWhere = { companyId: companyId, skippedAt: null };
  const locations = await ctx.prisma.externalLocation.findMany({
    where: commonWhere,
  });
  const jobs = await ctx.prisma.externalJob.findMany({
    where: commonWhere,
  });
  const levels = await ctx.prisma.externalLevel.findMany({ where: commonWhere });

  const rowErrors: { rowId: number; error: string }[] = [];
  const mapSuccessRowIdToExternalEmployeeId = new Map<number, number>();

  const columnMap = initialImportColumnsMap(ctx);
  const schema = importInitialExternalEmployeesSchema(staticModels.currencies);

  const externalEmployees = compact(
    await mapSeries(rows, async (unvalidatedRow) => {
      try {
        const validRows = validateRowsWithSchema(ctx, {
          entityRows: [unvalidatedRow],
          columnMap,
          schema,
        });

        if (validRows.length === 0) {
          rowErrors.push({ rowId: unvalidatedRow.id, error: "invalid schema" });
          return null;
        }

        const row = assertNotNil(validRows[0]);

        const currency = staticModels.currencies.find((currency) => {
          return currency.code === row.currency;
        }) as Currency;

        // By finding the already mapped locations, we avoid creating duplicates with a different externalId
        // in case these entities came from HRIS and were already MAPPED.
        // This avoids employees ending up in NEEDS_REMAPPING status
        const locationExternalId =
          locations.find((location) => location.name === row.location && location.mappedLocationId !== null)
            ?.externalId ?? row.location;
        const jobExternalId =
          jobs.find((job) => job.name === row.jobTitle && job.mappedJobId !== null)?.externalId ?? row.jobTitle;
        const levelExternalId =
          levels.find((level) => level.name === row.level && level.mappedLevel !== null)?.externalId ?? row.level;

        const externalEmployeePayload: EmployeeData["input"] = {
          source: ExternalEmployeeSource.SPREADSHEET,
          externalId: uniqid(),
          employeeNumber: row.employeeNumber,
          status: ExternalEmployeeStatus.UNMAPPED,
          firstName: row.firstName,
          lastName: row.lastName,
          email: row.email,
          gender: row.gender,
          isFounder: row.isFounder || false,
          hireDate: row.hireDate,
          birthDate: row.birthDate,
          businessUnit: row.businessUnit,
          fteDivider: row.fteDivider,
          company: {
            connect: { id: companyId },
          },
          currency: { connect: { id: currency.id } },
          location: {
            connectOrCreate: {
              where: {
                companyId_externalId: {
                  companyId: companyId,
                  externalId: locationExternalId,
                },
              },
              create: {
                name: row.location,
                externalId: locationExternalId,
                autoMappingEnabled: true,
                company: {
                  connect: { id: companyId },
                },
              },
            },
          },
          job: {
            connectOrCreate: {
              where: {
                companyId_externalId: {
                  companyId: companyId,
                  externalId: jobExternalId,
                },
              },
              create: {
                name: row.jobTitle,
                externalId: jobExternalId,
                company: {
                  connect: { id: companyId },
                },
              },
            },
          },
          level: {
            connectOrCreate: {
              where: {
                companyId_externalId: {
                  companyId: companyId,
                  externalId: levelExternalId,
                },
              },
              create: {
                name: row.level,
                externalId: levelExternalId,
                company: {
                  connect: { id: companyId },
                },
              },
            },
          },
        };

        const remunerationItems: EmployeeData["remunerationItems"] = [
          {
            source: ExternalEmployeeSource.SPREADSHEET,
            externalId: "fixed-salary",
            amount: Math.round(row.baseSalary || 0),
            status: ExternalRemunerationStatus.LIVE,
            nature: {
              connectOrCreate: {
                where: {
                  companyId_source_externalId: {
                    companyId: companyId,
                    source: ExternalEmployeeSource.SPREADSHEET,
                    externalId: "fixed-salary",
                  },
                },
                create: {
                  source: ExternalEmployeeSource.SPREADSHEET,
                  externalId: "fixed-salary",
                  name: "Basic salary",
                  mappedType: ExternalRemunerationType.FIXED_SALARY,
                  company: { connect: { id: companyId } },
                },
              },
            },
          },
        ];

        if (row.fixedBonus) {
          remunerationItems.push({
            source: ExternalEmployeeSource.SPREADSHEET,
            externalId: "fixed-bonus",
            amount: Math.round(row.fixedBonus),
            status: ExternalRemunerationStatus.LIVE,
            nature: {
              connectOrCreate: {
                where: {
                  companyId_source_externalId: {
                    companyId: companyId,
                    source: ExternalEmployeeSource.SPREADSHEET,
                    externalId: "fixed-bonus",
                  },
                },
                create: {
                  source: ExternalEmployeeSource.SPREADSHEET,
                  externalId: "fixed-bonus",
                  name: "Fixed bonus",
                  mappedType: ExternalRemunerationType.FIXED_BONUS,
                  company: { connect: { id: companyId } },
                },
              },
            },
          });
        }

        if (row.onTargetBonus) {
          remunerationItems.push({
            source: ExternalEmployeeSource.SPREADSHEET,
            externalId: "variable-bonus",
            amount: Math.round(row.onTargetBonus),
            status: ExternalRemunerationStatus.LIVE,
            nature: {
              connectOrCreate: {
                where: {
                  companyId_source_externalId: {
                    companyId: companyId,
                    source: ExternalEmployeeSource.SPREADSHEET,
                    externalId: "variable-bonus",
                  },
                },
                create: {
                  source: ExternalEmployeeSource.SPREADSHEET,
                  externalId: "variable-bonus",
                  name: "Variable bonus",
                  mappedType: ExternalRemunerationType.VARIABLE_BONUS,
                  company: { connect: { id: companyId } },
                },
              },
            },
          });
        }

        const customRemunerationItems = await value(async () => {
          if (Object.keys(row.customRemunerationItems).length === 0) {
            return [];
          }

          const customRemunerationItemNames = Object.keys(row.customRemunerationItems);
          const customRemunerationItems = await ctx.prisma.customRemunerationItem.findMany({
            where: { name: { in: customRemunerationItemNames }, companyId: company.id },
          });

          return compact(
            customRemunerationItems.map((item) => {
              const formattedItem = {
                customRemunerationItemId: item.id,
                customRemunerationItem: {
                  name: item.name,
                  frequency: item.frequency,
                  type: item.type,
                  subtype: item.subtype,
                },
                value: row.customRemunerationItems[item.name] ?? null,
              };

              return mapCustomRemunerationItem(
                {
                  source: ExternalEmployeeSource.SPREADSHEET,
                  companyId: company.id,
                },
                formattedItem
              );
            })
          );
        });

        if (customRemunerationItems.length > 0) {
          remunerationItems.push(...customRemunerationItems);
        }

        const additionalFieldValues = await value(async () => {
          if (Object.keys(row.additionalFields).length === 0) {
            return [];
          }

          const additionalFieldNames = Object.keys(row.additionalFields);
          const additionalFields = await ctx.prisma.additionalField.findMany({
            where: { name: { in: additionalFieldNames }, companyId: companyId },
          });

          return additionalFields.map(({ id, name }) => ({
            additionalFieldId: id,
            additionalFieldMappingId: null,
            value: row.additionalFields[name]?.stringValue ?? null,
          }));
        });

        const { externalEmployee } = await syncExternalEmployee(
          ctx,
          {
            input: externalEmployeePayload,
            remunerationItems,
            additionalFieldValues: computeAdditionalFieldValuePayloads(company, additionalFieldValues),
            ...(row.fteDivider && { fte: row.fteDivider.toString(), ignoreFte: false }),
          },
          { company: company, staticModels, discriminatorKey: "employeeNumber", fteFactor }
        );

        mapSuccessRowIdToExternalEmployeeId.set(row.id, externalEmployee.id);

        return { ...externalEmployee, manager: row.manager, performanceRating: row.performanceRating };
      } catch (error) {
        logError(
          ctx,
          `[spreadsheet-import][initial] Error while processing row id ${unvalidatedRow.id} : ${error.toString()}`,
          {
            companyId: params.companyId,
            importId: params.importId,
            importRowId: unvalidatedRow.id,
            error: error.toString(),
          }
        );

        rowErrors.push({ rowId: unvalidatedRow.id, error: error.toString() });
        return null;
      }
    })
  );

  const performanceReviewCycle = await findOrCreatePerformanceReviewCycle(ctx, {
    companyId: companyId,
    performanceReviewScope: null,
  });

  const externalEmployeesWithRatings = chain(externalEmployees)
    .filter(({ performanceRating }) => !!performanceRating)
    .map(({ id, performanceRating }) => ({ id, performanceRating: assertNotNil(performanceRating) }))
    .value();

  await savePerformanceReviewRatings(ctx, {
    performanceReviewCycleId: performanceReviewCycle.id,
    externalEmployees: externalEmployeesWithRatings,
  });

  const hierarchy = compact(
    await mapSeries(externalEmployees, async (externalEmployee) => {
      const manager = await value(async () => {
        if (!externalEmployee.manager) {
          return null;
        }

        return ctx.prisma.externalEmployee.findFirst({
          where: {
            companyId: company.id,
            employeeNumber: externalEmployee.manager,
          },
          select: { id: true, externalId: true },
        });
      });

      if (!manager) {
        return null;
      }

      return {
        source: externalEmployee.source,
        externalEmployeeId: externalEmployee.id,
        managerExternalId: manager.externalId,
      };
    })
  );

  await prepareAndSyncHierarchicalRelationships(ctx, hierarchy, company);

  return { rowErrors, mapSuccessRowIdToExternalEmployeeId };
};

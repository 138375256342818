import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
export const fetchCompanyComparisonEmployeeLocations = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const companyWithLocations = await ctx.prisma.company.findUniqueOrThrow({
    include: {
      compareSpecificLocations: {
        include: {
          country: true,
        },
      },
    },
    where: {
      id: user.companyId,
    },
  });

  return companyWithLocations.compareSpecificLocations;
};

import { UserRole } from "@prisma/client";
import { type NullableAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const getUserRoles = (user: NullableAuthenticatedUser) => {
  return {
    isSuperAdmin: user?.isSuperAdmin ?? false,
    isManager: user?.permissions.isManager ?? false,
    isHr: user?.permissions.role === UserRole.HR,
    isAdmin: user?.permissions.role === UserRole.ADMIN,
    isRecruiter: user?.permissions.role === UserRole.RECRUITER,
    isEmployee: user?.permissions.role === UserRole.EMPLOYEE,
  };
};

export type UserRoles = ReturnType<typeof getUserRoles>;

import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { logInfo, logWarn } from "~/lib/logger";

const EXIT_SUCCESS = 0;

export const handleGracefulExit = (ctx: AppContext): void => {
  // Makes sure we don't forget this required ENV everywhere we need it
  // Note: in DEV this is not available
  if (!process.env.NEXT_MANUAL_SIG_HANDLE) {
    logWarn(ctx, "[server] Manual SIG handling disabled");
    return;
  }

  logInfo(ctx, "[server] Setting up manual SIG handling");

  ["SIGINT", "SIGTERM"].forEach((signal) => {
    process.on(signal, async () => {
      logInfo(ctx, `[server] ${signal} received`);

      await ctx.prisma.$disconnect();
      ctx.redis.disconnect();

      logInfo(ctx, `[server] Gracefully handled termination, exiting in ${config.datadog.flushTimeInMs}ms`);

      // This allows datadog to flush traces before process exits
      setTimeout(() => process.exit(EXIT_SUCCESS), config.datadog.flushTimeInMs);
    });
  });
};

import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { noop } from "~/lib/lodash";

type ItemId = string | null;

type ScrollSpyVisibleItemProps = {
  currentlyVisibleItemId: ItemId;
  setCurrentlyVisibleItemId: (id: ItemId) => void;
};

const ScrollSpyVisibleItemContext = createContext<ScrollSpyVisibleItemProps>({
  currentlyVisibleItemId: null,
  setCurrentlyVisibleItemId: noop,
});

export const useScrollSpyVisibleItem = () => {
  return useContext(ScrollSpyVisibleItemContext);
};

export const ScrollSpyVisibleItemProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [currentlyVisibleItemId, setCurrentlyVisibleItemId] = useState<ItemId>(null);
  const config = useMemo(
    () => ({
      currentlyVisibleItemId,
      setCurrentlyVisibleItemId,
    }),
    [currentlyVisibleItemId]
  );

  return <ScrollSpyVisibleItemContext.Provider value={config}>{children}</ScrollSpyVisibleItemContext.Provider>;
};

type ScrollSpyItemProps = React.PropsWithChildren<{
  id: string;
  intersectionRatio?: number;
}>;

export const ScrollSpyItem: React.FC<ScrollSpyItemProps> = ({ id, children, intersectionRatio = 0.25 }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const { setCurrentlyVisibleItemId } = useScrollSpyVisibleItem();

  const observer = useMemo(() => {
    // This is a workaround for SSR
    if (typeof IntersectionObserver === "undefined") {
      return {
        observe: noop,
      };
    }

    return new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }

          if (entry.intersectionRatio >= intersectionRatio) {
            setCurrentlyVisibleItemId(id);
          }
        });
      },
      {
        root: null,
        threshold: 0.25,
        rootMargin: "0px",
      }
    );
  }, []);

  useEffect(() => {
    if (!elementRef?.current) {
      return;
    }

    observer.observe(elementRef.current);
  }, [elementRef]);

  return (
    <div ref={elementRef} id={id}>
      {children}
    </div>
  );
};

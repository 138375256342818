import { type AppContext } from "~/lib/context";
import { UNAVAILABLE_REQUIRED_USER_ERROR_MESSAGE } from "~/lib/requiredUserMessage";

export const getRequiredUser = (ctx: AppContext) => {
  if (ctx.user) {
    return ctx.user;
  }

  throw new Error(UNAVAILABLE_REQUIRED_USER_ERROR_MESSAGE);
};

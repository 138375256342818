export const selectExternalEmployeeData = {
  id: true,
  firstName: true,
  lastName: true,
  hireDate: true,
  birthDate: true,
  employeeNumber: true,
  job: {
    select: {
      name: true,
    },
  },
  level: {
    select: {
      name: true,
    },
  },
};

import { Gender } from "@prisma/client";
import { chain, clamp, matches, meanBy, pullAllBy } from "~/lib/lodash";
import { isNotNull } from "~/lib/utils";
import { type EmployeesBucket } from "~/services/company-dashboard/generateEmployeesBuckets";
import { computeEmployeeCompensation, hasKnownGender } from "~/services/employee";
import { getAmountForPercentile } from "~/services/employee-stats/utils";

export const computeGenderMarketFairnessStatsForBuckets = ({ buckets }: { buckets: EmployeesBucket[] }) => {
  const employeesWithPercentageDifferences = buckets.flatMap((bucket) =>
    computeGenderMarketFairnessStatsForBucket({ bucket })
  );

  const [menEmployeesPercentageDifferences, womenWithPercentageDifferences] = chain(employeesWithPercentageDifferences)
    .filter(isNotNull)
    .partition(({ gender }) => gender === Gender.MALE)
    .value();

  if (!menEmployeesPercentageDifferences.length || !womenWithPercentageDifferences.length) {
    return null;
  }

  const meanMenPercentageDifference = meanBy(menEmployeesPercentageDifferences, (item) => item.percentageDifference);
  const meanWomenPercentageDifference = meanBy(womenWithPercentageDifferences, (item) => item.percentageDifference);

  const diff = meanWomenPercentageDifference - meanMenPercentageDifference;

  // Starting at 100%, remove 5% for each point favoring men, or 2.5% for each point favoring women
  const normalisedDiff = diff > 0 ? diff / 2 : diff;
  const score = clamp(1 - Math.abs(normalisedDiff) * 5, 0, 1);

  return {
    meanMenPercentageDifference,
    meanWomenPercentageDifference,
    diff,
    score,
    matches,
  };
};

const computeGenderMarketFairnessStatsForBucket = ({ bucket }: { bucket: EmployeesBucket }) => {
  const comparableEmployees = pullAllBy(bucket.employees, bucket.companyEmployees, ({ id }) => id);

  const distinctCompaniesCount = chain(comparableEmployees)
    .uniqBy(({ companyId }) => companyId)
    .size()
    .value();

  if (comparableEmployees.length < 5 || distinctCompaniesCount < 2) {
    return null;
  }

  const employeesWithDefinedGender = bucket.companyEmployees.filter(hasKnownGender());

  return employeesWithDefinedGender.map((employee) => {
    const amounts = chain(comparableEmployees)
      .push(employee)
      .map((employee) => computeEmployeeCompensation(employee))
      .value();

    const { percentageDifference } = getAmountForPercentile({
      amount: computeEmployeeCompensation(employee),
      amounts,
      percentile: 0.5,
    });

    return { ...employee, percentageDifference };
  });
};

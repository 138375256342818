import MuiLink, { type LinkProps as MuiLinkProps } from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
/* eslint-disable-next-line no-restricted-imports */
import NextLink, { type LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import { type Query, type Route } from "nextjs-routes";
import { type AnchorHTMLAttributes, type ReactElement, forwardRef } from "react";

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled("a")({});

export interface NextLinkComposedProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">,
    Omit<NextLinkProps, "href" | "as" | "onClick" | "onMouseEnter" | "onTouchStart"> {
  to: NextLinkProps["href"] | typeof NO_LINK;
  linkAs?: NextLinkProps["as"];
  newTab?: boolean;
}

export const NextLinkComposed = forwardRef<HTMLAnchorElement, NextLinkComposedProps>(
  function NextLinkComposed(props, ref) {
    const { to, linkAs, replace, scroll, shallow, prefetch, legacyBehavior = true, locale, newTab, ...other } = props;

    const newTabProps = newTab ? { target: "_blank", rel: "noreferrer" } : {};

    if (to === NO_LINK) {
      return props.children as ReactElement;
    }

    return (
      <NextLink
        href={to}
        prefetch={prefetch}
        as={linkAs}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref
        locale={locale}
        legacyBehavior={legacyBehavior}
      >
        <Anchor ref={ref} {...other} {...newTabProps} />
      </NextLink>
    );
  }
);

export const NO_LINK = "";

export type ExternalLink = `${"https://" | "mailto:"}${string}`;

const isExternalLink = (to: LinkProps["to"]): to is ExternalLink => {
  return typeof to === "string" && (to.indexOf("http") === 0 || to.indexOf("mailto:") === 0);
};

export type LinkProps = {
  activeClassName?: string;
  as?: NextLinkProps["as"];
  // Definition of LinkProps omit pathname is causing an error
  to: Route | Exclude<Route, { query: Query | undefined }>["pathname"] | ExternalLink | typeof NO_LINK;
  linkAs?: NextLinkProps["as"]; // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean;
  newTab?: boolean;
  disabled?: boolean;
} & Omit<NextLinkComposedProps, "to" | "linkAs" | "href"> &
  Omit<MuiLinkProps, "href">;

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const {
    activeClassName = "active",
    as,
    className: classNameProps,
    to,
    disabled,
    legacyBehavior,
    linkAs: linkAsProp,
    locale,
    noLinkStyle,
    newTab,
    prefetch,
    replace,
    role,
    scroll,
    shallow,
    ...other
  } = props;

  const newTabProps = newTab ? { target: "_blank", rel: "noreferrer" } : {};

  const router = useRouter();
  const pathname = typeof to === "string" ? to : to.pathname;
  const className = classNames(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  if (disabled && props.children) {
    return props.children as ReactElement;
  }

  if (isExternalLink(to)) {
    if (noLinkStyle) {
      return <Anchor className={className} role={role} href={to} ref={ref} {...newTabProps} {...other} />;
    }

    return <MuiLink className={className} href={to} ref={ref} {...newTabProps} {...other} />;
  }

  const linkAs = linkAsProp || as;
  const nextjsProps = {
    to,
    linkAs,
    replace,
    scroll,
    shallow,
    prefetch,
    legacyBehavior,
    locale,
  };

  if (noLinkStyle) {
    return <NextLinkComposed className={className} ref={ref} {...newTabProps} {...nextjsProps} {...other} />;
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      className={className}
      ref={ref}
      {...newTabProps}
      {...nextjsProps}
      {...other}
    />
  );
});

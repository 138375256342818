import { CompensationReviewSalaryDisplay, type Currency, type SalaryGridTiersMode } from "@prisma/client";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { type AuthenticatedUser } from "~/lib/session";
import { getKeys } from "~/lib/utils";
import {
  type CompensationReviewRule,
  fetchCampaign,
  type FetchCampaignResult,
} from "~/services/compensation-review/campaigns/fetchCampaign";
import {
  canAccessAnalytics,
  canAccessBudgets,
  canAccessConfiguration,
  canAccessOffCycleConfiguration,
  canAccessOffCycleOverview,
  canAccessOverview,
  canAccessReviewers,
  canAccessYourReviews,
  canApproveOffCycleReview,
  canBypassOffCycleRules,
  canBypassRules,
  canOverrideOffCycleReviewersRecommendation,
  canOverrideReviewersRecommendation,
  canReviewAnnualPerformanceBonus,
  canReviewCampaign,
  canReviewExceptionalBonus,
  canSeeAnalytics,
  canSeeOverview,
  canSeeReviewers,
  canSeeYourReviews,
} from "~/services/compensation-review/compensationReviewPermissions";
import {
  campaignScope,
  type CompensationReviewScope,
  CompensationReviewScopeType,
  offCycleReviewScope,
} from "~/services/compensation-review/compensationReviewScope";
import {
  fetchAuthenticatedCompensationReviewReviewer,
  type FetchAuthenticatedCompensationReviewReviewerResult,
} from "~/services/compensation-review/fetchAuthenticatedCompensationReviewReviewer";
import {
  fetchOffCycleReviewConfiguration,
  type FetchOffCycleReviewConfigurationResult,
} from "~/services/compensation-review/off-cycle-reviews/fetchOffCycleReviewConfiguration";
import { getDefaultCurrency } from "~/services/currency";

export type CompensationReviewData = {
  scope: CompensationReviewScope;
  campaign?: FetchCampaignResult;
  companyId: number;

  authenticatedReviewer: FetchAuthenticatedCompensationReviewReviewerResult;

  salaryGrid?: {
    tiersMode: SalaryGridTiersMode;
    tiersNames: string[];
  };

  parameters: {
    budget: {
      prorationStartDate: Date | null;
    };

    currency: Pick<Currency, "code" | "euroExchangeRate" | "symbol">;
    companyCurrency: Pick<Currency, "code" | "euroExchangeRate">;

    finalReviewerId: number | null;
    maxReviewersCount: number;
    reviewerGuidelines: string | null;

    variablePayReviewEnabled: boolean;
    relativeVariablePayEnabled: boolean;
    annualPerformanceBonusReviewEnabled: boolean;
    exceptionalBonusReviewEnabled: boolean;
    preferedSalaryDisplay: CompensationReviewSalaryDisplay;
  };

  rules: CompensationReviewRule[];

  permissions: {
    canAccessConfiguration: boolean;
    canReview: boolean;
    canReviewExceptionalBonus: boolean;
    canOverrideReviewersRecommendation: boolean;
    canReviewAnnualPerformanceBonus: boolean;
    canAccessOverview: boolean;
    canBypassRules: boolean;
  };
};

export type CompensationReviewCampaignData = Omit<CompensationReviewData, "campaign"> & {
  campaign: FetchCampaignResult;

  permissions: CompensationReviewData["permissions"] & {
    canSeeYourReviews: boolean;
    canSeeOverview: boolean;
    canSeeReviewers: boolean;
    canSeeAnalytics: boolean;
    canAccessYourReviews: boolean;
    canAccessReviewers: boolean;
    canAccessAnalytics: boolean;
    canAccessBudgets: boolean;
  };
};

export type OffCycleReviewData = Omit<CompensationReviewData, "campaign"> & {
  configuration: FetchOffCycleReviewConfigurationResult;

  permissions: CompensationReviewData["permissions"] & {
    canApproveOffCycleReview: boolean;
  };
};

export type CompensationReviewContext = Omit<AppContext, "user"> & { user: AuthenticatedUser } & CompensationReviewData;

export type CompensationReviewCampaignContext = Omit<CompensationReviewContext, "campaign"> &
  CompensationReviewCampaignData;

export type OffCycleReviewContext = Omit<CompensationReviewContext, "campaign"> & OffCycleReviewData;

export const isCompensationReviewCampaignContext = (
  ctx: CompensationReviewContext
): ctx is CompensationReviewCampaignContext => {
  return !!ctx.campaign;
};

export const fetchCompensationReviewContext = async (
  ctx: AppContext,
  params: { scope: CompensationReviewScope }
): Promise<CompensationReviewContext> => {
  return match(params.scope)
    .with({ type: CompensationReviewScopeType.CAMPAIGN }, () =>
      fetchCompensationReviewCampaignContext(ctx, { campaignId: params.scope.id })
    )
    .with({ type: CompensationReviewScopeType.OFF_CYCLE_REVIEW }, () => fetchCompensationOffCycleReviewContext(ctx)) // todo pass ID
    .exhaustive();
};

export const fetchCompensationReviewCampaignContext = async (
  ctx: AppContext,
  params: { campaignId: number }
): Promise<CompensationReviewCampaignContext> => {
  const user = getRequiredUser(ctx);
  const companyCurrency = await getDefaultCurrency(ctx);

  const campaign = await fetchCampaign(ctx, params);
  const scope = campaignScope(campaign.id);

  const authenticatedReviewer = await fetchAuthenticatedCompensationReviewReviewer(ctx, { scope });

  const checkContext = { user, campaign, authenticatedReviewer, impersonation: ctx.impersonation };

  const permissions = {
    canSeeYourReviews: canSeeYourReviews(checkContext),
    canSeeOverview: canSeeOverview(checkContext),
    canSeeReviewers: canSeeReviewers(checkContext),
    canSeeAnalytics: canSeeAnalytics(checkContext),
    canAccessYourReviews: canAccessYourReviews(checkContext),
    canAccessOverview: canAccessOverview(checkContext),
    canAccessReviewers: canAccessReviewers(checkContext),
    canAccessConfiguration: canAccessConfiguration(checkContext),
    canAccessAnalytics: canAccessAnalytics(checkContext),
    canAccessBudgets: canAccessBudgets(checkContext),
    canReview: canReviewCampaign(checkContext),
    canOverrideReviewersRecommendation: canOverrideReviewersRecommendation(checkContext),
    canReviewExceptionalBonus: canReviewExceptionalBonus(checkContext),
    canReviewAnnualPerformanceBonus: canReviewAnnualPerformanceBonus(checkContext),
    canBypassRules: canBypassRules(checkContext),
  };

  if (!permissions.canAccessConfiguration) {
    campaign.budget.amount = null;
    campaign.budget.subBudgets = [];
  }

  Object.assign(ctx, {
    user,
    companyId: campaign.companyId,
    scope,
    campaign,
    authenticatedReviewer,
    salaryGrid: campaign.company.defaultSalaryGrid ?? undefined,
    parameters: {
      budget: {
        prorationStartDate: campaign.budget.prorationStartDate,
      },
      currency: campaign.currency,
      companyCurrency,
      finalReviewerId: campaign.finalReviewer?.id ?? null,
      maxReviewersCount: campaign.maxReviewersCount,
      reviewerGuidelines: campaign.reviewerGuidelines,
      variablePayReviewEnabled: campaign.variablePayReviewEnabled,
      relativeVariablePayEnabled: campaign.relativeVariablePayEnabled,
      annualPerformanceBonusReviewEnabled: campaign.annualPerformanceBonusReviewEnabled,
      exceptionalBonusReviewEnabled: campaign.exceptionalBonusReviewEnabled,
      preferedSalaryDisplay: campaign.preferedSalaryDisplay,
    },
    rules: campaign.rules,
    permissions,
  });

  return ctx as CompensationReviewCampaignContext;
};

export const fetchCompensationOffCycleReviewContext = async (ctx: AppContext): Promise<OffCycleReviewContext> => {
  const user = getRequiredUser(ctx);

  const companyCurrency = await getDefaultCurrency(ctx);

  const configuration = await fetchOffCycleReviewConfiguration(ctx);
  const scope = offCycleReviewScope(configuration.id);

  const authenticatedReviewer = await fetchAuthenticatedCompensationReviewReviewer(ctx, { scope }); // ?

  const checkContext = { user, configuration };

  const permissions = {
    canReviewExceptionalBonus: true,
    canReviewAnnualPerformanceBonus: true,
    canAccessConfiguration: canAccessOffCycleConfiguration(checkContext),
    canReview: !!authenticatedReviewer,
    canOverrideReviewersRecommendation: canOverrideOffCycleReviewersRecommendation(checkContext),
    canAccessOverview: canAccessOffCycleOverview(checkContext),
    canBypassRules: canBypassOffCycleRules(checkContext),
    canApproveOffCycleReview: canApproveOffCycleReview(checkContext),
  };

  if (!ctx.globalPermissionsContext.canAccessOffCycleReviews) {
    getKeys(permissions).forEach((key) => {
      permissions[key] = false;
    });
  }

  Object.assign(ctx, {
    user,
    configuration,
    companyId: configuration.companyId,
    scope,
    authenticatedReviewer,
    salaryGrid: configuration.company.defaultSalaryGrid ?? undefined,
    parameters: {
      budget: {
        prorationStartDate: null,
      },
      currency: companyCurrency,
      companyCurrency,
      finalReviewerId: configuration.finalReviewer?.id ?? null,
      maxReviewersCount: configuration.maxReviewersCount,
      reviewerGuidelines: configuration.reviewerGuidelines,
      variablePayReviewEnabled: true,
      relativeVariablePayEnabled: true,
      annualPerformanceBonusReviewEnabled: true,
      exceptionalBonusReviewEnabled: true,
      preferedSalaryDisplay: CompensationReviewSalaryDisplay.ON_TARGET_EARNINGS,
    },
    rules: configuration.rules,
    permissions,
  });

  return ctx as OffCycleReviewContext;
};

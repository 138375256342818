import { ExternalEmployeeStatus, type Prisma } from "@prisma/client";
import { map } from "bluebird";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { compact, orderBy, sum } from "~/lib/lodash";
import { and } from "~/lib/prismaHelpers";
import {
  buildRevieweesWherePayload,
  buildReviewersWherePayload,
} from "~/services/compensation-review/campaigns/admin/employeeReviewers";
import { type CompensationReviewContext } from "~/services/compensation-review/compensationReviewContext";
import { baseEmptyFilters, type FilterOptionsInput } from "~/services/employee-filter";
import {
  buildExternalEmployeeFilterWhereClauses,
  fetchExternalEmployeeFilterOptions,
} from "~/services/employee-filter/fetchExternalEmployeeFilterOptions";
import { formatExternalEmployeeName } from "~/services/external-employee";
import { selectExternalEmployeeUserPicture } from "~/services/external-employee/selectExternalEmployeeUserForPicture";
import { fetchSalaryRangeEmployeeRangePositionings } from "./fetchSalaryRangeEmployeeRangePositionings";

const selectRevieweesIds = {
  reviewees1: true,
  reviewees2: true,
  reviewees3: true,
  reviewees4: true,
  reviewees5: true,
  reviewees6: true,
  reviewees7: true,
  reviewees8: true,
  reviewees9: true,
  reviewees10: true,
} satisfies Prisma.CompensationReviewReviewerSelect;

const fetchCompensationReviewEmployeesPromotions = async (
  ctx: AppContext,
  scope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  return map([true, false], async (isPromoted) => {
    const count = await ctx.prisma.compensationReviewEmployee.count({
      where: {
        companyId: getRequiredUser(ctx).companyId,
        isPromoted,
        AND: [scope],
      },
    });

    return {
      id: isPromoted,
      name: isPromoted ? ctx.t("common.yes") : ctx.t("common.no"),
      count,
    };
  });
};

const buildReviewerCountClause = (ctx: AppContext, scope: Prisma.CompensationReviewEmployeeWhereInput) => {
  return Object.keys(selectRevieweesIds).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        where: {
          companyId: getRequiredUser(ctx).companyId,
          AND: [scope],
        },
      },
    }),
    {}
  );
};

export const fetchCompensationReviewReviewers = async (
  ctx: CompensationReviewContext,
  scope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  return ctx.prisma.compensationReviewReviewer.findMany({
    where: {
      AND: [
        {
          OR: compact([
            buildRevieweesWherePayload(ctx, scope),
            ctx.parameters.finalReviewerId && { id: ctx.parameters.finalReviewerId },
          ]),
        },
      ],
    },
    select: {
      _count: {
        select: buildReviewerCountClause(ctx, scope),
      },
      id: true,
      externalEmployee: {
        select: {
          firstName: true,
          lastName: true,
          externalId: true,
          employeeNumber: true,
          ...selectExternalEmployeeUserPicture,
        },
      },
    },
  });
};

const fetchCompensationReviewEmployeesPerformanceReviewRatings = async (
  ctx: AppContext,
  scope: Prisma.CompensationReviewEmployeeWhereInput
) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.performanceReviewRating.findMany({
    where: {
      companyId: user.companyId,
      compensationReviewEmployees: {
        some: scope,
      },
    },
    select: {
      _count: {
        select: {
          compensationReviewEmployees: {
            where: {
              companyId: user.companyId,
              AND: [scope],
            },
          },
        },
      },
      id: true,
      name: true,
    },
  });
};

export type FetchCompensationReviewEmployeeFilterOptionsParams = {
  scope?: Prisma.CompensationReviewEmployeeWhereInput;
};

export const fetchCompensationReviewEmployeeFilterOptions = async (
  ctx: CompensationReviewContext,
  params?: FetchCompensationReviewEmployeeFilterOptionsParams
) => {
  const user = getRequiredUser(ctx);
  const scope = params?.scope ?? {};

  const baseExternalEmployeeWhere = {
    companyId: ctx.user.companyId,
    status: { not: ExternalEmployeeStatus.SKIPPED },
  } satisfies Prisma.ExternalEmployeeWhereInput;

  const externalEmployeeFilterOptions = await fetchExternalEmployeeFilterOptions(ctx, {
    scope: and([baseExternalEmployeeWhere, scope?.externalEmployee]),
  });

  const [rawPerformanceReviewRatings, isPromoted, rawReviewers] = await Promise.all([
    fetchCompensationReviewEmployeesPerformanceReviewRatings(ctx, scope),
    fetchCompensationReviewEmployeesPromotions(ctx, scope),
    fetchCompensationReviewReviewers(ctx, scope),
  ]);

  const rawSalaryRangePositionings = user.company.defaultSalaryGridId
    ? await fetchSalaryRangeEmployeeRangePositionings(ctx, user.company.defaultSalaryGridId, {
        externalEmployee: { compensationReviewEmployees: { some: scope } },
      })
    : [];

  const performanceReviewRatings = rawPerformanceReviewRatings.map((performanceReview) => ({
    id: performanceReview.id,
    name: performanceReview.name,
    count: performanceReview._count.compensationReviewEmployees,
  }));

  const reviewers = rawReviewers.map((reviewer) => ({
    id: reviewer.id,
    name: formatExternalEmployeeName(reviewer.externalEmployee),
    count: sum(Object.values(reviewer._count)),
  }));

  const rangePositionings = rawSalaryRangePositionings.map((positioning) => ({
    id: positioning.id,
    name: positioning.name,
    count: positioning.count,
  }));

  return {
    ...baseEmptyFilters,
    ...externalEmployeeFilterOptions,
    rangePositionings,
    performanceReviewRatings: orderBy(performanceReviewRatings, ["count", "name"], ["asc", "asc"]),
    reviewers: orderBy(reviewers, ["name"], ["asc"]),
    isPromoted: orderBy(isPromoted, ["count"], ["asc"]),
  };
};

export type FetchCompensationReviewEmployeeFilterOptionsResult = AsyncReturnType<
  typeof fetchCompensationReviewEmployeeFilterOptions
>;

export const buildCompensationReviewEmployeeFilterWhereClauses = async (
  ctx: CompensationReviewContext,
  selectedFilterOptions: FilterOptionsInput
): Promise<Prisma.CompensationReviewEmployeeWhereInput[]> => {
  const externalEmployeeFilterOptions = await buildExternalEmployeeFilterWhereClauses(ctx, selectedFilterOptions, {
    omitPerformanceReviewRating: true,
  });

  return compact([
    !!externalEmployeeFilterOptions && {
      externalEmployee: externalEmployeeFilterOptions,
    },
    // Override the externalEmployee performanceReviewRatings
    // by the comp review performanceReviewRatings
    !!selectedFilterOptions?.performanceReviewRatings?.length && {
      performanceRatingId: { in: selectedFilterOptions.performanceReviewRatings },
    },

    // If there is more than one value it means we want true and false results
    // which means no where clause is needed
    selectedFilterOptions?.isPromoted?.length === 1 && {
      isPromoted: selectedFilterOptions.isPromoted[0],
    },

    !!selectedFilterOptions?.reviewers?.length &&
      buildReviewersWherePayload(ctx, { in: selectedFilterOptions.reviewers }),
  ]);
};

import { type Prisma } from "@prisma/client";

export const compensationReviewEmployeeSelectForReviewersChain = {
  id: true,
  externalEmployee: {
    select: {
      id: true,
      manager: {
        select: {
          id: true,
          managerExternalEmployeeId: true,
        },
      },
    },
  },
} satisfies Prisma.CompensationReviewEmployeeSelect;

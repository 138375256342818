import { MenuItem, Stack, Typography } from "@mui/material";
import { type ComponentProps } from "react";
import { useBoolean } from "react-use";
import { DropDown } from "~/components/ui/core/DropDown";

type Props<T extends string> = Omit<ComponentProps<typeof DropDown>, "children" | "label" | "open" | "onOpenChange"> & {
  value: T | null;
  onChange: (value: T) => void;
  options: T[];
  placeholder?: string;
  formatOption: (option: T) => { label: string; description?: string };
  formatOptionSeparator?: (option: T) => JSX.Element | undefined;
};

export const DropDownSelector = <T extends string>(props: Props<T>) => {
  const { value, onChange, options, placeholder = "", formatOption, formatOptionSeparator, MenuProps, ...rest } = props;

  const [showMenu, setShowMenu] = useBoolean(false);

  return (
    <DropDown
      {...rest}
      open={showMenu}
      onOpenChange={setShowMenu}
      label={value ? formatOption(value).label : placeholder}
      MenuProps={{
        ...MenuProps,
        closeAfterTransition: true,
        className: "w-64",
        anchorOrigin: { vertical: "bottom", horizontal: "right" },
        transformOrigin: { vertical: "top", horizontal: "right" },
      }}
    >
      {options.map((option) => {
        const { label, description } = formatOption(option);
        const separator = formatOptionSeparator?.(option);

        return (
          <Stack key={option} className="max-w-sm">
            <MenuItem
              className="whitespace-pre-wrap"
              value={option}
              selected={option === value}
              onClick={() => {
                onChange(option);
                setShowMenu(false);
              }}
            >
              <Stack spacing={1}>
                <Typography variant="subtitle1">{label}</Typography>
                {description && (
                  <Typography variant="caption" color="text.secondary">
                    {description}
                  </Typography>
                )}
              </Stack>
            </MenuItem>
            {separator}
          </Stack>
        );
      })}
    </DropDown>
  );
};

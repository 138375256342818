import type { NextApiResponse } from "next";
import { route } from "nextjs-routes";
import { makeSamlRedirectionUrl } from "~/services/auth/saml/samlUrls";

export class SamlAuthenticationEnforcedError extends Error {
  samlAuthenticationUrl: string;

  constructor(email: string, redirect?: string) {
    super("SAML authentication enforced");
    Object.setPrototypeOf(this, SamlAuthenticationEnforcedError.prototype);

    this.samlAuthenticationUrl = route(makeSamlRedirectionUrl({ email, redirect }));
  }
}

export const handleSamlAuthenticationEnforcedError = (res: NextApiResponse, error: SamlAuthenticationEnforcedError) => {
  return res.redirect(error.samlAuthenticationUrl);
};

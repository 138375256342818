import { type SpreadsheetImportRowStatus } from "@prisma/client";
import type { AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";

export const updateImportRow = async (
  ctx: AppContext,
  params: { rowId: number; status: SpreadsheetImportRowStatus; externalEmployeeId?: number; message?: string }
) => {
  const user = getRequiredUser(ctx);
  const { rowId, status, externalEmployeeId, message } = params;

  return ctx.prisma.externalEmployeeSpreadsheetImportRow.update({
    data: {
      status,
      ...(externalEmployeeId && { externalEmployeeId }),
      ...(message && { message }),
    },
    where: {
      id: rowId,
      companyId: user.companyId,
    },
  });
};

import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";

export const getImpersonationAccessHistoryForCompany = async (
  ctx: AppContext,
  { companyId }: { companyId: number }
) => {
  return ctx.prisma.companyImpersonationAccess.findMany({
    where: { companyId },
    include: {
      logs: {
        include: {
          user: {
            select: {
              id: true,
              email: true,
              lastName: true,
              firstName: true,
            },
          },
        },
      },
    },
    orderBy: { createdAt: "desc" },
  });
};

export type ImpersonationAccessHistory = AsyncReturnType<typeof getImpersonationAccessHistoryForCompany>;

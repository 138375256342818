import { value } from "~/components/helpers";
import { ApiImpersonationForbiddenError } from "~/lib/errors/apiImpersonationForbiddenError";
import { ApiImpersonationMismatchError } from "~/lib/errors/apiImpersonationMismatchError";
import { type MiddlewareContext, MiddlewareRuntime, next } from "~/lib/middleware/middleware";
import { parseString } from "~/lib/queryParams";
import {
  ImpersonateCompanyIdParam,
  ImpersonatedCompanyHeader,
  SessionKey,
  stopCompanyImpersonation,
} from "~/lib/session";
import { AuthenticatedUserIncludes } from "~/services/auth/authenticatedUserIncludes";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { hasImpersonationAccess } from "~/services/impersonation/helper";

export const resolveCompanyImpersonation = async (
  ctx: MiddlewareContext<{
    user: RequiredAuthenticatedUser;
    impersonatingUser: NullableAuthenticatedUser;
  }>
) => {
  const companyIdFromQuery = ctx.query ? parseString(ctx.query, ImpersonateCompanyIdParam) : undefined;
  if (ctx.user.isSuperAdmin && companyIdFromQuery) {
    ctx.req.session.set(SessionKey.IMPERSONATED_COMPANY_ID, companyIdFromQuery);
    await ctx.req.session.save();
  }

  // Automatically impersonate currently impersonated user's company
  const impersonatingUserCompanyId = ctx.impersonatingUser ? ctx.user.companyId.toString() : null;
  const companyId = ctx.req.session.get<string>(SessionKey.IMPERSONATED_COMPANY_ID);
  const targetCompanyId = impersonatingUserCompanyId ?? companyId;

  if (!targetCompanyId) {
    return next(ctx);
  }

  if (ctx.runtime === MiddlewareRuntime.API) {
    const impersonatedCompanyId = ctx.req.headers[ImpersonatedCompanyHeader.toLowerCase()];
    if (impersonatedCompanyId && impersonatedCompanyId !== targetCompanyId) {
      throw new ApiImpersonationMismatchError(
        "Impersonation Mismatch",
        targetCompanyId,
        impersonatedCompanyId.toString()
      );
    }
  }

  const companyIdAsInt = parseInt(targetCompanyId);
  const hasValidImpersonationAccess = await value(async () => {
    if (ctx.impersonatingUser?.companyId === ctx.user.companyId) {
      return true;
    }

    return hasImpersonationAccess(ctx.req, {
      companyId: companyIdAsInt,
    });
  });

  if (hasValidImpersonationAccess) {
    return next({
      ...ctx,
      user: {
        ...ctx.user,
        companyId: companyIdAsInt,
        company: await ctx.req.prisma.company.findUniqueOrThrow({
          where: { id: companyIdAsInt },
          include: AuthenticatedUserIncludes["company"]["include"],
        }),
      },
    });
  }

  await stopCompanyImpersonation(ctx.req);

  throw new ApiImpersonationForbiddenError("Impersonation not allowed", targetCompanyId);
};

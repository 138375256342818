import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { type FetchExternalEmployeeNextSalaryRangeInput } from "~/pages/api/salary-bands/fetch-external-employee-next-salary-range";
import { formatSalaryRangeName } from "~/services/salary-bands/helpers/formatSalaryBandName";

export const fetchExternalEmployeeNextSalaryRange = async (
  ctx: AppContext,
  input: FetchExternalEmployeeNextSalaryRangeInput
) => {
  const externalEmployee = await ctx.prisma.externalEmployee.findFirstOrThrow({
    where: { id: input.externalEmployeeId },
    select: {
      liveSalaryRangeEmployee: {
        select: {
          range: {
            select: {
              level: { select: { position: true } },
              band: {
                select: {
                  job: { select: { name: true } },
                  location: { select: { name: true } },
                  ranges: {
                    select: {
                      id: true,
                      level: { select: { name: true, position: true } },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  });

  const nextSalaryRange = externalEmployee.liveSalaryRangeEmployee?.range.band.ranges.find(
    (range) => range.level.position === (externalEmployee.liveSalaryRangeEmployee?.range.level.position ?? 0) - 1
  );

  if (!externalEmployee.liveSalaryRangeEmployee || !nextSalaryRange) return null;

  return {
    id: nextSalaryRange.id,
    name: formatSalaryRangeName(externalEmployee.liveSalaryRangeEmployee.range.band, nextSalaryRange.level),
  };
};

export type FetchExternalEmployeeNextSalaryRangeResult = AsyncReturnType<typeof fetchExternalEmployeeNextSalaryRange>;

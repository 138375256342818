// eslint-disable-next-line no-restricted-imports
import _ from "lodash";

export type Falsey = _.Falsey;
export type Dictionary<T> = _.Dictionary<T>;

export const assign = _.assign;
export const camelCase = _.camelCase;
export const capitalize = _.capitalize;
export const chain = _.chain;
export const chunk = _.chunk;
export const clamp = _.clamp;
export const cloneDeep = _.cloneDeep;
export const compact = _.compact;
export const concat = _.concat;
export const constant = _.constant;
export const countBy = _.countBy;
export const curry = _.curry;
export const debounce = _.debounce;
export const deburr = _.deburr;
export const difference = _.difference;
export const differenceBy = _.differenceBy;
export const every = _.every;
export const fill = _.fill;
export const filter = _.filter;
export const find = _.find;
export const findIndex = _.findIndex;
export const findKey = _.findKey;
export const first = _.first;
export const flatMap = _.flatMap;
export const flatten = _.flatten;
export const flow = _.flow;
export const get = _.get;
export const groupBy = _.groupBy;
export const has = _.has;
export const identity = _.identity;
export const includes = _.includes;
export const indexOf = _.indexOf;
export const intersection = _.intersection;
export const intersectionWith = _.intersectionWith;
export const isArray = _.isArray;
export const isBoolean = _.isBoolean;
export const isDate = _.isDate;
export const isEmpty = _.isEmpty;
export const isEqual = _.isEqual;
export const isFunction = _.isFunction;
export const isInteger = _.isInteger;
export const isNaN = _.isNaN;
export const isNil = _.isNil;
export const isNull = _.isNull;
export const isNumber = _.isNumber;
export const isObject = _.isObject;
export const isPlainObject = _.isPlainObject;
export const isString = _.isString;
export const isSymbol = _.isSymbol;
export const isUndefined = _.isUndefined;
export const kebabCase = _.kebabCase;
export const keyBy = _.keyBy;
export const keys = _.keys;
export const keysIn = _.keysIn;
export const last = _.last;
export const lowerCase = _.lowerCase;
export const map = _.map;
export const mapValues = _.mapValues;
export const matches = _.matches;
export const max = _.max;
export const maxBy = _.maxBy;
export const mean = _.mean;
export const meanBy = _.meanBy;
export const merge = _.merge;
export const min = _.min;
export const minBy = _.minBy;
export const noop = _.noop;
export const omit = _.omit;
export const omitBy = _.omitBy;
export const orderBy = _.orderBy;
export const padEnd = _.padEnd;
export const partition = _.partition;
export const pick = _.pick;
export const pickBy = _.pickBy;
export const pullAllBy = _.pullAllBy;
export const range = _.range;
export const reduce = _.reduce;
export const remove = _.remove;
export const replace = _.replace;
export const round = _.round;
export const sample = _.sample;
export const set = _.set;
export const sortBy = _.sortBy;
export const sum = _.sum;
export const sumBy = _.sumBy;
export const take = _.take;
export const takeRight = _.takeRight;
export const tap = _.tap;
export const throttle = _.throttle;
export const toPairs = _.toPairs;
export const trim = _.trim;
export const uniq = _.uniq;
export const uniqBy = _.uniqBy;
export const uniqueId = _.uniqueId;
export const upperFirst = _.upperFirst;
export const values = _.values;
export const valuesIn = _.valuesIn;
export const without = _.without;
export const words = _.words;
export const zipWith = _.zipWith;

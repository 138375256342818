import { QueueJobName } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { makeSingletonKey } from "~/lib/makeSingletonKey";
import { BaseJobDataSchema } from "~/lib/queue/baseJobDataSchema";
import { sendJob } from "~/lib/queue/sendJob";
import { type YupOutputType } from "~/lib/utils";
import { suggestMappingForExternalJobs } from "~/services/synchronization/suggestMappingForExternalJobs";

const SuggestJobsMappingJobDataSchema = BaseJobDataSchema;

export type SuggestJobsMappingJobData = YupOutputType<typeof SuggestJobsMappingJobDataSchema>;

export const suggestJobsMappingWorkerService = async (ctx: AppContext, data: SuggestJobsMappingJobData) => {
  const { companyId } = SuggestJobsMappingJobDataSchema.validateSync(data, { abortEarly: false });

  await suggestMappingForExternalJobs(ctx, companyId);
};

export const sendSuggestJobsMappingJob = async (ctx: AppContext, data: SuggestJobsMappingJobData) => {
  await sendJob(ctx, {
    jobName: QueueJobName.SUGGEST_JOBS_MAPPING,
    data,
    options: { singletonKey: makeSingletonKey({ companyId: data.companyId }, { unique: false }) },
  });
};

import { type TFunction } from "next-i18next";
import { isEqual } from "~/lib/lodash";

export type PercentileBounds = {
  low: number;
  middle: number;
  high: number;
};

export const DefaultTargetPercentiles = {
  low: 0.25,
  middle: 0.5,
  high: 0.75,
};

export const MinTargetPercentile = 0.1;
export const MaxTargetPercentile = 0.9;

export const DefaultPercentileRangeWidth = 0.1;

export const PercentileRangeWidthOptions = [0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4];

export const getTargetPercentilesPresets = (t: TFunction) => [
  { name: t("enum.target-percentiles-presets.below-market"), value: 0.25 },
  { name: t("enum.target-percentiles-presets.on-market"), value: 0.5 },
  { name: t("enum.target-percentiles-presets.above-market"), value: 0.7 },
  { name: t("enum.target-percentiles-presets.way-above-market"), value: 0.9 },
];

export const generatePercentileBounds = (params: {
  targetPercentile: number;
  rangeWidth: number;
}): PercentileBounds => {
  const low = +Math.max(MinTargetPercentile, params.targetPercentile - params.rangeWidth).toFixed(2);
  const high = +Math.min(MaxTargetPercentile, params.targetPercentile + params.rangeWidth).toFixed(2);

  return {
    low,
    middle: params.targetPercentile,
    high,
  };
};

export const getConsistentPercentilesValue = (value: PercentileBounds): PercentileBounds | null => {
  const middle = value.middle > value.high ? value.high : value.middle;
  const low = value.low > middle ? middle : value.low;
  const consistentValue = { low, middle, high: value.high };

  return isEqual(consistentValue, value) ? null : consistentValue;
};

import { type CompanySurvey, type FundingRound } from "@prisma/client";
import { type NumberRange } from "~/components/ui/LegacyCompanyFilters";

export const isWithinSize = (range: NumberRange) => {
  return (survey: CompanySurvey): boolean => {
    if (range.min === null && range.max === null) {
      return true;
    }

    if (!survey.employeesCount) {
      return false;
    }

    if (range.min && survey.employeesCount < range.min) {
      return false;
    }

    if (range.max && survey.employeesCount > range.max) {
      return false;
    }

    return true;
  };
};

export const hasFundingRound = (rounds: FundingRound[]) => {
  return (survey: Pick<CompanySurvey, "lastFundingRound">): boolean => {
    if (!rounds.length) {
      return true;
    }

    if (!survey.lastFundingRound) {
      return false;
    }

    return rounds.includes(survey.lastFundingRound);
  };
};

import { CompanyType, type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";

export const companyForStatsSelect = {
  id: true,
  useAdvancedLevels: true,
  useExternalLevels: true,
  marketPositioningId: true,
  marketPositioning: true,
  globalTargetPercentile: true,
  defaultCountryId: true,
  companyTargetPercentiles: {
    select: {
      jobFamilyId: true,
      value: true,
    },
  },
  comparisonGeographicStrategy: true,
  compareSpecificLocations: {
    select: {
      id: true,
      countryId: true,
    },
  },
} satisfies Prisma.CompanySelect;

export type CompanyWithCompensationSettings = AsyncReturnType<typeof fetchCompanyWithCompensationSettings>;

export const fetchCompanyWithCompensationSettings = async (ctx: AppContext, params: { companyId: number }) => {
  return ctx.prisma.company.findFirstOrThrow({
    where: {
      id: params.companyId,
      type: CompanyType.PARTICIPANT,
    },
    select: companyForStatsSelect,
  });
};

/* eslint-disable @typescript-eslint/no-var-requires */

const colors = require("tailwindcss/colors");

module.exports = {
  content: ["./src/**/*.ts", "./src/**/*.tsx"],
  corePlugins: {
    preflight: false,
  },
  plugins: [
    require("@tailwindcss/aspect-ratio"),
    require("@tailwindcss/typography"),
    require("tailwindcss-bg-patterns"),
  ],
  theme: {
    extend: {
      animation: {
        wiggle: "wiggle 3s ease-in-out infinite",
        wiggler: "wiggler 3s ease-in-out infinite",
        scale: "scale 75ms ease-in-out 1",
        glow: "glow 10s ease-in-out infinite",
        grow: "grow 500ms ease-in-out 1",
        chart: "chart 400ms cubic-bezier(.84,.01,.75,1.38) 1",
        tilt: "tilt 10s linear infinite",
        cookies: "cookies 150ms linear",
      },
      borderWidth: {
        16: "16px",
      },
      boxShadow: {
        "full-spread": "0 0 0 1000px",
      },
      colors: {
        purple: colors.violet,

        gender: {
          male: colors.violet,
          female: colors.teal,
          none: colors.yellow,
        },

        gray: {
          50: "#F9FBFB",
          100: "#F3F6F5",
          200: "#E5EBEA",
          300: "#D1DBD9",
          400: "#9CAFAC",
          500: "#6B807C",
          600: "#4B635F",
          700: "#37514C",
          800: "#1F3733",
          900: "#112723",
        },

        primary: {
          50: "#F2FBF9",
          100: "#E4FAF6",
          200: "#BAEDE5",
          300: "#98D9CE",
          400: "#54C0AD",
          500: "#319E8B",
          600: "#218072",
          700: "#1E675D",
          800: "#1D524C",
          900: "#1C4540",
        },

        secondary: {
          50: "#FFF1F4",
          100: "#FFE3EA",
          200: "#FFCCD9",
          300: "#FF9FB9",
          400: "#FF5F8B",
          500: "#E23F6C",
          600: "#CD1D5B",
          700: "#C30D51",
          800: "#A30E4A",
          900: "#8B1046",
        },

        blue: {
          50: "#F1F7FE",
          100: "#D9E9F9",
          200: "#C0DAF7",
          300: "#A4CCF2",
          400: "#67AAEA",
          500: "#397AB8",
          600: "#2F6298",
          700: "#264F7D",
          800: "#204269",
          900: "#1D3A5E",
        },
      },
      flex: {
        2: "2 2 0%",
      },
      fontFamily: {
        sans: ["Inter", "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto"],
        title: [
          "'Work Sans'",
          "ui-sans-serif",
          "system-ui",
          "-apple-system",
          "BlinkMacSystemFont",
          "Segoe UI",
          "Roboto",
        ],
      },
      fontSize: {
        "2xs": ".6rem",
      },
      keyframes: {
        wiggle: {
          "0%, 100%": { transform: "rotate(-2deg) scale(1)" },
          "50%": { transform: "rotate(2deg) scale(1.05)" },
        },
        wiggler: {
          "0%, 100%": { transform: "rotate(-4deg) scale(1)" },
          "50%": { transform: "rotate(4deg) scale(1.2)" },
        },
        scale: {
          "0%": { opacity: 0, transform: "scale(0)" },
          "100%": { opacity: 1, transform: "scale(1)" },
        },
        glow: {
          "0%, 100%": { opacity: 1 },
          "50%": { opacity: 0.8 },
        },
        grow: {
          "0%, 100%": { transform: "scale(1)" },
          "50%": { transform: "scale(1.5)" },
        },
        chart: {
          "0%": { transform: "scaleY(0)" },
          "100%": { transform: "scaleY(1)" },
        },
        tilt: {
          "0%, 50%, 100%": { transform: "rotate(0deg) scaleY(1.05) scaleX(1.01)" },
          "25%": { transform: "rotate(0.5deg) scaleY(1) scaleX(1)" },
          "75%": { transform: "rotate(-0.5deg) scaleY(1) scaleX(1)" },
        },
        cookies: {
          "0%": { transform: "scale(0) translateX(92px)", opacity: 0 },
          "100%": { transform: "scale(1) translateX(0)", opacity: 1 },
        },
      },
      screens: {
        "v-lg": { raw: "(min-height: 767px)" },
        "v-xl": { raw: "(min-height: 1023px)" },
        "3xl": "1792px",
      },
      scale: {
        200: "2",
      },
      spacing: {
        "-10": "-2.5rem",
      },
      zIndex: {
        60: "60",
        70: "70",
        80: "80",
        90: "90",
        99: "99",
      },
      width: {
        7: "1.75rem",
      },
      typography: {
        DEFAULT: {
          css: {
            p: {
              marginTop: "1rem",
              marginBottom: "1rem",
            },
          },
        },
      },
    },
  },
};

import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, LocationStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const DeleteLocationInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.DELETE_LOCATION>().required(),
    initialState: LocationStateSchema.required(),
  })
);

export type DeleteLocationChange = YupOutputType<typeof DeleteLocationInputSchema>;

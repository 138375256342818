// Throw this error on kombo 503 timeout
// And catch it in sync external employees to avoid deactivation of the integration
export class KomboTimeoutError extends Error {
  error: string;

  constructor(error: string) {
    super(error);

    this.error = error;

    Object.setPrototypeOf(this, KomboTimeoutError.prototype);
  }
}

import { SvgIcon, type SvgIconProps } from "@mui/material";
import React from "react";

export const CompensationReview: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M16.7133 3.15625H6.75976C5.87121 3.15625 5.41749 3.73246 5.41749 4.62039V18.9028C5.41749 19.8474 5.92793 20.3669 6.911 20.3669H16.7889C17.7719 20.3669 18.2729 19.8474 18.2729 18.9028V12.3378L19.7948 10.817V18.9216C19.7948 20.9054 18.8212 21.8877 16.8645 21.8877H6.82592C4.8787 21.8877 3.89564 20.9054 3.89564 18.9216V4.6015C3.89564 2.66506 4.84089 1.63544 6.72194 1.63544H16.7133V3.15625ZM19.7933 4.55528L18.2729 6.07315V4.62039C18.2729 3.73246 17.8287 3.15625 16.9402 3.15625H16.7133V1.63544H16.9685C18.8435 1.63544 19.7794 2.64825 19.7933 4.55528Z"
        fill="current"
      />
      <path
        d="M9.58608 15.37C9.58608 15.6911 9.31191 15.9557 8.99055 15.9557H7.67666C7.35527 15.9557 7.08114 15.6911 7.08114 15.37C7.08114 15.0488 7.34581 14.7843 7.67666 14.7843H8.99055C9.32143 14.7843 9.58608 15.0488 9.58608 15.37ZM11.9126 12.4228H7.67666C7.34581 12.4228 7.08114 12.1583 7.08114 11.8277C7.08114 11.5065 7.34581 11.2515 7.67666 11.2515H13.0859L11.9126 12.4228Z"
        fill="current"
      />
      <path
        d="M21.7232 7.07633L22.4321 6.35846C22.7723 6.0184 22.7723 5.55554 22.4415 5.22494L22.2146 4.99823C21.9122 4.69596 21.4396 4.73374 21.1182 5.04546L20.4093 5.75391L21.7232 7.07633ZM11.1268 16.5885L13.0552 15.7289L21.0237 7.78478L19.6908 6.47181L11.7413 14.4159L10.8338 16.2768C10.7487 16.4468 10.9472 16.6641 11.1268 16.5885Z"
        fill="current"
      />
      <path
        d="M12.8192 6.34113C13.0087 6.34113 13.1264 6.54731 13.0299 6.7105V6.7105C12.9859 6.78503 12.9057 6.83075 12.8192 6.83075H9.38953C9.20915 6.83075 9.09071 6.64229 9.16895 6.47976V6.47976C9.20974 6.39502 9.29548 6.34113 9.38953 6.34113H12.8192ZM12.2507 7.29048C12.4452 7.29048 12.5642 7.50399 12.4618 7.66938V7.66938C12.4166 7.74249 12.3367 7.78699 12.2507 7.78699H9.39264C9.21031 7.78699 9.09018 7.597 9.16837 7.43228V7.43228C9.20948 7.34567 9.29678 7.29048 9.39264 7.29048H12.2507ZM13.6518 4.90068C13.8532 4.99008 13.9086 5.23833 13.7939 5.42643L13.7735 5.45984C13.6679 5.63299 13.4418 5.68721 13.2572 5.60327V5.60327C13.1358 5.5481 13.0016 5.50212 12.8544 5.46535C12.7072 5.42857 12.5461 5.41018 12.3711 5.41018C12.0748 5.41018 11.8132 5.47071 11.5865 5.59177C11.3598 5.7113 11.1818 5.89366 11.0525 6.13885C10.9252 6.38251 10.8616 6.69129 10.8616 7.06521C10.8616 7.43912 10.9252 7.74791 11.0525 7.99157C11.1818 8.23522 11.3598 8.41605 11.5865 8.53405C11.8132 8.65205 12.0748 8.71104 12.3711 8.71104C12.5461 8.71104 12.7072 8.69342 12.8544 8.65818C13.0016 8.62293 13.1339 8.57849 13.2512 8.52485V8.52485C13.4257 8.44641 13.6404 8.49643 13.7411 8.65918L13.7748 8.71373C13.8905 8.9008 13.838 9.14926 13.6364 9.23712C13.5141 9.29038 13.3837 9.33493 13.2452 9.37076C12.9688 9.44278 12.6774 9.4788 12.3711 9.4788C11.862 9.4788 11.4105 9.38302 11.0167 9.19146C10.6249 8.99838 10.3176 8.72177 10.0949 8.36165C9.87212 7.99999 9.76074 7.56785 9.76074 7.06521C9.76074 6.56104 9.87212 6.12812 10.0949 5.76647C10.3176 5.40482 10.6249 5.12744 11.0167 4.93436C11.4105 4.73974 11.862 4.64243 12.3711 4.64243C12.6893 4.64243 12.9847 4.68151 13.2572 4.75966C13.3955 4.79829 13.5271 4.8453 13.6518 4.90068Z"
        fill="current"
      />
    </SvgIcon>
  );
};

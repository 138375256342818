import { match } from "ts-pattern";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";

export const CompensationReviewScopeType = {
  CAMPAIGN: "CAMPAIGN",
  OFF_CYCLE_REVIEW: "OFF_CYCLE_REVIEW",
} as const;

export type CompensationReviewScopeType =
  (typeof CompensationReviewScopeType)[keyof typeof CompensationReviewScopeType];

export const CompensationReviewScopeSchema = object({
  type: mixed<CompensationReviewScopeType>().oneOf(Object.values(CompensationReviewScopeType)).required(),
  id: number().required(),
});

export type CompensationReviewScope = YupOutputType<typeof CompensationReviewScopeSchema>;

export const prismaCompensationReviewScope = (scope: CompensationReviewScope) => {
  return match(scope)
    .with({ type: CompensationReviewScopeType.CAMPAIGN }, () => ({ campaignId: scope.id }))
    .with({ type: CompensationReviewScopeType.OFF_CYCLE_REVIEW }, () => ({ offCycleReviewConfigurationId: scope.id }))
    .exhaustive();
};

export const campaignScope = (campaignId: number) => {
  return { type: CompensationReviewScopeType.CAMPAIGN, id: campaignId };
};

export const offCycleReviewScope = (offCycleReviewConfigurationId: number) => {
  return { type: CompensationReviewScopeType.OFF_CYCLE_REVIEW, id: offCycleReviewConfigurationId };
};

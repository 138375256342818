import { styled } from "@mui/material";
import Tooltip, { tooltipClasses, type TooltipProps } from "@mui/material/Tooltip";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    padding: 0,
    border: "none",
  },
}));

import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, SalaryGridTiersStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const UpdateTiersInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.UPDATE_TIERS>().required(),
    initialState: SalaryGridTiersStateSchema.required(),
    newState: SalaryGridTiersStateSchema.required(),
  })
);

export type UpdateTiersChange = YupOutputType<typeof UpdateTiersInputSchema>;

import { type User } from "@prisma/client";
import { type IncomingMessage } from "http";
import { nanoid } from "nanoid";
import { type GetServerSidePropsContext, type NextApiRequest } from "next";
import { type AsyncReturnType } from "type-fest";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import {
  trackImpersonateCompany,
  trackImpersonateSubsidiary,
  trackImpersonateUser,
  trackStopImpersonateCompany,
  trackStopImpersonateUser,
  trackUserMissingSession,
} from "~/lib/external/segment/server/events";
import { isString } from "~/lib/lodash";
import { parseNumber } from "~/lib/queryParams";
import { type fetchRequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { CompensationReviewScopeType } from "~/services/compensation-review/compensationReviewScope";
import { type PermissionsStatus } from "~/services/user/permissions/authenticationOptions";

export const SessionKey = {
  USER_ID: "userId",
  USER_HASH: "userHash",

  SESSION_HASH: "sessionHash",

  IMPERSONATED_USER_ID: "impersonatedUserId",
  IMPERSONATED_COMPANY_ID: "companyId",
  IMPERSONATED_SUBSIDIARY_ID: "subsidiaryId",
};

export const getUserSessionKey = (userSessionHash: string) => `user-session:${userSessionHash}`;

export const signIn = async (req: IncomingMessage, user: Pick<User, "id">) => {
  const { hash: sessionHash } = await req.prisma.userSession.create({
    data: {
      hash: nanoid(16),
      userId: user.id,
    },
  });

  await saveSession(req, { sessionHash, userId: user.id });

  return sessionHash;
};

export const devSignIn = async (req: IncomingMessage) => {
  if (!config.app.isLocal || !config.dev.emailOverride) throw new Error("Forbidden");

  const devUser = await req.prisma.user.findUniqueOrThrow({ where: { email: config.dev.emailOverride } });

  await signIn(req, devUser);
};

export const saveSession = async (req: IncomingMessage, params: { sessionHash: string; userId: number }) => {
  req.session.set<string>(SessionKey.USER_ID, `${params.userId}`);
  req.session.set<string>(SessionKey.SESSION_HASH, `${params.sessionHash}`);

  await req.session.save();
};

export const impersonateUser = async (req: IncomingMessage, userId: number) => {
  req.session.set(SessionKey.IMPERSONATED_USER_ID, `${userId}`);

  await req.session.save();
  await trackImpersonateUser(req);
};

export const stopUserImpersonation = async (req: NextApiRequest) => {
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);

  await req.session.save();
  await trackStopImpersonateUser(req);
};

export const impersonateCompany = async (req: IncomingMessage, { companyId }: { companyId: number }) => {
  req.session.set(SessionKey.IMPERSONATED_COMPANY_ID, `${companyId}`);
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);
  req.session.unset(SessionKey.IMPERSONATED_SUBSIDIARY_ID);

  await req.session.save();
  await trackImpersonateCompany(req);
};

export const impersonateSubsidiary = async (req: IncomingMessage, { subsidiaryId }: { subsidiaryId: number }) => {
  req.session.set(SessionKey.IMPERSONATED_SUBSIDIARY_ID, `${subsidiaryId}`);
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);
  req.session.unset(SessionKey.IMPERSONATED_COMPANY_ID);

  await req.session.save();
  await trackImpersonateSubsidiary(req);
};

export const stopCompanyImpersonation = async (req: IncomingMessage) => {
  req.session.unset(SessionKey.IMPERSONATED_USER_ID);
  req.session.unset(SessionKey.IMPERSONATED_COMPANY_ID);
  req.session.unset(SessionKey.IMPERSONATED_SUBSIDIARY_ID);

  await req.session.save();
  await trackStopImpersonateCompany(req);
};

export const signOut = async (req: IncomingMessage) => {
  const userId = req.session.get<string>(SessionKey.USER_ID);
  const userIdInt = parseInt(userId ?? "");
  const sessionHash = req.session.get<string>(SessionKey.SESSION_HASH);

  if (!isNaN(userIdInt) && sessionHash) {
    await req.prisma.userSession.deleteMany({
      where: {
        AND: {
          hash: sessionHash,
          userId: userIdInt,
        },
      },
    });
  }

  if (sessionHash) {
    await req.redis.del(getUserSessionKey(sessionHash));
  }

  req.session.destroy();
};

export const validateSession = async (req: IncomingMessage, userId: string) => {
  const sessionHash = req.session.get<string>(SessionKey.SESSION_HASH);
  if (!sessionHash) {
    await signOut(req);
    return;
  }

  const session = await req.remember(getUserSessionKey(sessionHash), async () => {
    return req.prisma.userSession.findUnique({
      where: {
        hash_userId: {
          hash: sessionHash,
          userId: parseInt(userId),
        },
      },
    });
  });

  if (!session) {
    await signOut(req);
    await trackUserMissingSession(req, parseInt(userId));
  }
};

/*
 * Header passed through XHR requests to ensure the client & server impersonated companies are synchronised.
 * @see https://www.notion.so/figures-hr/Personio-API-keys-issue-b6d5aa1ccf0146969d9c0781b6162ed3
 */
export const ImpersonatedCompanyHeader = "X-Impersonated-Company-ID";

export const ImpersonateCompanyIdParam = "impersonateCompanyId";

export type AuthenticationOptions = {
  optional?: boolean;
  apiKey?: (keyof (typeof config)["app"]["publicApiKey"])[];
  superAdmin?: boolean;
} & Partial<PermissionsStatus>;

export type AuthenticatedUser = AsyncReturnType<typeof fetchRequiredAuthenticatedUser>;

export const populateCompensationReviewMetadata = (params: {
  req?: NextApiRequest;
  ctx?: GetServerSidePropsContext;
}) => {
  if (params.ctx?.resolvedUrl.startsWith("/compensation-review/") && isString(params.ctx?.query?.campaignId)) {
    params.ctx.req.compensationReviewScope = {
      type: CompensationReviewScopeType.CAMPAIGN,
      id: +params.ctx.query.campaignId,
    };

    return;
  }

  if (!params.req?.url?.startsWith("/api/compensation-review")) {
    return;
  }

  // if multipart form data, there is no body so we don't get the scope or campaignId from there but from a query param
  if (
    params.req?.query &&
    params.req?.headers &&
    params.req?.headers["content-type"]?.includes("multipart/form-data")
  ) {
    if ("campaignId" in params.req.query) {
      params.req.compensationReviewScope = {
        type: CompensationReviewScopeType.CAMPAIGN,
        id: parseNumber(params.req.query, "campaignId"),
      };
      return;
    }
  }

  if (params.req.body && "scope" in params.req.body) {
    params.req.compensationReviewScope = params.req.body.scope;
    return;
  }

  if (params.req.body && "campaignId" in params.req.body) {
    params.req.compensationReviewScope = {
      type: CompensationReviewScopeType.CAMPAIGN,
      id: params.req.body.campaignId,
    };
    return;
  }
};

export const initImpersonationContext = (
  ctx: AppContext,
  params: {
    user: {
      target: AuthenticatedUser;
      impersonatedBy: AuthenticatedUser;
    } | null;
    companyId: number | null;
    subsidiaryId: number | null;
  }
) => {
  ctx.impersonation = params;
};

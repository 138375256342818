import { type Route } from "nextjs-routes";
import { ForbiddenError } from "~/lib/errors/forbiddenError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";

export const protectSuperAdminRoutes = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser }>,
  options: { routeRequiresSuperAdmin: boolean }
) => {
  if (!options.routeRequiresSuperAdmin || ctx.user.isSuperAdmin) {
    return next(ctx);
  }

  throw new ForbiddenError("Unauthorised access", {
    pathname: ctx.req.headers.referer ?? "/sign-in",
    query: {
      ...(!ctx.req.headers.referer && { error: "forbidden" }),
    },
  } as Route);
};

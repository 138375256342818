import React, { useCallback, useContext, useState } from "react";
import { type IconName } from "~/components/ui/Icons";

type Alert = {
  id: string;
  message: string | JSX.Element;
  icon: IconName;
  type: "success" | "error";
  timeout: number;
};

type AlertInput = {
  message: string | JSX.Element;
  icon?: IconName;
  type?: "success" | "error";
  timeout?: number;
};

type AlertsProps = {
  alerts: Alert[];
  triggerAlert: (alert: AlertInput) => void;
};

const noop = async () => {
  //
};

const AlertsContext = React.createContext<AlertsProps>({
  alerts: [],
  triggerAlert: noop,
});

export const useAlerts = (): AlertsProps => {
  return useContext(AlertsContext);
};

export const AlertsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const triggerAlert = useCallback(
    (alert: AlertInput) => {
      const newAlert: Alert = {
        id: `${+new Date()}`,
        type: "success",
        icon: "check",
        timeout: 5000,
        ...alert,
      };

      setAlerts([...alerts, newAlert]);

      setTimeout(() => {
        const index = alerts.findIndex((item) => {
          return item.id === newAlert.id;
        });

        const newAlerts = [...alerts];
        newAlerts.splice(index, 1);

        setAlerts(newAlerts);
      }, newAlert.timeout);
    },
    [alerts]
  );

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        triggerAlert,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

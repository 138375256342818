import { AdditionalFieldNature, ExternalEmployeeStatus, Gender, type Prisma } from "@prisma/client";
import { map, mapSeries } from "bluebird";
import { parseISO } from "date-fns";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { compact, get, orderBy } from "~/lib/lodash";
import { and } from "~/lib/prismaHelpers";
import { getKeys } from "~/lib/utils";
import {
  baseEmptyFilters,
  type FilterOptionsInput,
  isDateRangeAdditionalKey,
  isNumberRangeAdditionalKey,
  isPercentageRangeAdditionalKey,
  isStringAdditionalKey,
} from "~/services/employee-filter";
import { buildUniqueSalaryRangeEmployeeFilterWhereClauses } from "~/services/employee-filter/buildUniqueSalaryRangeEmployeeFilterWhereClauses";
import { fetchSalaryRangeEmployeeRangePositionings } from "~/services/employee-filter/fetchSalaryRangeEmployeeRangePositionings";
import { formatGender } from "~/services/external-employee/gender";
import { validatePopulationFilters } from "~/services/populations/validatePopulationFilters";

const fetchCompanyPopulations = async (ctx: AppContext, populationId?: number) => {
  return ctx.prisma.population.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      ...(populationId && { id: { not: populationId } }),
    },
    select: { id: true, name: true },
  });
};

const fetchCompanyExternalEmployeesLocations = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  return ctx.prisma.externalLocation.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      employees: { some: scope },
    },
    select: {
      _count: {
        select: {
          employees: { where: scope },
        },
      },
      id: true,
      name: true,
      country: {
        select: {
          id: true,
          name: true,
          alpha2: true,
        },
      },
    },
  });
};

const fetchCompanyExternalEmployeesLevels = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  return ctx.prisma.externalLevel.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      employees: { some: scope },
    },
    select: {
      _count: {
        select: {
          employees: { where: scope },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesJobs = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  return ctx.prisma.externalJob.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      employees: { some: scope },
    },
    select: {
      _count: {
        select: {
          employees: { where: scope },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesPerformanceReviewRatings = async (
  ctx: AppContext,
  scope: Prisma.ExternalEmployeeWhereInput
) => {
  return ctx.prisma.performanceReviewRating.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      externalEmployees: {
        some: {
          AND: [scope, { status: { not: ExternalEmployeeStatus.SKIPPED } }],
        },
      },
    },
    select: {
      _count: {
        select: {
          externalEmployees: { where: scope },
        },
      },
      id: true,
      name: true,
    },
  });
};

const fetchCompanyExternalEmployeesManagers = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  const managerExternalEmployeeIds = (await ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      managerExternalEmployeeId: { not: null },
      AND: [scope],
    },
    distinct: ["managerExternalEmployeeId"],
    select: {
      managerExternalEmployeeId: true,
    },
  })) as { managerExternalEmployeeId: number }[];

  return ctx.prisma.externalEmployee.findMany({
    where: {
      id: { in: managerExternalEmployeeIds.map((manager) => manager.managerExternalEmployeeId) },
      companyId: getRequiredUser(ctx).companyId,
      managees: { some: scope },
    },
    select: {
      _count: {
        select: {
          managees: { where: scope },
        },
      },
      id: true,
      firstName: true,
      lastName: true,
    },
  });
};

const fetchCompanyExternalEmployeesGenders = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  return mapSeries(Object.values(Gender), async (gender) => {
    const count = await ctx.prisma.externalEmployee.count({
      where: {
        gender,
        companyId: getRequiredUser(ctx).companyId,
        AND: [scope],
      },
    });

    return {
      gender,
      count,
    };
  });
};

const fetchCompanyExternalEmployeeBusinessUnits = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  const externalEmployees = ctx.featureFlags.CAN_ACCESS_BUSINESS_UNITS
    ? ((await ctx.prisma.externalEmployee.findMany({
        where: {
          companyId: getRequiredUser(ctx).companyId,
          businessUnit: { not: null },
          AND: [scope],
        },
        distinct: ["businessUnit"],
        select: {
          businessUnit: true,
        },
      })) as { businessUnit: string }[])
    : [];

  return map(externalEmployees, async ({ businessUnit }) => {
    const count = await ctx.prisma.externalEmployee.count({
      where: {
        companyId: getRequiredUser(ctx).companyId,
        OR: [{ businessUnit }, { extraBusinessUnits: { has: businessUnit } }],
        AND: [scope],
      },
    });

    return {
      id: businessUnit,
      name: businessUnit,
      count,
    };
  });
};

export const fetchCompanyStringAdditionalFields = async (ctx: AppContext, scope: Prisma.ExternalEmployeeWhereInput) => {
  const additionalFields = await ctx.prisma.additionalField.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      nature: AdditionalFieldNature.STRING,
      values: {
        some: { externalEmployee: scope },
      },
    },
    select: {
      values: {
        distinct: ["stringValue"],
        select: {
          id: true,
          stringValue: true,
        },
        orderBy: [{ stringValue: "asc" }],
      },
      id: true,
      name: true,
    },
  });

  return map(additionalFields, async (additionalField) => {
    const rawCount = await ctx.prisma.additionalFieldValue.groupBy({
      by: ["stringValue"],
      where: {
        additionalFieldId: additionalField.id,
        externalEmployee: scope,
      },
      _count: {
        externalEmployeeId: true,
      },
      orderBy: [{ _count: { externalEmployeeId: "desc" } }],
    });

    const count = rawCount.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.stringValue]: curr._count.externalEmployeeId,
      }),
      {}
    ) as Record<string, number>;

    const values = additionalField.values.map((value) => ({
      id: value.stringValue,
      name: value.stringValue,
      count: count[value.stringValue] ?? 0,
    }));

    return {
      ...additionalField,
      values: orderBy(values, ["count", "name"], ["desc", "asc"]),
    };
  });
};

export const fetchCompanyRangeAdditionalFields = async (ctx: AppContext) => {
  return ctx.prisma.additionalField.findMany({
    where: {
      companyId: getRequiredUser(ctx).companyId,
      nature: { in: [AdditionalFieldNature.DATE, AdditionalFieldNature.NUMBER, AdditionalFieldNature.PERCENTAGE] },
      values: {
        some: {},
      },
    },
    select: {
      id: true,
      name: true,
      nature: true,
    },
  });
};

export type FetchExternalEmployeeFilterOptionsParams = {
  scope?: Prisma.ExternalEmployeeWhereInput;
  populationId?: number;
};

export const fetchExternalEmployeeFilterOptions = async (
  ctx: AppContext,
  params?: FetchExternalEmployeeFilterOptionsParams
) => {
  const user = getRequiredUser(ctx);

  const scope = and([params?.scope, { status: { not: ExternalEmployeeStatus.SKIPPED } }]);

  const [
    populations,
    rawLocations,
    rawLevels,
    rawJobs,
    rawGenders,
    additionalFields,
    rangeAdditionalFields,
    businessUnits,
    rawPerformanceReviewRatings,
    rawManagers,
  ] = await Promise.all([
    fetchCompanyPopulations(ctx, params?.populationId),
    fetchCompanyExternalEmployeesLocations(ctx, scope),
    fetchCompanyExternalEmployeesLevels(ctx, scope),
    fetchCompanyExternalEmployeesJobs(ctx, scope),
    fetchCompanyExternalEmployeesGenders(ctx, scope),
    fetchCompanyStringAdditionalFields(ctx, scope),
    fetchCompanyRangeAdditionalFields(ctx),
    fetchCompanyExternalEmployeeBusinessUnits(ctx, scope),
    fetchCompanyExternalEmployeesPerformanceReviewRatings(ctx, scope),
    fetchCompanyExternalEmployeesManagers(ctx, scope),
  ]);

  const rawSalaryRangePositionings = user.company.defaultSalaryGridId
    ? await fetchSalaryRangeEmployeeRangePositionings(ctx, user.company.defaultSalaryGridId, {
        externalEmployee: scope,
      })
    : [];

  const locations = rawLocations.map((loc) => ({ ...loc, count: loc._count.employees }));
  const levels = rawLevels.map((level) => ({ ...level, count: level._count.employees }));
  const jobs = rawJobs.map((job) => ({ ...job, count: job._count.employees }));
  const managers = rawManagers.map((manager) => ({
    ...manager,
    name: `${manager.firstName} ${manager.lastName}`,
    count: manager._count.managees,
  }));
  const performanceReviewRatings = rawPerformanceReviewRatings.map((performanceReview) => ({
    id: performanceReview.id,
    name: performanceReview.name,
    count: performanceReview._count.externalEmployees,
  }));
  const genders = rawGenders.map((gender) => ({
    id: gender.gender,
    name: formatGender(ctx.t, gender.gender),
    count: gender.count,
  }));
  const rangePositionings = rawSalaryRangePositionings.map((positioning) => ({
    id: positioning.id,
    name: positioning.name,
    count: positioning.count,
  }));

  return {
    ...baseEmptyFilters,
    populations,
    locations: orderBy(locations, ["count", "name"], ["desc", "asc"]),
    levels: orderBy(levels, ["count", "name"], ["desc", "asc"]),
    jobs: orderBy(jobs, ["count", "name"], ["desc", "asc"]),
    performanceReviewRatings: orderBy(performanceReviewRatings, ["position", "name"], ["asc", "asc"]),
    managers: orderBy(managers, ["count", "lastName", "firstName"], ["desc", "asc", "asc"]),
    genders: orderBy(genders, ["count", "name"], ["desc", "asc"]),
    businessUnits: orderBy(businessUnits, ["count", "name"], ["desc", "asc"]),
    hireDate: null,
    additionalFields,
    rangeAdditionalFields,
    rangePositionings,
  };
};

export const buildExternalEmployeeFilterWhereClauses = async (
  ctx: AppContext,
  selectedFilterOptions: FilterOptionsInput,
  options?: {
    omitPerformanceReviewRating?: boolean;
    omitLiveSalaryRangeEmployee?: boolean;
  }
): Promise<Prisma.ExternalEmployeeWhereInput | null> => {
  const populations = await ctx.prisma.population.findMany({
    where: { companyId: getRequiredUser(ctx).companyId, id: { in: selectedFilterOptions.populations } },
    select: { query: true, filters: true, includedExternalEmployeeIds: true, excludedExternalEmployeeIds: true },
  });

  const populationWhereClauses = await mapSeries(populations, async (population) => {
    const { where } = await convertPopulationToExternalEmployeeWhereClause(ctx, {
      ...population,
      filters: validatePopulationFilters(population.filters),
    });

    return where;
  });

  const additionalFields = getKeys(selectedFilterOptions)
    .filter(isStringAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("additional-field-", "")),
      additionalFieldValues: selectedFilterOptions[key],
    }));

  const numberAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isNumberRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("number-additional-field-", "")),
      min: selectedFilterOptions[key]?.[0] ? Number(selectedFilterOptions[key]?.[0]) : undefined,
      max: selectedFilterOptions[key]?.[1] ? Number(selectedFilterOptions[key]?.[1]) : undefined,
    }));

  const percentageAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isPercentageRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("percentage-additional-field-", "")),
      min: selectedFilterOptions[key]?.[0] ? Number(selectedFilterOptions[key]?.[0]) : undefined,
      max: selectedFilterOptions[key]?.[1] ? Number(selectedFilterOptions[key]?.[1]) : undefined,
    }));

  const dateAdditionalFields = getKeys(selectedFilterOptions)
    .filter(isDateRangeAdditionalKey)
    .map((key) => ({
      additionalFieldId: Number(key.replace("date-additional-field-", "")),
      min: (selectedFilterOptions[key]?.[0] as Date) ?? undefined,
      max: (selectedFilterOptions[key]?.[1] as Date) ?? undefined,
    }));

  const additionalFieldConditions = compact([
    ...additionalFields.map(
      ({ additionalFieldId, additionalFieldValues }) =>
        additionalFieldValues?.length && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              stringValue: { in: additionalFieldValues },
            },
          },
        }
    ),
    ...numberAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              numberValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
    ...percentageAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              percentageValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
    ...dateAdditionalFields.map(
      ({ additionalFieldId, min, max }) =>
        (min || max) && {
          additionalFieldValues: {
            some: {
              additionalFieldId,
              dateValue: {
                ...(!!min && { gte: min }),
                ...(!!max && { lte: max }),
              },
            },
          },
        }
    ),
  ]) satisfies Prisma.ExternalEmployeeWhereInput[];

  const salaryRangeEmployeeWhere = !options?.omitLiveSalaryRangeEmployee
    ? buildUniqueSalaryRangeEmployeeFilterWhereClauses(selectedFilterOptions)
    : [];

  const baseWhereClauses = compact([
    !!selectedFilterOptions?.locations?.length && {
      locationId: { in: selectedFilterOptions.locations },
    },
    !!selectedFilterOptions?.jobs?.length && {
      jobId: { in: selectedFilterOptions.jobs },
    },
    !!selectedFilterOptions?.levels?.length && {
      levelId: { in: selectedFilterOptions.levels },
    },
    !options?.omitPerformanceReviewRating &&
      !!selectedFilterOptions?.performanceReviewRatings?.length && {
        performanceReviewRatingId: { in: selectedFilterOptions.performanceReviewRatings },
      },
    !!selectedFilterOptions?.managers?.length && {
      managerExternalEmployeeId: { in: selectedFilterOptions.managers },
    },
    !!selectedFilterOptions?.businessUnits?.length && {
      OR: [
        { businessUnit: { in: selectedFilterOptions.businessUnits } },
        { extraBusinessUnits: { hasSome: selectedFilterOptions.businessUnits } },
      ],
    },
    !!selectedFilterOptions.genders?.length && {
      gender: {
        in: compact(selectedFilterOptions.genders.map((rawGender) => get(Gender, rawGender))),
      },
    },
    selectedFilterOptions.hireDate?.length && {
      hireDate: {
        ...(selectedFilterOptions.hireDate?.[0] && {
          gte: parseISO(selectedFilterOptions.hireDate[0]),
        }),
        ...(selectedFilterOptions.hireDate?.[1] && {
          lte: parseISO(selectedFilterOptions.hireDate[1]),
        }),
      },
    },
    salaryRangeEmployeeWhere.length && {
      liveSalaryRangeEmployee: {
        AND: salaryRangeEmployeeWhere,
      },
    },
    ...additionalFieldConditions,
  ]);

  if (baseWhereClauses.length === 0 && populationWhereClauses.length === 0) {
    return null;
  }

  return and([
    baseWhereClauses.length && { AND: baseWhereClauses },
    populationWhereClauses && { OR: populationWhereClauses },
  ]);
};

export const convertPopulationToExternalEmployeeWhereClause = async (
  ctx: AppContext,
  population: {
    query: string | null;
    filters: FilterOptionsInput | null;
    includedExternalEmployeeIds: number[];
    excludedExternalEmployeeIds: number[];
  }
) => {
  const baseWhere = and([
    { companyId: getRequiredUser(ctx).companyId },
    population.query && ({ normalisedColumns: { contains: population.query, mode: "insensitive" } } as const),
  ]) satisfies Prisma.ExternalEmployeeWhereInput;

  const filterWhere = population.filters
    ? await buildExternalEmployeeFilterWhereClauses(ctx, population.filters)
    : undefined;

  const where = and([
    { OR: [and([baseWhere, filterWhere]), { id: { in: population.includedExternalEmployeeIds } }] },
    { NOT: { id: { in: population.excludedExternalEmployeeIds } } },
  ]);

  return { baseWhere, filterWhere, where };
};

import { randomUUID } from "crypto";
import { getClientIp } from "request-ip";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";

export const assignRequestId = async (ctx: MiddlewareContext) => {
  if (!ctx.req.headers["x-request-id"]) {
    ctx.req.headers["x-request-id"] = randomUUID();
  }

  ctx.req.ip = getClientIp(ctx.req);

  return next(ctx);
};

import {
  type Currency,
  type ExternalEmployeeSpreadsheetImport,
  Gender,
  SpreadsheetImportMode,
  SpreadsheetImportRowStatus,
} from "@prisma/client";
import { mixed, number, object, string } from "yup";
import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { parseExcelDate } from "~/lib/dates";
import { XlsxImportError } from "~/lib/errors/xlsxImportError";
import { getStaticModelsForSync } from "~/lib/integration";
import { chain, chunk, isBoolean, isNil, isString } from "~/lib/lodash";
import { logError, logInfo } from "~/lib/logger";
import { parseMoney } from "~/lib/money";
import { assertNotNil, getId } from "~/lib/utils";
import { findImportRows } from "~/services/external-employee/import/findImportRows";
import { initialImportColumnsMap } from "~/services/initialImportColumnsMap";
import {
  handleDeletedEmployees,
  integrationSettingsForSyncInclude,
} from "~/services/synchronization/syncExternalEmployees";
import { validateRowsWithSchema } from "~/services/xlsxToJson";
import { IMPORT_SPREADSHEET_ROW_BATCH_SIZE, sendImportSpreadsheetRowJob } from "~/workers/import/importSpreadsheetRow";

type ImportInitialInput = {
  companyId: number;
  collectedAt: Date;
  import: ExternalEmployeeSpreadsheetImport;
};

export const importInitialExternalEmployeesSchema = (currencies: Pick<Currency, "code">[]) => {
  return object({
    employeeNumber: string()
      .required()
      .transform((value) => {
        return `${value}`;
      }),
    firstName: string(),
    lastName: string(),
    email: string(),
    jobTitle: string()
      .required()
      .transform((value) => {
        return value.trim();
      }),
    level: string().required(),
    currency: string()
      .required()
      .oneOf(
        currencies.map((currency) => {
          return currency.code;
        })
      )
      .transform((value) => {
        return value.substr(0, 3);
      }),
    fteDivider: number()
      .nullable()
      .min(0)
      .max(1)
      .transform((value) => {
        if (!value) {
          return null;
        }

        const parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
          return null;
        }

        return parsedValue;
      }),
    baseSalary: mixed<number>()
      .required()
      .transform(parseMoney)
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => !!value && value >= 0,
      }),
    onTargetBonus: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }
        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    fixedBonus: mixed<number>()
      .transform((value) => {
        if (value === "") {
          return null;
        }
        return parseMoney(value);
      })
      .test({
        name: "positive",
        message: { key: "common.errors.number.positive" },
        test: (value) => (isNil(value) ? true : value >= 0),
      }),
    isFounder: mixed<boolean>().transform((value) => {
      if (isBoolean(value)) {
        return value;
      } else if (isString(value)) {
        return value.toUpperCase() === "YES";
      }
    }),
    manager: string().transform((value) => {
      return `${value}`;
    }),
    location: string().required(),
    performanceRating: string(),
    hireDate: mixed<Date>().nullable().transform(parseExcelDate),
    birthDate: mixed<Date>().nullable().transform(parseExcelDate),
    businessUnit: string(),
    gender: mixed<Gender>()
      .required()
      .oneOf([Gender.MALE, Gender.FEMALE, Gender.UNDISCLOSED])
      .transform((value) => {
        if (value === "Non-binary / Not disclosed") {
          return Gender.UNDISCLOSED;
        }

        if (value.toLowerCase() === "male") {
          return Gender.MALE;
        }

        if (value.toLowerCase() === "female") {
          return Gender.FEMALE;
        }

        return value.toUpperCase();
      }),
  });
};

export const startImportInitial = async (ctx: AppContext, params: ImportInitialInput): Promise<void> => {
  const { companyId } = params;

  const company = await ctx.prisma.company.findUniqueOrThrow({
    where: { id: companyId },
    include: {
      integrationSettings: {
        include: integrationSettingsForSyncInclude,
      },
      defaultCountry: true,
    },
  });

  const staticModels = await getStaticModelsForSync(ctx);

  const rows = await findImportRows(ctx, {
    importId: params.import.id,
    statuses: [SpreadsheetImportRowStatus.INIT],
  });

  logInfo(ctx, `[spreadsheet-import][initial] Starting an import of ${rows.length} rows`, {
    companyId,
    importId: params.import.id,
  });

  const validRows = validateRowsWithSchema(ctx, {
    entityRows: rows,
    columnMap: initialImportColumnsMap(ctx),
    schema: importInitialExternalEmployeesSchema(staticModels.currencies),
  });

  // (flag) eligible for pub/sub
  const survey = await value(() => {
    if (company.liveSurveyId) {
      return ctx.prisma.companySurvey.update({
        where: { id: company.liveSurveyId },
        data: {
          collectedAt: params.collectedAt,
        },
      });
    } else {
      return ctx.prisma.companySurvey.create({
        data: {
          status: "LIVE",
          collectedAt: params.collectedAt,
          company: {
            connect: { id: company.id },
          },
        },
      });
    }
  });

  // (flag) eligible for pub/sub
  await ctx.prisma.company.update({
    where: { id: company.id },
    data: {
      lastSpreadsheetImportedAt: new Date(),
      liveSurvey: {
        connect: { id: survey.id },
      },
    },
  });

  const employeeNumbers = validRows.map((row) => {
    return row.employeeNumber;
  });

  // We shouldn't import the XLSX if there are any duplicate employee numbers
  const duplicateEmployeeNumbers = chain(employeeNumbers)
    .groupBy()
    .pickBy((occurrences) => occurrences.length > 1)
    .keys()
    .value();

  if (duplicateEmployeeNumbers.length) {
    logError(
      ctx,
      `[spreadsheet-import][initial] Aborted import : the following employee numbers have duplicates (${duplicateEmployeeNumbers.join(
        ","
      )})`,
      {
        companyId,
        importId: params.import.id,
      }
    );

    throw new XlsxImportError(`Aborted import : duplicates employee numbers)`);
  }

  // Handle employees that may have been deleted since the last import
  if (params.import.importMode === SpreadsheetImportMode.ERASE) {
    const deletedEmployeesNumber = await handleDeletedEmployees(ctx, {
      companyId,
      employeeNumbers,
      ignoreSkipped: true,
    });

    logInfo(
      ctx,
      `[spreadsheet-import][initial] Deleted ${deletedEmployeesNumber} employees that were not in the spreadsheet`,
      {
        companyId,
        importId: params.import.id,
      }
    );
  }

  const firstRows = assertNotNil(chunk(validRows, IMPORT_SPREADSHEET_ROW_BATCH_SIZE)[0]);
  await sendImportSpreadsheetRowJob(ctx, {
    companyId: company.id,
    importId: params.import.id,
    rowIds: firstRows.map(getId),
  });
};

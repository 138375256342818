import { type UserLocale } from "@prisma/client";
import { type JsonObject, type JsonValue } from "type-fest";

export const getTranslations = (translations: JsonValue | null, fallback: string, locale: UserLocale): string => {
  if (!translations || typeof translations !== "object") return fallback;

  const castedTranslations = translations as JsonObject;

  return (castedTranslations[locale] as string) || fallback;
};

import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import {
  BaseConfigurationChangeSchema,
  SalaryBandBenchmarkedLocationsStateSchema,
} from "~/services/salary-bands/audit-logs/schemas";

export const UpdateBenchmarkedLocationsInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.UPDATE_BENCHMARKED_LOCATIONS>().required(),
    bandId: number().required(),
    initialState: SalaryBandBenchmarkedLocationsStateSchema.required(),
    newState: SalaryBandBenchmarkedLocationsStateSchema.required(),
  })
);

export type UpdateBenchmarkedLocationsChange = YupOutputType<typeof UpdateBenchmarkedLocationsInputSchema>;

import { type NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { value } from "~/components/helpers";
import { logError } from "~/lib/logger";

interface Props {
  statusCode?: number;
  message?: string;
}

const Page: NextPage<Props> = ({ statusCode, message }) => {
  const router = useRouter();

  useEffect(() => {
    const code = statusCode?.toString() ?? "500";

    void router.push({ pathname: `/error/500`, query: { statusCode: code, message: code === "500" ? "" : message } });
  }, []);

  return <></>;
};

Page.getInitialProps = async ({ req, res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const message = value(() => {
    if (statusCode === 500) {
      return null;
    }

    if (res) {
      return res.statusMessage;
    }

    if (err) {
      return err.message;
    }

    return "Unknown error";
  });

  if (req) {
    logError(req, "[ssr] rendering error", { error: err });
  }

  return { statusCode, ...(message && { message }) };
};

export default Page;

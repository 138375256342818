import { type PrismaClient } from "@prisma/client";
import { readFileSync } from "fs";
import { join } from "path";
import { match } from "ts-pattern";
import { config } from "~/config";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PrismaPayload = Record<string, any>;
export type PrismaDelegateFunction = (args?: unknown | undefined) => unknown;
export type PrismaModel = Extract<keyof PrismaClient, string>;

export const ProtectedPrismaMethods = [
  "findFirst",
  "findFirstOrThrow",
  "findMany",
  "deleteMany",
  "updateMany",
  "count",
  "aggregate",
  "groupBy",
  "create",
  "createMany",
] as const;

export const ProtectedPrismaUniqueMethods = ["findUnique", "findUniqueOrThrow", "update", "delete", "upsert"] as const;

export const PrismaMethods = [...ProtectedPrismaMethods, ...ProtectedPrismaUniqueMethods] as const;

export type PrismaMethod = (typeof PrismaMethods)[number];

export const prismaSchemaDef = (() => {
  if (typeof window !== "undefined") {
    return null;
  }

  const jsonDirectory = match(config.app.isCli)
    .with(true, () => join(__dirname, "../../prisma/json-schema/json-schema.json"))
    .otherwise(() => join(process.cwd(), "./prisma/json-schema/json-schema.json"));

  const prismaSchemaContent = readFileSync(jsonDirectory, "utf8");
  return JSON.parse(prismaSchemaContent);
})();

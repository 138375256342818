import { Product, type Subscription } from "@prisma/client";
import { match } from "ts-pattern";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";

export enum ProductPermission {
  CAN_ACCESS_BENCHMARK = "CAN_ACCESS_BENCHMARK",
  CAN_ACCESS_COMPENSATION_REVIEW = "CAN_ACCESS_COMPENSATION_REVIEW",
  CAN_ACCESS_SALARY_BANDS = "CAN_ACCESS_SALARY_BANDS",
}

export type SubscriptionStatusWithPermissions = Record<ProductPermission, boolean>;

export const getActiveProductsForUser = async (ctx: AppContext) => {
  if (ctx.user) {
    return computeSubscriptionsWithCompany(ctx);
  }

  return {
    [ProductPermission.CAN_ACCESS_BENCHMARK]: true,
    [ProductPermission.CAN_ACCESS_SALARY_BANDS]: false,
    [ProductPermission.CAN_ACCESS_COMPENSATION_REVIEW]: false,
  };
};

const hasValidSubscription = (subscriptions: Subscription[], product: Product) => {
  return subscriptions.some((subscription) => subscription.product === product);
};

export const computeSubscriptionsWithCompany = async (ctx: AppContext) => {
  const allCompanySubscriptions = await getValidProductsForUser(ctx);

  const subscriptionStatus: SubscriptionStatusWithPermissions = {
    [ProductPermission.CAN_ACCESS_BENCHMARK]: false,
    [ProductPermission.CAN_ACCESS_COMPENSATION_REVIEW]: false,
    [ProductPermission.CAN_ACCESS_SALARY_BANDS]: false,
  };

  Object.values(Product).forEach((product) => {
    match(product)
      .with(Product.BENCHMARK, () => {
        subscriptionStatus[ProductPermission.CAN_ACCESS_BENCHMARK] = hasValidSubscription(
          allCompanySubscriptions,
          Product.BENCHMARK
        );
      })
      .with(Product.COMPENSATION_REVIEW, () => {
        subscriptionStatus[ProductPermission.CAN_ACCESS_COMPENSATION_REVIEW] = hasValidSubscription(
          allCompanySubscriptions,
          Product.COMPENSATION_REVIEW
        );
      })
      .with(Product.SALARY_BANDS, () => {
        subscriptionStatus[ProductPermission.CAN_ACCESS_SALARY_BANDS] = hasValidSubscription(
          allCompanySubscriptions,
          Product.SALARY_BANDS
        );
      })
      .exhaustive();
  });

  return subscriptionStatus;
};

export const getValidProductsForUser = async (ctx: AppContext) => {
  const authenticatedUser = getRequiredUser(ctx);

  return ctx.prisma.subscription.findMany({
    where: {
      companyId: authenticatedUser.companyId,
      startsAt: { lte: new Date() },
      OR: [{ endsAt: { gte: new Date() } }, { endsAt: null }],
    },
  });
};

import { type Prisma } from "@prisma/client";

export const whereSalaryRangeIs = (params: { salaryGridId: number; salaryBandId: number; salaryRangeId: number }) => {
  return {
    id: params.salaryRangeId,
    band: whereSalaryBandIs(params),
  } satisfies Prisma.SalaryRangeWhereInput;
};

export const whereSalaryBandIs = (params: { salaryGridId: number; salaryBandId: number }) => {
  return {
    id: params.salaryBandId,
    grid: whereSalaryGridIs(params),
  } satisfies Prisma.SalaryBandWhereInput;
};

export const whereSalaryGridIs = (params: { salaryGridId: number }) => {
  return {
    id: params.salaryGridId,
  } satisfies Prisma.SalaryGridWhereInput;
};

import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import type { AuthenticationError } from "~/lib/errors/authenticationError";
import { logWarn } from "~/lib/logger";

export class ApiFeatureAccessError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, ApiFeatureAccessError.prototype);
  }
}

export const handleFeatureAccessError = (ctx: AppContext, res: NextApiResponse, error: AuthenticationError) => {
  logWarn(ctx, "[error] Invalid feature access", { error });

  return res.status(HttpStatus.FORBIDDEN).json({ error: error.message });
};

import { type Prisma } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { canAccessAdditionalField } from "~/services/user/permissions/functions";

export const getAllAdditionalFields = async (
  ctx: AppContext,
  params?: { integrationSettingsId?: number; ids?: number[] }
) => {
  const user = getRequiredUser(ctx);

  const additionalFields = await ctx.prisma.additionalField.findMany({
    where: {
      companyId: user.companyId,
      ...(params?.ids && { id: { in: params.ids } }),
    },
    select: {
      id: true,
      name: true,
      nature: true,
      companyId: true,
    },
    orderBy: {
      name: "asc",
    },
  });

  return additionalFields.filter((field) => {
    return canAccessAdditionalField(ctx, { additionalFieldId: field.id });
  });
};

export type GetAllAdditionalFieldsResult = AsyncReturnType<typeof getAllAdditionalFields>;
export type SimpleAdditionalField = GetAllAdditionalFieldsResult[number];

export const externalEmployeeFieldsSelectForDisplay = {
  fteDivider: true,
  gender: true,
  hireDate: true,
  performanceReviewRating: {
    select: {
      name: true,
      description: true,
    },
  },
  businessUnit: true,
  additionalFieldValues: {
    select: {
      stringValue: true,
      numberValue: true,
      dateValue: true,
      percentageValue: true,
      additionalFieldId: true,
    },
  },
  managerExternalEmployeeId: true,
  manager: {
    select: {
      id: true,
      firstName: true,
      lastName: true,
      employeeNumber: true,
      externalId: true,
    },
  },
} satisfies Prisma.ExternalEmployeeSelect;

import { type IntegrationSettings, type IntegrationSource } from "@prisma/client";
import { randomUUID } from "crypto";
import { createWriteStream } from "fs";
import { type Response } from "node-fetch";
import { tmpdir } from "os";
import { join } from "path";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { logInfo } from "~/lib/logger";

export const getEmployeeProfilePicture = async (
  ctx: AppContext,
  options: {
    apiEmployeeId: string;
    source: IntegrationSource;
    fetch: () => Promise<Response>;
    integrationSettings: IntegrationSettings;
  }
): Promise<string | undefined> => {
  if (options.integrationSettings.anonymous || config.dev.ignoreIntegrationProfilePics) {
    return undefined;
  }

  const path = join(tmpdir(), `${options.source}-image-${options.apiEmployeeId}-${randomUUID()}.png`);

  try {
    const res = await options.fetch();

    return new Promise((resolve, reject) => {
      const fileStream = createWriteStream(path);

      res.body.pipe(fileStream);

      res.body.on("error", (err) => {
        reject(err);
      });

      fileStream.on("finish", function () {
        resolve(path);
      });
    });
  } catch (error) {
    logInfo(ctx, `[${options.source}] There was an error when fetching an employee's profile picture.`, {
      apiEmployeeId: options.apiEmployeeId,
      source: options.source,
      error,
    });
  }
};

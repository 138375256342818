import { type Prisma, type UserLocale } from "@prisma/client";
import { type JsonValue } from "type-fest";
import { flattenObject } from "~/lib/flattenObject";
import { getTranslations } from "~/lib/i18n/getTranslations";
import { isArray, isObject, isPlainObject, map, mapValues, set } from "~/lib/lodash";
import { type PrismaMethod, type PrismaPayload, prismaSchemaDef } from "~/lib/prismaTypes";

const TRANSLATED_MODELS: Prisma.ModelName[] = [
  "Job",
  "JobFamily",
  "Country",
  "Currency",
  "EmployeeLocation",
  "CompanyTag",
];

const translatedMethods: PrismaMethod[] = [
  "findUnique",
  "findUniqueOrThrow",
  "findFirst",
  "findFirstOrThrow",
  "findMany",
  "create",
  "update",
  "upsert",
];

const hasTranslationField = (path: string, modelName: Prisma.ModelName): boolean => {
  if (!path && TRANSLATED_MODELS.includes(modelName)) return true;

  const properties = prismaSchemaDef.definitions[modelName].properties;
  const propertyList = path.split(".");

  if (propertyList[0] === "name") return !!properties["translations"];

  const children = propertyList[0];
  if (!children) return false;

  const childrenProperty = properties[children];
  if (!childrenProperty) return false;

  const correspondingDependencyModel = getModelNameFromProperty(childrenProperty);
  if (!correspondingDependencyModel) return false;

  return hasTranslationField(propertyList.slice(1).join("."), correspondingDependencyModel);
};

type PrismaProperty = {
  $ref?: string;
  items?: {
    $ref?: string;
  };
  anyOf?: Record<string, string>[];
};

const getModelNameFromProperty = (property: PrismaProperty) => {
  const anyOfItem = property.anyOf?.find((item: Record<string, string>) => !!item?.$ref);
  const reference = property?.$ref || property.items?.$ref || anyOfItem?.$ref;

  if (!reference) return null;

  return reference.replace("#/definitions/", "") as keyof typeof Prisma.ModelName;
};

export const addTranslationsToArgs = (args: PrismaPayload, modelName: Prisma.ModelName, method: PrismaMethod) => {
  if (!translatedMethods.includes(method)) return;
  if (!args.select) return;

  const flattenSelect = Object.keys(flattenObject(args.select)).map((key) => key.replaceAll("select.", ""));
  const filteredSelectWithTranslation = flattenSelect.filter(
    (path) => hasTranslationField(path, modelName) && path.split(".").length > 1 && path.endsWith("name")
  );

  if (TRANSLATED_MODELS.includes(modelName)) {
    set(args.select, "translations", true);
  }

  if (filteredSelectWithTranslation.length === 0) return;

  const pathToEdit = filteredSelectWithTranslation.map((path) =>
    path.substring(0, path.length - 5).replaceAll(".", ".select.")
  );

  pathToEdit.forEach((path) => {
    set(args.select, `${path}.select.translations`, true);
  });
};

type TranslatedModel<NameField extends string, TranslationField extends string> = {
  [key in NameField]: string;
} & {
  [key in TranslationField]: JsonValue;
};

export const translatePrismaResponse = (res: unknown, method: PrismaMethod, locale: UserLocale) => {
  if (!translatedMethods.includes(method)) return res;

  return mapValuesDeep(res, (value, path, object) => {
    if (path === "name" && isTranslatable(object, "name", "translations")) {
      return getTranslations(object.translations, object.name, locale);
    }

    if (path === "description" && isTranslatable(object, "description", "descriptionTranslations")) {
      return getTranslations(object.descriptionTranslations, object.description, locale);
    }

    return value;
  });
};

const isTranslatable = <NameField extends string, TranslationField extends string>(
  object: unknown,
  nameField: NameField,
  translationField: TranslationField
): object is TranslatedModel<NameField, TranslationField> => {
  return isObject(object) && nameField in object && translationField in object;
};

const mapValuesDeep = (
  object: unknown,
  fn: (value: unknown, key: unknown, baseObject: unknown) => unknown,
  key?: unknown,
  baseObject = object
): unknown => {
  if (isArray(object)) {
    return map(object, (innerObject, index) => mapValuesDeep(innerObject, fn, index));
  }

  if (isPlainObject(object)) {
    return mapValues(object as object, (value, key) => mapValuesDeep(value, fn, key, object));
  }

  if (isObject(object)) {
    return object;
  }

  return fn(object, key, baseObject);
};

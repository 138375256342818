import makeContext from "~/lib/context/makeContext";

declare const globalThis: {
  context: ReturnType<typeof makeContext>;
} & typeof global;

export const getServerContext = () => {
  const context = globalThis.context ?? makeContext({ forApp: true });

  globalThis.context = context;

  return context;
};

import { LinearProgress, linearProgressClasses, styled } from "@mui/material";
import React, { type ComponentProps } from "react";

type Props = {
  className?: string;
  BarProps?: ComponentProps<typeof LinearProgress>;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: theme.borderRadius.xl,
  ["&"]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.borderRadius.xl,
  },
}));

export const ProgressBar: React.FC<Props> = ({ className, BarProps }) => {
  return (
    <div className={className}>
      <BorderLinearProgress color={BarProps?.value === 100 ? "primary" : "secondary"} {...BarProps} />
    </div>
  );
};

import type { AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { logInfo, logWarn } from "~/lib/logger";
import { getId } from "~/lib/utils";
import { type findImportRows } from "~/services/external-employee/import/findImportRows";
import { parsePartialExternalEmployees } from "~/services/external-employee/import/process/partial/parsePartialExternalEmployees";
import { persistPartialExternalEmployees } from "~/services/external-employee/import/process/partial/persistPartialExternalEmployees";

type Params = {
  rows: AsyncReturnType<typeof findImportRows>;
  companyId: number;
  importId: number;
};

export const importPartialRows = async (ctx: AppContext, params: Params) => {
  logInfo(ctx, `[spreadsheet-import][partial] Importing ${params.rows.length} rows`, {
    companyId: params.companyId,
    importId: params.importId,
    rowIds: params.rows.map(getId).join(","),
  });

  const { rows, warnings } = await parsePartialExternalEmployees(ctx, { rows: params.rows });

  await persistPartialExternalEmployees(ctx, {
    spreadsheetRows: rows,
  });

  const warningRowIds = warnings.unknownEmployeesRows.map(getId);

  logWarn(ctx, `[spreadsheet-import][partial] Warnings occured while processing rows ${warningRowIds.join(",")}`, {
    companyId: params.companyId,
    importId: params.importId,
    rowIds: warningRowIds,
  });

  return { rowWarnings: warningRowIds.map((rowId) => ({ rowId, warning: "unknown employee" })) };
};

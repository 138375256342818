import { value } from "~/components/helpers";
import { config } from "~/config";

// Differentiate between preview & production for Datadog env.
export const datadogEnv = value(() => {
  if (config.app.isLocal) {
    return "development";
  }

  if (config.app.isPreview) {
    return "preview";
  }

  if (config.app.isStaging) {
    return "staging";
  }

  return "production";
});

import { useRouter } from "next/router";
import type React from "react";
import { useEffect } from "react";
import { trackPageLoaded } from "~/lib/external/segment/web/events";

export const PageViewTracker: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    let previousRoute = router.route as string;

    const onRouteChangeComplete = (newRoute: string) => {
      const previousPath = previousRoute.split("?")[0];
      const newPath = newRoute.split("?")[0];

      if (previousPath !== newPath) {
        void trackPageLoaded({ path: router.route });
      }

      previousRoute = newRoute;
    };

    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    void trackPageLoaded({ path: router.route });
  }, []);

  return null;
};

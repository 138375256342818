import commandScore from "command-score";
import { max } from "~/lib/lodash";
import { stripDiacritics } from "~/lib/utils";

export const filterCommandPalette = (search: string, keywords?: string[]) => {
  if (!search) return 1;
  if (!keywords) return 0;

  const diacriticFreeSearch = stripDiacritics(search);
  const scores = keywords.map((keyword) => commandScore(stripDiacritics(keyword), diacriticFreeSearch));

  return max(scores) ?? 0;
};

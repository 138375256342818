import { createContext, useContext } from "react";

const PortalRootContext = createContext("#app");

export const usePortalRoot = (): string => {
  return useContext(PortalRootContext);
};

type PortalRootContextProps = React.PropsWithChildren<{
  selector: string;
}>;

export const PortalRootProvider: React.FC<PortalRootContextProps> = ({ selector, children }) => {
  return <PortalRootContext.Provider value={selector}>{children}</PortalRootContext.Provider>;
};

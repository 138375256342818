import { ApiFeatureAccessError } from "~/lib/errors/apiFeatureAccessError";
import { type MiddlewareContext, next } from "~/lib/middleware/middleware";
import { type NullableAuthenticatedUser, type RequiredAuthenticatedUser } from "~/services/auth/fetchAuthenticatedUser";
import { type FeatureFlagName } from "~/services/featureFlags";

export const protectRouteWithFeatureFlag = async (
  ctx: MiddlewareContext<{ user: RequiredAuthenticatedUser; impersonatingUser: NullableAuthenticatedUser }>,
  options: { feature?: FeatureFlagName }
) => {
  if (!options.feature || ctx.req.featureFlags[options.feature]) {
    return next(ctx);
  }

  throw new ApiFeatureAccessError(`User cannot access the feature "${options.feature}"`);
};

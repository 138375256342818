import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, JobStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const DeleteJobInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.DELETE_JOB>().required(),
    initialState: JobStateSchema.required(),
  })
);

export type DeleteJobChange = YupOutputType<typeof DeleteJobInputSchema>;

import { type SalaryRangeEmployeeRangePositioning } from "@prisma/client";
import { type ParsedUrlQuery } from "querystring";
import { array, boolean, mixed, number, object, string } from "yup";
import { DateFormats } from "~/lib/dates";
import { compact } from "~/lib/lodash";
import { parseArray, parseBooleanArray, parseDateArray, parseNumericArray } from "~/lib/queryParams";
import { type YupOutputType, getKeys } from "~/lib/utils";
import { type FetchCompensationReviewEmployeeFilterOptionsResult } from "~/services/employee-filter/fetchCompensationReviewEmployeeFilterOptions";
import { type FetchSalaryRangeEmployeeFilterOptionsResult } from "~/services/employee-filter/fetchSalaryRangeEmployeeFilterOptions";

export const baseEmptyFilters = {
  populations: [],
  jobs: [],
  locations: [],
  levels: [],
  performanceReviewRatings: [],
  managers: [],
  businessUnits: [],
  genders: [],
  hireDate: [],
  isPromoted: [],
  reviewers: [],
  compaRatio: [],
  rangePositionings: [],
};

export type FetchEmployeeFilterOptionsResult =
  | FetchCompensationReviewEmployeeFilterOptionsResult
  | FetchSalaryRangeEmployeeFilterOptionsResult;

export const FilterOptionsSchema = object({
  populations: array(number().required()),
  jobs: array(number().required()),
  locations: array(number().required()),
  levels: array(number().required()),
  performanceReviewRatings: array(number().required()),
  managers: array(number().required()),
  businessUnits: array(string().required()),
  genders: array(string().required()),
  hireDate: array(string()),
  isPromoted: array(boolean()),
  reviewers: array(number().required()),
  compaRatio: array(number()),
  rangePositionings: array(mixed<SalaryRangeEmployeeRangePositioning>().required()),
}).nullable();

export type FilterOptionsInput = YupOutputType<typeof FilterOptionsSchema> & {
  [key: `additional-field-${number}`]: string[];
  [key: `date-additional-field-${number}`]: [Date, Date];
  [key: `number-additional-field-${number}`]: [number, number];
  [key: `percentage-additional-field-${number}`]: [number, number];
};

export type SelectedFilterOptions = {
  // External employee filters
  populations: number[];
  locations: number[];
  jobs: number[];
  levels: number[];
  managers: number[];
  businessUnits: string[];
  genders: string[];
  hireDate: [string, string];
  [key: `additional-field-${number}`]: string[];
  [key: `date-additional-field-${number}`]: [Date, Date];
  [key: `number-additional-field-${number}`]: [number, number];
  [key: `percentage-additional-field-${number}`]: [number, number];

  // Compensation review employee filters
  isPromoted: boolean[];
  reviewers: number[];

  // Salary range employee filters
  compaRatio: [number, number];
  rangePositionings: SalaryRangeEmployeeRangePositioning[];

  // Shared filters
  performanceReviewRatings: number[];
};

export const isStringAdditionalKey = (key: string): key is `additional-field-${number}` =>
  key.startsWith("additional-field-");

export const isNumberRangeAdditionalKey = (key: string): key is `number-additional-field-${number}` =>
  key.startsWith("number-additional-field-");

export const isPercentageRangeAdditionalKey = (key: string): key is `percentage-additional-field-${number}` =>
  key.startsWith("percentage-additional-field-");

export const isDateRangeAdditionalKey = (key: string): key is `date-additional-field-${number}` =>
  key.startsWith("date-additional-field-");

export const buildFiltersQueryParams = (
  filters: FilterOptionsInput,
  params: {
    formatDate: (date: Date | string, format: string) => string;
  }
) => {
  const additionalFieldKeys = getKeys(filters).filter(isStringAdditionalKey);
  const numberRangeAdditionalFieldKeys = getKeys(filters)
    .filter(isNumberRangeAdditionalKey)
    .filter((fieldId) => compact(filters[fieldId]).length);
  const percentageRangeAdditionalFieldKeys = getKeys(filters)
    .filter(isPercentageRangeAdditionalKey)
    .filter((fieldId) => compact(filters[fieldId]).length);
  const dateRangeAdditionalFieldKeys = getKeys(filters)
    .filter(isDateRangeAdditionalKey)
    .filter((fieldId) => compact(filters[fieldId]).length);

  return {
    // ExternalEmployee filters
    "populations": filters.populations?.join(","),
    "locations": filters.locations?.join(","),
    "jobs": filters.jobs?.join(","),
    "levels": filters.levels?.join(","),
    "managers": filters.managers?.join(","),
    "genders": filters.genders?.join(","),
    "business-units": filters.businessUnits?.join(","),
    "hire-date": filters.hireDate
      ?.map((date) => (date ? params.formatDate(date, DateFormats.DATE_PICKER) : ""))
      .join(","),
    ...additionalFieldKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: filters[key]?.join(","),
      }),
      {}
    ),
    ...numberRangeAdditionalFieldKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: filters[key]?.map((value) => value ?? "").join(","),
      }),
      {}
    ),
    ...percentageRangeAdditionalFieldKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: filters[key]?.map((value) => value ?? "").join(","),
      }),
      {}
    ),
    ...dateRangeAdditionalFieldKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: filters[key]?.map((value) => (value ? value.getTime() : "")).join(","),
      }),
      {}
    ),

    // Salary range employee filters
    "compa-ratio": filters.compaRatio?.map((compaRatio) => compaRatio).join(","),
    "range-positionings": filters.rangePositionings?.map((rangePositioning) => rangePositioning).join(","),

    // Compensation review employee filters
    "is-promoted": filters.isPromoted?.map((isPromoted) => `${isPromoted}`).join(","),
    "reviewers": filters.reviewers?.join(","),

    // Shared filters
    "performance-ratings": filters.performanceReviewRatings?.join(","),
  };
};

export const parseSelectedFilterOptions = (query: ParsedUrlQuery): SelectedFilterOptions => {
  const additionalFields = getKeys(query).filter(isStringAdditionalKey);
  const numberRangeAdditionalFields = getKeys(query).filter(isNumberRangeAdditionalKey);
  const percentageRangeAdditionalFields = getKeys(query).filter(isPercentageRangeAdditionalKey);
  const dateRangeAdditionalFields = getKeys(query).filter(isDateRangeAdditionalKey);

  return {
    // External employee filters only
    populations: parseNumericArray(query, "populations"),
    locations: parseNumericArray(query, "locations"),
    jobs: parseNumericArray(query, "jobs"),
    levels: parseNumericArray(query, "levels"),
    managers: parseNumericArray(query, "managers"),
    genders: parseArray(query, "genders"),
    businessUnits: parseArray(query, "business-units"),
    hireDate: parseArray(query, "hire-date") as [string, string],
    ...additionalFields.reduce((acc, key) => ({ ...acc, [key]: parseArray(query, key) }), {}),
    ...dateRangeAdditionalFields.reduce((acc, key) => ({ ...acc, [key]: parseDateArray(query, key) }), {}),
    ...numberRangeAdditionalFields.reduce((acc, key) => ({ ...acc, [key]: parseNumericArray(query, key, true) }), {}),
    ...percentageRangeAdditionalFields.reduce(
      (acc, key) => ({ ...acc, [key]: parseNumericArray(query, key, true) }),
      {}
    ),

    // Compensation Review employee filters only
    isPromoted: parseBooleanArray(query, "is-promoted"),
    reviewers: parseNumericArray(query, "reviewers"),

    // Salary range employee filters only
    compaRatio: parseNumericArray(query, "compa-ratio") as [number, number],
    rangePositionings: parseArray(query, "range-positionings"),

    // Shared filters
    performanceReviewRatings: parseNumericArray(query, "performance-ratings"),
  };
};

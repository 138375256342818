import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
export const getAllCustomRemunerationItem = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  return ctx.prisma.customRemunerationItem.findMany({
    where: {
      companyId: user.companyId,
    },
  });
};

export type GetAllCustomRemunerationItemResult = AsyncReturnType<typeof getAllCustomRemunerationItem>;

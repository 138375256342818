import {
  CompensationFrequency,
  type ExternalEmployeeSource,
  ExternalRemunerationStatus,
  type Prisma,
} from "@prisma/client";
import slugify from "slugify";
import { match } from "ts-pattern";
import { type CustomRemunerationItemMappingForSync } from "~/services/synchronization/syncExternalEmployees";

type EnrichedCustomRemunerationItemMapping = Pick<
  CustomRemunerationItemMappingForSync,
  "customRemunerationItemId" | "customRemunerationItem"
> & {
  value?: string | null;
};

export const mapCustomRemunerationItem = (
  integrationSettings: {
    source: ExternalEmployeeSource;
    companyId: number;
  },
  item: EnrichedCustomRemunerationItemMapping
) => {
  if (!item.value) {
    return null;
  }

  const multiplier = match(item.customRemunerationItem.frequency)
    .with(CompensationFrequency.MONTHLY, () => 12)
    .with(CompensationFrequency.QUARTERLY, () => 4)
    .with(CompensationFrequency.YEARLY, () => 1)
    .exhaustive();

  const amount = Math.round(parseInt(item.value) * multiplier);

  if (!amount) {
    return null;
  }

  const externalId = `custom-${slugify(item.customRemunerationItem.name).toLowerCase()}`;

  return {
    source: integrationSettings.source,
    externalId,
    amount: Math.round(amount * 100),
    status: ExternalRemunerationStatus.LIVE,
    customRemunerationItem: {
      connect: {
        id: item.customRemunerationItemId,
      },
    },
    nature: {
      connectOrCreate: {
        where: {
          companyId_source_externalId: {
            companyId: integrationSettings.companyId,
            source: integrationSettings.source,
            externalId,
          },
        },
        create: {
          source: integrationSettings.source,
          externalId,
          name: item.customRemunerationItem.name,
          mappedType: item.customRemunerationItem.type,
          mappedSubType: item.customRemunerationItem.subtype,
          company: {
            connect: {
              id: integrationSettings.companyId,
            },
          },
        },
      },
    },
  } satisfies Prisma.ExternalRemunerationItemCreateWithoutEmployeeInput;
};

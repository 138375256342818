import { type Prisma } from "@prisma/client";
import { meanBy } from "~/lib/lodash";

export const selectCompanyTargetPercentiles = {
  companyTargetPercentiles: { select: { jobFamilyId: true, value: true } },
  globalTargetPercentile: true,
  id: true,
  marketPositioning: true,
} satisfies Prisma.CompanySelect;

export type CompanyWithTargetPercentiles = Prisma.CompanyGetPayload<{ select: typeof selectCompanyTargetPercentiles }>;

export const approximateTargetPercentileRankFromJobFamilies = (params: {
  company: CompanyWithTargetPercentiles;
  jobFamilyIds: number[];
}) => {
  if (!params.jobFamilyIds.length) {
    return params.company.globalTargetPercentile;
  }

  return meanBy(params.jobFamilyIds, (jobFamilyId) => {
    const customTargetPercentile = params.company.companyTargetPercentiles.find(
      (targetPercentile) => targetPercentile.jobFamilyId === jobFamilyId
    );

    return customTargetPercentile?.value ?? params.company.globalTargetPercentile;
  });
};

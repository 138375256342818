import { type Currency, SalaryGridMeasure } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";
import { safePercentileRank } from "~/lib/math";
import { type ComputeSalaryRangePercentileRankInput } from "~/pages/api/salary-bands/compute-salary-range-percentile-rank";
import { getDefaultCurrency } from "~/services/currency";
import { computeEmployeeCompensation } from "~/services/employee";
import { whereSalaryRangeIs } from "~/services/salary-bands/access/helpers";
import { fetchLiveEmployeesForBenchmark } from "~/services/salary-bands/benchmark/fetchLiveEmployeesForBenchmark";

export const computeSalaryRangePercentileRank = async (
  ctx: AppContext,
  input: ComputeSalaryRangePercentileRankInput
) => {
  const currency = await getDefaultCurrency(ctx);

  const salaryRange = await ctx.prisma.salaryRange.findFirstOrThrow({
    where: whereSalaryRangeIs(input),
    select: {
      id: true,
      level: {
        select: {
          benchmarkedLevels: {
            select: {
              level: true,
            },
          },
        },
      },
      band: {
        select: {
          measure: true,
          marketPositioning: true,
          benchmarkedJobs: {
            select: {
              job: {
                select: {
                  id: true,
                  familyId: true,
                },
              },
            },
          },
          benchmarkedLocations: {
            select: {
              location: {
                select: { id: true },
              },
            },
          },
        },
      },
    },
  });

  const employees = await fetchLiveEmployeesForBenchmark(ctx, {
    jobIds: salaryRange.band.benchmarkedJobs.map((benchmarkedJob) => benchmarkedJob.job.id),
    locationIds: salaryRange.band.benchmarkedLocations.map((benchmarkedLocation) => benchmarkedLocation.location.id),
    levels: salaryRange.level.benchmarkedLevels.map((benchmarkedLevel) => benchmarkedLevel.level),
    marketPositioning: salaryRange.band.marketPositioning,
  });

  if (!employees.length) {
    return null;
  }

  return computeAmountPercentileRank({
    amount: input.amount,
    currency,
    employees,
    measure: salaryRange.band.measure,
  });
};

export const computeAmountPercentileRank = (params: {
  currency: Currency;
  employees: AsyncReturnType<typeof fetchLiveEmployeesForBenchmark>;
  amount: number;
  measure: SalaryGridMeasure;
}) => {
  const baseSalaries = params.employees.map((employee) =>
    computeEmployeeCompensation(employee, {
      measure: params.measure === SalaryGridMeasure.BASE_SALARY ? "baseSalary" : "onTargetEarnings",
      targetCurrency: params.currency,
    })
  );

  return safePercentileRank([...baseSalaries, params.amount], params.amount) || null;
};

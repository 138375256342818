import { type LevelFramework } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { uniq } from "~/lib/lodash";

export const fetchAllowedCompanyLevelFramework = async (ctx: AppContext) => {
  const publicLevelFrameworks = await ctx.prisma.levelFramework.findMany({
    where: {
      isPublic: true,
    },
  });

  let privateAllowedLevelFrameworks: LevelFramework[] = [];

  if (ctx.user) {
    const user = getRequiredUser(ctx);
    const allowedThroughCompany = await ctx.prisma.company.findUnique({
      where: {
        id: user.companyId,
      },
      select: {
        levelFrameworks_: true,
      },
    });

    privateAllowedLevelFrameworks = allowedThroughCompany?.levelFrameworks_ ?? [];
  }

  return uniq([...publicLevelFrameworks, ...privateAllowedLevelFrameworks]);
};

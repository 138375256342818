import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object } from "yup";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { createQuery } from "~/lib/reactQuery";
import {
  fetchCommandPaletteCommands,
  type FetchCommandPaletteCommandsResult,
} from "~/services/command-palette/fetchCommandPaletteCommands";

export const FetchCommandPaletteCommandsSchema = object({}).noUnknown();

const handler: NextApiHandler<FetchCommandPaletteCommandsResult> = async (req, res) => {
  const commands = await fetchCommandPaletteCommands(req);

  return res.status(HttpStatus.OK).json(commands);
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const FETCH_COMMAND_PALETTE_COMMANDS_QUERY_KEY: ApiRoute["pathname"] =
  "/api/command-palette/fetch-command-palette-commands";

export const useFetchCommandPaletteCommandsQuery = createQuery<
  typeof handler,
  typeof FetchCommandPaletteCommandsSchema
>({
  path: FETCH_COMMAND_PALETTE_COMMANDS_QUERY_KEY,
  schema: FetchCommandPaletteCommandsSchema,
});

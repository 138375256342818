import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, SalaryRangeStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const UpdateRangeInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.UPDATE_RANGE>().required(),
    bandId: number().required(),
    rangeId: number().nullable(),
    initialState: SalaryRangeStateSchema.required(),
    newState: SalaryRangeStateSchema.required(),
  })
);

export type UpdateRangeChange = YupOutputType<typeof UpdateRangeInputSchema>;

import { type PutObjectCommandInput } from "@aws-sdk/client-s3";
import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { logWarn } from "~/lib/logger";

export const uploadFile = async (ctx: AppContext, input: PutObjectCommandInput) => {
  try {
    await ctx.files.putObject(input);
  } catch (error) {
    logWarn(ctx, "[upload-file] Failed to upload file", { error, input });

    throw new BusinessLogicError(`Failed to upload file [${error.message}]`);
  }
};

import { CompanyType, EmployeeStatus, JobFamilyCategory, type Prisma } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { sumBy } from "~/lib/lodash";
import { logInfo } from "~/lib/logger";
import { ratioBy } from "~/lib/math";
import { getCompanyDashboardStats } from "~/services/company-dashboard/getCompanyDashboardStats";
import { getEuroCurrency } from "~/services/currency";
import { computeEmployeeCompensation, hasKnownGender } from "~/services/employee";

type UpdateCompanySurveyStatsOptions = {
  companyId: number;
};

export const updateCompanyStats = async (ctx: AppContext, options: UpdateCompanySurveyStatsOptions) => {
  const company = await ctx.prisma.company.findFirstOrThrow({
    where: {
      id: options.companyId,
      type: CompanyType.PARTICIPANT,
    },
    include: {
      stats: true,
      defaultCountry: {
        include: { locations: true },
      },
      employees: {
        where: { status: EmployeeStatus.LIVE },
        include: {
          currency: true,
          survey: true,
          job: {
            include: { family: true },
          },
          company: {
            include: { tags: true },
          },
          location: {
            include: {
              country: true,
              stats: true,
            },
          },
        },
      },
    },
  });

  logInfo(ctx, `[company-stats] Updating stats with ${company.employees.length} employees`, { companyId: company.id });

  const genderCompletionRate = ratioBy(company.employees, hasKnownGender());
  const dashboardStats = await getCompanyDashboardStats(ctx, { companyId: company.id });
  const euro = await getEuroCurrency(ctx);

  const totalPayroll = sumBy(company.employees, (employee) => {
    const baseAmount = computeEmployeeCompensation(employee, { targetCurrency: euro });

    return baseAmount * (1 + (employee.location.stats?.employmentCostRate ?? 0));
  });

  const payload = {
    genderCompletionRate,
    totalPayroll,
    compensationMeanDifferenceFromTarget: dashboardStats.compensationStats.meanPercentageDifferenceFromTarget,
    generalAndAdministrativeCompensationMeanDifferenceFromTarget:
      dashboardStats.compensationStatsByJobFamilyCategory.find(
        (stats) => stats.jobFamilyCategory === JobFamilyCategory.GENERAL_AND_ADMINISTRATIVE
      )?.stats.meanPercentageDifferenceFromTarget,
    techCompensationMeanDifferenceFromTarget: dashboardStats.compensationStatsByJobFamilyCategory.find(
      (stats) => stats.jobFamilyCategory === JobFamilyCategory.TECH
    )?.stats.meanPercentageDifferenceFromTarget,
    salesAndMarketingMeanDifferenceFromTarget: dashboardStats.compensationStatsByJobFamilyCategory.find(
      (stats) => stats.jobFamilyCategory === JobFamilyCategory.SALES_AND_MARKETING
    )?.stats.meanPercentageDifferenceFromTarget,
    payGapScore: dashboardStats.payGapStats?.score ?? null,
    topEarnersScore: dashboardStats.topEarnersStats?.score ?? null,
    managementScore: dashboardStats.managementStats?.score ?? null,
    marketFairnessScore: dashboardStats.marketFairnessStats?.score ?? null,
    genderEqualityIndex: dashboardStats.genderEqualityIndex ?? null,
  } satisfies Prisma.CompanyStatsCreateWithoutCompanies_Input;

  if (company.stats) {
    return ctx.prisma.companyStats.update({
      where: { id: company.stats.id },
      data: payload,
    });
  }

  return ctx.prisma.company.update({
    where: { id: company.id },
    data: {
      stats: { create: payload },
    },
  });
};

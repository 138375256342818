import { chain, sumBy } from "~/lib/lodash";
import { type EmployeesBucket } from "~/services/company-dashboard/generateEmployeesBuckets";

export const compileMatchedJobs = ({ buckets }: { buckets: EmployeesBucket[] }) => {
  return chain(buckets)
    .groupBy(({ job }) => job.id)
    .values()
    .flatMap((buckets) => {
      const { job } = buckets[0] as EmployeesBucket;
      const companyEmployeesCount = sumBy(buckets, ({ companyEmployees }) => companyEmployees.length);

      return { companyEmployeesCount, job };
    })
    .orderBy(({ companyEmployeesCount }) => companyEmployeesCount, "desc")
    .value();
};

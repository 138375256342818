import { Stack, Typography } from "@mui/material";
import { type Currency, Gender, type Prisma } from "@prisma/client";
import classNames from "classnames";
import React, { type PropsWithChildren, type ReactNode } from "react";
import CountryFlag from "react-country-flag";
import { Icon } from "~/components/ui/core/Icon";
import { type IconName } from "~/components/ui/Icons";
import { useMoney } from "~/hooks/useMoney";
import { useSession } from "~/hooks/useSession";
import { useI18n } from "~/lib/i18n/useI18n";
import { isString } from "~/lib/lodash";
import { computeEmployeeCompensation, formatEmployeeName } from "~/services/employee";
import { formatLevel } from "~/services/employee/employeeLevel";
import { formatGender } from "~/services/external-employee/gender";

export const employeeSelectForCard = {
  id: true,
  gender: true,
  firstName: true,
  lastName: true,
  externalId: true,
  employeeNumber: true,
  currency: true,
  level: true,
  baseSalary: true,
  fixedBonus: true,
  fixedBonusPercentage: true,
  onTargetBonus: true,
  onTargetBonusPercentage: true,
  job: {
    select: {
      id: true,
      name: true,
    },
  },
  location: {
    select: {
      id: true,
      name: true,
      country: {
        select: {
          id: true,
          alpha2: true,
          name: true,
        },
      },
    },
  },
} satisfies Prisma.EmployeeSelect;

export type EmployeeForCard = Prisma.EmployeeGetPayload<{
  select: typeof employeeSelectForCard;
}>;

type Props = {
  employee: EmployeeForCard;
  currency: Currency;
};

export const EmployeeCard: React.FC<Props> = ({ employee, currency }) => {
  const { t } = useI18n();
  const { user } = useSession();
  const targetMoney = useMoney(currency);
  const baseMoney = useMoney(employee.currency);

  const needsCurrencyConversion = employee.currency.code !== currency.code;

  return (
    <div className="flex w-96 flex-col p-1">
      <div className="m-[-9px] mb-0 rounded-t-sm bg-gradient-to-br from-primary-400 to-primary-600 py-3 px-2">
        <Typography variant="h3" className="!text-gray-50">
          {formatEmployeeName(employee)}
        </Typography>
      </div>

      <Stack gap={1} className="mt-1.5">
        <Line icon="hammer-wrench">
          {formatLevel(t, employee.level, user?.company.useAdvancedLevels ?? true)} {employee.job.name}
        </Line>

        <Line icon="gender">
          {employee.gender && (
            <span className="flex items-center">
              <span className="mr-1">{formatGender(t, employee.gender)}</span>
              <EmployeeDot gender={employee.gender} />
            </span>
          )}
          {!employee.gender && <span className="text-gray-400">Not provided</span>}
        </Line>

        <Line icon={<CountryFlag svg className="ml-1" countryCode={employee.location.country.alpha2} />}>
          {employee.location.name}, {employee.location.country.name}
        </Line>
      </Stack>

      <Stack className="mt-4" gap={1}>
        <Line icon="money-check">
          Base salary:{" "}
          {targetMoney.format(
            computeEmployeeCompensation(employee, { measure: "baseSalary", targetCurrency: currency }),
            {
              roundTo: 1,
            }
          )}
          {needsCurrencyConversion && (
            <span className="ml-1 flex space-x-0.5 text-gray-400">
              <span>
                (
                {baseMoney.format(
                  computeEmployeeCompensation(employee, { measure: "baseSalary", targetCurrency: employee.currency }),
                  { roundTo: 1 }
                )}
                )
              </span>
            </span>
          )}
        </Line>

        <Line icon="package">
          Total cash:{" "}
          {targetMoney.format(computeEmployeeCompensation(employee, { targetCurrency: currency }), { roundTo: 1 })}
          {needsCurrencyConversion && (
            <span className="ml-1 flex space-x-0.5 text-gray-400">
              <span>
                (
                {baseMoney.format(computeEmployeeCompensation(employee, { targetCurrency: employee.currency }), {
                  roundTo: 1,
                })}
                )
              </span>
            </span>
          )}
        </Line>
      </Stack>
    </div>
  );
};

type EmployeeDotProps = {
  gender: Gender | null;
};

const Line: React.FC<PropsWithChildren<{ icon: IconName | ReactNode }>> = ({ children, icon }) => {
  return (
    <Stack direction="row" gap={1.5} className="items-center">
      {isString(icon) ? <Icon name={icon as IconName} className="text-gray-400" /> : icon}
      <Typography variant="subtitle1" className="text-gray-700">
        {children}
      </Typography>
    </Stack>
  );
};

const EmployeeDot: React.FC<EmployeeDotProps> = ({ gender }) => {
  return (
    <div
      className={classNames({
        "block h-2.5 w-2.5 transform cursor-pointer border bg-gradient-to-br transition hover:scale-150  focus:outline-none":
          true,
        "rounded-full border-gender-none-800 from-gender-none-600 to-gender-none-700":
          gender === Gender.UNDISCLOSED || gender === null,
        "rotate-45 scale-90 border-gender-female-800 from-gender-female-600 to-gender-female-700":
          gender === Gender.FEMALE,
        "border-gender-male-800 from-gender-male-600 to-gender-male-700": gender === Gender.MALE,
      })}
    />
  );
};

import React, { createContext, useContext } from "react";

type HttpImpersonationHeaderProps = React.PropsWithChildren<{
  companyId: number;
  shouldUseHttpImpersonationHeader: boolean;
}>;

const HttpImpersonationHeaderContext = createContext<HttpImpersonationHeaderProps>({
  companyId: -1,
  shouldUseHttpImpersonationHeader: false,
});

export const useHttpImpersonationHeader = () => {
  return useContext(HttpImpersonationHeaderContext);
};

export const HttpImpersonationHeaderProvider: React.FC<HttpImpersonationHeaderProps> = ({
  companyId,
  shouldUseHttpImpersonationHeader,
  children,
}) => {
  return (
    <HttpImpersonationHeaderContext.Provider value={{ companyId, shouldUseHttpImpersonationHeader }}>
      {children}
    </HttpImpersonationHeaderContext.Provider>
  );
};

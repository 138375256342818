import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { createMutation } from "~/lib/reactQuery";
import { updateUserLocale } from "~/services/user/updateUserLocale";

const handler: NextApiHandler = async (req, res) => {
  const user = getRequiredUser(req);

  await updateUserLocale(req, user);

  return res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const useUpdateUserLocaleQuery = createMutation<typeof handler>({
  path: "/api/user/update-user-locale",
});

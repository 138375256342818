import { type AsyncReturnType } from "type-fest";
import { type AppContext } from "~/lib/context";

export const getJobsForPredictiveBenchmark = async (ctx: AppContext, params: { jobIds: number[] }) => {
  const jobs = await ctx.prisma.job.findMany({
    where: {
      id: { in: params.jobIds },
    },
  });

  return jobs.map((job) => ({
    ...job,
    name: job.translations?.["EN" as keyof typeof job.translations] ?? job.name,
  }));
};

export type JobsForPredictiveBenchmark = AsyncReturnType<typeof getJobsForPredictiveBenchmark>;

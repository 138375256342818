import { type PerformanceReviewRating } from "@prisma/client";
import React from "react";
import { TitledTooltip } from "~/components/ui/core/TitledTooltip";

type Props = {
  performanceRating: Pick<PerformanceReviewRating, "name" | "description">;
};

export const PerformanceRatingTooltip: React.FC<Props> = ({ performanceRating }) => {
  if (!performanceRating.description) return <>{performanceRating.name}</>;

  return (
    <TitledTooltip heading={performanceRating.name} text={performanceRating.description}>
      <span className="border-b border-dotted border-primary-500">{performanceRating.name}</span>
    </TitledTooltip>
  );
};

import linearInterpolator from "linear-interpolator";
import { isNil, zipWith } from "~/lib/lodash";
import { assertNotNil } from "~/lib/utils";

type InterpolatedRange = {
  target?: number | null;
  source: "original" | "interpolated";
};

export const interpolateMissingSalaryRangeTargetsForMeasure = <T extends { target?: number | null }>(
  ranges: (T | undefined)[]
) => {
  const hasRangesWithoutMarketData = ranges.some((salaryRange) => !salaryRange?.target);
  const hasTwoRangesWithMarketData = ranges.filter((salaryRange) => !isNil(salaryRange?.target)).length >= 2;

  if (!hasRangesWithoutMarketData || !hasTwoRangesWithMarketData) {
    return ranges.map((range) => ({ ...range, source: "original" }));
  }

  const orderedRanges = [...ranges].reverse();

  const points = orderedRanges
    .map((range, index) => {
      return [index, range?.target];
    })
    .filter((point) => !isNil(point[1])) as [number, number][];

  const interpolate = linearInterpolator(points);

  const interpolatedRanges: InterpolatedRange[] = orderedRanges.map((range, index) => {
    if (isNil(range?.target)) {
      return {
        ...range,
        target: interpolate(index),
        source: "interpolated",
      };
    }

    const originalRange = assertNotNil(range);

    return {
      ...originalRange,
      source: "original",
    };
  });

  interpolatedRanges.forEach((range, index, interpolatedRanges) => {
    if (range.source === "original" || isNil(range.target) || index === 0) {
      return;
    }

    const previousRangeTarget = interpolatedRanges[index - 1]?.target;

    if (!isNil(previousRangeTarget) && range.target < previousRangeTarget) {
      range.target = previousRangeTarget;
    }
  });

  return interpolatedRanges.reverse();
};

export const interpolateMissingSalaryRangeTargets = <
  T extends { target?: number | null },
  U extends { baseSalary?: T; onTargetEarnings?: T },
>(
  salaryRangesMarketData: U[]
) => {
  const baseSalaryRanges = salaryRangesMarketData.map((range) => range.baseSalary);
  const onTargetRanges = salaryRangesMarketData.map((range) => range.onTargetEarnings);

  const interpolatedBaseSalaryRanges = interpolateMissingSalaryRangeTargetsForMeasure(baseSalaryRanges);
  const interpolatedOnTargetRanges = interpolateMissingSalaryRangeTargetsForMeasure(onTargetRanges);

  return zipWith(
    interpolatedBaseSalaryRanges,
    interpolatedOnTargetRanges,
    salaryRangesMarketData,
    (baseSalary, onTargetEarnings, salaryRangeMarketData) => ({
      ...salaryRangeMarketData,
      baseSalary,
      onTargetEarnings,
    })
  );
};

import { cloneContext } from "~/lib/cloning";
import { type AppContext } from "~/lib/context";
import { next, type MiddlewareContext } from "~/lib/middleware/middleware";

export const cloneContextToRequest = async (ctx: MiddlewareContext, options: { context: AppContext }) => {
  if (process.env.IS_JEST) return next(ctx);

  Object.assign(ctx.req, cloneContext(options.context));

  return next(ctx);
};

import { type Company, type EmployeeLevel } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { chain } from "~/lib/lodash";
import { getMergedLevels } from "~/services/employee/employeeLevel";
import { getLiveEmployees } from "~/services/employee/getLiveEmployees";

export const getDashboardEmployees = async (
  ctx: AppContext,
  params: { company: Pick<Company, "id" | "useAdvancedLevels">; comparableCompanyIds: number[] }
) => {
  const { company, comparableCompanyIds } = params;
  const mergeAdvancedLevels = !company.useAdvancedLevels;

  const employeesForFilters = await ctx.prisma.employee.findMany({
    where: {
      companyId: company.id,
      status: "LIVE",
    },
    select: {
      jobId: true,
      level: true,
      location: {
        select: {
          id: true,
          countryId: true,
        },
      },
    },
  });

  if (!employeesForFilters.length) {
    return [];
  }

  const buckets = chain(employeesForFilters)
    .flatMap(({ jobId, level: employeeLevel, location }) => {
      const mergedLevels = getMergedLevels(employeeLevel, { mergeAdvancedLevels }) as EmployeeLevel[];

      return mergedLevels.map((level) => ({ countryId: location.countryId, jobId, level }));
    })
    .compact()
    .uniqBy(({ countryId, jobId, level }) => [countryId, jobId, level].join(","))
    .value();

  const employees = await getLiveEmployees(ctx, { buckets, companyIds: comparableCompanyIds });

  // Rebuilding the object seems to drastically improve the performances of all the data processing code.
  // I'm still not sure why.
  return JSON.parse(JSON.stringify(employees));
};

import { CUSTOM_JOB_PREFIX } from "~/services/custom-jobs/customJobPrefix";

export const splitCustomJobIds = (mixedJobIds: string[]) => {
  const jobIds = mixedJobIds.filter((jobId) => !jobId.startsWith(CUSTOM_JOB_PREFIX)).map((jobId) => parseInt(jobId));
  const customJobIds = mixedJobIds
    .filter((jobId) => jobId.startsWith(CUSTOM_JOB_PREFIX))
    .map((jobId) => parseInt(jobId.replace(CUSTOM_JOB_PREFIX, "")));

  return {
    jobIds,
    customJobIds,
  };
};

import { type Company, CompanyImpersonationLogAction } from "@prisma/client";
import { addDays } from "date-fns";
import { type AsyncReturnType } from "type-fest";
import { config } from "~/config";
import { type AppContext } from "~/lib/context";
import { notifyImpersonationAccessApproval } from "~/lib/external/slack/notifications";
import { findImpersonationAccess } from "~/services/impersonation/findImpersonationAccess";

export const IMPERSONATION_ACCESS_DURATION_IN_DAYS = 60;
const IMPERSONATION_ACCESS_ASSISTED_ONBOARDING_DURATION_IN_DAYS = 30;

export const hasImpersonationAccess = async (
  ctx: AppContext,
  params: {
    companyId: number;
    logAccess?: boolean;
    logMessage?: string;
  }
) => {
  // Allow impersonation in all envs but production
  if (config.app.env !== "test" && !config.app.isProduction) {
    return true;
  }

  const exceptionalImpersonationAccess = await ctx.prisma.exceptionalImpersonationLog.findFirst({
    where: {
      companyId: params.companyId,
      expiresAt: { gte: new Date() },
    },
  });

  if (!!exceptionalImpersonationAccess) {
    return true;
  }

  const impersonationAccess = await findImpersonationAccess(ctx, { companyId: params.companyId });

  if (!!impersonationAccess && params.logAccess) {
    await ctx.prisma.companyImpersonationAccess.update({
      data: {
        logs: {
          create: {
            userId: ctx.user?.id ?? null,
            message: params.logMessage ?? "Impersonation requested",
            action: CompanyImpersonationLogAction.ACCESS,
          },
        },
      },
      where: { id: impersonationAccess.id },
    });
  }

  return !!impersonationAccess;
};

export type HasImpersonationAccess = AsyncReturnType<typeof hasImpersonationAccess>;

export const createAssistedOnboardingImpersonationAccess = async (ctx: AppContext, company: Company) => {
  const accessData = await ctx.prisma.companyImpersonationAccess.create({
    data: {
      expiresAt: addDays(new Date(), IMPERSONATION_ACCESS_ASSISTED_ONBOARDING_DURATION_IN_DAYS),
      companyId: company.id,
      logs: {
        create: {
          userId: ctx.user?.id ?? undefined,
          message: "Default access for setup and implementation period",
          action: CompanyImpersonationLogAction.CREATION,
        },
      },
    },
  });

  await notifyImpersonationAccessApproval(ctx, { company, accessData });
};

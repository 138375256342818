import { useEffect, useState } from "react";

const DEFAULT_THRESHOLD_IN_MS = 200;

export const useDelayedLoadingState = (params: { value: boolean; threshold?: number }) => {
  const [delayedTrue, setDelayedTrue] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (params.value) {
      timer = setTimeout(() => setDelayedTrue(true), params.threshold ?? DEFAULT_THRESHOLD_IN_MS);
    } else {
      setDelayedTrue(false);

      if (timer) {
        clearTimeout(timer);
      }
    }

    // Cleanup timeout if input changes or component unmounts
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [params.value]);

  return delayedTrue;
};

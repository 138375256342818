import { type Currency } from "@prisma/client";
import AnimatedNumber from "animated-number-react";
import classNames from "classnames";
import React from "react";
import { useMoney } from "~/hooks/useMoney";
import { convertCurrency } from "~/lib/money";

type Props = {
  amount: number;
  currency: Pick<Currency, "code" | "euroExchangeRate">;
  convertToCurrency?: Pick<Currency, "code" | "euroExchangeRate">;
  roundTo?: number;
  animated?: boolean;
  signed?: boolean;
  direction?: "row" | "column";
  className?: string;
};

export const Money: React.FC<Props> = ({
  amount,
  currency,
  convertToCurrency,
  roundTo = 1,
  animated = false,
  signed = false,
  direction = "column",
  className,
}) => {
  const money = useMoney(currency);
  const baseMoney = useMoney(convertToCurrency ?? currency);

  const requiresCurrencyConversion = !!convertToCurrency && currency.code !== convertToCurrency.code;

  return (
    <span
      className={classNames(className, {
        "inline-flex": true,
        "flex-row space-x-2": direction === "row",
        "flex-col": direction === "column",
      })}
    >
      <span className={classNames({ "text-gray-400": requiresCurrencyConversion })}>
        {animated && (
          <AnimatedNumber
            duration={150}
            formatValue={(amount) => {
              return money.format(amount, { roundTo });
            }}
            value={amount}
          />
        )}
        {!animated && (
          <>
            {signed && amount > 0 && "+"}
            {money.format(amount, { roundTo })}
          </>
        )}
      </span>
      {convertToCurrency && requiresCurrencyConversion && (
        <span>
          {signed && convertCurrency(amount, currency, convertToCurrency) > 0 && "+"}
          <>{baseMoney.format(convertCurrency(amount, currency, convertToCurrency), { roundTo })}</>
        </span>
      )}
    </span>
  );
};

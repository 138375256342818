import { value } from "~/components/helpers";
import { type AppContext } from "~/lib/context";
import { isNumber } from "~/lib/lodash";

export const computeImpersonationStatus = (ctx: AppContext) => {
  const userImpersonationStatus = value(() => {
    if (!ctx.impersonation?.user) {
      return { impersonatingUser: false } as const;
    }

    return {
      impersonatingUser: true,
      impersonatingUserBy: ctx.impersonation.user.impersonatedBy,
      impersonatingUserFor: ctx.impersonation.user.target,
    } as const;
  });

  const companyImpersonationStatus = value(() => {
    if (!isNumber(ctx.impersonation?.companyId)) {
      return { impersonatingCompany: false } as const;
    }

    return {
      impersonatingCompany: true,
      impersonatingCompanyId: ctx.impersonation.companyId,
    } as const;
  });

  return { ...userImpersonationStatus, ...companyImpersonationStatus };
};

export type ImpersonationStatus = ReturnType<typeof computeImpersonationStatus>;

import { SalaryGridMeasure, type SalaryRangeEmployee } from "@prisma/client";
import { match } from "ts-pattern";
import { roundTo } from "~/lib/math";

export const computeRangePenetration = (params: { amount: number; min: number; max: number }) => {
  if (params.max - params.min <= 0) {
    return null;
  }
  const min = roundTo(params.min, 10_000);
  const max = roundTo(params.max, 10_000);

  return (params.amount - min) / (max - min);
};

export const getRangePenetrationForMeasure = (
  salaryRangeEmployee: Pick<SalaryRangeEmployee, "baseSalaryRangePenetration" | "onTargetEarningsRangePenetration">,
  measure: SalaryGridMeasure
) =>
  match(measure)
    .with(SalaryGridMeasure.BASE_SALARY, () => salaryRangeEmployee.baseSalaryRangePenetration)
    .with(SalaryGridMeasure.ON_TARGET_EARNINGS, () => salaryRangeEmployee.onTargetEarningsRangePenetration)
    .exhaustive();

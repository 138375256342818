import { EmployeeStatus, type Prisma, RemappingRequestStatus } from "@prisma/client";
import { type AsyncReturnType } from "type-fest";
import { type EmployeeForBadgeList, selectForEmployeeBadgeList } from "~/components/ui/employee/EmployeeBadgeList";
import { type AppContext } from "~/lib/context";
import { getRequiredUser } from "~/lib/getRequiredUser";
export const getCompanyRemappingRequest = async (ctx: AppContext) => {
  const user = getRequiredUser(ctx);

  const remappingRequests = await ctx.prisma.remappingRequest.findMany({
    where: {
      companyId: user.companyId,
      status: RemappingRequestStatus.PENDING,
    },
    select: {
      id: true,
      createdAt: true,
      status: true,
      company: {
        select: {
          id: true,
          name: true,
        },
      },
      newJobId: true,
      externalJob: {
        select: {
          name: true,
        },
      },
      previousJob: {
        select: {
          id: true,
          name: true,
        },
      },
    },
  });

  const remappingRequestsWithAdditionalInfo = await Promise.all(
    remappingRequests.map(async (remappingRequest) => {
      const { newJobId, previousJob } = remappingRequest;

      const newJob = await ctx.prisma.job.findUnique({
        where: {
          id: newJobId,
        },
        select: {
          name: true,
        },
      });

      let impactedEmployees: EmployeeForBadgeList[] = [];
      let count = 0;

      if (previousJob?.id) {
        const whereClause = {
          companyId: user.companyId,
          status: EmployeeStatus.LIVE,
          jobId: previousJob.id,
          externalJobTitle: remappingRequest.externalJob.name,
        } satisfies Prisma.EmployeeWhereInput;

        [impactedEmployees, count] = await Promise.all([
          ctx.prisma.employee.findMany({
            take: 5,
            where: {
              ...whereClause,
            },
            select: {
              ...selectForEmployeeBadgeList,
            },
          }),
          ctx.prisma.employee.count({
            where: {
              ...whereClause,
            },
          }),
        ]);
      }

      return {
        ...remappingRequest,
        newJobName: newJob?.name,
        impactedEmployees,
        impactedEmployeesCount: count,
      };
    })
  );

  return remappingRequestsWithAdditionalInfo;
};

export type GetCompanyRemappingRequestResult = AsyncReturnType<typeof getCompanyRemappingRequest>;

import React from "react";
import { formatPercent } from "~/lib/math";

type Props = {
  to: number | null;
  compare: number | null;
  decimals?: number;
  literal?: boolean;
};

export const PercentageDifference: React.FC<Props> = ({ to, compare, decimals, literal }) => {
  if (!to || !compare) {
    return null;
  }

  const diff = compare / to - 1;
  const sign = diff < 0 ? "-" : "+";

  return (
    <>
      {!literal && sign}
      {formatPercent(Math.abs(diff), decimals)}
      {literal && (
        <>
          {diff > 0 && " more"}
          {diff < 0 && " less"}
        </>
      )}
    </>
  );
};

import { type TFunction } from "i18next";
import { match } from "ts-pattern";

export const PaletteCommandType = {
  EMPLOYEE: "EMPLOYEE",
  SALARY_BAND: "SALARY_BAND",
  NAVIGATION: "NAVIGATION",
  HELP_CENTER: "HELP_CENTER",
  ADVANCED_ACTIONS: "ADVANCED_ACTIONS",
} as const;

export type PaletteCommandType = (typeof PaletteCommandType)[keyof typeof PaletteCommandType];

export const formatPaletteCommandType = (t: TFunction, type: PaletteCommandType) => {
  return match(type)
    .with(PaletteCommandType.EMPLOYEE, () => ({
      label: t("enum.palette-command-type.employee"),
      group: t("enum.palette-command-type.employee-group"),
    }))
    .with(PaletteCommandType.SALARY_BAND, () => ({
      label: t("enum.palette-command-type.salary-band"),
      group: t("enum.palette-command-type.salary-band-group"),
    }))
    .with(PaletteCommandType.NAVIGATION, () => ({
      label: t("enum.palette-command-type.navigation"),
      group: t("enum.palette-command-type.navigation-group"),
    }))
    .with(PaletteCommandType.HELP_CENTER, () => ({
      label: t("enum.palette-command-type.help-center"),
      group: t("enum.palette-command-type.help-center-group"),
    }))
    .with(PaletteCommandType.ADVANCED_ACTIONS, () => ({
      label: t("enum.palette-command-type.advanced-actions"),
      group: t("enum.palette-command-type.advanced-actions-group"),
    }))
    .exhaustive();
};

import classNames from "classnames";
import React from "react";
import { Icon } from "~/components/ui/core/Icon";
import { useAlerts } from "~/hooks/useAlerts";

const Colors = {
  success: "border-primary-200 bg-primary-100 text-primary-800 z-[99]",
  error: "border-secondary-200 bg-secondary-100 text-secondary-900",
};

export const Alerts: React.FC = () => {
  const { alerts } = useAlerts();

  if (!alerts.length) {
    return null;
  }

  return (
    <div className="pointer-events-none fixed bottom-0 right-0 left-0 z-[1300] m-2 mx-auto max-w-xl">
      <div className="pointer-events-auto flex flex-col items-center space-y-1">
        {alerts.map((alert, index) => {
          return (
            <div
              key={index}
              className={classNames(Colors[alert.type], {
                "flex items-center space-x-2 rounded border py-2 px-3 shadow": true,
              })}
            >
              <Icon name={alert.icon} size="lg" />
              <span className="flex flex-col space-y-1 whitespace-pre">{alert.message}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

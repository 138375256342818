import { type SalaryGridConfigurationChangeLabel } from "@prisma/client";
import { mixed, number, object } from "yup";
import { type YupOutputType } from "~/lib/utils";
import { BaseConfigurationChangeSchema, SalaryBandStateSchema } from "~/services/salary-bands/audit-logs/schemas";

export const CreateBandInputSchema = BaseConfigurationChangeSchema.concat(
  object({
    label: mixed<typeof SalaryGridConfigurationChangeLabel.CREATE_BAND>().required(),
    bandId: number().required(),
    newState: SalaryBandStateSchema.required(),
  })
);

export type CreateBandChange = YupOutputType<typeof CreateBandInputSchema>;

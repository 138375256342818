import {
  type DefinedUseQueryResult,
  type QueryClient,
  type QueryKey,
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type FormikHelpers } from "formik";
import { type NextApiHandler } from "next";
import { useRef } from "react";
import { type AnySchema } from "yup";
import { value } from "~/components/helpers";
import { type ApiRoute, useApi } from "~/hooks/useApi";
import { compact, isObject } from "~/lib/lodash";
import { type YupInputType, type YupOutputType } from "~/lib/utils";

export const createQuery = <
  Handler extends NextApiHandler<unknown>,
  ValidationSchema extends AnySchema = never,
  ResponseType = Handler extends NextApiHandler<infer ResponseType> ? ResponseType : unknown,
>(params: {
  path: ApiRoute["pathname"];
  schema?: ValidationSchema;
  options?: (params: { queryClient: QueryClient }) => UseQueryOptions<ResponseType, unknown>;
}) => {
  const useQueryWrapper = (config?: {
    input?: YupInputType<ValidationSchema>;
    options?: UseQueryOptions<ResponseType, unknown> & { paginated?: boolean };
  }) => {
    const { apiFetch } = useApi();
    const queryClient = useQueryClient();

    const input = value(() => {
      if (!config?.input) {
        return {};
      }

      if (config?.options?.enabled === false) {
        return {};
      }

      return params.schema ? params.schema.validateSync(config.input) : config.input;
    });

    const options = {
      ...params.options?.({ queryClient }),
      refetchOnWindowFocus: false,
      ...config?.options,
    };

    if (options.initialData) {
      options.keepPreviousData = true;
      options.refetchOnMount = false;
      options.cacheTime = 0;
    }

    const initialDataRef = useRef(options.initialData);

    if (options.paginated) {
      options.initialData = initialDataRef.current;
    }

    const query = useQuery({
      queryKey: [params.path, config?.input],
      queryFn: ({ signal }) => {
        return apiFetch<ResponseType>(params.path, {
          method: "POST",
          signal,
          body: input,
        });
      },
      ...options,
    });

    if (options.paginated) {
      initialDataRef.current = query.data;
    }

    return query;
  };

  return useQueryWrapper as typeof UseQueryWrapper<YupInputType<ValidationSchema>, ResponseType>;
};

export const createMutation = <
  Handler extends NextApiHandler<unknown>,
  ValidationSchema extends AnySchema = AnySchema,
  ResponseType = Handler extends NextApiHandler<infer ResponseType> ? ResponseType : unknown,
>(params: {
  path: ApiRoute["pathname"];
  schema?: ValidationSchema;
  contentType?: "application/json" | "multipart/form-data";
  options?: (params: {
    queryClient: QueryClient;
  }) => UseMutationOptions<ResponseType, unknown, YupOutputType<ValidationSchema>>;
}) => {
  return (
    options?: UseMutationOptions<ResponseType, unknown, YupOutputType<ValidationSchema>> & {
      successMessage?: string;
      setErrors?: FormikHelpers<unknown>["setErrors"];
      query?: ApiRoute["query"];
    }
  ) => {
    const { apiFetch } = useApi();
    const queryClient = useQueryClient();
    const { successMessage, setErrors, query, ...reactQueryOptions } = options ?? {};
    const computedOptions = params.options?.({ queryClient });

    return useMutation({
      mutationFn: (variables) => {
        let input: unknown;

        //handling spreadsheet import
        if (params.contentType === "multipart/form-data" && isObject(variables)) {
          const formData = new FormData();
          Object.entries(variables).forEach(([key, value]) => {
            if (value === null || value === undefined) {
              return;
            }

            formData.append(key, value);
          });
          input = formData;
        }

        //handling validation without transforming the data
        if (params.schema && params.contentType !== "multipart/form-data") {
          params.schema.validateSync(variables);
          input = variables;
        }

        return apiFetch<ResponseType>(params.path, {
          method: "POST",
          body: input,
          query,
          successMessage,
          setErrors,
        });
      },
      ...computedOptions,
      ...reactQueryOptions,
      onSuccess: (data: ResponseType, variables: YupOutputType<ValidationSchema>, context) => {
        return Promise.all([
          options?.onSuccess?.(data, variables, context),
          computedOptions?.onSuccess?.(data, variables, context),
        ]);
      },
      onError: (data: ResponseType, variables: YupOutputType<ValidationSchema>, context) => {
        return Promise.all([
          options?.onError?.(data, variables, context),
          computedOptions?.onError?.(data, variables, context),
        ]);
      },
      onMutate: (variables: YupOutputType<ValidationSchema>) => {
        return Promise.all([options?.onMutate?.(variables), computedOptions?.onMutate?.(variables)]);
      },
      onSettled: (data, error, variables, context) => {
        return Promise.all([
          options?.onSettled?.(data, error, variables, context),
          computedOptions?.onSettled?.(data, error, variables, context),
        ]);
      },
    });
  };
};

export const invalidateQuery = async (
  queryClient: QueryClient,
  queryKey: ApiRoute["pathname"] | readonly unknown[],
  ...queries: Record<string, unknown>[]
) => {
  await queryClient.invalidateQueries(compact([queryKey as QueryKey, ...queries]));
};

function UseQueryWrapper<
  TInput = unknown,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(config?: {
  input?: TInput;
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "initialData"> & {
    initialData: TQueryFnData | (() => TQueryFnData);
    paginated?: boolean;
  };
}): DefinedUseQueryResult<TData, TError>;

function UseQueryWrapper<
  TInput = unknown,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(config?: {
  input?: TInput;
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "placeholderData"> & {
    placeholderData: TQueryFnData | (() => TQueryFnData);
    paginated?: boolean;
  };
}): DefinedUseQueryResult<TData, TError>;

function UseQueryWrapper<
  TInput = unknown,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(config?: {
  input?: TInput;
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { paginated?: boolean };
}): UseQueryResult<TData, TError>;

function UseQueryWrapper<
  TInput = unknown,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(config?: {
  input?: TInput;
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { paginated?: boolean };
}) {
  return useQuery(config?.options ?? {});
}

import { ExternalEmployeeStatus } from "@prisma/client";
import { type AppContext } from "~/lib/context";
import { salaryRangeExternalEmployeeSelect } from "~/services/salary-bands/access/fetchSalaryRange";

export const fetchCompanyExternalEmployees = async (ctx: AppContext, params: { companyId: number }) => {
  return ctx.prisma.externalEmployee.findMany({
    where: {
      companyId: params.companyId,
      status: { not: ExternalEmployeeStatus.SKIPPED },
    },
    select: salaryRangeExternalEmployeeSelect,
  });
};

import { useRouter } from "next/router";
import NProgress from "nprogress";
import type React from "react";
import { useEffect } from "react";

export const GlobalProgressBar: React.FC = () => {
  const router = useRouter();

  useEffect(() => {
    let previousRoute = router.route;

    let nprogressTimeout: NodeJS.Timeout | null = null;
    NProgress.configure({
      showSpinner: false,
    });

    router.events.on("routeChangeStart", (newRoute) => {
      if (nprogressTimeout) {
        clearTimeout(nprogressTimeout);
      }

      if (previousRoute === newRoute) {
        return;
      }

      nprogressTimeout = setTimeout(() => {
        NProgress.start();
      }, 200);
    });

    router.events.on("routeChangeComplete", (newRoute) => {
      const previousPath = previousRoute.split("?")[0];
      const newPath = newRoute.split("?")[0];

      if (previousPath !== newPath) {
        document.querySelector("#app")?.scrollTo?.(0, 0);
      }

      if (nprogressTimeout) {
        clearTimeout(nprogressTimeout);
      }

      previousRoute = newRoute;

      NProgress.done();
    });

    router.events.on("routeChangeError", () => {
      if (nprogressTimeout) {
        clearTimeout(nprogressTimeout);
      }

      NProgress.done();
    });
  }, []);

  return null;
};

import HttpStatus from "http-status-codes";
import type { NextApiResponse } from "next";
import type { AppContext } from "~/lib/context";
import { logError } from "~/lib/logger";

type GithubErrorContext = Record<string, string | number>;

export class GithubError extends Error {
  public readonly context: GithubErrorContext;

  constructor(message: string, context: GithubErrorContext) {
    super(message);
    Object.setPrototypeOf(this, GithubError.prototype);
    this.context = context;
  }
}

export const handleGithubError = (ctx: AppContext, res: NextApiResponse, error: GithubError) => {
  logError(ctx, "[error] Github error", { error });

  return res.status(HttpStatus.BAD_REQUEST).json({ error: error.message });
};
